import React from 'react';
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import classNames from 'classnames';
import JExcelComponent from "./JExcelComponent";
import jexcel from 'jexcel';
import { DatePicker } from "@material-ui/pickers";
import { arrayInsert, arrayRemove, clone, formatDate, getCellsFromFormula, hashCode } from "../shared/Helpers";
import NumberFormat from "react-number-format";

import TooltipIconButton from "../shared/TooltipIconButton";
import MaskedInput from 'react-text-mask';
import Dialog from "@material-ui/core/Dialog";
import { DialogContent, DialogTitle } from "../shared/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import ReactDOMServer from "react-dom/server";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import EventIcon from '@material-ui/icons/Event';
import BaseComponent from "../shared/BaseComponent";

const buttonStyle = { display: 'block', textAlign: 'center', width: '1.313rem', height: '1.313rem', fontWeight: 'bold', fontSize: '1.313rem', lineHeight: '1.3rem' };
const dateFormat = 'yyyy-MM-dd';
const yearFormat = 'yyyy';

class TableAnswer extends BaseComponent {
    ref = React.createRef();
    jexcel = null;
    cellValue = '';
    state = {
        values: [],
        selection: null,
        dialogData: null,
        datePicker: null,
    };
    details = [];
    editors = {};
    options = {
        editable: true,
        allowDeleteColumn: false,
        allowInsertColumn: false,
        allowManualInsertColumn: false,
        allowInsertRow: false,
        allowManualInsertRow: false,
        allowDeleteRow: false,
        wordWrap: true,
        columnResize: false,
    };
    onChangeEnabled = true;

    shouldComponentUpdate(nextProps, nextState) {
        if (['no_data', 'unrelated'].includes(nextProps.answerDetails)) {
            this.onChangeEnabled = false;
        }

        return true;
    }

    getTableDefaultOptions = () => {
        return Object.assign(this.options, {
            onselection: this.selectionHandler,
            updateCell: this.updateCell,
            onchange: this.changeHandler, // it's called on every cell changed!
            oninsertrow: this.insertRowHandler,
            ondeleterow: this.deleteRowHandler,
            onblur: () => this.setState({ selection: null }),
        });
    };

    getTableOptions = () => {
        return {};
    };

    componentDidMount() {
        this.jexcel = this.ref.current.jexcel;
        this.jexcel.ignoreHistory = true;
        this.forceUpdate();
    }

    insertRowHandler = (el, rowNumber, numOfRows, rowRecords, insertBefore) => {
        for (let i = insertBefore ? rowNumber : ++rowNumber; i < rowNumber + numOfRows; ++i) {
            arrayInsert(this.details, i, [new Array(this.jexcel.options.columns.length).fill(null)]);
        }
        this.changeHandler();
    };

    deleteRowHandler = (el, rowIndex, numOfRows, rowRecords) => {
        for (let i = rowIndex; i < rowIndex + numOfRows; ++i) {
            arrayRemove(this.details, i);
        }

        this.changeHandler();
    };

    changeHandler = (instance, cell, x, y, value, action) => {
        if (!this.jexcel) {
            return;
        }
        this.jexcel.updateTableReferences(); // needed to recalculate all formulas

        if (action === 'delete') { // all parameters will be set when change comes from jExcel
            this.details[y][x] = null;
        }

        let data = this.jexcel.getData();
        data.forEach((row, y) => {
            row.forEach((v, x) => {
                if (typeof v === 'object' || (this.details[y] && this.details[y][x])) {
                    if (this.details[y] && ['no_data', 'unrelated'].includes(this.details[y][x])) {
                        data[y][x] = { value: '', details: this.details[y][x] };
                    } else {
                        data[y][x] = { value: data[y][x], details: this.details[y][x] };
                    }
                }
            });
        });

        if (this.onChangeEnabled) {
            this.props.onChange(data);
        }
    };

    getCellInput = (cellMeta) => {
        const { classes } = this.props;
        const hash = hashCode(cellMeta);

        if (this.editors[hash] === undefined) {
            const { type, config } = cellMeta;

            this.editors[hash] = {
                openEditor: (cell, empty, event) => {
                    // Get cell position
                    const y = cell.getAttribute('data-y');
                    const x = cell.getAttribute('data-x');
                    const value = this.jexcel.options.data[y][x];

                    const estimatedComponent = !this.props.details.includes('estimated') ? '' : <TooltipIconButton
                        className={classNames("estimatedButton", classes.button, classes.estimatedButton, this.details[y][x] === 'estimated' ? classes.buttonActive : null)}
                        title="Wartość szacunkowa"
                        size="small"
                        disabled={this.props.disabled}
                        /* onClick={() => {}} // doesn't work */ >
                        <span style={buttonStyle}>～</span>
                    </TooltipIconButton>;

                    const noDataComponent = !this.props.details.includes('no_data') ? '' : <TooltipIconButton
                        className={classNames("noDataButton", classes.button, classes.noDataButton, this.details[y][x] === 'no_data' ? classes.buttonActive : null)}
                        title="Brak danych"
                        size="small"
                        disabled={this.props.disabled}
                        /* onClick={() => {}} // doesn't work */ >
                        <span style={buttonStyle}>∅</span>
                    </TooltipIconButton>;

                    let unrelatedButton = '';
                    let noDataButton = '';
                    let estimatedButton = '';
                    let calendarButton = '';
                    if (['year', 'date'].includes(type)) {
                        calendarButton = <TooltipIconButton
                            className={classNames("calendarButton", classes.button, classes.calendarButton)}
                            title="Kalendarz"
                            size="small"
                            disabled={this.props.disabled}
                            /* onClick={() => {}} // doesn't work */ >
                            <EventIcon/>
                        </TooltipIconButton>;
                    }
                    switch (type) {
                        case 'boolean':
                        case 'choice_single':
                        case 'choice_multiple':
                            this.cellValue = value || {};
                            this.setState({ dialogData: { choices: config.choices, type, y, x } });
                        case  'label':
                        case  'undefined':
                        default:
                            this.jexcel.edition = null;
                            return;
                        case  'formula':
                            const { unrelated_no_data } = this.getFormulaDetails(config);
                            if (!unrelated_no_data) {
                                this.jexcel.edition = null;
                                return;
                            }
                            noDataButton = noDataComponent;
                            estimatedButton = estimatedComponent;
                            break;
                        case 'number':
                            estimatedButton = estimatedComponent;
                        case  'date':
                        case  'year':
                        case  'text':
                        case  'open':
                        case  'regexp':
                        case  'mask':
                        case  'email':
                            noDataButton = noDataComponent;
                            unrelatedButton = !this.props.details.includes('unrelated') ? '' : <TooltipIconButton
                                className={classNames("unrelatedButton", classes.button, classes.unrelatedButton, this.details[y][x] === 'unrelated' ? classes.buttonActive : null)}
                                title="Nie dotyczy"
                                size="small"
                                disabled={this.props.disabled}
                                /* onClick={() => {}} // doesn't work */ >
                                <span style={buttonStyle}>ⓧ</span>
                            </TooltipIconButton>;
                    }
                    this.cellValue = empty === true ? '' : value;

                    // Overflow
                    if (x > 0) {
                        this.jexcel.records[y][x - 1].style.overflow = 'hidden';
                    }
                    // Edit cell
                    cell.classList.add('editor');
                    cell.innerHTML = '';

                    let input = this.getInputForCell(cell, cellMeta, event);

                    this.props.onFocus();

                    ReactDOM.render(<>{input}
                        {estimatedButton}
                        {unrelatedButton}
                        {noDataButton}
                        {calendarButton}
                    </>, cell);
                },
                closeEditor: this.closeEditor(type),
                setValue: (cell, value, force, action) => {
                    const y = cell.getAttribute('data-y');
                    const x = cell.getAttribute('data-x');

                    if (value.length && ['no_data', 'unrelated'].includes(this.details[y][x])) {
                        if (type === 'formula') {
                            this.details[y][x] = 'override';
                        } else {
                            this.details[y][x] = null;
                        }
                    }
                    cell.innerHTML = value;
                },
            };
        }

        return this.editors[hash];
    };

    setChoiceValue = (choiceId) => event => {
        let choices = this.cellValue;
        choices[choiceId] = event.target.value;
        this.cellValue = choices;

        this.forceUpdate();
    };

    setChoice = event => {
        const { dialogData } = this.state;
        const { type, y, x } = dialogData;
        let value = event.target.value;

        let choices = {};
        switch (type) {
            case 'choice_multiple':
                choices = clone(this.cellValue);
            case 'choice_single':
                if (event.target.checked) {
                    choices[value] = choices[value] || '';
                } else {
                    delete choices[value];
                }
        }
        value = Object.keys(choices).sort().reduce((r, k) => (r[k] = choices[k], r), {});

        this.cellValue = value;
        this.details[y][x] = null;

        this.forceUpdate();
    };

    updateCell = (instance, cell, x, y, value, label, cellName) => {
        if (cell.dataset.merged) {
            if (cell.dataset.x !== '' + x || cell.dataset.y !== '' + y) {
                return; // only primary merged cell should be used
            }
        }
        const jexcelInstance = instance.jexcel;
        const { type, config } = (this.constructor.name.substr(0, 18) === 'DynamicTableAnswer' // workaround
            // somehow after build it becomes: "DynamicTableAnswer_DynamicTableAnswer"
            ? jexcelInstance.options.columns[x]
            : jexcelInstance.options.editors[cellName]) || {};
        if (!type) {
            console.error('MISSING EDITOR', cellName, instance);
            return;
        }

        switch (type) {
            case 'formula':
                const formula = config.replace(/\$/g, y + 1).toUpperCase();
                const { estimated, no_data, unrelated, unrelated_no_data } = this.getFormulaDetails(formula);

                if (!this.details[y]) {
                    this.details[y] = [];
                }

                if (unrelated) {
                    cell.classList.remove('editable');
                    this.details[y][x] = 'unrelated';
                    value = '';
                    jexcelInstance.options.data[y][x] = value;
                } else if (no_data || unrelated_no_data) {
                    cell.classList.add('editable');
                    if (this.details[y][x] === 'override' || value.length) {
                        // user value
                    } else {
                        this.details[y][x] = 'no_data';
                        value = '';
                        jexcelInstance.options.data[y][x] = value;
                    }
                } else {
                    cell.classList.remove('editable');
                    this.details[y][x] = estimated ? 'estimated' : null;
                    value = jexcelInstance.executeFormula(formula, x, y);
                    if (jexcelInstance.options.data[y] !== undefined && jexcelInstance.options.data[y][x] !== undefined) { // FIXME: what it protects from exactly?
                        jexcelInstance.options.data[y][x] = value;
                    }
                }
                break;
            case 'label':
                value = config;
                break;
            case 'undefined':
                return;
            case 'number':
                if (value.length && config.suffix) {
                    value = value + ' ' + config.suffix;
                }
                break;
            case 'boolean':
                // if (value.length) {
                //     value = !!+value ? 'Tak' : 'Nie'; // "+" converts string to number
                // }
                const boolString = ReactDOMServer.renderToString(<RadioButtonUncheckedIcon fontSize="small" style={{ verticalAlign: 'text-top' }}/>);
                const boolCheckedString = ReactDOMServer.renderToString(<RadioButtonCheckedIcon fontSize="small" style={{ verticalAlign: 'text-top' }}/>);
                let bools = [];
                bools.push((value.length && !!+value ? boolCheckedString : boolString) + ' Tak' ); // whitespace
                bools.push((value.length && !+value ? boolCheckedString : boolString) + ' Nie' ); // whitespace
                value = bools.join('<br/>');
                break;
            case 'choice_single':
                if (!value) {
                    value = {};
                }
                const radioString = ReactDOMServer.renderToString(<RadioButtonUncheckedIcon fontSize="small" style={{ verticalAlign: 'text-top' }}/>);
                const radioCheckedString = ReactDOMServer.renderToString(<RadioButtonCheckedIcon fontSize="small" style={{ verticalAlign: 'text-top' }}/>);
                let radios = [];
                config.choices.forEach(v => {
                    const strippedName = (v.name + '').replace(/<[^>]+>/g, '');
                    const text = value[strippedName];
                    radios.push((text === undefined ? radioString : radioCheckedString) + ' ' + (text ? v.name + ': ' + text : v.name)); // whitespace
                });
                value = radios.join('<br/>');
                break;
            case 'choice_multiple':
                if (!value) {
                    value = {};
                }
                const checkboxString = ReactDOMServer.renderToString(<CheckBoxOutlineBlankIcon fontSize="small" style={{ verticalAlign: 'bottom' }}/>);
                const checkboxCheckedString = ReactDOMServer.renderToString(<CheckBoxIcon fontSize="small" style={{ verticalAlign: 'bottom' }}/>);
                let checkboxes = [];
                config.choices.forEach(v => {
                    const strippedName = (v.name + '').replace(/<[^>]+>/g, '');
                    const text = value[strippedName];
                    checkboxes.push((text === undefined ? checkboxString : checkboxCheckedString) + ' ' + (text ? v.name + ': ' + text : v.name)); // whitespace
                });
                value = checkboxes.join('<br/>');
                break;
        }

        if (this.details[y]) {
            if (this.details[y][x] === 'estimated') {
                value = '～ ' + value;
            }
            if (this.details[y][x] === 'no_data') {
                value = '∅ brak danych';
            }
            if (this.details[y][x] === 'unrelated') {
                value = 'ⓧ nie dotyczy';
            }
        }

        cell.classList.add('cell-' + type);
        cell.innerHTML = value;
    };

    updateDateCellValue = format => value => {
        const { datePicker } = this.state;
        const { x, y } = datePicker;

        let data = this.jexcel.getData();
        data[y][x] = value ? formatDate(format, value) : '';
        this.jexcel.setData(data);

        this.cellValue = '';
        this.setState({ datePicker: null }, this.changeHandler);
    };

    updateDialogCellValue = (y, x, details = null) => event => {
        switch (details) {
            case 'no_data':
            case 'unrelated':
                if (this.details[y][x] === details) {
                    this.details[y][x] = null;
                } else {
                    this.details[y][x] = details;
                    this.cellValue = '';
                }
                break;
            default:
                this.details[y][x] = null;
        }

        let data = this.jexcel.getData();
        data[y][x] = this.cellValue;
        this.jexcel.setData(data);
        this.cellValue = '';
        this.changeHandler();
        this.setState({ dialogData: null });
    };

    closeDialog = () => {
        this.setState({ dialogData: null });
    };

    closeEditor = (type) => (cell, save) => {
        this.onChangeEnabled = true;
        switch (type) {
            case 'boolean':
            case 'choice_single':
            case 'choice_multiple':
            case 'label':
            case 'undefined':
            default:
                return;
            case 'number':
                const y = cell.getAttribute('data-y');
                const x = cell.getAttribute('data-x');
                const cellName = jexcel.getColumnNameFromId([x, y]);
                const cellMeta = this.props.table.meta[cellName] || {};
                const { config } = cellMeta;
                if (config && config.min !== undefined && config.min.length) {
                    const numberInput = cell.children[0];
                    if (config.min.length && parseFloat(this.cellValue) < config.min) {
                        numberInput.setCustomValidity('Wartość nie może być mniejsza niż ' + config.min);
                        numberInput.reportValidity();
                        this.cellValue = '';
                    }
                }
            case 'regexp':
            case 'mask':
            case 'email':
                if (!cell.children[0].checkValidity()) {
                    this.cellValue = '';
                }
            case 'formula':
            case 'text':
            case 'open':
            case 'date':
            case 'year':
        }

        if (cell) {
            ReactDOM.unmountComponentAtNode(cell);
        }
        delete cell['_reactRootContainer'];

        return this.cellValue;
    };

    getFormulaDetails = (formula) => {
        const formulaCells = getCellsFromFormula(formula.toUpperCase());
        let no_data = true;
        let unrelated = true;
        let unrelated_no_data = true;
        let estimated = false;
        if (formulaCells) {
            formulaCells.forEach(cellName => {
                const [cellX, cellY] = jexcel.getIdFromColumnName(cellName, true);
                if (this.details[cellY]) {
                    if (this.details[cellY][cellX] === 'estimated') {
                        estimated = true;
                    } else {
                        if (this.details[cellY][cellX] !== 'unrelated') {
                            unrelated = false;
                        }
                        if (this.details[cellY][cellX] !== 'no_data') {
                            no_data = false;
                        }
                        if (this.details[cellY][cellX] !== 'no_data' && this.details[cellY][cellX] !== 'unrelated') {
                            unrelated_no_data = false;
                        }
                    }
                }
            });
        }

        return { estimated, unrelated, no_data, unrelated_no_data };
    };

    focusHandler = keyEvent => focusEvent => {
        if (keyEvent && keyEvent.which === 113) { // F2
            return;
        }
        focusEvent.target.select();
    };

    closeDateEditorHandler = cell => format => event => {
        if (event.relatedTarget) {
            if (event.relatedTarget.classList.contains('calendarButton')) {
                const y = cell.getAttribute('data-y');
                const x = cell.getAttribute('data-x');
                this.setState({ datePicker: { y, x, format } });

                this.jexcel.closeEditor(cell, true);
                return;
            }
        }

        this.closeEditorHandler(cell)(event);
    };

    closeEditorHandler = (cell) => event => {
        const y = cell.getAttribute('data-y');
        const x = cell.getAttribute('data-x');

        if (event.relatedTarget) {
            if (event.relatedTarget.classList.contains('unrelatedButton')) {
                if (this.details[y][x] === 'unrelated') {
                    this.details[y][x] = null;
                } else {
                    this.details[y][x] = 'unrelated';
                    this.cellValue = '';
                }
            }
            if (event.relatedTarget.classList.contains('noDataButton')) {
                if (this.details[y][x] === 'no_data') {
                    this.details[y][x] = null;
                } else {
                    this.details[y][x] = 'no_data';
                    this.cellValue = '';
                }
            }
            if (event.relatedTarget.classList.contains('estimatedButton')) {
                if (this.details[y][x] === 'estimated') {
                    this.details[y][x] = null;
                } else {
                    this.details[y][x] = 'estimated';
                }
            }
        }
        this.jexcel.closeEditor(cell, true);
        this.changeHandler(null, cell, x, y, this.details[y][x], 'setDetail');
    };

    textareaAutoHeight = event => {
        const target = event.target;
        target.style.height = 'auto';
        // const computed = window.getComputedStyle(target);
        const height = target.scrollHeight
            // + parseInt(computed.getPropertyValue('border-top-width'), 10)
            // + (parseInt(computed.getPropertyValue('padding-top'), 10))
            // + parseInt(computed.getPropertyValue('padding-bottom'), 10)
            // + parseInt(computed.getPropertyValue('border-bottom-width'), 10)
        ;
        target.style.height = height + 'px';
    };

    getInputForCell = (cell, cellMeta, event) => {
        const { type, config } = cellMeta;
        const cellInfo = cell.getBoundingClientRect();
        const style = {
            width: cellInfo.width,
            height: cellInfo.height - 2,
            minHeight: cellInfo.height - 2,
        };
        // const y = cell.getAttribute('data-y');
        // const x = cell.getAttribute('data-x');

        switch (type) {
            case 'text':
                return <input
                    className="tableInput"
                    type="text"
                    style={style}
                    defaultValue={this.cellValue}
                    onChange={(event) => this.cellValue = event.target.value}
                    onBlur={this.closeEditorHandler(cell)}
                    onFocus={this.focusHandler(event)}
                    autoFocus
                />;
            case 'open':
                return <textarea
                    className="tableInput"
                    style={style}
                    defaultValue={this.cellValue}
                    onChange={(event) => this.cellValue = event.target.value}
                    onBlur={this.closeEditorHandler(cell)}
                    onFocus={focusEvent => {
                        this.textareaAutoHeight(focusEvent);
                        this.focusHandler(event)(focusEvent);
                    }}
                    autoFocus
                    onInput={this.textareaAutoHeight}
                />;
            case 'number':
            case 'formula':
                return <NumberFormat
                    getInputRef={(el) => this.numberInput = el}
                    className="tableInput"
                    style={style}
                    placeholder="Liczba"
                    value={this.cellValue}
                    thousandSeparator=""
                    decimalSeparator=","
                    suffix={config.suffix ? ' ' + config.suffix : ''}
                    decimalScale={parseInt(config.precision) || 0}
                    fixedDecimalScale={!!config.precision}
                    allowNegative={true}
                    isNumericString={true}
                    onValueChange={(event) => this.cellValue = event.value}
                    isAllowed={values => {
                        this.numberInput.setCustomValidity('');
                        if (config.max.length && values.floatValue > config.max) {
                            this.numberInput.setCustomValidity('Wartość nie może być większa niż ' + config.max);
                            this.numberInput.reportValidity();
                            return false;
                        }
                        return true;
                    }}
                    onBlur={(event) => {
                        this.numberInput.setCustomValidity('');
                        if (config.min.length && parseFloat(this.cellValue) < config.min) {
                            this.numberInput.setCustomValidity('Wartość nie może być mniejsza niż ' + config.min);
                            setTimeout(() => this.numberInput.reportValidity(), 100);
                            this.cellValue = '';
                        } else {
                            this.closeEditorHandler(cell)(event);
                        }
                    }}
                    onFocus={this.focusHandler(event)}
                    autoFocus
                />;
            case  'date':
                return <MaskedInput
                    className="tableInput"
                    style={style}
                    placeholder={dateFormat}
                    type="text"
                    defaultValue={this.cellValue}
                    onChange={event => {
                        if (event.target.checkValidity()) {
                            this.cellValue = event.target.value;
                            event.target.classList.remove('error');
                        } else {
                            event.target.classList.add('error');
                        }
                    }}
                    onBlur={this.closeDateEditorHandler(cell)(dateFormat)}
                    onFocus={this.focusHandler(event)}
                    autoFocus
                    mask={[/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
                    pattern={'[1-2][0-9][0-9][0-9]-[0-1][0-9]-[0-3][0-9]'}
                />;
            case  'year':
                return <MaskedInput
                    className="tableInput"
                    style={style}
                    placeholder={yearFormat}
                    type="text"
                    defaultValue={this.cellValue}
                    onChange={event => {
                        if (event.target.checkValidity()) {
                            this.cellValue = event.target.value;
                            event.target.classList.remove('error');
                        } else {
                            event.target.classList.add('error');
                        }
                    }}
                    onBlur={this.closeDateEditorHandler(cell)(yearFormat)}
                    onFocus={this.focusHandler(event)}
                    autoFocus
                    mask={[/\d/, /\d/, /\d/, /\d/]}
                    pattern={'[1-2][0-9][0-9][0-9]'}
                />;
            case  'email':
                return <input
                    className="tableInput"
                    style={style}
                    type="email"
                    defaultValue={this.cellValue}
                    onChange={event => {
                        event.target.reportValidity();
                        this.cellValue = event.target.value;
                    }}
                    onBlur={this.closeEditorHandler(cell)}
                    onFocus={this.focusHandler(event)}
                    autoFocus
                />;
            case  'regexp':
                return <input
                    className="tableInput"
                    style={style}
                    type="text"
                    defaultValue={this.cellValue}
                    onChange={event => {
                        event.target.reportValidity();
                        this.cellValue = event.target.value;
                    }}
                    onBlur={this.closeEditorHandler(cell)}
                    onFocus={this.focusHandler(event)}
                    autoFocus
                    pattern={config}
                />;
            case  'mask':
                let mask, placeholder, pattern;
                switch (config) {
                    case 'postcode':
                        mask = [/\d/, /\d/, '-', /\d/, /\d/, /\d/];
                        placeholder = '00-000';
                        pattern = '[0-9\\-]{6}';
                        break;
                    case 'phone':
                        mask = [/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/];
                        placeholder = '000 000 000';
                        pattern = '[0-9 ]{11}';
                        break;
                }
                return <MaskedInput
                    className="tableInput"
                    style={style}
                    placeholder={placeholder}
                    type="text"
                    defaultValue={this.cellValue}
                    onChange={event => {
                        event.target.reportValidity();
                        this.cellValue = event.target.value;
                    }}
                    onBlur={this.closeEditorHandler(cell)}
                    onFocus={this.focusHandler(event)}
                    autoFocus
                    mask={mask}
                    pattern={pattern}
                />;
            case 'label':
            case 'undefined':
            case 'boolean':
            case 'choice_single':
            case 'choice_multiple':
            default:
                return '';
        }
    };

    selectionHandler = (instance, x1, y1, x2, y2, origin) => {
        this.setState({
            selection: {
                x1, y1,
                x2, y2,
                c1: jexcel.getColumnNameFromId([x1, y1]),
                c2: jexcel.getColumnNameFromId([x2, y2]),
            }
        }, () => {
            // if (!this.jexcel.edition && !this.props.disabled && x1 === x2 && y1 === y2 && this.jexcel.records[y1] && this.jexcel.records[y1][x1]) {
            //     const cell = this.jexcel.records[y1][x1];
            if (!this.jexcel.edition && !this.props.disabled && this.jexcel.highlighted.length === 1) {
                const cell = this.jexcel.highlighted[0];
                const classList = cell.classList;
                if ([...classList].some(v => ['cell-boolean', 'cell-choice_single', 'cell-choice_multiple'].includes(v))) {
                    this.jexcel.openEditor(cell);
                }
            }
        });
    };

    renderButtons = () => {
        return !!this.jexcel && <>
            {this.renderDeleteButton()}
            {this.renderAddButton()}
        </>
    };

    render() {
        const { classes, disabled, details } = this.props;
        const { dialogData, datePicker } = this.state;
        const { choices, type, y, x } = dialogData || {};

        return <>
            <div className={classNames(classes.container, disabled ? classes.disabled : null)}>
                <JExcelComponent key={disabled} ref={this.ref} options={Object.assign(this.getTableDefaultOptions(), { editable: !disabled }, this.getTableOptions())}>
                    {this.renderButtons()}
                </JExcelComponent>
            </div>

            {!!datePicker && (
                <DatePicker
                    open={true}
                    clearable={true}
                    // value={datePicker ? formatDate(dateFormat, this.cellValue) : null}
                    onChange={this.updateDateCellValue(datePicker.format)}
                    onClose={() => this.setState({ datePicker: null })}
                    views={datePicker.format === yearFormat ? ['year'] : ['year', 'month', 'date']}
                    clearLabel="Wyczyść"
                    cancelLabel="Anuluj"
                    initialFocusedDate={this.cellValue ? formatDate(datePicker.format, this.cellValue) : null}
                    format={datePicker.format}
                    TextFieldComponent={props => ''} // get rid of the input
                />
            )}

            {!!dialogData && (
                <Dialog open={true} onClose={this.closeDialog}>
                    <DialogTitle onClose={this.closeDialog}>Wybierz opcje</DialogTitle>
                    <DialogContent>
                        {type === 'boolean' && (
                            <RadioGroup
                                name="boolean"
                                className={classes.group}
                                defaultValue={this.cellValue}
                                onChange={event => {
                                    this.cellValue = event.target.value;
                                    this.details[y][x] = null;
                                }}
                            >
                                <FormControlLabel value="1" control={<Radio/>} label="Tak" />
                                <FormControlLabel value="0" control={<Radio/>} label="Nie" />
                            </RadioGroup>
                        )}

                        {['choice_single', 'choice_multiple'].includes(type) && (
                            <FormControl component="fieldset">
                                <FormGroup>
                                    {Object.entries(choices).map(([choiceKey, choice]) => {
                                        const strippedName = (choice.name + '').replace(/<[^>]+>/g, '');
                                        let label = <>
                                            <span dangerouslySetInnerHTML={{ __html: choice.name }}/>
                                            {choice.has_text ?
                                                <TextField
                                                    required={true}
                                                    style={{ marginLeft: 10, verticalAlign: 'baseline' }}
                                                    onChange={this.setChoiceValue(strippedName)}
                                                    value={this.cellValue[strippedName] || ''}
                                                    disabled={this.cellValue[strippedName] === undefined}
                                                /> : ''
                                            }
                                        </>;

                                        return <FormControlLabel
                                            className={classes.checkable}
                                            key={choiceKey}
                                            control={type === 'choice_multiple' ?
                                                <Checkbox
                                                    onChange={this.setChoice}
                                                    checked={this.cellValue[strippedName] !== undefined}
                                                    value={strippedName + ''}
                                                /> :
                                                <Radio
                                                    onChange={this.setChoice}
                                                    checked={this.cellValue[strippedName] !== undefined}
                                                    value={strippedName + ''}
                                                />
                                            }
                                            label={label}
                                        />;
                                    })}
                                </FormGroup>
                            </FormControl>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <TooltipIconButton
                            className={classNames("noDataButton", classes.modalButton, this.details[y][x] === 'no_data' ? classes.buttonActive : null)}
                            title="Brak danych"
                            size="small"
                            disabled={this.props.disabled}
                            style={{ marginRight: 12, visibility: details.includes('no_data') ? 'visible' : 'hidden' }}
                            onClick={this.updateDialogCellValue(y, x, 'no_data', )}>
                            <span style={buttonStyle}>∅</span>
                        </TooltipIconButton>
                        <TooltipIconButton
                            className={classNames("unrelatedButton", classes.modalButton, this.details[y][x] === 'unrelated' ? classes.buttonActive : null)}
                            title="Nie dotyczy"
                            size="small"
                            disabled={this.props.disabled}
                            style={{ marginRight: 18, visibility: details.includes('unrelated') ? 'visible' : 'hidden' }}
                            onClick={this.updateDialogCellValue(y, x, 'unrelated')}>
                            <span style={buttonStyle}>ⓧ</span>
                        </TooltipIconButton>
                        <Button onClick={this.closeDialog} variant="contained">Anuluj</Button>
                        <Button onClick={this.updateDialogCellValue(y, x)} color="primary" variant="contained">Zastosuj</Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    }
}

TableAnswer.propTypes = {
    table: PropTypes.object.isRequired,
    values: PropTypes.array,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
};
TableAnswer.defaultProps = {
    table: {},
    values: [],
    disabled: false,
    onChange: () => {},
    onFocus: () => {},
};

export const tableStyles = (theme) => ({
    separator: {
        width: 10,
    },
    disabled: {
        '& td, .highlight-selected': {
            backgroundColor: '#fafafa',
        },
    },
    container: {
        zIndex: 100,
        // '& .jexcel': {
        //     width: '100%',
        // },
        '& .jexcel td': {
            position: 'relative',
        },
        '& .jexcel > thead > tr > td:first-child': {
            width: 36,
        },
        '& .jexcel_pagination': {
            display: 'none',
        },
        '& .jexcel_content': {
            paddingRight: 0,
        },
        '& .jexcel_toolbar': {
            paddingTop: 2,
            paddingBottom: 2,
        },
        '& .cell-formula:not(.editable)': {
            cursor: 'no-drop',
            backgroundColor: '#f3f3f3',
        },
        '& .cell-label': {
            cursor: 'no-drop',
            backgroundColor: '#f3f3f3',
        },
        '& .cell-undefined': {
            cursor: 'not-allowed',
            backgroundColor: '#f3f3f3',
        },
        '& .cell-choice_single': {
            textAlign: 'left !important',
            '& div': {
                display: 'inline-block',
            },
        },
        '& .cell-choice_multiple': {
            textAlign: 'left !important',
            '& div': {
                display: 'inline-block',
            },
        },
        '& .tableInput': {
            paddingTop: '0 !important',
            fontFamily: 'inherit',
            fontSize: 'inherit',
        },
        '& .error': {
            outline: '1px solid #f00',
        },
    },
    button: {
        zIndex: 1,
        bottom: -28,
        right: 0,
        position: 'absolute',
        backgroundColor: '#fff',
        border: '1px solid #aaa',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            borderColor: 'transparent',
        },
    },
    modalButton: {
        backgroundColor: '#fff',
        border: '1px solid transparent',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            borderColor: 'transparent',
        },
    },
    buttonActive: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
    },
    estimatedButton: {
        marginRight: 60,
    },
    noDataButton: {
        marginRight: 30,
    },
    unrelatedButton: {
        marginRight: 0,
    },
    calendarButton: {
        bottom: -6,
        marginRight: -30,
    },
    zeroButton: {
        fontWeight: 'bold',
        fontSize: '1.2em',
    },
    checkable: {
        '& .MuiCheckbox-root': {
            paddingTop: 3,
            paddingBottom: 0,
            alignSelf: 'flex-start',
        },
        '& .MuiFormControlLabel-label': {
            paddingTop: 3,
            paddingBottom: 3,
            alignSelf: 'flex-start',
        }
    }
});

export default TableAnswer;
