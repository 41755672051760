import { Component } from "react";
import ErrorContext from "./ErrorContext";

class BaseComponent extends Component {
    static contextType = ErrorContext;

    /**
     * Fixes Warning: Can't perform a React state update on an unmounted component
     */
    componentWillUnmount() {
        this.setState = (state, callback) => {};
    }

    componentDidCatch(error, info) {
        this.context.catchErrors(error, info);
    }
}

export default BaseComponent;
