import React from 'react';
import NCForm from '../nc-form/NCForm';

import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import DataComponent from "../shared/DataComponent";
import Axios from 'axios';
import User from "../auth/User";
import FabContainer from "../shared/FabContainer";
import PaperRoot from "../shared/PaperRoot";
import ConfirmDialog from "../shared/ConfirmDialog";
import FabButton, { FabLink } from "../shared/FabButton";
import DataTable from "../shared/DataTable";
import { getObjValuesFromArrayByAttribute, removeItemsFromArrayByAttribute } from "../shared/Helpers";
import { withStyles } from "@material-ui/core";
import UserPicker from "../users/UserPicker";

class ProjectEdit extends DataComponent {
    static permissions = ['edit-projects'];
    project_id = this.props.match.params.project_id;
    tableColumns = {
        id: 'ID',
        email: 'Login',
        phone: 'Nr telefonu',
        full_name: 'Imię i nazwisko',
    };

    constructor(props) {
        super(props);

        if (this.project_id) {
            this.url = '/projects/' + this.project_id;
        } else {
            this.state.isLoading = false;
        }

        this.state.userPicker = false;
        this.state.values.users = [];
    }

    saveObject = () => {
        if (this.hasObject()) {
            Axios.put(this.url, this.getValues()).then(response => {
                // this.reloadStateData(response.data, { errors: {} });
                this.props.history.push('/projects');
            }).catch(this.catchErrors);
        } else {
            Axios.post('/projects', this.getValues()).then(response => {
                // this.props.history.push('/projects/' + response.data.object.id + '/edit');
                this.props.history.push('/projects');
            }).catch(this.catchErrors);
        }
    };

    getTitle = () => {
        return this.hasObject() ? "Edycja projektu: " + this.getObject('name') : 'Dodaj projekt';
    };

    addUsers = (newUsers) => {
        let values = this.getValues();
        values.users = values.users.concat(newUsers);

        this.setState({ values, userPicker: false });
    };

    render() {
        if (this.state.isLoading) {
            return this.showLoader();
        }
        const { classes } = this.props;
        const { deleteDialog, userPicker } = this.state;

        const fields = [
            {
                name: 'name',
                type: 'text',
                label: 'Nazwa projektu',
                width: 400,
            }
        ];

        return <>
            <PaperRoot>
                <NCForm
                    fields={fields}
                    title={this.getTitle()}
                    values={this.getValues()}
                    errors={this.getErrors()}
                    onChange={this.changeHandler}
                    hideSubmitButton
                    // saveHandler={this.saveObject}
                />

                <DataTable
                    storageKey={this.constructor.name}
                    title="Użytkownicy"
                    columns={this.getTableColumns()}
                    data={this.getValues('users') || []}
                    options={{
                        selection: true,
                    }}
                    actions={[
                        {
                            tooltip: 'Dodaj',
                            icon: AddIcon,
                            iconProps: { className: classes.addButton },
                            isFreeAction: true,
                            onClick: () => this.setState({ userPicker: true }),
                        },
                        {
                            tooltip: 'Wyrzuć zaznaczone',
                            icon: DeleteIcon,
                            onClick: (event, rows) => {
                                let values = this.getValues();
                                values.users = removeItemsFromArrayByAttribute(values.users, getObjValuesFromArrayByAttribute(rows));
                                this.setState({ values }, this.changeHandler);
                            },
                        },
                    ]}
                />
            </PaperRoot>

            {userPicker && (
                <UserPicker
                    url="/projects/users"
                    users={this.getValues('users')}
                    changeHandler={this.addUsers}
                />
            )}

            <FabContainer>
                <FabLink title="Wróć" to="/projects" color="default"><ArrowBackIcon/></FabLink>

                {User.hasPermissionTo('edit-projects') && <>
                    {this.renderDeleteButton()}
                    <FabButton title="Zapisz" onClick={this.saveObject} color="primary"><SaveIcon/></FabButton>
                </>}
            </FabContainer>

            <ConfirmDialog open={deleteDialog} onClose={this.closeDeleteDialog} onConfirm={this.deleteObject('/projects')}>
                Czy na pewno chcesz usunąć ten projekt?<br/>
                Tylko administrator będzie miał możliwość przywrócenia usuniętego projektu.
            </ConfirmDialog>
        </>
    }
}

const styles = theme => ({
    addButton: {
        width: 30,
        height: 30,
        padding: 5,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        borderRadius: '50%',
        margin: -4,
    },
});

export default withStyles(styles)(ProjectEdit);
