import React from 'react';
import { NavLink } from 'react-router-dom';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SecurityIcon from '@material-ui/icons/Security';
import ListIcon from '@material-ui/icons/List';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PieChartIcon from '@material-ui/icons/PieChart';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import QuestionIcon from '@material-ui/icons/QuestionAnswer';
import FolderIcon from '@material-ui/icons/Folder';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ListAltIcon from '@material-ui/icons/ListAlt';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import User from '../auth/User';
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import PollIcon from '@material-ui/icons/Poll';
import SettingsIcon from '@material-ui/icons/Settings';
import { clone } from "../shared/Helpers";
import BaseComponent from "../shared/BaseComponent";

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 300,
        marginTop: 10,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    link : {
        '&:hover': {
            textDecoration : 'none',
            color: '#000000'
        },
        textDecoration : 'none',
        color : '#000000'
    },
    parent: {
        '&.Mui-selected': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
        },
    },
});

let menuItems = [
    {
        path: '/',
        exact: true,
        label: 'Kokpit',
        icon: DashboardIcon,
        open: false,
        permissions: ['view-dashboard'],
    },
    {
        path: '/users',
        label: 'Użytkownicy',
        icon: SupervisorAccountIcon,
        open: false,
        permissions: ['view-users', 'view-roles'],
        items: [
            {
                path: '/users/list',
                label: 'Lista użytkowników',
                icon: AccountBoxIcon,
                permissions: ['view-users'],
            },
            {
                path: '/users/roles',
                label: 'Role',
                icon: SecurityIcon,
                permissions: ['view-roles'],
            }
        ]
    },
    {
        path: '/notifications',
        label: 'Powiadomienia',
        icon: NotificationsActiveIcon,
        open: false,
        permissions: ['view-all-notifications', 'user-notifications'],
        items: [
            {
                path: '/notifications/list',
                label: 'Twoje powiadomienia',
                icon: NotificationsIcon,
                permissions: ['view-users'],
            },
            {
                path: '/notifications/all',
                label: 'Wszystkie powiadomienia',
                icon: NotificationsActiveIcon,
                permissions: ['view-all-notifications'],
            },
        ]
    },
    {
        path: '/projects',
        label: 'Projekty',
        icon: ListIcon,
        open: false,
        permissions: ['view-projects'],
    },
    {
        path: '/polls',
        label: 'Ankiety',
        icon: PollIcon,
        open: false,
        permissions: ['view-polls'],
    },
    {
        path: '/my-projects',
        label: 'Moje projekty',
        icon: AccountTreeIcon,
        open: false,
        permissions: ['!user-projects'],
    },
    {
        path: '/catalogs',
        label: 'Baza pytań',
        icon: LibraryBooksIcon,
        open: false,
        permissions: ['view-questions', 'view-catalogs'],
        items: [
            {
                path: '/catalogs/questions',
                label: 'Pytania',
                icon: QuestionIcon,
                permissions: ['view-questions'],
            },
            {
                path: '/catalogs/list',
                label: 'Katalogi',
                icon: FolderIcon,
                permissions: ['view-catalogs'],
            },
        ],
    },
    {
        path: '/museums',
        label: 'Baza muzeów',
        icon: AccountBalanceIcon,
        open: false,
        permissions: ['view-museums'],
    },
    {
        path: '/institution-manager',
        label: 'Instytucja',
        icon: AccountBalanceIcon,
        open: false,
        permissions: ['!museum-manager'], // "!" means that user (even superadmin!) needs to have exactly this permission
    },
    {
        path: '/museum-polls',
        label: 'Ankiety',
        icon: ListAltIcon,
        open: false,
        permissions: ['!view-museum-polls'],
    },
    {
        path: '/reports',
        label: 'Raporty',
        icon: PieChartIcon,
        open: false,
        permissions: ['system-reports', 'data-reports'],
        items: [
            {
                path: '/reports/system',
                label: 'Raporty systemowe',
                icon: ShowChartIcon,
                permissions: ['system-reports'],
            },
            {
                path: '/reports/data',
                label: 'Raporty z danych',
                icon: ListIcon,
                permissions: ['data-reports'],
            }/*,
            {
                path : '/answers-by-museums',
                label : 'Odpowiedzi na muzea',
                icon : ListIcon,
                permissions: ['data-reports'],
            },*/
            /*{
                path: '/richtext-reports',
                label : 'Raporty opisowe',
                icon: ShowChartIcon,
                permissions: ['system-reports'],
            }*/
        ]
    },
    {
        path: '/settings',
        label: 'Ustawienia',
        icon: SettingsIcon,
        open: false,
        permissions: ['view-settings'],
    },
];

const defaultTitle = document.title;

class Sidebar extends BaseComponent {
    menuItems = [];
    state = {
        menuOpens: []
    };

    constructor(props) {
        super(props);

        this.menuItems = clone(menuItems).filter((item, index) => {
            if (item.items && item.items.length) {
                item.items = item.items.filter((subItem, subIndex) => {
                    if (subItem.permissions === true || User.hasAnyPermission(subItem.permissions)) {
                        subItem.icon = menuItems[index].items[subIndex].icon;
                        return true;
                    }

                    return false;
                });
            }

            if (item.permissions === true || User.hasAnyPermission(item.permissions)) {
                item.icon = menuItems[index].icon;
                return true;
            }

            return false;
        });

        let menuOpens = [];
        this.menuItems.forEach((el, idx) => {
            menuOpens[idx] = false;
        });
        this.state.menuOpens = menuOpens;
    }

    handleClick = (itemIdx) => {
        let menuOpens = this.state.menuOpens;

        menuOpens.forEach((el, idx) => {
            menuOpens[idx] = idx !== itemIdx ? false : !menuOpens[idx];
        });

        this.setState({
            menuOpens: menuOpens,
            subIdx: -1
        });
    };

    handleSubClick = (subIdx) => {
        this.setState({
            subIdx: subIdx
        });
    };

    moveTo = (path) => {
        this.context.router.history.push('/');
    };

    changeDocumentTitle = label => (match, location) => {
        if (match) {
            document.title = label + ' - ' + defaultTitle;
        }

        return match;
    };

    pathToId = (path) => {
        return path.replace(/^\//, '').replace(/\//, '-');
    };

    render() {
        const { classes, compact } = this.props;

        return (
            <div className={classes.root}>
                <List
                    component="nav"
                    subheader={<ListSubheader component="div"></ListSubheader>}
                >
                    {this.menuItems.map((item, itemIdx) => {
                        let collapse;
                        let collapseArrow;

                        if (item.items && item.items.length > 0) {
                            if (item.items.length === 1) {
                                item = item.items[0];
                            } else {
                                collapseArrow = <div>{this.state.menuOpens[itemIdx] ? <ExpandLess/> : <ExpandMore/>}</div>;

                                collapse = <Collapse in={this.state.menuOpens[itemIdx]} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {item.items.map((subItem, subIdx) => {
                                            return <ListItem
                                                button
                                                id={this.pathToId(subItem.path)}
                                                key={subIdx}
                                                // selected={this.state.subIdx === subIdx && this.state.menuOpens[itemIdx]}
                                                // component={subItem.path ? NavLink : 'div'}
                                                component={NavLink}
                                                isActive={this.changeDocumentTitle(subItem.label)}
                                                title={subItem.label}
                                                to={subItem.path || ''}
                                                exact={!!subItem.exact}
                                                activeClassName="Mui-selected"
                                                onClick={() => this.handleSubClick(subIdx)}
                                            >
                                                <ListItemIcon>
                                                    <subItem.icon/>
                                                </ListItemIcon>
                                                {compact ? '' : <ListItemText primary={subItem.label}/>}
                                            </ListItem>
                                        })}
                                    </List>
                                </Collapse>
                            }
                        }
                        return <div key={itemIdx}>
                            <ListItem
                                button
                                id={this.pathToId(item.path)}
                                // selected={!collapse && this.state.menuOpens[itemIdx]}
                                // component={item.path ? NavLink : 'div'}
                                component={NavLink}
                                isActive={this.changeDocumentTitle(item.label)}
                                title={item.label}
                                to={item.path || ''}
                                exact={!!item.exact}
                                activeClassName="Mui-selected"
                                onClick={event => {
                                    if (item.items && item.items.length) {
                                        event.preventDefault();
                                    }
                                    this.handleClick(itemIdx);
                                }}
                                className={item.items && item.items.length ? classes.parent : null}
                            >
                                <ListItemIcon>
                                    <item.icon/>
                                </ListItemIcon>
                                {compact ? '' : <ListItemText primary={item.label}/>}
                                {compact ? '' : collapseArrow}
                            </ListItem>
                            {collapse}
                        </div>
                    })}
                </List>
            </div>
        );
    }
}

Sidebar.propTypes = {
    classes: PropTypes.object.isRequired,
    compact: PropTypes.bool,
};

export default withStyles(styles)(Sidebar);
