import { withRouter } from "react-router";
import Axios from "axios";
import BaseComponent from "../shared/BaseComponent";

class PasswordReset extends BaseComponent {
    componentDidMount() {
        Axios.get('/password-reset/' + this.props.match.params.token).then(response => {
            this.props.history.replace('/');
        }).catch(error => {
            this.props.history.replace('/');
        });
    }

    render() {
        return '';
    }
}

export default withRouter(PasswordReset);
