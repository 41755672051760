import React from 'react';
import PropTypes from "prop-types";
import { DialogTitle } from "../shared/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import TableColumnPicker from "./TableColumnPicker";
import BaseComponent from "../shared/BaseComponent";

class TableColumnPickerDialog extends BaseComponent {
    state = {
        columns: [],
    };

    multipleChangeHandler = columns => {
        this.setState({ columns });
    };

    addMultiple = () => {
        this.props.changeHandler(this.state.columns);
    };

    render() {
        const { question, changeHandler, multiple } = this.props;
        const { columns } = this.state;

        return <Dialog maxWidth="md" open={true} onClose={() => changeHandler(false)}>
            <DialogTitle onClose={() => changeHandler(false)}>Wybierz kolumnę: {question.name}</DialogTitle>
            <DialogContent style={{ paddingTop: 24 }}>
                <TableColumnPicker
                    table={question.properties.table}
                    changeHandler={multiple ? this.multipleChangeHandler : changeHandler}
                    multiple={multiple}
                />
            </DialogContent>
            <DialogActions>
                {!!multiple && (
                    <Button onClick={this.addMultiple} variant="contained" color="primary" disabled={!columns.length}>Użyj</Button>
                )}
                <Button onClick={() => changeHandler(false)} variant="contained">Zamknij</Button>
            </DialogActions>
        </Dialog>;
    }
}

TableColumnPickerDialog.propTypes = {
    question: PropTypes.object.isRequired,
    changeHandler: PropTypes.func.isRequired,
    multiple: PropTypes.bool,
};
TableColumnPickerDialog.defaultProps = {
    multiple: false,
};

export default TableColumnPickerDialog;
