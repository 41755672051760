import React from 'react';
import { Redirect } from "react-router-dom";
import User from './User';
import Typography from "@material-ui/core/Typography";
import Profile from "../user/ProfileEdit";
import BaseComponent from "../shared/BaseComponent";

export default function withUser(ComponentToSecure) {

    return class extends BaseComponent {
        state = {
            user: null
        };

        componentDidMount() {
            if (User.isLoggedIn()) {
                try {
                    const profile = User.getProfile();
                    this.setState({
                        user: profile
                    });

                    return;
                } catch (err) {
                    User.logOut();
                    this.setState({
                        user: null
                    });
                }
            }

            // this.props.history.replace('/login');
            // return <Redirect to="/login" />;
        }

        render() {
            const { user } = this.state;
            if (User.isLoggedIn()) {
                if (User.hasPermissionTo('change-password') && user && !user.remember_token) {
                    return <Profile {...this.props} {...this.state} />;
                } else if (ComponentToSecure.permissions === true || User.hasAnyPermission(ComponentToSecure.permissions)) {
                    return <ComponentToSecure /*key={ComponentToSecure.displayName}*/ {...this.props} {...this.state} />;
                } else if (ComponentToSecure.isDashboard === true && User.hasPermissionTo('redirect-to-museums')) {
                    return <Redirect to={{ pathname: '/museums' }}/>;
                } else {
                    return <Typography variant="h6">Access Denied</Typography>;
                }
            } else {
                return null;
            }
        }
    }
}
