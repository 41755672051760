import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

// import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import Avatar from '@material-ui/core/Avatar';
import InputBase from "@material-ui/core/InputBase";

// import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import deepOrange from '@material-ui/core/colors/deepOrange';
import deepPurple from '@material-ui/core/colors/deepPurple';

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

// import NCPollSnackbar from "../nc-poll/NCPollSnackbar";

import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

import Axios from 'axios';
import User from "../auth/User";
// import TextField from "@material-ui/core/TextField";
// import { Editor } from "@tinymce/tinymce-react/lib/es2015/main/ts";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import BaseComponent from "../shared/BaseComponent";
import ReplyIcon from '@material-ui/icons/Reply';
import { DialogTitle } from "../shared/Dialog";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import TooltipIconButton from "../shared/TooltipIconButton";
import List from "@material-ui/core/List";
import Badge from "@material-ui/core/Badge";
import AddCommentIcon from "@material-ui/icons/AddComment";
import ReadMore from "../shared/ReadMore";

class NCPollComment extends BaseComponent {
    defaultComment = { content: '', state: 'draft', user: { id: User.getUserId(), full_name: User.getUserName() } };
    state = {
        comment: this.defaultComment,
        newContent: false,
        isDeleted: false,
        anchorEl: null,
        errors: {},
        repliesDialog: false,
        replies: [],
        addReply: false,
        editReply: false,
    };

    constructor(props) {
        super(props);

        this.state.comment = Object.assign(this.state.comment, props.comment);
        this.state.replies = props.comment.replies || [];
        if (Boolean(props.editMode)) {
            this.state.newContent = this.state.comment.content;
        }
    }

    // static getDerivedStateFromProps(props, state) {
    //     if (!areObjectsEqual(props.comment.replies, state.comment.replies)) {
    //         state.addReply = false;
    //         state.editReply = false;
    //     }
    //     state.replies = props.comment.replies || [];
    //
    //     return state;
    // }

    catchErrors = (error) => {
        if (error.response && error.response.data && error.response.data.errors) {
            this.setState({ errors: error.response.data.errors });
        }
    };

    updateHandler = () => {
        this.props.updateHandler(true);
    };

    openMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    closeMenu = () => {
        this.setState({ anchorEl: null });
    };

    handleEdit = event => {
        this.setState({ newContent: this.state.comment.content });
        this.closeMenu();
    };

    cancelEdit = event => {
        this.setState({ newContent: false });
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    };

    handleSave = event => {
        if (this.state.newContent === this.props.comment.content) {
            return this.cancelEdit();
        }

        if (!this.state.comment.id) {
            let data = {
                commentable_id: this.props.commentableId,
                commentable_type: this.props.commentableType,
                museum_poll_id: this.props.museumPollId,
                parent_id: this.props.parentId,
                content: this.state.newContent,
            };

            Axios.post('/comments', data).then(response => {
                this.setState({ newContent: false, comment: response.data.object }, this.updateHandler);
            }).catch(this.catchErrors);
        } else {
            let data = {
                content: this.state.newContent
            };

            Axios.put('/comments/' + this.state.comment.id, data).then(response => {
                this.setState({ newContent: false, comment: response.data.object }, this.updateHandler);
            }).catch(this.catchErrors);
        }
    };

    handleDelete = event => {
        if (this.state.comment.id > 0) {
            Axios.delete('/comments/' + this.state.comment.id).then(response => {
                this.setState({ isDeleted: true, comment: this.defaultComment }, () => {
                    this.closeMenu();
                    this.updateHandler();
                });
            }).catch(this.catchErrors);
        } else {
            this.setState({ isDeleted: true }, this.closeMenu);
        }
    };

    setNewContent = event => {
        this.setState({ newContent: event.target.value });
    };

    moveCaretAtEnd = event => {
        let v = event.target.value;
        event.target.value = '';
        event.target.value = v;
    };

    nl2br(str, is_xhtml) {
        let breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    }

    openRepliesDialog = () => {
        this.setState({ repliesDialog: true });
    };

    closeRepliesDialog = () => {
        this.setState({ repliesDialog: false });
    };

    beginAddReply = () => {
        const lastReply = this.state.replies.slice(-1)[0];
        if (lastReply && lastReply.user.id === User.getUserId() && lastReply.state === 'draft') {
            this.setState({ editReply: true });
        } else {
            this.setState({ addReply: '' + -new Date() });
        }
    };

    cancelAddReply = () => {
        this.setState({ addReply: false, editReply: false });
    };

    render() {
        const { classes, showAvatar, allowReply, commentableId, commentableType, museumPollId, isReply, showFirstReply, updateKey, readMore } = this.props;
        const { comment, newContent, anchorEl, isDeleted, errors, repliesDialog, replies, addReply, editReply } = this.state;

        if (isDeleted) {
            return '';
        }

        const isOwner = comment.user.id === User.getUserId();
        const lastIndex = replies.length - 1;
        let error = errors['content'] ? errors['content'].join(', ') : false;

        return <>
            <ListItem alignItems="flex-start" className={classes.commentList}>
                {showAvatar ? (
                    <ListItemAvatar className={classes.avatar}>
                        <Avatar className={comment.state === 'draft' ? '' : (isOwner ? classes.orangeAvatar : classes.purpleAvatar)}>
                            {comment.user.full_name.substr(0, 1)}
                        </Avatar>
                    </ListItemAvatar>
                ) : ''}
                <ListItemText
                    className={isReply ? classes.itemReply : null}
                    primary={<>
                        <Typography className={classNames(classes.name, showAvatar ? null : (isOwner ? classes.orange : classes.purple))}
                                    component="span" color="textPrimary">{comment.user.full_name}</Typography>
                        <Typography className={classes.date} component="span" variant="caption" color="textSecondary">{comment.updated_at}</Typography>
                    </>}
                    secondaryTypographyProps={{ component: 'div', style: { lineHeight: 1.5 } }}
                    secondary={<>
                        {newContent === false ? (
                            readMore ? (
                                <ReadMore html={comment.content} lines={3} fixed={true} />
                            ) : (comment.content.split('\n').map((item, i) => {
                                return <Typography
                                    key={i}
                                    component="span"
                                    style={{ display: 'block', letterSpacing: 'normal', fontSize: '0.875rem' }}
                                    color="textSecondary">{item}</Typography>
                                })
                            )
                        ) : (
                            <FormControl error={!!error} fullWidth>
                                <InputBase
                                    placeholder="Wpisz komentarz"
                                    value={newContent}
                                    className={classes.inputField}
                                    onFocus={this.moveCaretAtEnd}
                                    onChange={this.setNewContent}
                                    margin="none"
                                    multiline
                                    autoFocus
                                />
                                {error && <FormHelperText>{error}</FormHelperText>}
                            </FormControl>
                        )}
                    </>}/>
                {isOwner && comment.state === 'draft' ? (
                    <ListItemSecondaryAction className={classes.action}>
                        {newContent === false ? (
                            User.hasPermissionTo('edit-polls-comments') && (
                                <>
                                    <IconButton aria-owns="edit-menu" onClick={this.openMenu}>
                                        <MoreVertIcon fontSize="small"/>
                                    </IconButton>
                                    <Menu
                                        id="edit-menu"
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={this.closeMenu}
                                    >
                                        {User.hasPermissionTo('edit-polls-comments') && <>
                                            <MenuItem onClick={this.handleEdit}><EditIcon className={classes.menuIcon}/>Edytuj</MenuItem>
                                            <MenuItem onClick={this.handleDelete}><DeleteIcon className={classes.menuIcon}/>Usuń</MenuItem>
                                        </>}
                                    </Menu>
                                </>
                            )
                        ) : (
                            <span className={classes.editIconWrapper}>
                                <IconButton className={classes.editIcon} onClick={this.handleSave}>
                                    <CheckIcon className={classes.green} fontSize="small"/>
                                </IconButton>
                                <IconButton className={classes.editIcon} onClick={this.cancelEdit}>
                                    <CloseIcon className={classes.red} fontSize="small"/>
                                </IconButton>
                            </span>
                        )}
                    </ListItemSecondaryAction>
                ) : (allowReply && (
                    <ListItemSecondaryAction className={classes.action}>
                        <Badge badgeContent={replies.length} color={comment.draft_replies_count ? 'secondary' : 'primary'} classes={{ badge: classes.badge }}>
                            <TooltipIconButton title={replies.length ? 'Odpowiedzi' : 'Odpowiedz'} onClick={this.openRepliesDialog}>
                                <ReplyIcon/>
                            </TooltipIconButton>
                        </Badge>
                    </ListItemSecondaryAction>
                ))}
            </ListItem>

            {!!showFirstReply && replies.slice(0, 1).map((reply, replyIndex) =>
                <NCPollComment
                    key={reply.id + 'firstReply' + updateKey}
                    updateKey={updateKey}
                    updateHandler={this.updateHandler}
                    classes={classes}
                    comment={reply}
                    showAvatar={false}
                    isReply={true}
                    readMore={true}
                />
            )}

            {repliesDialog && (
                <Dialog open={true} onClose={this.closeRepliesDialog} maxWidth="xs" className={classes.dialog}>
                    <DialogTitle onClose={this.closeRepliesDialog}>
                        <NCPollComment
                            key={comment.id + 'dialog' + updateKey}
                            updateKey={updateKey}
                            classes={classes}
                            comment={comment}
                        />
                    </DialogTitle>
                    <DialogContent>
                        <List disablePadding>
                            {replies.map((reply, replyIndex) =>
                                <NCPollComment
                                    key={reply.id + (editReply ? '_edit' : '') + 'replies' + updateKey}
                                    updateKey={updateKey}
                                    classes={classes}
                                    comment={reply}
                                    editMode={editReply && replyIndex === lastIndex}
                                    showAvatar={false}
                                    updateHandler={this.updateHandler}
                                />
                            )}
                        </List>
                    </DialogContent>
                    <DialogActions>
                        <List disablePadding>
                            {!!addReply && (
                                <NCPollComment
                                    editMode
                                    key={addReply}
                                    classes={classes}
                                    commentableId={commentableId}
                                    commentableType={commentableType}
                                    museumPollId={museumPollId}
                                    parentId={comment.id}
                                    updateHandler={this.updateHandler}
                                    onCancel={this.cancelAddComment}
                                    showAvatar={false}
                                />
                            )}
                        </List>
                        <TooltipIconButton title="Napisz komentarz" onClick={this.beginAddReply}>
                            <AddCommentIcon/>
                        </TooltipIconButton>
                    </DialogActions>
                </Dialog>
            )}
        </>
    }
}

NCPollComment.propTypes = {
    comment: PropTypes.object,
    updateHandler: PropTypes.func,
    onCancel: PropTypes.func,
    editMode: PropTypes.bool,
    showAvatar: PropTypes.bool,
    allowReply: PropTypes.bool,
    readMore: PropTypes.bool,
    museumPollId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    commentableId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    parentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    commentableType: PropTypes.string,
};
NCPollComment.defaultProps = {
    comment: {},
    editMode: false,
    showAvatar: true,
    allowReply: false,
    readMore: false,
    parentId: null,
    updateHandler: () => {},
};


const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    red: {
        color: red[500]
    },
    green: {
        color: green[500]
    },
    orange: {
        color: deepOrange[500],
    },
    orangeAvatar: {
        // margin: 10,
        color: '#fff',
        backgroundColor: deepOrange[500],
    },
    purple: {
        color: deepPurple[500],
    },
    purpleAvatar: {
        // margin: 10,
        color: '#fff',
        backgroundColor: deepPurple[500],
    },
    menuIcon: {
        marginRight: 10
    },
    inputField: {
        display: 'flex',
        color: theme.palette.text.secondary,
        fontSize: theme.typography.pxToRem(14),
        paddingTop: 1,
        paddingRight: 2,
        paddingLeft: 2,
        paddingBottom: 1,
        border: 0,
        width: '100%',
        outline: '1px solid #eee',
        backgroundColor: '#fff',
        marginTop: -1,
        marginRight: -2,
        marginLeft: -2,
        marginBottom: -1,
        lineHeight: 1.5,
    },
    editIcon: {
        padding: 4
    },
    editIconWrapper: {
        display: 'block',
        marginTop: 14,
        marginRight: -14
    },
    commentList: {
        // borderRight: '2px solid ' + theme.palette.secondary.main,
        // paddingRight: 16,
    },
    itemReply: {
        marginLeft: 56,
        paddingLeft: theme.spacing(1),
        borderLeft: '1px solid '+ theme.palette.primary.main,
    },
    name: {
        display: 'block',
    },
    date: {
        display: 'block',
    },
    action: {
        // position: 'absolute',
        right: 0,
    },
    badge: {
        top: theme.spacing(1),
        right: theme.spacing(1),
    },
    dialog: {
        '& .MuiDialog-scrollPaper': {
            justifyContent: 'right',
        },
        '& .MuiDialogTitle-root': {
            padding: 0,
        },
        '& .MuiDialogContent-root': {
            padding: 0,
            paddingRight: theme.spacing(2),
            width: 340,
        },
        '& .MuiList-root': {
            width: '100%',
        },
        '& .MuiListItem-secondaryAction': {
            paddingLeft: theme.spacing(1),
        },
    },
    // avatar: {
    //     [theme.breakpoints.down('lg')]: {
    //         display: 'none',
    //     },
    // },
});


export default withStyles(styles)(NCPollComment);
