import React from 'react';
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import DataComponent from "../shared/DataComponent";
import DataTable from "../shared/DataTable";

import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FabContainer from "../shared/FabContainer";
import { FabLink } from "../shared/FabButton";
import MuseumPoll from "../../models/MuseumPoll";
import Poll from "../../models/Poll";
import Museum from "../../models/Museum";

class PollInstancesList extends DataComponent {
    static permissions = [''];
    project_id = this.props.match.params.project_id;
    poll_id = this.props.match.params.poll_id;
    question_id = this.props.match.params.question_id;

    url = '/projects/' + this.project_id + '/polls/' + this.poll_id;
    tableColumns = {
        'museum.museum_name': 'Nazwa instytucji',
        'museum.branch_name': 'Nazwa oddziału',
        'poll.type_string': { field: 'poll.type', title: 'Rodzaj', lookup: Poll.types },
        'museum.data.organizational_form': { title: 'Forma organizacyjna muzeum', lookup: { '' : '- brak -', ...Museum.organizational_forms } },
        created_at: "Data wysłania",
        state_string: { field: 'state', title: 'Status', lookup: MuseumPoll.states },
        questions_summary: { title: 'Wypełnione pytania', filtering: false, sorting: false },
    };

    render() {
        if (this.state.isLoading) {
            return this.showLoader();
        }
        const { classes } = this.props;

        return (
            <>
                <DataTable
                    storageKey={this.constructor.name}
                    title={"Lista instytucji biorących udział w ankiecie: " + this.getObject('name')}
                    columns={this.getTableColumns()}
                    data={this.loadTableData(this.url)}
                    actions={[
                        rowData => ({
                            tooltip: 'Zweryfikuj ankietę',
                            icon: () => <Link to={'/museum-polls/' + rowData.id} className={classes.actionIcon}><CheckIcon/></Link>,
                        })
                    ]}
                />

                <FabContainer>
                    <FabLink title="Wróć" to={this.question_id
                        ? '/catalogs/questions/' + this.question_id + '/polls'
                        : '/projects/' + this.project_id + '/polls'} color="default"><ArrowBackIcon/></FabLink>
                    <FabLink title="Dodaj ankietę" to={"/projects/" + this.project_id + '/polls/add'} color="primary">
                        <AddIcon/>
                    </FabLink>
                </FabContainer>
            </>
        );
    }
}

const styles = theme => ({
    actionIcon: {
        width: 48,
        height: 48,
        color: 'inherit',
        padding: 12,
        margin: -12,
    },
});

export default withStyles(styles)(withRouter(PollInstancesList));
