import React  from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from "@material-ui/icons/Delete";
import PollMuseumsPicker from "./PollMuseumsPicker";
import DataComponent from "../shared/DataComponent";
import DataTable from "../shared/DataTable";
import { arrayUnique, getObjValuesFromArrayByAttribute, removeItemsFromArrayByAttribute } from "../shared/Helpers";
import Museum from "../../models/Museum";

class PollMuseums extends DataComponent {
    tableColumns = {
        idx: 'ID',
        museum_name: 'Nazwa instytucji',
        branch_name: 'Nazwa oddziału',
        type_string: { field: 'type', title: 'Typ', lookup: Museum.types, filtering: false },
        'data.email': 'Email',
        'data.phone_nb': 'Numer telefonu',
        // created_at: 'Data dodania',
    };

    constructor(props) {
        super(props);

        this.state.showPicker = false;
        this.state.values = props.museums;
    }

    shouldComponentUpdate(nextProps, nextState, nextContent) {
        return this.shouldComponentUpdateHandler(nextProps, nextState);
    }

    changeHandler = () => {
        this.props.changeHandler(this.props.valuesKey, this.getValues());
    };

    showPicker = () => {
        this.setState({ showPicker: true });
    };

    addMuseums = (museums) => {
        this.setState({
            showPicker: false,
            values: arrayUnique(this.getValues().concat(museums)),
        }, this.changeHandler);
    };

    render() {
        const { classes, pollType } = this.props;
        const { showPicker } = this.state;

        return <>
            <DataTable
                tableRef={this.tableRef}
                storageKey={this.constructor.name}
                title="Wybrane muzea"
                columns={this.getTableColumns()}
                data={this.getValues()}
                // parentChildData={(row, rows) => rows.find(v => v.id === row.parent_id)}
                options={{
                    selection: true,
                }}
                // onSelectionChange={(rows, rowData) => {
                //     if (rowData && rowData.parent_id) { // rowData is undefined when using SelectAllCheckbox
                //         let parent = getItemFromArrayByAttribute(this.getValues(), rowData.parent_id);
                //         if (!getItemFromArrayByAttribute(rows, rowData.parent_id, 'parent_id')) {
                //             parent.tableData.checked = false;
                //         }
                //
                //         this.forceUpdate();
                //     }
                // }}
                actions={[
                    {
                        tooltip: pollType ? 'Dodaj muzea' : 'Najpierw wybierz rodzaj ankiety',
                        icon: () => <AddCircleIcon className={classNames(classes.addButton, pollType ? null : classes.buttonDisabled)} />,
                        isFreeAction: true,
                        onClick: pollType ? this.showPicker : null,
                    },
                    // },
                    {
                        tooltip: 'Wyrzuć zaznaczone',
                        icon: DeleteIcon,
                        onClick: (event, rows) => {
                            let values = removeItemsFromArrayByAttribute(this.getValues(), getObjValuesFromArrayByAttribute(rows));
                            // if (pollType === 'branch') {
                            //     values.forEach((object, index) => {
                            //         if (object.parent_id) {
                            //             // remove orphans:
                            //             if (!getItemFromArrayByAttribute(values, object.parent_id)) {
                            //                 values = removeItemsFromArrayByAttribute(values, object.id);
                            //             }
                            //         } else {
                            //             // remove empty parents:
                            //             if (!getItemFromArrayByAttribute(values, object.id, 'parent_id')) {
                            //                 values = removeItemsFromArrayByAttribute(values, object.id);
                            //             }
                            //         }
                            //     });
                            // }
                            this.setState({ values }, this.changeHandler);
                        },
                    },
                ]}
            />

            {showPicker && <PollMuseumsPicker
                changeHandler={this.addMuseums}
                museums={this.getValues()}
                pollType={pollType}
            />}
        </>
    }
}

PollMuseums.propTypes = {
    valuesKey: PropTypes.string,
    museums: PropTypes.array,
    pollType: PropTypes.string,
    changeHandler: PropTypes.func.isRequired,
};
PollMuseums.defaultProps = {
    valuesKey: 'museums',
    museums: [],
};

const styles = theme => {
    return {
        addButton: {
            color: theme.palette.primary.main,
        },
        buttonDisabled: {
            color: theme.palette.grey[500],
            cursor: 'default',
        },
    };
};

export default withStyles(styles)(PollMuseums);
