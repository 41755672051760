const Museum = {
    types: {
        branched: 'Oddziałowe',
        branchless: 'Bezoddziałowe',
        branch: 'Oddział',
    },
    organizational_forms: {
        'państwowe': 'państwowe',
        'samorządowe': 'samorządowe',
        'muzea nieposiadające osobowości prawnej': 'muzea nieposiadające osobowości prawnej',
    },
    voivodeships: [
        'dolnośląskie',
        'kujawsko-pomorskie',
        'lubelskie',
        'lubuskie',
        'łódzkie',
        'małopolskie',
        'mazowieckie',
        'opolskie',
        'podkarpackie',
        'podlaskie',
        'pomorskie',
        'śląskie',
        'świętokrzyskie',
        'warmińsko-mazurskie',
        'wielkopolskie',
        'zachodniopomorskie',
    ],
    dataColumns: {
        'data.nip': 'NIP',
        'data.regon': 'REGON',
        'data.street': 'Ulica (MKiDN)',
        'data.building_nb': 'Nr domu (MKiDN)',
        'data.local_nb': 'Nr lokalu (MKiDN)',
        'data.city': 'Miejscowość (MKiDN)',
        'data.postcode': 'Kod pocztowy (MKiDN)',
        'data.post': 'Poczta (MKiDN)',
        'data.commune': 'Gmina (MKiDN)',
        'data.county': 'Powiat (MKiDN)',
        'data.voivodeship': 'Województwo (MKiDN)',
        'data.phone_nb': 'Nr telefonu (MKiDN)',
        'data.email': 'e-mail (MKiDN)',
        'data.contact_street': 'Ulica (korespondencja)',
        'data.contact_building_nb': 'Nr domu (korespondencja)',
        'data.contact_local_nb': 'Nr lokalu (korespondencja)',
        'data.contact_city': 'Miejscowość (korespondencja)',
        'data.contact_postcode': 'Kod pocztowy (korespondencja)',
        'data.contact_post': 'Poczta (korespondencja)',
        'data.contact_commune': 'Gmina (korespondencja)',
        'data.contact_county': 'Powiat (korespondencja)',
        'data.contact_voivodeship': 'Województwo (korespondencja)',
        'data.contact_phone_nb': 'Nr telefonu (korespondencja)',
        'data.contact_email': 'E-mail (korespondencja)',
        'data.www': 'Strona www muzeum',
        'data.facebook': 'Adres profilu na Facebooku (opcjonalnie)',
        'data.catalog_url': 'Adres internetowego katalogu zbiorów',
        'data.year': 'Rok założenia muzeum',
        'data.administrative_size': 'Wielkość jednostki administracyjnej',
        'data.territory': 'Teren prowadzenie działalności',
        'data.financing': 'Źródło finansowania',
        'data.organizational_form': 'Forma organizacyjna muzeum',
        'data.co_lead': 'Współprowadzenie',
        'data.entity_type': 'Rodzaj podmiotu organizującego',
        'data.entity_name': 'Pełna nazwa podmiotu organizującego',
        'data.entity_street': 'Ulica (podmiot)',
        'data.entity_building_nb': 'Nr domu (podmiot)',
        'data.entity_local_nb': 'Nr lokalu (podmiot)',
        'data.entity_city': 'Miejscowość (podmiot)',
        'data.entity_postcode': 'Kod pocztowy (podmiot)',
        'data.entity_post': 'Poczta (podmiot)',
        'data.entity_commune': 'Gmina (podmiot)',
        'data.entity_county': 'Powiat (podmiot)',
        'data.entity_voivodeship': 'Województwo (podmiot)',
        'data.entity_phone_nb': 'Nr telefonu (podmiot)',
        'data.entity_email': 'E-mail (podmiot)',
        'data.entity2_type': 'Rodzaj podmiotu organizującego 2',
        'data.entity2_name': 'Pełna nazwa podmiotu organizującego 2',
        'data.entity3_type': 'Rodzaj podmiotu organizującego 3',
        'data.entity3_name': 'Pełna nazwa podmiotu organizującego 3',
        'data.entity4_type': 'Rodzaj podmiotu organizującego 4',
        'data.entity4_name': 'Pełna nazwa podmiotu organizującego 4',
        'data.outdoor': 'Muzeum na wolnym powietrzu',
        'data.scientific_type': 'Typ muzeum',
        'data.scientific_type_value': 'Jakie? (tylko dla muzeów jednorodnych)',
        'data.activity_document': 'Dokument stanowiący podstawę działalności muzeum',
        'data.mkidn_statute': 'Uzgodnienie statutu lub regulaminu z MKiDN',
        'data.register_entry': 'Wpis do Państwowego Rejestru Muzeów',
        'data.prm_number': 'Nr PRM',
        'data.sm_account': 'Konto w systemie SM',
        'data.sm_contribution': 'Udział w SM',
        'data.sm_last_edition': 'Udział w ostatniej edycji SM',
        'data.comments': 'Uwagi',
    },
};

export default Museum
