const MuseumPoll = {
    states: {
        'new': 'oczekuje na wypełnienie',
        'filling': 'w trakcie wypełniania',
        'sent': 'wysłana do weryfikacji',
        'needs_correction': 'wymaga korekty',
        'accepted': 'zaakceptowana',
    },
};

export default MuseumPoll
