import React from 'react';
import { withRouter } from "react-router";
import DataComponent from "../shared/DataComponent";
import DataTable from "../shared/DataTable";
import User from "../auth/User";

class SettingsList extends DataComponent {
    static permissions = ['view-settings'];
    url = '/settings';
    tableColumns = {
        title: 'Nazwa',
        type_string: { title: 'Rodzaj', column: 'type' },
        updated_at: 'Data aktualizacji',
    };

    render() {
        if (this.state.isLoading) {
            return this.showLoader();
        }

        return <>
            <DataTable
                storageKey={this.constructor.name}
                title="Lista ustawień"
                columns={this.getTableColumns()}
                data={this.loadTableData(this.url)}
                editLink={User.hasPermissionTo('edit-settings') ? (rowData) => '/settings/' + rowData.name : null}
            />
        </>
    }
}

export default withRouter(SettingsList);
