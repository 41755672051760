import React from 'react';
import NCForm from '../nc-form/NCForm';
import { withRouter } from "react-router";

import DataComponent from "../shared/DataComponent";
import Axios from 'axios';
import User from "../auth/User";
import PaperRoot from "../shared/PaperRoot";

class ProfileEdit extends DataComponent {
    static permissions = true; // ['change-password', 'change-email', 'change-name']
    url = '/profile';

    saveObject = (data) => {
        const { email, first_name, last_name, password, password_confirmation } = data;
        let allowed = {};
        if (User.hasPermissionTo('change-email')) {
            allowed.email = email;
        }
        if (User.hasPermissionTo('change-email')) {
            allowed.first_name = first_name;
            allowed.last_name = last_name;
        }
        if (User.hasPermissionTo('change-password')) {
            allowed.password = password;
            allowed.password_confirmation = password_confirmation;
        }
        Axios.put('/profile', allowed).then(response => {
            User.setToken(response.data.token);
            this.reloadStateData(response.data);
        }).catch(this.catchErrors);
    };

    getTitle = () => {
        return User.hasRememberToken() ? "Twoje konto" : "Wymagana zmiana hasła";
    };

    render() {
        if (this.state.isLoading) {
            return this.showLoader();
        }

        const fields = [
            {
                name: 'email',
                type: 'text',
                label: 'Login (adres email)',
                width: 616,
                disabled: !User.hasPermissionTo('change-email'),
            },
            { type: 'divider' },
            {
                name: 'first_name',
                type: 'text',
                label: 'Imię',
                width: 300,
                disabled: !User.hasPermissionTo('change-name'),
            },
            {
                name: 'last_name',
                type: 'text',
                label: 'Nazwisko',
                width: 300,
                disabled: !User.hasPermissionTo('change-name'),
            },
            { type: 'divider' },
            {
                name: 'password',
                type: 'password',
                label: this.hasObject() ? 'Nowe hasło' : 'Hasło',
                width: 300,
                disabled: !User.hasPermissionTo('change-password'),
            },
            {
                name: 'password_confirmation',
                type: 'password',
                label: 'Potwierdź hasło',
                width: 300,
                disabled: !User.hasPermissionTo('change-password'),
            },
        ];

        return <PaperRoot>
            <NCForm
                // key={'profile' + User.getRememberToken()} // not needed cause it's now controlled
                fields={fields}
                title={this.getTitle()}
                values={this.getValues()}
                errors={this.getErrors()}
                onChange={this.changeHandler}
                saveHandler={this.saveObject}
                hideSubmitButton={!User.hasAnyPermission(['change-password', 'change-email', 'change-name'])}
            />
        </PaperRoot>
    }
}

export default withRouter(ProfileEdit);
