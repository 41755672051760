import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import QuestionTableCreator from "./QuestionTableCreator";
import jexcel from "jexcel";
import Chip from "@material-ui/core/Chip";
import Tooltip from '@material-ui/core/Tooltip';
import Typography from "@material-ui/core/es/Typography/Typography";
import QuestionPicker from "../questions/QuestionPicker";
import { withStyles } from "@material-ui/core";
import { clone } from "../shared/Helpers";

class TableQuestionPicker extends QuestionTableCreator {
    historyActive = false;

    constructor(props) {
        super(props);

        this.options.allowInsertRow = false;
        this.options.allowManualInsertRow = false;
        this.options.allowDeleteRow = false;
        this.options.onselection = this.selectionHandler;

        this.state.values = clone(props.question.parent_question, {});
    }

    renderToolbar = () => {
        return ''
    };

    renderDialogs = () => {
        if (!!this.state.cell) {
            return <QuestionPicker
                question={this.props.question}
                changeHandler={this.setCellData}
            />
        }

        return '';
    };

    setCellData = (parentQuestion, parentCell) => {
        if (parentQuestion) {
            let { cell, values } = this.state;
            const { name, x, y } = cell;

            values[name] = { x, y, id: parentQuestion.id, cell: parentCell, question: parentQuestion };

            this.setState({ values, cell: null }, () => {
                this.props.changeHandler(values);
                this.jexcel.updateTableReferences();
                // this.forceUpdate();
            });
        } else {
            this.setState({ cell: null });
        }
    };

    removeCellData = (cellName) => event => {
        let { values } = this.state;
        delete values[cellName];
        this.setState({ values }, () => {
            this.props.changeHandler(values);
            this.jexcel.resetSelection();
            this.jexcel.updateTableReferences();
        });
    };

    selectionHandler = (instance, x, y, x2, y2) => {
        const name = jexcel.getColumnNameFromId([x, y]);
        let { values } = this.state;
        if (!values[name]) {
            this.setState({ cell: { name, x, y } });
        } else {
            // do nothing - cell has value
        }
    };

    getCellLabel = (cellName, cellDom) => {
        if (!cellName || !this.state.values[cellName]) {
            return '';
        }

        const { classes } = this.props;
        const { /*x, y, id,*/ cell, question } = this.state.values[cellName];

        ReactDOM.render(<Tooltip PopperProps={{ disablePortal: true }} title={question.name + (cell ? ' [komórka: ' + cell.name + ']' : '')}>
            <Chip
                label={<>
                    <Typography noWrap={true} className={classes.chipText}>{question.name}</Typography>
                    {cell ? <Typography className={classes.chipText}>&nbsp;[{cell.name}]</Typography> : ''}
                </>}
                onDelete={this.removeCellData(cellName)}
                size="small"
            />
        </Tooltip>, cellDom);

        // return cellDom.innerHtml;
    };
}

TableQuestionPicker.propTypes = {
    question: PropTypes.object.isRequired,
    table: PropTypes.object.isRequired,
    changeHandler: PropTypes.func.isRequired,
};
TableQuestionPicker.defaultProps = {
    table: {},
    changeHandler: () => {},
    isDynamic: false,
};

const styles = theme => ({
    chipText: {
        maxWidth: 110,
        fontSize: 'inherit',
    },
});

export default withStyles(styles)(TableQuestionPicker);
