import React from 'react';
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/core/styles';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import FabButton from "../shared/FabButton";
import { Editor } from "@tinymce/tinymce-react";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import BaseComponent from "../shared/BaseComponent";

class NCChoiceCreator extends BaseComponent {
    state = {
        choices: {},
    };

    constructor(props) {
        super(props);

        this.state.choices = Object.values(this.props.choices);
        this.onChange = this.props.onChange;
    }

    handleChange = () => {
        this.onChange(this.props.valuesKey, this.state.choices);
    };

    changeItem = (key, field) => event => {
        let choices = this.state.choices;
        if (event.target.type === 'checkbox') {
            choices[key][field] = event.target.checked;
        } else {
            choices[key][field] = event.target.value;
        }
        this.setState({ choices: choices }, this.handleChange);
    };

    changeTinyItem = (key, field) => event => {
        let choices = this.state.choices;
        choices[key][field] = event.target.getContent();

        this.setState({ choices: choices }, this.handleChange);
    };

    addItem = (key) => event => {
        let choices = this.state.choices;

        if (key === undefined) {
            choices.push({ name: '', has_text: false });
        } else {
            choices.splice(key, 0, { name: '', has_text: false });
        }
        this.setState({ choices: choices }, this.handleChange);
    };

    removeItem = (key) => event => {
        let choices = this.state.choices;
        choices.splice(key, 1);
        this.setState({ choices: choices }, this.handleChange);
    };

    render() {
        const { classes, errors, hasText, width, tiny } = this.props;
        const { choices } = this.state;

        return (<List dense>
            {choices.map((item, key) => {
                let error = errors[key] || {};
                return <ListItem dense className={classes.listItem} key={key}>
                    <ListItemIcon>
                        <div>
                            <FabButton title="Dodaj" onClick={this.addItem(key)} color="primary" className={classes.smallButton} style={{ marginRight: 10 }}>
                                <AddIcon className={classes.smallIcon}/>
                            </FabButton>
                            <FabButton title="Usuń" onClick={this.removeItem(key)} color="secondary" className={classes.smallButton} style={{ marginRight: 14 }}>
                                <RemoveIcon className={classes.smallIcon}/>
                            </FabButton>
                        </div>
                    </ListItemIcon>
                    {tiny ? (
                        <FormControl error={!!error} style={{ width }}>
                            <Editor
                                placeholder={"Odpowiedź " + (key + 1)}
                                initialValue={item.name || ""}
                                init={{
                                    menubar: false,
                                    plugins: 'autoresize paste',
                                    paste_as_text: true,
                                    content_css: '/tinymce.css?2',
                                    // https://www.tiny.cloud/docs/advanced/editor-control-identifiers/#toolbarcontrols
                                    toolbar: [
                                        'undo redo',
                                        'removeformat bold italic underline strikethrough fontsizeselect',
                                    ].join(' | '),
                                    force_br_newlines: false,
                                    force_p_newlines: false,
                                    forced_root_block: 'div',
                                    entity_encoding: 'raw',
                                    statusbar: false,
                                    elementpath: false,
                                    branding: false,
                                    contextmenu: '',
                                    autoresize_bottom_margin: 0,
                                }}
                                onChange={this.changeTinyItem(key, 'name')}
                            />
                            {error['name'] && <FormHelperText>{error['name']}</FormHelperText>}
                        </FormControl>
                    ) : (
                        <TextField
                            placeholder={"Odpowiedź " + (key + 1)}
                            margin="normal"
                            className={classes.textField}
                            style={{ width }}
                            onChange={this.changeItem(key, 'name')}
                            value={item.name || ""}
                            error={!!error['name']}
                            helperText={error['name'] ? error['name'] : ''}
                        />
                    )}
                    {hasText &&(
                        <FormControlLabel
                            control={<Checkbox
                                onChange={this.changeItem(key, 'has_text')}
                                checked={!!item.has_text}
                                value="1"
                            />}
                            label="Pole tekstowe na odpowiedź"
                            style={{ width: 250, marginLeft: 0 }}
                        />
                    )}
                </ListItem>
            })}

            <ListItem dense style={{ marginTop: 10 }} className={classes.listItem} key="add">
                <FabButton title="Dodaj" onClick={this.addItem()} color="primary" className={classes.smallButton}>
                    <AddIcon className={classes.smallIcon}/>
                </FabButton>
            </ListItem>
        </List>);
    }
}

NCChoiceCreator.propTypes = {
    onChange: PropTypes.func,
    valuesKey: PropTypes.string,
    choices: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ]).isRequired,
    errors: PropTypes.array,
    hasText: PropTypes.bool,
    width: PropTypes.number,
    tiny: PropTypes.bool,
};
NCChoiceCreator.defaultProps = {
    onChange: () => {},
    valuesKey: 'choices',
    choices: [],
    errors: [],
    hasText: true,
    width: 500,
    tiny: true,
};

const styles = theme => ({
    listItem: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    textField: {
        marginTop: 0,
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(),
    },
    smallButton: {
        width: 20,
        height: 20,
        minWidth: 0,
        minHeight: 0,
    },
    smallIcon: {
        width: 20,
        height: 20,
    },
});

export default withStyles(styles)(NCChoiceCreator);
