import React from 'react';
import { withRouter } from "react-router";
import DataComponent from "../shared/DataComponent";
import DataTable from "../shared/DataTable";

import User from "../auth/User";
import Poll from "../../models/Poll";

class AllPollsList extends DataComponent {
    static permissions = ['view-polls'];
    url = '/projects/polls';
    tableColumns = {
        'project.name': 'Projekt',
        id: 'ID ankiety',
        name: 'Nazwa ankiety',
        type_string: { field: 'type', title: 'Rodzaj', lookup: Poll.types },
        year: 'Okres',
        'user.full_name': 'Właściciel',
        starts_at: 'Data rozpoczęcia',
        ends_at: 'Data zakończenia',
        deadline: 'Data zablokowania',
        created_at: 'Data dodania',
    };

    constructor(props) {
        super(props);

        this.addDeletedAtColumn();
    }

    render() {
        if (this.state.isLoading) {
            return this.showLoader();
        }

        return (
            <DataTable
                storageKey={this.constructor.name}
                title="Lista wszystkich ankiet"
                columns={this.getTableColumns()}
                data={this.loadTableData(this.url)}
                showLink={User.hasPermissionTo('view-polls') ? (rowData) => '/projects/' + rowData.project_id + '/polls/' + rowData.id + '/list' : null}
                editLink={User.hasPermissionTo('edit-polls') ? (rowData) => '/projects/' + rowData.project_id + '/polls/' + rowData.id + '/edit' : null}
            />
        );
    }
}

export default withRouter(AllPollsList);
