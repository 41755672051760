const Question = {
    types: {
        // 'label': 'Etykieta',
        'boolean': 'TAK / NIE',
        'number': 'Liczbowe',
        'text': 'Tekstowe',
        'regexp': 'RegExp',
        'mask': 'Maska',
        'email': 'Email',
        'open': 'Otwarte',
        'choice_single': 'Jednokrotny wybór',
        'choice_multiple': 'Wielokrotny wybór',
        'choice_fixed': 'Skala odpowiedzi',
        'static_table': 'Tabela statyczna',
        'dynamic_table': 'Tabela dynamiczna',
        'date': 'Data',
        'year': 'Rok',
        'file': 'Plik',
    },
    maskVariants: {
        'phone': 'Numer telefonu',
        'postcode': 'Kod pocztowy',
    },
    states: {
        'draft': 'Wersja robocza',
        'ready': 'Gotowe do moderacji',
        'published': 'Opublikowane',
    },
    details: {
        'no_data': 'Brak danych',
        'unrelated': 'Nie dotyczy',
        'estimated': 'Wartość szacunkowa',
        'zeros': 'Wyzeruj',
        'clear': 'Wyczyść',
    },
    aggregateTypes: ['lt', 'eq', 'gt'],
};

export default Question
