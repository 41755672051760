import PropTypes from "prop-types";
import Question from "../../models/Question";
import TablePicker from "../shared/TablePicker";

class PollQuestionsPicker extends TablePicker {
    url = '/projects/polls/questions';
    tableColumns = {
        id: 'ID',
        name_string: 'Pytanie',
        is_active_string: { field: 'is_active', title: 'Aktywne?', lookup: { 0: 'Nie', 1: 'Tak' } },
        is_public_string: { field: 'is_public', title: 'Publiczne?', lookup: { 0: 'Nie', 1: 'Tak' } },
        type_string: { field: 'type', title: 'Typ', lookup: Question.types },
        'catalog.name': 'Katalog',
    };
    dialogTitle = 'Dodawanie pytań do działu: ';
    tableTitle = 'Dostępne pytania';

    constructor(props) {
        super(props);

        this.dialogTitle += props.sectionName;
        this.values = props.questions;
    }
}

PollQuestionsPicker.propTypes = {
    questions: PropTypes.array,
    changeHandler: PropTypes.func.isRequired,
    sectionName: PropTypes.string,
};
PollQuestionsPicker.defaultProps = {
    questions: [],
    sectionName: '',
};

export default PollQuestionsPicker;
