import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import NCForm from '../nc-form/NCForm';
import Button from '@material-ui/core/Button';
import Axios from 'axios';
import AddIcon from '@material-ui/icons/Add';
import Chip from '@material-ui/core/Chip';
import PollQuestionPicker from "../polls/PollQuestionPicker";
import PollPicker from "../polls/PollPicker";
import { dotSet } from "../shared/Helpers";
import BaseComponent from "../shared/BaseComponent";

class SystemReports extends BaseComponent {
    static permissions = ['system-reports'];
    state = {
        values: {
            questions: [],
            poll_id: null,
            type: null,
        },
        questionPicker: false,
        pollPicker: false,
        poll: false,
    }

    generateReport = (values) => {
        if (values.type !== '') {
            Axios({
                url: '/system-reports/' + values.type,
                method: 'POST',
                responseType: 'blob',
                data: this.state.values
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'raport.xlsx');
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
            });
        }
    }

    changeSettings = (name, value) => {
        let pollPicker = false;
        if (name === 'poll_id' && value === true) {
            value = null;
            pollPicker = true;
        }
        this.setState({ values: dotSet(this.state.values, name, value), pollPicker });
    }

    addQuestions = (questions) => {
        let values = this.state.values;

        values['questions'] = values['questions'].concat(questions);

        this.setState({ values: values, questionPicker: false });
    }

    setPoll = poll => {
        this.setState({
            poll,
            values: dotSet(this.state.values, 'poll_id', poll ? poll.id : ''),
            pollPicker: false,
        });
    };

    openQuestionsPicker = () => {
        this.setState({ questionPicker: true });
    }

    deleteQuestion = (key, event) => () => {
        let values = this.state.values;
        delete values.questions[key];

        this.setState({ values });
    }

    render() {
        const { classes } = this.props;
        const { values, poll, pollPicker, questionPicker } = this.state;

        const fields = [
            {
                name: 'type',
                type: 'select',
                label: 'Rodzaj raportu',
                width: 400,
                options: [
                    {
                        value: 'users',
                        text: 'Założone konta'
                    },
                    {
                        value: 'museums-filled',
                        text: 'Muzea, które złożyły ankiety'
                    },
                    {
                        value: 'museums-not-filled',
                        text: 'Muzea, które nie złożyły ankiety'
                    },
                    {
                        value: 'museums-not-participating',
                        text: 'Muzea, które nie brały udziału w ankiecie'
                    }
                ]
            },
            {
                label: 'Okres od',
                name: 'date_from',
                type: 'datetime',
                disablePast: false,
                width: 160
            },
            {
                label: 'Okres do',
                name: 'date_to',
                type: 'datetime',
                disablePast: false,
                width: 160
            },
            {
                type: 'divider',
                style: {
                    flexBasis: '100%',
                }
            }
        ];

        if (['museums-filled', 'museums-not-filled', 'museums-not-participating'].includes(values.type)) {
            let pollOptions = [{ value: true, text: '- wyszukaj -' }];
            if (poll) {
                pollOptions = [{ value: poll.id, text: poll.name + ' [' + poll.type_string + ']' }].concat(pollOptions);
            }
            fields.push({
                label: 'Ankieta',
                name: 'poll_id',
                type: 'select',
                width: 752,
                emptyValue: '- wszystkie -',
                options: pollOptions,
                // containerStyle: { width: '100%', maxWidth: 'calc(100vw - 40px)' },
                // width: '100%',
            });
            if (['museums-filled', 'museums-not-filled'].includes(values.type)) {
                fields.push({ type: 'divider' });
                if (poll) {
                    fields.push({
                        label: 'Stan wypełnienia działów',
                        name: 'section_counts',
                        type: 'boolean',
                    });
                }
                fields.push({ type: 'divider' });
                fields.push({
                    type: 'custom',
                    component: <Box key="questions" mt={2}>
                        <Button variant="contained" color="primary" onClick={this.openQuestionsPicker}><AddIcon/> Dodaj pytanie uzupełniające</Button>
                        <Box mt={2}>
                            {values.questions.map((question, key) => {
                                return <Chip
                                    key={key}
                                    label={question.name_string}
                                    variant="outlined"
                                    onDelete={this.deleteQuestion(key)}
                                />
                            })}
                        </Box>
                    </Box>
                });
            }
        }

        return (
            <Paper className={classes.root}>
                <NCForm
                    fields={fields}
                    values={values}
                    hideSubmitButton={values.type === ''}
                    onChange={this.changeSettings}
                    saveHandler={this.generateReport}
                    saveButtonLabel="Generuj raport"
                    title="Wybierz rodzaj raportu"
                />

                {!!questionPicker && (
                    <PollQuestionPicker
                        sectionName="Raporty systemowe"
                        questions={values.questions}
                        changeHandler={this.addQuestions}
                    />
                )}

                {!!pollPicker && (
                    <PollPicker changeHandler={this.setPoll} multiple={false}/>
                )}
            </Paper>
        );
    }
}

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
});

export default withStyles(styles)(SystemReports);
