import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router";
import { withStyles } from '@material-ui/core/styles';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from "@material-ui/icons/Delete";
import UnarchiveIcon from '@material-ui/icons/Unarchive';

import NCForm from "../nc-form/NCForm";
import DataComponent from "../shared/DataComponent";
import DataTable from "../shared/DataTable";
import { clone, debounce } from "../shared/Helpers";
import TooltipIconButton from "../shared/TooltipIconButton";
import { DialogContent, DialogTitle } from "../shared/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";

class PollUnregisteredMuseums extends DataComponent {
    formRef = React.createRef();
    tableColumns = {
        email: 'Email',
        name: 'Nazwa instytucji',
    };

    constructor(props) {
        super(props);

        this.state.item = null;
        this.state.editItem = null;
        this.state.isEmailValid = false;
        this.state.isLoading = false;
        this.state.values = this.props.unregisteredMuseums;
    }

    changeHandler = debounce(() => {
        this.props.changeHandler(this.props.valuesKey, this.getValues());
    });

    changeItem = (name, value) => {
        let item = this.state.item;
        item[name] = value;

        this.setState({ item, isEmailValid: this.formRef.current.checkValidity() });
    };

    showAddItem = () => {
        this.setState({ item: { email: '', name: '' } });
    };

    addItem = () => {
        this.setState({
            values: [this.state.item].concat(this.state.values),
            item: null,
            isEmailValid: false,
        }, this.changeHandler);
    };

    showEditItem = (key) => {
        this.setState({ item: clone(this.state.values[key]), editItem: key, isEmailValid: true });
    };

    editItem = (key) => event => {
        let values = this.state.values;
        values[key] = clone(this.state.item);

        this.setState({
            values: values,
            item: null,
            editItem: null,
            isEmailValid: false,
        }, this.changeHandler);
    };

    loadCSV = event => {
        this.setState({ isLoading: true });

        const Papa = require("papaparse/papaparse.min.js");
        let values = this.getValues();

        Papa.parse(event.target.files[0], {
            skipEmptyLines: true,
            step: (results, parser) => {
                const [email, name] = results.data;
                if (email) {
                    values.push({ email, name });
                }
            },
            complete: (results, file) => {
                this.setState({ values, isLoading: false }, this.changeHandler);
            },
        });

        // let fileReader = new FileReader();
        // fileReader.onloadstart = () => {
        //     this.setState({ isLoading: true });
        //     console.log('loading');
        // };
        // fileReader.onloadend = () => {
        //     console.log(fileReader.result);
        //     // PARSE HERE
        //     this.setState({ values, isLoading: false }, this.changeHandler);
        // };
        // fileReader.readAsText(event.target.files[0]);
    };

    closeDialogs = () => {
        this.setState({ item: null, editItem: null, isEmailValid: false });
    };

    render() {
        const { classes } = this.props;
        const { item, editItem, isEmailValid, isLoading } = this.state;
        const editAction = editItem !== null ? 'Zmień' : 'Dodaj';

        return <>
            <input
                id="csv-file"
                type="file"
                accept=".csv,text/csv"
                style={{ display: 'none' }}
                onChange={this.loadCSV}
            />
            <DataTable
                isLoading={isLoading}
                tableRef={this.tableRef}
                storageKey={this.constructor.name}
                title="Instytucje niezarejestrowane"
                columns={this.getTableColumns()}
                data={this.getValues()}
                options={{
                    selection: true,
                }}
                onRowClick={(event, row) => {
                    this.showEditItem(row.tableData.id);
                }}
                actions={[
                    {
                        tooltip: 'Dodaj',
                        icon: () => <AddCircleIcon className={classes.addButton} />,
                        isFreeAction: true,
                        onClick: this.showAddItem,
                    },
                    {
                        component: <TooltipIconButton
                            title="Importuj z CSV"
                            component="label"
                            variant="contained"
                            color="primary"
                            htmlFor="csv-file">
                            <UnarchiveIcon className={classes.addButton}/>
                        </TooltipIconButton>,
                        isFreeAction: true,
                    },
                    {
                        tooltip: 'Usuń',
                        icon: DeleteIcon,
                        onClick: (event, rows) => {
                            let values = this.getValues();
                            rows.forEach((row) => {
                                delete values[row.tableData.id];
                            });
                            this.setState({ values: values.filter(Boolean) }, this.changeHandler);
                        },
                    },
                ]}
            />

            {item !== null && <Dialog open={true} onClose={this.closeDialogs}>
                <DialogTitle onClose={this.closeDialogs}>{editAction} instytucję niezarejestrowaną</DialogTitle>
                <DialogContent>
                    <NCForm
                        formRef={this.formRef}
                        fields={[
                            {
                                name: 'email',
                                type: 'email',
                                label: 'Adres email osoby kontaktowej',
                                width: 560,
                            },
                            {
                                name: 'name',
                                type: 'text',
                                label: 'Nazwa instytucji',
                                width: 560,
                            },
                        ]}
                        values={item}
                        onChange={this.changeItem}
                        hideSubmitButton
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={this.closeDialogs} color="default">Anuluj</Button>
                    <Button variant="contained"
                            onClick={editItem !== null ? this.editItem(editItem) : this.addItem}
                            disabled={!isEmailValid}
                            color="primary">{editAction}</Button>
                </DialogActions>
            </Dialog>}
        </>
    }
}

PollUnregisteredMuseums.propTypes = {
    valuesKey: PropTypes.string,
    unregisteredMuseums: PropTypes.array.isRequired,
    changeHandler: PropTypes.func.isRequired,
};
PollUnregisteredMuseums.defaultProps = {
    valuesKey: 'unregistered_museums',
    unregisteredMuseums: [],
};

const styles = theme => ({
    addButton: {
        color: theme.palette.primary.main,
    },
});

export default withStyles(styles)(withRouter(PollUnregisteredMuseums));
