import React from 'react';
import { withRouter } from "react-router";
import DataComponent from "../shared/DataComponent";
import DataTable from "../shared/DataTable";

import AddIcon from '@material-ui/icons/Add';
import User from "../auth/User";
import FabContainer from "../shared/FabContainer";
import { FabLink } from "../shared/FabButton";

class RoleList extends DataComponent {
    static permissions = ['view-roles'];
    url = '/users/roles';
    tableColumns = {
        id: 'ID',
        title: 'Nazwa',
        users_count: { title: 'Liczba użytkowników', filtering: false },
        protected_string: { field: 'protected', title: 'Systemowa?', lookup: { 0: 'Nie', 1: 'Tak' } },
        created_at: 'Data utworzenia',
    };

    constructor(props) {
        super(props);

        this.addDeletedAtColumn();
    }

    render() {
        if (this.state.isLoading) {
            return this.showLoader();
        }

        return <>
            <DataTable
                storageKey={this.constructor.name}
                title={"Lista ról"}
                columns={this.getTableColumns()}
                data={this.loadTableData(this.url)}
                editLink={User.hasPermissionTo('edit-roles') ? (rowData) => '/users/roles/' + rowData.id + '/edit' : null}
            />

            <FabContainer>
                {User.hasPermissionTo('edit-roles') && (
                    <FabLink title="Dodaj rolę" to="/users/roles/add" color="primary"><AddIcon/></FabLink>
                )}
            </FabContainer>
        </>
    }
}

export default withRouter(RoleList);
