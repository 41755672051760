import React from 'react';
import { withRouter } from "react-router";
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';

import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import Button from '@material-ui/core/Button';
import Paper from "@material-ui/core/Paper";

import { Popper, Tooltip } from "@material-ui/core";
import { blue, yellow, green, red, purple } from '@material-ui/core/colors';

import List from "@material-ui/core/List";
import User from "../auth/User";
import PollComments from "../nc-poll/PollComments";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ExpansionPanelDetails from '@material-ui/core/AccordionActions';
import NCPollAnswer from "../nc-poll/NCPollAnswer";
import Divider from "@material-ui/core/Divider";
import ExpansionPanelActions from '@material-ui/core/AccordionActions';
import MuiExpansionPanel from '@material-ui/core/Accordion';
import MuiExpansionPanelSummary from '@material-ui/core/AccordionSummary';
import ListSubheader from "@material-ui/core/ListSubheader";
import PropTypes from "prop-types";
import BaseComponent from "../shared/BaseComponent";
import MuseumPollContext from "./MuseumPollContext";

class MuseumViewPollSection extends BaseComponent {
    static contextType = MuseumPollContext;
    panelRef = React.createRef();
    legend = '';

    state = {
        isExpanded: true,
    };

    render() {
        const {
            classes,
            section,
            updateKey,
            answersData,
            museumPoll,
            isPollLocked,
            lockedBy,
            answersCounts,
            questionsCounts,
            showAnswersCounts,
            showActions,
            showComments,
            updateHandler,
            validateHandler,
            answerHandler,
            setPollStateHandler,
            permissionsDialogHandler,
        } = this.props;
        if (!section) {
            return '';
        }
        const { isExpanded } = this.state;
        const { isSaving = false } = this.context.getState();
        const isLocked = !!lockedBy;

        const isSectionLocked = (
            (section.state === 'ready' || isLocked || isPollLocked)
            && !User.hasPermissionTo('verify-museum-polls')
        );

        let sectionClass = null;
        switch (true) {
            case isLocked:
                sectionClass = classes.colorChanging;
                break;
            case answersCounts === questionsCounts:
                sectionClass = classes.colorReady;
                break;
            case answersCounts >= 1:
                sectionClass = classes.colorFilling;
                break;
            case answersCounts === 0:
                sectionClass = classes.colorWaiting;
                break;
        }

        return <ExpansionPanel ref={this.panelRef}
                               className={classNames(classes.panel, sectionClass)}
                               expanded={isExpanded}
                               onChange={() => this.setState({ isExpanded: !isExpanded })}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                {!!this.panelRef.current && isLocked && (
                    <Popper placement="top"
                            style={{ zIndex: 10 }}
                            disablePortal={true}
                            open={true}
                            modifiers={{ inner: { enabled: true } }}
                            anchorEl={this.panelRef}>
                        <Paper className={classes.popper}>
                            <Typography>Wypełnia: {lockedBy}</Typography>
                        </Paper>
                    </Popper>
                )}
                <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                    <Typography variant="h5" component="h2" className={classes.heading} style={{ marginTop: isLocked ? 24 : 0 }}>
                        {section.name}
                        {User.hasPermissionTo('edit-museum-poll-permissions') && (
                            User.getMuseumId() === museumPoll.museum_id || User.hasAnyPermission('verify-museum-polls', 'send-museum-polls')
                        ) && (
                            <IconButton style={{ marginLeft: 10, zIndex: 0 }} aria-label="Close" className={classes.closeButton} onClick={permissionsDialogHandler}>
                                <SettingsIcon/>
                            </IconButton>
                        )}
                        {showAnswersCounts && (
                            <Tooltip title="Udzielonych odpowiedzi">
                                <Typography component="span" className={classes.questionsCount} style={{ marginRight: 24, marginTop: 16 }}>
                                    {answersCounts}/{questionsCounts}
                                </Typography>
                            </Tooltip>
                        )}
                    </Typography>
                    <div className={classes.panelMain}>
                        <List disablePadding>
                            <ListSubheader className={classes.ownerInfo}>Osoba do kontaktu</ListSubheader>
                            {section.owners.map((owner, ownerKey) =>
                                <ListItem key={ownerKey} alignItems="flex-start">
                                    <ListItemAvatar>
                                        <Avatar className={classes.ownerAvatar}>
                                            {owner.user.first_name.substr(0, 1) + owner.user.last_name.substr(0, 1)}
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={owner.user.full_name}
                                        secondaryTypographyProps={{ component: 'div' }}
                                        secondary={<>
                                            {owner.user.phone ? <>{owner.user.phone}<br/></> : ''}
                                            {owner.user.email ? <a href={'mailto:' + owner.user.email} onClick={e => e.stopPropagation()}>{owner.user.email}</a> : ''}
                                        </>}
                                    />
                                </ListItem>
                            )}
                        </List>
                    </div>

                    <List className={classes.panelComments} disablePadding onClick={(e) => e.stopPropagation()}>
                        {showComments && (
                            <PollComments
                                key={section.id + updateKey}
                                updateKey={updateKey}
                                comments={section.comments}
                                museumPollId={museumPoll.id}
                                commentableId={section.id}
                                commentableType="PollSection"
                                updateHandler={updateHandler}
                                isLocked={isSectionLocked}
                                unlockComments={User.hasPermissionTo('verify-museum-polls') && museumPoll.state === 'sent'}
                            />
                        )}
                    </List>
                </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.panelDetails}>
                <Typography component='div' dangerouslySetInnerHTML={{ __html: section.description }}/>
                {section.poll_questions.map((pollQuestion, pollQuestionKey) => {
                    const answerData = answersData[pollQuestion.question_id] || {};
                    const parentData = answersData[answerData.parentId];
                    if (!answerData || !answerData.isVisible || (parentData && !parentData.isVisible)) {
                        return '';
                    }
                    const isMarked = museumPoll.state === 'sent' && pollQuestion.answer_updated_at > museumPoll.correction_date;

                    return <NCPollAnswer
                        context={this.context}
                        key={pollQuestionKey + (section.updateKey || '')}
                        updateKey={updateKey}
                        number={answerData.number}
                        isLocked={isSectionLocked}
                        isMarked={isMarked}
                        unlockComments={User.hasPermissionTo('verify-museum-polls') && museumPoll.state === 'sent'}
                        pollQuestion={pollQuestion}
                        answer={pollQuestion.answer}
                        details={pollQuestion.answer_details}
                        museumPollId={museumPoll.id}
                        validateHandler={validateHandler}
                        updateHandler={updateHandler}
                        answerHandler={answerHandler(pollQuestionKey, pollQuestion.question_id)}
                        autoSave={!museumPoll.was_sent_to_verification}
                    />
                })}
            </ExpansionPanelDetails>
            <Divider/>
            {showActions && (
                <ExpansionPanelActions>
                    {section.state === 'ready' ? (
                        User.hasPermissionTo('unlock-museum-poll-sections') && (
                            <Button size="large" variant="contained" color="secondary" className={classes.confirmButton}
                                    onClick={setPollStateHandler('needs_correction', section.id)}
                                    disabled={isPollLocked || isSaving}>
                                Odblokuj
                            </Button>
                        )
                    ) : (
                        User.hasPermissionTo('lock-museum-poll-sections') && (
                            <Button size="large" variant="contained" color={section.state === 'ready' ? 'primary' : 'secondary'}
                                    className={classes.confirmButton}
                                    onClick={setPollStateHandler('ready', section.id)}
                                    disabled={['sent', 'accepted'].includes(museumPoll.state)
                                    || isPollLocked
                                    || answersCounts !== questionsCounts
                                    || isLocked
                                    || isSaving
                                    }>
                                Gotowe do złożenia
                            </Button>
                        )
                    )}
                </ExpansionPanelActions>
            )}
        </ExpansionPanel>
    }
}

MuseumViewPollSection.propTypes = {
    section: PropTypes.object.isRequired,
    museumPoll: PropTypes.object,
    answersData: PropTypes.object,
    isPollLocked: PropTypes.bool,
    lockedBy: PropTypes.string,
    answersCounts: PropTypes.number,
    questionsCounts: PropTypes.number,
    showAnswersCounts: PropTypes.bool,
    showActions: PropTypes.bool,
    showComments: PropTypes.bool,
    answerHandler: PropTypes.func,
    updateHandler: PropTypes.func,
    validateHandler: PropTypes.func,
    setPollStateHandler: PropTypes.func,
    permissionsDialogHandler: PropTypes.func,
};
MuseumViewPollSection.defaultProps = {
    museumPoll: {},
    answersData: {},
    isPollLocked: false,
    lockedBy: '',
    answersCounts: 0,
    questionsCounts: 0,
    showAnswersCounts: false,
    showActions: true,
    showComments: true,
    answerHandler: () => () => {},
    updateHandler: () => {},
    validateHandler: () => {},
    setPollStateHandler: () => () => {},
    permissionsDialogHandler: () => {},
};

const ExpansionPanel = withStyles({
    root: {
        width: '100%',
    },
    expanded: {
        margin: 0
    },
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
    root: {
        minHeight: 0,
        '&$expanded': {
            minHeight: 0,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiExpansionPanelSummary);

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    popper: {
        ...theme.mixins.gutters(),
        paddingTop: 4,
        paddingBottom: 4,
        marginTop: 2,
        marginLeft: 2,
    },
    stepper: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: theme.spacing(1),
        backgroundColor: '#fff',
    },
    stepperBox: {
        top: 64,
        zIndex: 100,
        position: 'sticky',
    },
    addButton: {
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 20,
        left: 'auto',
        position: 'fixed',
    },
    textArea: {
        flex: 12,
        width: 800
    },
    button: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(),
    },
    actionsContainer: {
        marginBottom: theme.spacing(),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    heading: {
        flexBasis: '100%',
        // fontSize: theme.typography.pxToRem(15),
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    ownerName: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        marginTop: 5
    },
    panelDetails: {
        display: 'block',
    },
    panelMain: {
        flexBasis: '70%',
    },
    panelComments: {
        flexBasis: '30%',
    },
    panel: {
        width: '100%',
        margin: '16px 0',
    },
    ownerInfo: {
        lineHeight: 'normal',
    },
    ownerAvatar: {
        backgroundColor: purple[400],
    },
    table: {
        width: '100%',
    },
    badge: {
        marginLeft: 10
    },
    menuIcon: {
        marginRight: 10
    },
    addCommentIcon: {
        cursor: 'pointer'
    },
    confirmButton: {
        marginTop: 10,
        clear: 'both',
        marginRight: 10
    },
    legendButton: {
        whiteSpace: 'nowrap',
    },
    questionsCount: {
        position: 'absolute',
        top: 0,
        right: 0,
    },
    stepBadge: {
        transform: 'none',
        position: 'relative',
        fontSize: 'inherit',
        height: theme.spacing(3),
        padding: 8,
    },
    colorWaiting: {
        backgroundColor: blue[200],
    },
    colorFilling: {
        backgroundColor: yellow[200],
    },
    colorReady: {
        backgroundColor: green[200],
    },
    colorChanging: {
        backgroundColor: red[200],
    },
    warningList: {
        paddingLeft: theme.spacing(2),
    },
    noPermissions: {
        marginTop: theme.spacing(2),
        fontStyle: 'italic',
    },
});

export default withStyles(styles)(withRouter(MuseumViewPollSection));
