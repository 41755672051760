import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router";
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import Slide from '@material-ui/core/Slide';
import Axios from 'axios';
import { queryStringify } from "./Helpers";
import User from "../auth/User";
import BaseComponent from "./BaseComponent";

const styles = theme => ({
    loaderIndicator: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        width: '100%',
        zIndex: 99999
    },
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
    },
    warning: {
        backgroundColor: amber[700],
    },
});

class Loader extends BaseComponent {
    queue = [];
    state = {
        message: {},
        isLoading: false,
        open: false,
    };

    constructor(props) {
        super(props);

        Axios.defaults.withCredentials = true;
        Axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;
        Axios.defaults.paramsSerializer = (params) => {
            return queryStringify(params);
        };

        Axios.interceptors.response.use((response) => {
            const { data, config } = response;
            const { message } = data;

            if (!config.timeout) { // do not use loader for hidden requests
                this.setState({ isLoading: false });
            }
            this.queueMessage(message);

            // if(response.data.data !== undefined){
            //     // simplify response so it contains data only:
            //     response.data = response.data.data;
            // }

            return response;
        }, (error) => {
            this.setState({ isLoading: false });

            if (error.code === 'ECONNABORTED') {
                return; // prevents from showing errors on page refresh
            }
            if (error.response) {
                const { status, data } = error.response;

                if (['logout', 'token_error'].includes(data.token)) {
                    // User.logOut();
                    this.queueMessage({ type: 'error', content: 'Twoja sesja wygasła - zaloguj się ponownie.' });
                    localStorage.removeItem('id_token');
                    if (data.token === 'logout') {
                        this.props.history.push(); // refresh
                    }
                } else if (data.message) {
                    this.queueMessage(data.message);
                } else if (status) {
                    this.queueMessage({ type: 'error', content: 'Błąd serwera: ' + status });
                }
            } else {
                this.queueMessage({ type: 'error', content: 'Błąd serwera: ' + error });
            }

            return Promise.reject(error);
        });

        Axios.interceptors.request.use((config) => {
            if (!config.timeout) { // do not use loader for hidden requests
                this.setState({ isLoading: true });
            }

            const token = User.getToken();
            if (token !== null) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            User.clearDecodedToken(); // clears cached token

            return config;
        }, function (err) {
            return Promise.reject(err);
        });
    }

    queueMessage = (message) => {
        if (message) {
            message.key = -new Date();
            this.queue.push(message);

            if (this.state.open) {
                this.setState({ open: false });
            } else {
                this.processQueue();
            }
        }
    };

    processQueue = () => {
        if (this.queue.length > 0) {
            this.setState({ message: this.queue.shift(), open: true });
        }
    };

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({ open: false });
    };

    render() {
        const { classes } = this.props;
        const { isLoading, message } = this.state;

        return <>
            {isLoading && <LinearProgress color="secondary" className={classes.loaderIndicator}/>}
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                key={message.key}
                open={this.state.open}
                onClose={this.handleClose}
                onExited={this.processQueue}
                autoHideDuration={message.type === 'error' ? null : 6000} // don't hide errors cause they might be important
                TransitionComponent={Slide}
            >
                <SnackbarContent
                    aria-describedby="message-id"
                    className={classes[message.type] || classes.error}
                    message={<span id="message-id">{Array.isArray(message.content) ? message.content.join(' ') : message.content}</span>}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className={classes.close}
                            onClick={this.handleClose}
                        >
                            <CloseIcon/>
                        </IconButton>,
                    ]}
                />
            </Snackbar>
        </>
    }
}

export default withStyles(styles)(withRouter(Loader));
