import React from 'react';
import Axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from "@material-ui/core";
import NCForm from '../nc-form/NCForm';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import * as d3 from "d3";
import * as fc from "d3fc";
import { scaleLinear } from 'd3-scale'
import BaseComponent from "../shared/BaseComponent";

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  header: {
    marginBottom: theme.spacing(),
  },
  scaleHeaderCell : {
    display: 'inline-block',
    fontSize: theme.typography.pxToRem(14),
    padding: 10,
    transform: 'rotate(-45deg);',
    width: '20%',
    textAlign: 'left',
    marginBottom: 70
  },
  scaleFirstCell : {
    display: 'inline-block',
    fontSize: theme.typography.pxToRem(14),
    verticalAlign: 'top',
    marginRight: 20,
    textAlign: 'right',
    width: 100
  },
  chartBar : {
    display: 'inline-block',
    width: '20%',
    minHeight: 20,
    marginRight: 2
  },
  chartBarContainer : {
    display: 'inline-block',
    width: '20%',
    minHeight: 20,
    marginRight: 2,
    marginBottom: 10
  },
  chartBarInner : {
    display: 'inline-block',
    width: '100%',
    minHeight: 20,
    marginRight: 2
  },
  chartContainer : {
    width: 'calc(100% - 20px)',
    marginTop: 50
  },
  firstChartRow : {
    display: 'flex',
    alignItems: 'flex-end',
  }
});

class ScaleReport extends BaseComponent {
    state = {
        data : [],
        values: {
            show_values: false,
            base_color: '#FF8500',
            year : null
        }
    };

    componentDidMount() {
        let questions = this.props.params.questions.map((v) => {return { id: v.id, cell: v.cell }; });
        let polls = this.props.params.polls.map((v) => v.id);
        let museums = this.props.params.museums.map((v) => v.id);
        let conditions = this.props.params.conditions;
        const { poll, state, filling_type, filling_year, question_type, estimated_data, data, museum } = this.props.params.values;

        Axios.post('/data-reports/getScaleData', {
            questions, polls, museums, state,
            poll, filling_type, filling_year, question_type, estimated_data,
            data, museum, conditions,
        }).then((response) => {
            let values = this.state.values;

            if (Object.keys(response.data.data).length > 0)
            {
                values.year = Object.keys(response.data.data)[0];

                if (Object.keys(response.data.data[values.year]).length > 0)
                {
                    values.question = Object.keys(response.data.data[values.year])[0];
                    Object.assign(values, this.props.params.additionalData.scale);

                    this.setState({
                        data : response.data.data,
                        values : values
                    });

                    return;
                }
            }

            Object.assign(values, this.props.params.additionalData.scale);

            this.setState({
                data : response.data.data,
                values : values
            });
        });
    }

    changeSettings = (name, value) => {
        let values = this.state.values;
        values[name] = value;

        this.setState({values});
    }

    getQuestionStats = (question) => {
        let min = 0;
        let max = 0;

        let tmpData = {};

        for(let i = 0; i < question.properties.choices.length; i++)
        {
            tmpData[question.properties.choices[i].name] = {};
            for(let j = 0; j < question.properties.choice_options.length; j++)
            {
                tmpData[question.properties.choices[i].name][question.properties.choice_options[j].name] = 0;
            }
        }

        for(let i = 0; i < question.data.length; i++)
        {
            for(let key in question.data[i])
            {
                let val = question.data[i][key];

                tmpData[key][val]++;

                if (tmpData[key][val] > max)
                {
                    max = tmpData[key][val];
                }
                if (tmpData[key][val] < min || min == -1)
                {
                   // min = tmpData[key][val];
                }
            }
        }

        return {max: max, min: min, data: tmpData};
    }

    getPercent = (data, key) => {
        let total = 0;
        let val = 0;

        for(let i in data)
        {
            total += data[i];
            if (i == key)
            {
                val = data[i];
            }
        }

        return val*100/total;
    }

    renderTable = () => {
        const { classes } = this.props;
        let question = this.state.data[this.state.values.year][this.state.values.question];

        let questionStats = this.getQuestionStats(question);

        let colorScale = scaleLinear().domain([questionStats.min, questionStats.max]).range(['#EDEDE2', this.state.values.base_color]);

        let content = <div><Typography variant="h5">{question.question}</Typography>
            <div className={classes.chartContainer}>
            <div className={classes.firstChartRow}><p className={classes.scaleFirstCell}></p>{question.properties.choice_options.map((v) => {return <p className={classes.scaleHeaderCell}>{v.name}</p>})}</div>
            <div>
                {question.properties.choices.map((v) => {return <div className={classes.firstChartRow}>
                    <p className={classes.scaleFirstCell}  style={{height: this.state.values.show_values ? 70 : 20}}>{v.name_string}</p>
                    {question.properties.choice_options.map((v2) => {
                        let val = questionStats.data[v.name][v2.name];
                        let percent = this.getPercent(questionStats.data[v.name], v2.name);

                        let height = (percent/1.5)+20;//*50/questionStats.max;
                        let tooltipInfo = percent+'% ('+val+')';

                        if (this.state.values.show_values)
                        {
                            return <div className={classes.chartBarContainer}><Tooltip title={tooltipInfo}><p className={classes.chartBarInner} style={{height: height, backgroundColor: colorScale(parseInt(val))}}/></Tooltip>{tooltipInfo}</div>
                        } else {
                            return <Tooltip title={tooltipInfo}><p className={classes.chartBar} style={{backgroundColor: colorScale(parseInt(val))}}/></Tooltip>
                        }

                    })}
                </div>})}
            </div></div></div>;

        return content;
    }

    render() {
        const { classes } = this.props;

        const fields = [
            {
                type: 'boolean',
                name: 'show_values',
                label: 'Wyświetlaj wartości',

            },
            {
                type : 'divider',
                style : {
                    flexBasis : '100%',
                }
            },
            {
                type: 'colorpicker',
                name: 'base_color',
                label: 'Kolor'
            },
            {
                type : 'divider',
                style : {
                    flexBasis : '100%',
                }
            },
            {
                type: 'select',
                name: 'year',
                label: 'Rok',
                width : 400,
                options: Object.keys(this.state.data).map((v) => {return {value: parseFloat(v), text: v};})
            },
            {
                type : 'divider',
                style : {
                    flexBasis : '100%',
                }
            },
            {
                type: 'select',
                name: 'question',
                label: 'Pytanie',
                width : 400,
                options: this.state.values.year ? Object.keys(this.state.data[this.state.values.year]).map((v) => {return {value: parseFloat(v), text: this.state.data[this.state.values.year][v].question};}) : []
            },
            {
                type : 'divider',
                style : {
                    flexBasis : '100%',
                }
            }
        ];

        return (
              <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-start"
                >
                    <Grid item xs>
                        <NCForm values={this.state.values} fields={fields} hideSubmitButton={true} saveButtonLabel="Aktualizuj mapę" title="Ustawienia" onChange={this.changeSettings}/>
                    </Grid>
                    <Grid style={{textAlign: 'center'}} item xs className="scale-chart-container">{this.state.values.question && this.renderTable()}</Grid>
              </Grid>
            );
    }
}

export default withStyles(styles)(ScaleReport);
