import React from 'react';
import PropTypes from "prop-types";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Question from "../../models/Question";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import TooltipIconButton from "../shared/TooltipIconButton";
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import DataComponent from "../shared/DataComponent";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";

import {
    arrayInsert,
    arrayRemove,
    clone,
    getItemFromArrayByAttribute,
} from "../shared/Helpers";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import DeleteIcon from '@material-ui/icons/Delete';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import Dialog from "@material-ui/core/Dialog";
import { DialogTitle } from "../shared/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import Chip from '@material-ui/core/Chip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from "@material-ui/core/ListItemText";
import Tooltip from "@material-ui/core/Tooltip";

import Typography from '@material-ui/core/Typography';
import TextField from "@material-ui/core/TextField";
import DataTable from "../shared/DataTable";
import TableCellPickerDialog from "../nc-table-creator/TableCellPickerDialog";
import ConfirmDialog from "../shared/ConfirmDialog";

class ConditionsPicker extends DataComponent {
    classes = makeStyles(styles);
    tableColumns = {
        id: 'ID',
        name_string: 'Pytanie',
        is_active_string: { field: 'is_active', title: 'Aktywne?', lookup: { 0: 'Nie', 1: 'Tak' } },
        is_public_string: { field: 'is_public', title: 'Publiczne?', lookup: { 0: 'Nie', 1: 'Tak' } },
        type_string: { field: 'type', title: 'Typ', lookup: Question.types },
        'catalog.name': 'Katalog',
        actions: {
            field: 'function', title: 'Akcje', filtering: false, callback: rowData => {
                if (['dynamic_table'].includes(rowData.type)) return ''; // TODO: should we support dynamic tables?

                return <TooltipIconButton title={rowData.type === 'static_table' ? 'Wybierz komórkę' : 'Wybierz'} onClick={this.clickHandler(rowData)}>
                    {rowData.type === 'static_table' ? <AddBoxOutlinedIcon/> : <CheckCircleIcon/>}
                </TooltipIconButton>;
            },
        },
    };
    questions = []; // loaded questions cache

    constructor(props) {
        super(props);
        this.state.conditions = { children: props.conditions ? clone(props.conditions) : [] };
        this.state.questionPicker = false;
        this.state.cellPicker = false;
        this.state.confirmDialog = false;

        if (Array.isArray(props.questions)) {
            this.questions = props.questions;
        }
        // else if (typeof props.questions === 'string') {
        //     this.questions = this.loadTableData(props.questions);
        // }
    }

    inlineChangeHandler = () => {
        if (this.props.inline) {
            this.props.changeHandler(this.state.conditions.children);
        }
    };

    deleteItem = (parent, index) => event => {
        const callback = () => {
            arrayRemove(parent.children, index);
            this.setState({ confirmDialog: false }, this.inlineChangeHandler);
        };

        if (this.props.inline) {
            callback();
        } else {
            this.setState({
                confirmDialog: {
                    text: 'Na pewno usunąć wybrany warunek?',
                    onConfirm: callback,
                }
            });
        }
    };

    deleteAll = event => {
        this.setState({
            confirmDialog: {
                text: 'Na pewno usunąć wszystkie warunki?',
                onConfirm: () => {
                    this.setState({ confirmDialog: false, conditions: { children: [] } });
                }
            }
        });
    };

    addGroup = (parent, index = null) => event => {
        const item = { children: [], operator: 'and' };
        if (index === null) {
            parent.children.push(item);
        } else {
            arrayInsert(parent.children, index, item);
        }
        this.setState(this.state);
    };

    getQuestion = (id) => {
        return  getItemFromArrayByAttribute(this.questions, id);
    };

    closeQuestionPicker = event => {
        this.setState({ questionPicker: false, cellPicker: false });
    };

    clickHandler = (question) => event => {
        if (question.type === 'static_table') {
            this.setState({ cellPicker: question });
        } else {
            this.addQuestion(question);
        }
    };

    addCell = (name, x, y, meta) => {
        if (!name) {
            return this.setState({ cellPicker: false });
        }
        this.addQuestion(this.state.cellPicker, { name, x, y, type: meta.type });
    };

    addQuestion = (question, cell = null) => {
        if (question) {
            const { parent, index } = this.state.questionPicker;
            const item = {
                question_id: question.id,
                cell,
                type: question.type,
                comparison: this.getComparators(question.type)[0] || '=',
                value: this.getDefaultValue(question),
                operator: 'and'
            };
            if (index === null) {
                parent.children.push(item);
            } else {
                arrayInsert(parent.children, index, item);
            }

            this.questions.push(question);

            this.closeQuestionPicker();

            this.inlineChangeHandler();
        }
    };

    renderOperatorSelect = (item) => {
        return <Select disableUnderline className={this.classes.select} value={item.operator} onChange={event => {
            item.operator = event.target.value;
            this.setState(this.state, this.inlineChangeHandler);
        }}>
            <MenuItem value="and">AND</MenuItem>
            <MenuItem value="or">OR</MenuItem>
        </Select>
    };

    getComparators = (type) => {
        switch (type) {
            case 'date':
            case 'year':
            case 'number':
            case 'formula':
                return ['=', '!=', '>', '>=', '<', '<='];
            case 'label':
            case 'text':
            case 'regexp':
            case 'mask':
            case 'email':
            case 'open':
                return ['=', '!=', 'like', 'not like'];
            case 'choice_single':
            case 'choice_multiple':
            case 'choice_fixed':
            case 'static_table':
                return ['=', '!='];
            case 'dynamic_table':
                return ['???'];
            case 'file':
            case 'boolean':
            default:
                return [];
        }
    };

    getDefaultValue = (question) => {
        switch (question.type) {
            case 'file':
            case 'boolean':
                return '1';
            case 'choice_single':
            case 'choice_multiple':
            case 'choice_fixed':
                const { choices, choice_options } = question.properties;
                if (!choice_options) {
                    if (!!choices[0]) {
                        return choices[0].name;
                    }
                } else {
                    if (!!choices[0] && !!choice_options[0]) {
                        return JSON.stringify({ [choices[0].name]: choice_options[0].name });
                    }
                }
            default:
                return '';
        }
    };

    renderValueInput = (item) => {
        let { type, cell } = item;
        if (cell && cell.type) {
            type = cell.type;
        }
        const question = this.getQuestion(item.question_id);

        switch (type) {
            case 'file':
            case 'boolean':
                return <Select className={this.classes.select} value={item.value} onChange={event => {
                    item.value = event.target.value;
                    this.setState(this.state, this.inlineChangeHandler);
                }}>
                    <MenuItem value="1">TAK</MenuItem>
                    <MenuItem value="0">NIE</MenuItem>
                </Select>;
            case 'date':
            case 'year':
            case 'number':
            case 'formula':
            case 'label':
            case 'text':
            case 'regexp':
            case 'mask':
            case 'email':
            case 'open':
                return <TextField
                    className={this.classes.textField}
                    onChange={event => {
                        item.value = event.target.value;
                        this.setState(this.state);
                    }}
                    onBlur={event => {
                        item.value = event.target.value.trim();
                        this.inlineChangeHandler();
                    }}
                    defaultValue={item.value}
                />;
            case 'choice_single':
            case 'choice_multiple':
            case 'choice_fixed':
                let properties = question.properties;
                if (question.type === 'static_table') {
                    properties = question.properties?.table?.meta[cell.name]?.config;
                }
                const { choices, choice_options } = properties;
                return <>
                    <Select className={this.classes.select}
                            value={item.value}
                            onChange={event => {
                                item.value = !choice_options ? event.target.value : JSON.parse(event.target.value);
                                this.setState(this.state, this.inlineChangeHandler);
                            }}>
                        {!!choice_options ? (
                            Object.entries(choices || {}).map(([choiceKey, choice]) => {
                                return Object.entries(choice_options || {}).map(([optionKey, option]) => {
                                    return <MenuItem key={optionKey} value={JSON.stringify({ [choice.name]: option.name })}>{choice.name}: {option.name}</MenuItem>;
                                });
                            })
                        ) : (
                            Object.entries(choices || {}).map(([choiceKey, choice]) => {
                                // const choiceValue = choice.name_string || choice.name;
                                const strippedName = (choice.name + '').replace(/<[^>]+>/g, '');
                                return <MenuItem key={choiceKey} value={strippedName}>{strippedName}</MenuItem>;
                            })
                        )}
                    </Select>
                </>
            case 'static_table':
            case 'dynamic_table':
                return '???';
            default:
                return '';
        }
    };

    rendercomparisonSelect = (item) => {
        let { type, cell } = item;
        if (cell && cell.type) {
            type = cell.type;
        }
        const comparators = this.getComparators(type);

        if (comparators.length) {
            return <Select className={this.classes.operatorsSelect} value={item.comparison} onChange={event => {
                item.comparison = event.target.value;
                this.setState(this.state, this.inlineChangeHandler);
            }}>
                {comparators.map((comparator, index) =>
                    <MenuItem key={index} value={comparator}>{comparator.toUpperCase()}</MenuItem>
                )}
            </Select>
        } else {
            return '';
        }
    };

    renderCondition = (item, parent, index) => {
        if (!!item.question_id) {
            const question = this.getQuestion(item.question_id);
            if (!question) return ''; // skip removed questions
            return <List dense key={index} className={this.classes.list}>
                <ListItem dense divider>
                    <ListItemIcon>
                        <TooltipIconButton size="small" title="Usuń warunek" onClick={this.deleteItem(parent, index)}><DeleteIcon color="secondary"/></TooltipIconButton>
                    </ListItemIcon>
                    <ListItemText
                        primary={<>
                            <Tooltip title={question.name_string + (item.cell ? ' [komórka: ' + item.cell.name + ']' : '')}>
                                <Chip
                                    label={<>
                                        <Typography noWrap={true} className={this.classes.chipText}>{question.name_string}</Typography>
                                        {item.cell ? <Typography className={this.classes.chipText}>&nbsp;[{item.cell.name}]</Typography> : ''}
                                    </>}
                                    size="small"
                                />
                            </Tooltip>
                            {this.rendercomparisonSelect(item)}
                            {this.renderValueInput(item)}
                        </>}
                        primaryTypographyProps={{ component: 'div' }}
                    />
                </ListItem>
                {parent.children.length - 1 !== index && (
                    <ListItem dense divider className={this.classes.listOperator}>
                        {this.renderOperatorSelect(item)}
                    </ListItem>
                )}
            </List>
        } else {
            return <div key={index}>
                <Card elevation={4}>
                    <CardContent className={this.classes.cardContent}>
                        {item.children.map((group, groupIndex) => this.renderCondition(group, item, groupIndex))}
                    </CardContent>
                    <CardActions>
                        <TooltipIconButton size="small" title="Usuń grupę" onClick={this.deleteItem(parent, index)}><DeleteIcon color="secondary"/></TooltipIconButton>
                        {this.renderAddButtons(item, null, { size: 'small' })}
                    </CardActions>
                </Card>
                {parent.children.length - 1 !== index && <>
                    {this.renderOperatorSelect(item)}
                    {this.renderAddButtons(parent, index + 1)}
                </>}
            </div>
        }
    };

    renderAddButtons = (parent, index = null, props = {}) => {
        return <>
            {this.props.grouping && (
                <TooltipIconButton {...props} title="Dodaj grupę" onClick={this.addGroup(parent, index)}><CreateNewFolderIcon/></TooltipIconButton>
            )}
            <TooltipIconButton {...props} title="Dodaj pytanie"
                           onClick={() => this.setState({ questionPicker: { parent, index } })}>
                <PlaylistAddIcon/>
            </TooltipIconButton>
        </>
    };

    renderContent = () => {
        const { conditions } = this.state;
        return <>
            {conditions.children.map((group, groupIndex) => this.renderCondition(group, conditions, groupIndex))}
            {this.renderAddButtons(conditions)}
        </>
    };

    render() {
        const { dialogTitle, changeHandler, inline } = this.props;
        const { conditions, questionPicker, cellPicker, confirmDialog } = this.state;

        return <>
            {inline ? (
                this.renderContent()
            ) : (
                <Dialog maxWidth="xl" open={true} onClose={() => changeHandler(false)}>
                    <DialogTitle onClose={() => changeHandler(false)}>{dialogTitle}</DialogTitle>
                    <DialogContent className={this.classes.dialogContent}>
                        {this.renderContent()}
                    </DialogContent>
                    <DialogActions>
                        {!!conditions.children.length && (
                            <TooltipIconButton title="Usuń wszystkie" onClick={this.deleteAll}>
                                <DeleteIcon color="secondary"/>
                            </TooltipIconButton>
                        )}
                        <Button variant="outlined" onClick={() => changeHandler(false)}>Anuluj</Button>
                        <Button variant="contained" color="primary" onClick={() => changeHandler(conditions.children)}>Zastosuj</Button>
                    </DialogActions>
                </Dialog>
            )}

            {!!questionPicker && (
                <Dialog fullScreen open={true} onClose={this.closeQuestionPicker}>
                    <DialogTitle onClose={this.closeQuestionPicker}>Dodaj pytanie do warunków:</DialogTitle>
                    <DialogContent>
                        <DataTable
                            storageKey={this.constructor.name}
                            title="Dostępne pytania"
                            columns={this.getTableColumns()}
                            data={this.props.questions}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={this.closeQuestionPicker}>Zamknij</Button>
                    </DialogActions>
                </Dialog>
            )}

            {!!cellPicker && (
                <TableCellPickerDialog
                    question={cellPicker}
                    changeHandler={this.addCell}
                />
            )}

            {!!confirmDialog && (
                <ConfirmDialog open={true}
                               cancelTitle="Nie"
                               confirmTitle="Tak"
                               onConfirm={confirmDialog.onConfirm}
                               onClose={() => this.setState({ confirmDialog: false })}>
                    {confirmDialog.text}
                </ConfirmDialog>
            )}
        </>
    }
}

ConditionsPicker.propTypes = {
    dialogTitle: PropTypes.string,
    conditions: PropTypes.array,
    questions: PropTypes.any.isRequired,
    changeHandler: PropTypes.func.isRequired,
    inline: PropTypes.bool,
    grouping: PropTypes.bool,
};
ConditionsPicker.defaultProps = {
    dialogTitle: 'Warunki widoczności:',
    conditions: [],
    inline: false,
    grouping: true,
};

const styles = theme => ({
    dialogContent: {
        minWidth: 860,
    },
    select: {
        marginLeft: theme.spacing(2),
        minWidth: 60,
        verticalAlign: 'middle',
    },
    operatorsSelect: {
        marginLeft: theme.spacing(2),
        minWidth: 56,
        textAlign: 'center',
        verticalAlign: 'middle',
    },
    cardContent: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    list: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    listOperator: {
        paddingLeft: 72,
        paddingTop: 0,
        paddingBottom: 0,
    },
    chipText: {
        maxWidth: 400,
        fontSize: 'inherit'
    },
    textField: {
        marginLeft: theme.spacing(2),
        fontSize: 'inherit'
    },
});

export default ConditionsPicker;
