import React from 'react';
import { Link } from "react-router-dom";
import DataComponent from "../shared/DataComponent";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Axios from "axios";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";

class UserNotifications extends DataComponent {
    static permissions = ['user-notifications'];
    url = '/notifications/new';

    onDataLoaded = () => {
        this.props.countHandler(this.state.data.count);
    };

    markAsSeen = (notification_id) => event => {
        Axios.put('/notifications', { notification_id }, { timeout: 30000 }).then(() => {
            this.props.countHandler(this.state.data.count - 1);
        });

        this.props.onClick();
    };

    render() {
        if (this.state.isLoading) {
            return <div style={{ textAlign: 'center' }}>{this.showLoader()}</div>
        }
        const { list } = this.state.data;

        return <List disablePadding>
            {list.length === 0 && (
                <ListItem>
                    <ListItemText secondary="Nic nowego się nie wydarzyło" secondaryTypographyProps={{ align: "center" }}/>
                </ListItem>
            )}
            {list.map((notification, index) => (
                <ListItem button key={index}
                          component={notification.route_string ? Link : null}
                          to={notification.route_string || ''}
                          alignItems="flex-start"
                          onClick={this.markAsSeen(notification.id)}>
                    <ListItemAvatar>
                        <Tooltip title={notification.user ? notification.user.full_name : 'NIMOZ'}>
                            <Avatar>
                                {notification.user ? notification.user.first_name.substr(0, 1) + notification.user.last_name.substr(0, 1) : 'NIM'}
                            </Avatar>
                        </Tooltip>
                    </ListItemAvatar>
                    <ListItemText
                        primary={<div dangerouslySetInnerHTML={{ __html: notification.content }}/>}
                        secondary={notification.created_at}
                    />
                    {/*<ListItemSecondaryAction>*/}
                    {/*    <TooltipIconButton title="Przeczytane" edge="end" onClick={this.markAsSeen(notification.id)}>*/}
                    {/*        <DoneIcon/>*/}
                    {/*    </TooltipIconButton>*/}
                    {/*</ListItemSecondaryAction>*/}
                </ListItem>
            ))}
        </List>
    }
}

UserNotifications.propTypes = {
    onClick: PropTypes.func,
    countHandler: PropTypes.func,
};
UserNotifications.defaultProps = {
    onClick: () => {},
    countHandler: () => {},
};

export default UserNotifications;
