import React from 'react';
import { withRouter } from "react-router";
import DataComponent from "../shared/DataComponent";
import FabContainer from "../shared/FabContainer";
import DataTable from "../shared/DataTable";

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { FabLink } from "../shared/FabButton";
import Poll from "../../models/Poll";
import User from "../auth/User";

class QuestionPolls extends DataComponent {
    static permissions = ['view-polls'];
    question_id = this.props.match.params.question_id;
    catalog_id = this.props.match.params.catalog_id;

    tableColumns = {
        id: 'ID',
        name: 'Nazwa ankiety',
        type_string: { field: 'type', title: 'Rodzaj', lookup: Poll.types },
        year: 'Okres',
        starts_at: 'Data rozpoczęcia',
        ends_at: 'Data zakończenia',
        deadline: 'Data zablokowania',
        created_at: 'Data dodania',
        'project.name': 'Projekt',
    };

    constructor(props) {
        super(props);

        this.url = '/catalogs/questions/' + this.question_id + '/polls';

        this.addDeletedAtColumn();
    }

    render() {
        if (this.state.isLoading) {
            return this.showLoader();
        }

        return (
            <>
                <DataTable
                    storageKey={this.constructor.name}
                    title={"Lista ankiet w których występuje pytanie: " + this.getObject('name_string')}
                    columns={this.getTableColumns()}
                    data={this.loadTableData(this.url)}
                    showLink={User.hasPermissionTo('view-polls') ? (
                        (rowData) => this.catalog_id ? '/projects/' + rowData.project_id + '/polls/' + rowData.id + '/list' : '/catalogs/questions/' + this.question_id + '/polls/' + rowData.id + '/list'
                    ) : null}
                    // editLink={User.hasPermissionTo('edit-polls') ? (rowData) => '/projects/' + this.project_id + '/polls/' + rowData.id + '/edit' : null}
                />

                <FabContainer>
                    <FabLink title="Wróć" to={'/catalogs' + (this.catalog_id ? '/list/' + this.catalog_id : '') + '/questions'} color="default"><ArrowBackIcon/></FabLink>
                </FabContainer>
            </>
        );
    }
}

export default withRouter(QuestionPolls);
