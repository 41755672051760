import React, { Component } from "react";
import ErrorContext from "../shared/ErrorContext";

const MuseumPollContext = React.createContext({});

class MuseumPollContextProvider extends Component {
    static contextType = ErrorContext;

    state = {
        museumPoll: {},
        expandedQuestions: {},
    };

    _setState = data => {
        this.setState(data);
    };

    _getState = key => {
        if (key === undefined) {
            return this.state;
        } else {
            return this.state[key];
        }
    }

    _catchErrors = (error, info) => {
        this.context.catchErrors(error, info);
    };

    render() {
        const { Provider } = MuseumPollContext;
        const { children } = this.props;

        return <Provider value={{ setState: this._setState, getState: this._getState, catchErrors: this._catchErrors }}>
            {children}
        </Provider>;
    }
}

export { MuseumPollContextProvider };
export default MuseumPollContext;
