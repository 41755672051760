import React from 'react';
import PropTypes from "prop-types";
import Question from "../../models/Question";
import TablePicker from "../shared/TablePicker";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import TooltipIconButton from "../shared/TooltipIconButton";
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import TableCellPickerDialog from "../nc-table-creator/TableCellPickerDialog";
import TableColumnPickerDialog from "../nc-table-creator/TableColumnPickerDialog";

class QuestionPicker extends TablePicker {
    url = this.props.url;

    tableOptions = {
        selection: false,
    };

    tableColumns = {
        id: 'ID',
        name_string: 'Pytanie',
        is_active_string: { field: 'is_active', title: 'Aktywne?', lookup: { 0: 'Nie', 1: 'Tak' } },
        is_public_string: { field: 'is_public', title: 'Publiczne?', lookup: { 0: 'Nie', 1: 'Tak' } },
        type_string: { field: 'type', title: 'Typ', lookup: Question.types },
        is_imported: { field: 'is_imported', title: 'Z importu?', lookup: { 0: 'Nie', 1: 'Tak' } },
        'catalog.name': 'Katalog',
        actions: {
            field: 'function', title: 'Akcje', filtering: false, callback: rowData => {
                if (rowData.type === 'dynamic_table' && !this.props.enableColumnPicker) return '';

                return <TooltipIconButton title={['static_table', 'dynamic_table'].includes(rowData.type)
                    ? (rowData.type === 'dynamic_table' ? 'Wybierz kolumnę' : 'Wybierz komórkę')
                    : 'Wybierz'} onClick={this.clickHandler(rowData)}>
                    {['static_table', 'dynamic_table'].includes(rowData.type) ? <AddBoxOutlinedIcon/> : <CheckCircleIcon/>}
                </TooltipIconButton>;
            },
        },
    };
    dialogTitle = this.props.dialogTitle;
    tableTitle = this.props.tableTitle;

    constructor(props) {
        super(props);

        this.state.cellPicker = false;
        this.state.columnPicker = false;
        this.state.cells = [];
    }

    changeHandler = (save, cell) => () => {
        this.props.changeHandler(!save ? null : save, cell);
    };

    clickHandler = (rowData) => event => {
        if (rowData.type === 'static_table') {
            this.setState({ cellPicker: rowData });
        } else if (rowData.type === 'dynamic_table') {
            this.setState({ columnPicker: rowData });
        } else {
            this.changeHandler(rowData)();
        }
    };

    cellHandler = (name, x, y, meta) => {
        if (name) {
            if (this.props.multiple) {
                this.changeHandler(this.state.cellPicker, name)(); // array of cells
            } else {
                this.changeHandler(this.state.cellPicker, { name, x, y, meta })();
            }
        } else {
            this.setState({ cellPicker: false });
        }
    };

    columnHandler = (column, x) => {
        if (column) {
            if (this.props.multiple) {
                this.changeHandler(this.state.columnPicker, column)(); // array of columns
            } else {
                this.changeHandler(this.state.columnPicker, { column, x })();
            }
        } else {
            this.setState({ columnPicker: false });
        }
    };

    renderTableDialogActions = () => {
        return <DialogActions>
            <Button onClick={this.changeHandler(false)} variant="contained">Zamknij</Button>
        </DialogActions>
    };

    render() {
        const { multiple } = this.props;
        const { cellPicker, columnPicker } = this.state;

        return <>
            {super.render()}
            {cellPicker !== false && (
                <TableCellPickerDialog
                    question={cellPicker}
                    changeHandler={this.cellHandler}
                    multiple={multiple}
                />
            )}
            {columnPicker !== false && (
                <TableColumnPickerDialog
                    question={columnPicker}
                    changeHandler={this.columnHandler}
                    multiple={multiple}
                />
            )}
        </>
    }
}

QuestionPicker.propTypes = {
    url: PropTypes.string,
    dialogTitle: PropTypes.string,
    tableTitle: PropTypes.string,
    changeHandler: PropTypes.func.isRequired,
    enableColumnPicker: PropTypes.bool,
    multiple: PropTypes.bool,
    pollFilters: PropTypes.object,
    museumFilters: PropTypes.object,
    dataFilters: PropTypes.object,
};
QuestionPicker.defaultProps = {
    url: '/catalogs/questions',
    dialogTitle: 'Dodawanie dziedziczenia odpowiedzi do pytania:',
    tableTitle: 'Dostępne pytania',
    enableColumnPicker: false,
    multiple: false,
    pollFilters: {},
    museumFilters: {},
    dataFilters: {},
};

export default QuestionPicker;
