import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router";
import Sidebar from '../sidebar/Sidebar';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { fade } from '@material-ui/core/styles/colorManipulator';
import AccountCircle from '@material-ui/icons/AccountCircle';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import MoreIcon from '@material-ui/icons/MoreVert';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import User from "../auth/User";
import Axios from "axios";
import NotificationsMenu from "../notifications/NotificationsMenu";
import TooltipIconButton from "./TooltipIconButton";
import Grid from '@material-ui/core/Grid';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import BaseComponent from "./BaseComponent";

const drawerWidth = 280;
const drawerWidthCompact = 56;

class AppMenu extends BaseComponent {
    html = document.getElementsByTagName('html')[0];
    state = {
        anchorEl: null,
        mobileMoreAnchorEl: null,
        mobileOpen: false,
        compact: false,
        version: '',
        fontSize: '',
    };

    constructor(props) {
        super(props);

        this.state.fontSize = localStorage.getItem('fontSize') || '';
    }

    componentDidMount() {
        Axios.get('/version').then((response) => {
            this.setState({ version: response.data });
        });
    }

    handleDrawerToggle = () => {
        this.setState(state => ({ mobileOpen: !state.mobileOpen }));
    };

    handleCompactToggle = () => {
        this.setState(state => ({ compact: !state.compact }), () => {
            if (this.state.compact) {
                this.html.classList.add('compact');
            } else {
                this.html.classList.remove('compact');
            }
        });
    };

    handleProfileMenuOpen = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
        this.handleMobileMenuClose();
    };

    handleMobileMenuOpen = event => {
        this.setState({ mobileMoreAnchorEl: event.currentTarget });
    };

    handleMobileMenuClose = () => {
        this.setState({ mobileMoreAnchorEl: null });
    };

    goToProfile = () => {
        this.props.history.push('/profile');
        this.handleMenuClose();
    };

    changeContrast = (high) => event => {
        if (high) {
            this.html.classList.add('highContrast');
            localStorage.setItem('highContrast', 1);
        } else {
            this.html.classList.remove('highContrast');
            localStorage.removeItem('highContrast');
        }
    };

    changeFontSize = (fontSize = '') => event => {
        if (fontSize) {
            localStorage.setItem('fontSize', fontSize);
        } else {
            localStorage.removeItem('fontSize');
        }

        this.html.style.fontSize = fontSize + (fontSize ? 'px' : '');

        this.setState({ fontSize });
    };

    render(){
        const { anchorEl, mobileMoreAnchorEl, fontSize, compact } = this.state;
        const { classes, theme, logoutFunction } = this.props;
        const isMenuOpen = Boolean(anchorEl);
        const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

        const drawer = (
            <div>
                <div className={classes.toolbar}>
                    {/*<Typography className={classes.title} variant="h5" noWrap>{window.APP_NAME}</Typography>*/}
                    {/*<Tooltip title="Data ostatniej aktualizacji">*/}
                    {/*    <Typography id="version" className={classes.version} noWrap>{this.state.version}</Typography>*/}
                    {/*</Tooltip>*/}
                    <img src="//statystykamuzeow.pl/storage/logo.png" alt="Statystyka muzeów"
                         title={"Statystyka muzeów: " + this.state.version}
                         className={classes.logo}
                    />
                </div>
                <Divider />
                <Sidebar compact={compact} />
            </div>
        );

        return <>
            <AppBar position="fixed" className={classNames(compact ? classes.appBarCompact : classes.appBar)}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="Otwórz menu"
                        onClick={this.handleDrawerToggle}
                        className={classes.drawerButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <IconButton
                        color="inherit"
                        aria-label="Zmniejsz menu"
                        onClick={this.handleCompactToggle}
                        className={classes.compactButton}
                    >
                        {compact ? <SkipNextIcon/> : <SkipPreviousIcon/>}
                    </IconButton>

                    <div className={classes.search}>

                    </div>
                    <div className={classes.grow}>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                        >
                            <span>Kontrast:</span>
                            <TooltipIconButton size="small"
                                               onClick={this.changeContrast(false)}
                                               title="normalny kontrast">
                                <VisibilityOutlinedIcon/>
                            </TooltipIconButton>
                            <TooltipIconButton size="small"
                                               onClick={this.changeContrast(true)}
                                               title="wysoki kontrast">
                                <VisibilityIcon/>
                            </TooltipIconButton>

                            <span style={{ marginLeft: '1em' }}>Czcionka:</span>
                            <TooltipIconButton size="small"
                                               className={fontSize === '14' ? classes.sizeActive : null}
                                               style={{ fontSize: 14 }}
                                               onClick={this.changeFontSize(14)}
                                               title="mała czcionka">
                                <span className={classes.sizeLabel} style={{ fontSize: 14 }}>A</span>
                            </TooltipIconButton>
                            <TooltipIconButton size="small"
                                               className={fontSize === '' ? classes.sizeActive : null}
                                               style={{ fontSize: 16 }}
                                               onClick={this.changeFontSize()}
                                               title="domyślna czcionka">
                                <span className={classes.sizeLabel} style={{ fontSize: 16 }}>A</span>
                            </TooltipIconButton>
                            <TooltipIconButton size="small"
                                               className={fontSize === '18' ? classes.sizeActive : null}
                                               onClick={this.changeFontSize(18)}
                                               title="duża czcionka">
                                <span className={classes.sizeLabel} style={{ fontSize: 18 }}>A</span>
                            </TooltipIconButton>
                        </Grid>
                    </div>
                    <div className={classes.sectionDesktop}>
                        {/*<IconButton color="inherit">*/}
                        {/*    <Badge badgeContent={0} color="secondary">*/}
                        {/*        <MailIcon />*/}
                        {/*    </Badge>*/}
                        {/*</IconButton>*/}
                        {User.hasPermissionTo('user-notifications') && (
                            <NotificationsMenu/>
                        )}
                        <IconButton disableRipple={true} style={{ cursor: 'default' }}>
                            <Typography className={classes.username}>{User.getUserName()}</Typography>
                        </IconButton>
                        <IconButton
                            aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                            aria-haspopup="true"
                            onClick={this.handleProfileMenuOpen}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                    </div>
                    <div className={classes.sectionMobile}>
                        <IconButton aria-haspopup="true" onClick={this.handleMobileMenuOpen} color="inherit">
                            <MoreIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMenuOpen}
                onClose={this.handleMenuClose}
            >
                <MenuItem onClick={this.goToProfile}>Ustawienia</MenuItem>
                <MenuItem onClick={logoutFunction}>Wyloguj</MenuItem>
            </Menu>
            <Menu
                anchorEl={mobileMoreAnchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMobileMenuOpen}
                onClose={this.handleMobileMenuClose}
            >
                <MenuItem onClick={this.goToProfile}>Ustawienia</MenuItem>
                <MenuItem onClick={logoutFunction}>Wyloguj</MenuItem>
            </Menu>

            <nav className={classNames(compact ? classes.drawerCompact : classes.drawer)}>
                <Hidden smUp implementation="css">
                    <Drawer
                        container={this.props.container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={this.state.mobileOpen}
                        onClose={this.handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: compact ? classes.drawerCompact : classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
        </>
    }
}

const styles = theme => ({
    root: {
        display: 'flex',
    },
    grow: {
        flexGrow: 1,
        textAlign: 'center',
    },
    sizeLabel: {
        display: 'inline-block',
        width: '22px',
        height: '22px',
        lineHeight: '20px',
    },
    sizeActive: {
        textDecoration: 'underline',
    },
    username: {
        color: '#fff',
    },
    drawerButton: {
        marginRight: 20,
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    compactButton: {
        marginRight: 20,
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    toolbar: {
        ...theme.mixins.toolbar,
        padding: 8,
        display: 'flex',
        justifyContent: 'center',
    },
    logo: {
        maxWidth: '100%',
        alignSelf: 'center',
    },
    version: {
        cursor: 'help',
        textAlign: 'center',
        fontSize: theme.typography.pxToRem(13),
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(9),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        paddingTop: theme.spacing(),
        paddingRight: theme.spacing(),
        paddingBottom: theme.spacing(),
        paddingLeft: theme.spacing(10),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    appBar: {
        marginLeft: drawerWidth,
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
        },
    },
    appBarCompact: {
        marginLeft: drawerWidth,
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidthCompact}px)`,
        },
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    drawerCompact: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidthCompact,
            flexShrink: 0,
        },
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerPaperCompact: {
        width: drawerWidth,
        [theme.breakpoints.up('sm')]: {
            width: drawerWidthCompact,
        },
    },
});

AppMenu.propTypes = {
    logoutFunction: PropTypes.func,
    theme: PropTypes.object.isRequired,
    compact: PropTypes.bool,
    compactHandler: PropTypes.func,
};

export default withStyles(styles, { withTheme: true })(withRouter(AppMenu));
