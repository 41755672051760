import React from 'react';
import { withRouter } from "react-router";
import DataComponent from "../shared/DataComponent";
import DataTable from "../shared/DataTable";
import User from "../auth/User";

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import FabContainer from "../shared/FabContainer";
import { FabLink } from "../shared/FabButton";
import TooltipIconButton, { TooltipIconButtonLink } from "../shared/TooltipIconButton";
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import ConfirmDialog from "../shared/ConfirmDialog";
import Axios from "axios";

class CatalogList extends DataComponent {
    static permissions = ['view-catalogs'];
    url = '/catalogs';
    tableColumns = {
        name_depth: { title: 'Nazwa', column: 'name' },
        id: 'ID',
        published_questions_count: { title: 'Ilość pytań zaakceptowanych', filtering: false },
        ready_questions_count: { title: 'Ilość pytań zaproponowanych', filtering: false },
        created_at: "Data utworzenia",
    };
    tableRef = React.createRef();

    constructor(props) {
        super(props);

        if (User.hasPermissionTo('edit-catalogs')) {
            this.tableColumns.actions = {
                field: 'function', title: 'Akcje', filtering: false, callback: rowData => {
                    // !rowData.questions_count && ( // do we really want to limit this?
                    return <>
                        {rowData.deleted_at ? (
                            <TooltipIconButton title="Przywróć" onClick={this.openRestoreDialog(rowData)}><RestoreFromTrashIcon/></TooltipIconButton>
                        ) : <>
                            <TooltipIconButtonLink title="Dodaj podkatalog" to={'/catalogs/list/' + rowData.id + '/add'}><CreateNewFolderIcon/></TooltipIconButtonLink>
                            <TooltipIconButtonLink title="Edytuj" to={'/catalogs/list/' + rowData.id + '/edit'}><EditIcon/></TooltipIconButtonLink>
                        </>}
                    </>
                }
            }
        }

        this.state.restoreDialog = false;

        this.addDeletedAtColumn();
    }

    openRestoreDialog = (rowData) => event => {
        this.setState({ restoreDialog: '/catalogs/' + rowData.id });
    };

    restoreObject = () => {
        Axios.patch(this.state.restoreDialog).then(response => {
            this.setState({ restoreDialog: false }, () => this.tableRef.current.onFilterChangeDebounce());
        }).catch(this.catchErrors);
    };

    render() {
        if (this.state.isLoading) {
            return this.showLoader();
        }

        return <>
            <DataTable
                tableRef={this.tableRef}
                storageKey={this.constructor.name}
                title={"Lista katalogów"}
                columns={this.getTableColumns()}
                data={this.loadTableData(this.url)}
                showLink={User.hasPermissionTo('view-questions') ? (rowData) => '/catalogs/list/' + rowData.id + '/questions' : null}
                // editLink={User.hasPermissionTo('edit-catalogs') ? (rowData) => '/catalogs/list/' + rowData.id + '/edit' : null}
                parentChildData={(row, rows) => rows.find(v => v.id === row.parent_id)}
            />

            <FabContainer>
                {User.hasPermissionTo('edit-catalogs') && (
                    <FabLink title="Dodaj katalog" to="/catalogs/list/add" color="primary"><AddIcon/></FabLink>
                )}
            </FabContainer>

            <ConfirmDialog title="Przywracanie" open={!!this.state.restoreDialog} onClose={() => this.setState({ restoreDialog: false })} onConfirm={this.restoreObject}>
                Czy na pewno chcesz przywrócić wybraną pozycję?
            </ConfirmDialog>
        </>
    }
}

export default withRouter(CatalogList);
