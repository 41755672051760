import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuseumEdit from "./MuseumEdit";
import User from "../auth/User";
import { Paper } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Axios from "axios";

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    buttonContainer: {
        margin: 0,
        top: 'auto',
        right: 0,
        bottom: 20,
        left: 'auto',
        position: 'fixed',
    },
    button: {
        marginRight: 20,
    },
    selectField: {
        marginTop: 0,
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(),
        width: 200,
    },
});

class MuseumManager extends MuseumEdit {
    static permissions = ['museum-manager'];

    getObjectId() {
        return User.getMuseumId();
    }

    getTitle = () => {
        return this.getObject().name;
    };

    saveObject = () => {
        Axios.put('/museums/' + this.getObjectId(), this.getValues()).then(response => {
            this.props.history.push('/institution-manager');
        }).catch(this.catchErrors);
    };

    render() {
        if (this.getObjectId()) {
            return super.render();
        } else {
            const { classes } = this.props;

            return (<Paper>
                <Typography className={classes.root} variant="h6" component="h6">Brak przydzielonej instytucji</Typography>
            </Paper>);
        }
    }
}

export default withStyles(styles)(MuseumManager);
