import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from "prop-types";
import jexcel from 'jexcel';
import 'jexcel/dist/jexcel.css';
import BaseComponent from "../shared/BaseComponent";

class JExcelComponent extends BaseComponent {
    jexcel;
    options = {
        columnDrag: false,
        rowDrag: false,
        paste: false,
        allowRenameColumn: false,
        rowNumbersColWidth: 26,
        defaultColWidth: 150,
        disableColWidth: false,
        columnResize: true,
        columnSorting: false,
        allowExport: false,
        // copyCompatibility: true,
        about: false,
        text: {
            noRecordsFound: 'Nie odnaleziono wyników',
            showingPage: 'Strona {0} z {1} pozycji',
            show: 'Pokaż ',
            search: 'Znajdź',
            entries: ' pozycji',
            columnName: 'Nazwa kolumny',
            insertANewColumnBefore: 'Wstaw nową kolumnę z lewej',
            insertANewColumnAfter: 'Wstaw nową kolumnę z prawej',
            deleteSelectedColumns: 'Usuń zaznaczone kolumny',
            renameThisColumn: 'Zmień nazwę kolumny',
            orderAscending: 'Sortowanie rosnące',
            orderDescending: 'Sortowanie malejące',
            insertANewRowBefore: 'Wstaw nowy wiersz powyżej',
            insertANewRowAfter: 'Wstaw nowy wiersz poniżej',
            deleteSelectedRows: 'Usuń zaznaczone wiersze',
            editComments: 'Edytuj komentarze',
            addComments: 'Dodaj komentarze',
            comments: 'Komentarze',
            clearComments: 'Wyczyść komentarze',
            copy: 'Kopiuj...',
            paste: 'Wklej...',
            saveAs: 'Zapisz jako...',
            about: 'O jExcel',
            noActions: 'Brak dostępnych akcji',
            areYouSureToDeleteTheSelectedRows: 'Na pewno chcesz usunać zaznaczone wiersze?',
            areYouSureToDeleteTheSelectedColumns: 'Na pewno chcesz usunać zaznaczone kolumny?',
            thisActionWillDestroyAnyExistingMergedCellsAreYouSure: 'Ta akcja zniszczy wszystkie scalone komórki. Na pewno?',
            thisActionWillClearYourSearchResultsAreYouSure: 'Ta akcja wyczyści wyniki wyszukiwania. Na pewno?',
            thereIsAConflictWithAnotherMergedCell: 'Jest konflikt z inną scaloną komórką',
            invalidMergeProperties: 'Nieprawidłowe scalone właściwości',
            cellAlreadyMerged: 'Komórka jest już scalona',
            noCellsSelected: 'Nie wybrano komórek',
        },
    };

    constructor(props) {
        super(props);

        this.options = Object.assign(this.options, props.options);
    }

    componentDidMount() {
        this.jexcel = jexcel(this.props.adminMode ? ReactDOM.findDOMNode(this) : ReactDOM.findDOMNode(this).children[0], this.options);
    };

    render() {
        if (this.props.adminMode) {
            return <div id="spreadsheet">
                {this.props.children}
            </div>
        } else {
            return <div id="spreadsheet">
                <div></div>
                <div>{this.props.children}</div>
            </div>
        }
    }
}

JExcelComponent.propTypes = {
    options: PropTypes.object,
    adminMode: PropTypes.bool,
};
JExcelComponent.defaultProps = {
    adminMode: false,
};

export default JExcelComponent;
