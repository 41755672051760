import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { DialogContent, DialogTitle } from "./Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import BaseComponent from "./BaseComponent";

class ConfirmDialog extends BaseComponent {
    render() {
        const { children, open, title, confirmTitle, cancelTitle, isContentText, onClose, onConfirm, actions, dialogProps } = this.props;

        return <Dialog open={!!open} onClose={onClose} {...dialogProps}>
            <DialogTitle onClose={onClose}>{title}</DialogTitle>
            <DialogContent>
                {isContentText ? <DialogContentText>{children}</DialogContentText> : children}
            </DialogContent>
            <DialogActions>
                {actions ? actions : <>
                    {cancelTitle && <Button variant="outlined" onClick={onClose}>{cancelTitle}</Button>}
                    {confirmTitle && <Button variant="contained" onClick={onConfirm} color="secondary">{confirmTitle}</Button>}
                </>}
            </DialogActions>
        </Dialog>
    }
}

ConfirmDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func,
    title: PropTypes.string,
    confirmTitle: PropTypes.string,
    cancelTitle: PropTypes.string,
    isContentText: PropTypes.bool,
    open: PropTypes.any,
    actions: PropTypes.any,
    dialogProps: PropTypes.object,
};
ConfirmDialog.defaultProps = {
    onConfirm: () => {},
    title: 'Usuwanie',
    confirmTitle: 'Potwierdzam',
    cancelTitle: 'Anuluj',
    isContentText: true,
    open: false,
    dialogProps: {},
};

export default ConfirmDialog;
