import React from 'react';
import Axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import NCForm from '../nc-form/NCForm';
import Plot from 'react-plotly.js';
import TableRenderers from '../../react-pivottable/TableRenderers';
import createPlotlyRenderers from '../../react-pivottable/PlotlyRenderers';
import PivotTableUI from '../../react-pivottable/PivotTableUI';

import '../../react-pivottable/pivottable.css';
import {Grid, Typography} from '@material-ui/core';
import BaseComponent from "../shared/BaseComponent";

const PlotlyRenderers = createPlotlyRenderers(Plot);

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  header: {
    marginBottom: theme.spacing(),
  },
});

class PivotReport extends BaseComponent {
    state = {
        loading: true,
        data : [],
        yearsData : [],
        values: {
            year: null,
            table : {}
        },
    };

    componentDidMount() {
        let questions = this.props.params.questions.map((v) => {return {id: v.id, cell: v.cell}; });
        let polls = this.props.params.polls.map((v) => v.id);
        let museums = this.props.params.museums.map((v) => v.id);
        let conditions = this.props.params.conditions;
        const { poll, state, filling_type, filling_year, question_type, estimated_data, data, museum } = this.props.params.values;

        Axios.post('/data-reports/getPivotData', {
            questions, polls, museums, state,
            poll, filling_type, filling_year, question_type, estimated_data,
            data, museum, conditions,
        }).then((response) => {

            let values = this.state.values;
            values.year = Object.keys(response.data.data)[0];

            Object.assign(values, this.props.params.additionalData.pivot);

            this.setState({
                loading: false,
                values: values,
                yearsData : response.data.data
            });
        });
    }

    changeSettings = (name, value) => {
        let values = this.state.values;
        values[name] = value;

        this.setState({values});
    }

   /* changeSettings(name, value) {
        let values = this.state.values;
        values[name] = value;

        this.setState({values});

        if ((this.state.values.previousPreset != this.state.values.preset) && (this.state.values.preset != false)){
            let values = this.state.values;
            this.setState({'values':values});

            if (this.state.values.preset == 'addNew'){
                this.handleSavePresetClick();
            }

            else if (this.state.values.preset == 'delete'){
                this.handleDeletePresetClick();
            }

            else {
                values.presetTitle = this.state.presets[this.state.values.preset].text
                values.previousPreset = this.state.values.preset

                let tmp = this.unpackPivotTable(this.state.presetsStorage[this.state.values.preset])
                this.setState({
                    aggregatorName: tmp.aggregatorName,
                    //aggregators: {Liczba: ƒ, Liczba unikalnych wartości: ƒ, Lista unikalnych wartości: ƒ, Suma: ƒ, Suma liczbowa: ƒ, …}
                    colOrder: tmp.colOrder,
                    cols: tmp.cols,
                    //data: (101) [Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), …]
                    derivedAttributes: tmp.derivedAttributes,
                    hiddenAttributes: tmp.hiddenAttributes,
                    hiddenFromAggregators: tmp.hiddenFromAggregators,
                    hiddenFromDragDrop: tmp.hiddenFromDragDrop,
                    menuLimit: tmp.menuLimit,
                    //onChange: ƒ onChange(s)
                    rendererName: tmp.rendererName,
                    //renderers: {Tabela: ƒ, Tabela - heatmap: ƒ, Tabela - heatmap kolumn: ƒ, Tabela - heatmap wierszy: ƒ, Eksport TSV: ƒ, …}
                    rowOrder: tmp.rowOrder,
                    rows: tmp.rows,
                    sorters: tmp.sorters,
                    //tableColorScaleGenerator: ƒ redColorScaleGenerator(values)
                    tableOptions: tmp.tableOptions,
                    unusedOrientationCutoff:  tmp.unusedOrientationCutoff,
                    vals:  tmp.vals,
                    valueFilter:  tmp.valueFilter,
                });
            }
        }
    }

    handleDeletePresetClick = () => {
        let values = this.state.values;
        if (values.previousPreset){
            let user_id = User.getUserId()
                Axios.delete('/preset-report-table-config/' + user_id +'/'+(this.state.presets[values.previousPreset].text), {
            }).then((response) => {
                //
            });

            this.state.presets[values.previousPreset] = false
            delete this.state.presets[values.previousPreset]
            this.state.presetsStorage[values.previousPreset] = false
            delete this.state.presetsStorage[values.previousPreset]
        }
        values.presetTitle = ''
        values.previousPreset = false;
        values.preset = false;
        this.setState({'values':values, 'presets' : this.state.presets, 'presetsStorage' : this.state.presetsStorage});
    }

    handleSavePresetClick = () => {
        let values = this.state.values;
        this.setState({'values':values});
        let presetKey = 1
        for (let i in this.state.presets){
            if (i >= presetKey){
                presetKey = i * 1 + 1
            }
        }
        let presetTitle = 'Preset ' + (presetKey - 0)
        if (this.state.values.presetTitle != ''){
            presetTitle = this.state.values.presetTitle;
        }
        let found = false
        for (var i in this.state.presets){
            if (this.state.presets[i].text == presetTitle){
                found = i;
                presetKey = found
                break;
            }
        }
        let presetObject = this.packPivotTable()

        if (!found){
            this.state.presets[presetKey] = {value: presetKey, text : presetTitle};
            this.state.presetsStorage[presetKey] = presetObject;
        } else {
            this.state.presetsStorage[found] = presetObject;
        }
        values.previousPreset = presetKey
        values.preset = presetKey
        values.presetTitle = this.state.presets[this.state.values.preset].text
        this.setState({'values':values, 'presets' : this.state.presets, 'presetsStorage' : this.state.presetsStorage});

        let user_id = User.getUserId()
        Axios.put('/preset-report-table-config/' + user_id , {
            user_id: user_id,
            title: presetTitle,
            packed : presetObject
        }).then((response) => {
            //
        });
    }

    unpackPivotTable = (packed) =>
        JSON.parse(packed)

    packPivotTable = () =>
        JSON.stringify({
            aggregatorName: this.state.aggregatorName,
            //aggregators: {Liczba: ƒ, Liczba unikalnych wartości: ƒ, Lista unikalnych wartości: ƒ, Suma: ƒ, Suma liczbowa: ƒ, …}
            colOrder: this.state.colOrder,
            cols: this.state.cols,
            //data: (101) [Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), Array(9), …]
            derivedAttributes: this.state.derivedAttributes,
            hiddenAttributes: this.state.hiddenAttributes,
            hiddenFromAggregators: this.state.hiddenFromAggregators,
            hiddenFromDragDrop: this.state.hiddenFromDragDrop,
            menuLimit: this.state.menuLimit,
            //onChange: ƒ onChange(s)
            rendererName: this.state.rendererName,
            //renderers: {Tabela: ƒ, Tabela - heatmap: ƒ, Tabela - heatmap kolumn: ƒ, Tabela - heatmap wierszy: ƒ, Eksport TSV: ƒ, …}
            rowOrder: this.state.rowOrder,
            rows: this.state.rows,
            sorters: this.state.sorters,
            //tableColorScaleGenerator: ƒ redColorScaleGenerator(values)
            tableOptions: this.state.tableOptions,
            unusedOrientationCutoff:  this.state.unusedOrientationCutoff,
            vals:  this.state.vals,
            valueFilter:  this.state.valueFilter,
        })*/

    render() {
        // const { classes } = this.props;
        const { values, yearsData, loading } = this.state;

        const fields = [
            {
                type: 'select',
                name: 'year',
                label: 'Rok',
                width : 400,
                options: Object.keys(yearsData).map((v) => {return {value: parseFloat(v), text: v};})
            },
        ];

        return <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
        >
            <Grid item xs>
                <NCForm values={values} fields={fields} hideSubmitButton={true} title="Ustawienia" onChange={this.changeSettings}/>
            </Grid>
            <Grid item lg>
                {loading ? (
                    <Typography style={{ marginTop: 100 }}>Ładowanie danych...</Typography>
                ) : (
                    values.year && yearsData[values.year] !== undefined && yearsData[values.year].length
                ) ? <PivotTableUI
                        onChange={s => this.setState({ values: { ...values, ...s } })}
                        renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
                        {...values}
                        data={yearsData[values.year]}
                        sort={false}
                        secondaryValues={[
                            // WARNING: these must match with server values
                            'miejscowość',
                            'kod pocztowy',
                            'poczta',
                            'gmina',
                            'powiat',
                            'województwo',
                            'Wielkość jednostki administracyjnej',
                            'Teren prowadzenie działalności',
                            'Muzeum na wolnym powietrzu',
                            'Typ muzeum',
                            'jakie? (tylko dla muzeów jednorodnych)',
                            'rok',
                            'Źródło finansowania',
                            'Wpis do Państwowego Rejestru Muzeów',
                            'Forma organizacyjna muzeum',
                            'Współprowadzenie',
                            'Dokument stanowiący podstawę działalności muzeum',
                            'Uzgodnienie statutu lub regulaminu z MKiDN',
                            'Rodzaj podmiotu organizującego',
                            //
                            'Rodzaj',
                            'Typ ankiety',
                            'Status ankiety',
                        ]}
                    />
                    : <Typography style={{ marginTop: 100 }}>Brak danych dla podanej konfiguracji.</Typography>
                }
            </Grid>
        </Grid>
    }
}

export default withStyles(styles)(PivotReport);
