import React  from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withRouter } from "react-router";
import { withStyles } from '@material-ui/core/styles';
import BaseComponent from "../shared/BaseComponent";

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { DateTimePicker, DatePicker } from "@material-ui/pickers";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import ColorPicker from 'material-ui-color-picker'

import { Editor } from '@tinymce/tinymce-react';
import 'tinymce';
import 'tinymce/themes/silver';
import 'tinymce/plugins/table';
import 'tinymce/plugins/link';
import 'tinymce/plugins/autoresize';

import { dotGet, dotSet, coalesce, coalesceString, formatDate } from "../shared/Helpers";
import Tooltip from "@material-ui/core/Tooltip";
import MaskedInput from "react-text-mask";
import Museum from "../../models/Museum";

const TextMask = (props) => {
    const { inputRef, ...other } = props;

    return <MaskedInput
        ref={ref => inputRef(ref ? ref.inputElement : null)}
        {...other}
    />
};

class NCForm extends BaseComponent {
    formRef = React.createRef();
    state = {
        values: {},
    };
    values = {};
    ids = [];

    updateProps()
    {
        if (this.props.values !== undefined) {
            this.values = this.props.values;
        }

        this.props.fields.forEach((field) => {
            const value = dotGet(this.values, field.name);

            if (value !== undefined) {
                dotSet(this.state.values, field.name, dotGet(this.values, field.name))
            } else {
                if (field.multiple !== undefined && field.multiple) {
                    dotSet(this.state.values, field.name, []);
                } else {
                    dotSet(this.state.values, field.name, '');
                }
            }
        });
    }

    constructor(props) {
        super(props);

        this.updateProps();

        this.onChange = this.props.onChange;
        this.formRef = props.formRef;
    }

    componentWillReceiveProps(nextProps) {
        this.props = nextProps;
        this.updateProps();
        if (nextProps.saveTrigger) {
            this.props.saveHandler(this.state.values);
        }
    }

    handleChange = name => event => {
        const value = event.target.value;

        this.setState({ values: dotSet(this.state.values, name, value) });

        this.onChange(name, value);
    };

    handleColorChange = name => value => {
        this.setState({ values: dotSet(this.state.values, name, value) });
        this.onChange(name, value);
    };

    handleTinyChange = name => event => {
        const value = event.target.getContent();

        this.setState({ values: dotSet(this.state.values, name, value) });

        this.onChange(name, value);
    };

    handleDateChange = (name, format) => value => {
        value = value ? formatDate(format, value) : null;

        this.setState({ values: dotSet(this.state.values, name, value) });

        this.onChange(name, value);
    };

    handleGroupCheckboxChange = (name, option) => event => {
        const path = name + '.' + option;
        let values = this.state.values;

        this.setState({ values: dotSet(values, path, event.target.checked) }, () => this.onChange(name, dotGet(values, name)));
    };

    handleCheckboxChange = name => event => {
        dotSet(this.state.values, name, event.target.checked);

        this.onChange(name, event.target.checked);
    };

    getFieldId = (field, key = '') => {
        const id = (field.name || field.type).replace(/\[\]|\[|\]/g, '_');
        let uniqueId = id, i = 1;
        while (this.ids.includes(uniqueId)) {
            uniqueId = id + '_' + i++ + '-' + key;
        }

        return uniqueId;
    };

    renderFields(fields) {
        const { classes } = this.props;
        const { values } = this.state;

        return fields.map((field, k1) => {
            const thisFieldStyle = Object.assign({}, field.style, { width: field.width !== "" ? field.width : 200 });
            const thisFieldOptions = field.options !== undefined ? field.options : [];
            const fieldValue = dotGet(values, field.name);
            const errors = dotGet(this.props.errors, field.name);
            const error = errors ? errors.join(', ') : false;
            const inputProps = field.inputProps || {};

            if (field.type === 'info') {
                return <Typography key={k1} variant="h6" component="h2" className={classes.info} style={thisFieldStyle}>
                    {field.text}
                </Typography>
            } else if (field.type === 'array') {
                /*if (field.fields !== undefined)
                {
                  return this.renderFields(field.fields);
                } else {
                  return;
                }*/
            } else if (field.type === 'divider') {
                return <div key={k1} className={classes.divider} style={thisFieldStyle}></div>
            } else if (field.type === 'hr') {
                return <hr key={k1} className={classes.hr} style={thisFieldStyle}></hr>
            } else if (field.type === 'datetime') {
                const format = field.format || 'yyyy-MM-dd HH:mm';
                return <DateTimePicker
                    id={this.getFieldId(field)}
                    name={field.name || field.type + '_' + k1}
                    clearable={true}
                    clearLabel="Wyczyść"
                    cancelLabel="Anuluj"
                    initialFocusedDate={formatDate('yyyy-MM-dd HH:00')}
                    key={k1}
                    minutesStep={field.minutesStep || 5}
                    label={field.label}
                    value={fieldValue ? formatDate(format, fieldValue) : null}
                    format={format}
                    ampm={false}
                    disablePast={!!field.disablePast}
                    className={classes.textField}
                    style={thisFieldStyle}
                    onChange={this.handleDateChange(field.name, format)}
                    error={!!error}
                    helperText={error ? error : ''}
                />
            } else if (field.type === 'colorpicker') {
                const value = coalesce(fieldValue, field.defaultValue) || null;
                return <>
                    <ColorPicker
                        id={this.getFieldId(field)}
                        label={field.label}
                        TextFieldProps={{ style: thisFieldStyle, className: classNames(classes.textField, classes.picker) }}
                        value={value}
                        onChange={this.handleColorChange(field.name)}
                    />
                    <span className={classes.pickerColor} style={{ backgroundColor: value }}/>
                </>
            } else if (field.type === 'year') {
                const format = field.format || 'yyyy';
                return <DatePicker
                    id={this.getFieldId(field)}
                    clearable={true}
                    clearLabel="Wyczyść"
                    cancelLabel="Anuluj"
                    key={k1}
                    views={["year"]}
                    minDate="1700"
                    label={field.label}
                    value={coalesce(fieldValue, field.defaultValue) || null}
                    disablePast={!!field.disablePast}
                    className={classes.textField}
                    style={thisFieldStyle}
                    onChange={this.handleDateChange(field.name, format)}
                    error={!!error}
                    helperText={error ? error : ''}
                />
            } else if (field.type === 'postcode') {
                return <TextField
                    id={this.getFieldId(field)}
                    key={k1}
                    label={field.label}
                    defaultValue={coalesceString(fieldValue, field.defaultValue)}
                    className={classes.textField}
                    style={thisFieldStyle}
                    onBlur={this.handleChange(field.name)}
                    placeholder="00-000"
                    InputProps={{
                        inputComponent: TextMask,
                    }}
                    inputProps={{
                        mask: [/\d/, /\d/, '-', /\d/, /\d/, /\d/],
                        pattern: "[0-9\\-]{6}",
                        // guide: true,
                        // showMask: true,
                        ...inputProps,
                    }}
                    type="text"
                    margin="normal"
                    error={!!error}
                    helperText={error ? error : ''}
                    disabled={!!field.disabled}
                />;
            } else if (field.type === 'phone') {
                return <TextField
                    id={this.getFieldId(field)}
                    key={k1}
                    label={field.label}
                    defaultValue={coalesceString(fieldValue, field.defaultValue)}
                    className={classes.textField}
                    style={thisFieldStyle}
                    onBlur={this.handleChange(field.name)}
                    placeholder="000 000 000"
                    InputProps={{
                        inputComponent: TextMask,
                    }}
                    inputProps={{
                        mask: [/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/],
                        pattern: "[0-9 ]{11}",
                        // guide: true,
                        // showMask: true,
                        ...inputProps,
                    }}
                    type="text"
                    margin="normal"
                    error={!!error}
                    helperText={error ? error : ''}
                    disabled={!!field.disabled}
                />;
            } else if (field.type === 'text') {
                return <TextField
                    id={this.getFieldId(field)}
                    key={k1}
                    label={field.label}
                    defaultValue={coalesceString(fieldValue, field.defaultValue)}
                    className={classes.textField}
                    style={thisFieldStyle}
                    onBlur={this.handleChange(field.name)}
                    margin="normal"
                    error={!!error}
                    helperText={error ? error : ''}
                    disabled={!!field.disabled}
                    inputProps={{ ...inputProps }}
                >{field.component || ''}</TextField>
            } else if (field.type === 'email') {
                return <TextField
                    id={this.getFieldId(field)}
                    key={k1}
                    label={field.label}
                    defaultValue={coalesceString(fieldValue, field.defaultValue)}
                    className={classes.textField}
                    style={thisFieldStyle}
                    // onChange={this.handleChange(field.name)}
                    onBlur={event => {
                        event.target.reportValidity();
                        this.handleChange(field.name)(event);
                    }}
                    type="email"
                    margin="normal"
                    error={!!error}
                    helperText={error ? error : ''}
                    disabled={!!field.disabled}
                    inputProps={{ ...inputProps }}
                >{field.component || ''}</TextField>
            } else if (field.type === 'number') {
                return <TextField
                    id={this.getFieldId(field)}
                    key={k1}
                    type="number"
                    inputProps={{ min: coalesceString(field.min), max: coalesceString(field.max), step: coalesceString(field.step), ...inputProps }}
                    label={field.label}
                    defaultValue={coalesce(fieldValue, field.defaultValue, '')}
                    className={classes.textField}
                    style={thisFieldStyle}
                    onBlur={this.handleChange(field.name)}
                    margin="normal"
                    error={!!error}
                    helperText={error ? error : ''}
                    disabled={!!field.disabled}
                />
            } else if (field.type === 'tinyBasic') {
                return <FormControl style={Object.assign({ paddingTop: 50 }, thisFieldStyle)} className={classes.formControl} key={k1} error={!!error}>
                    <InputLabel htmlFor={field.name}>{field.label}</InputLabel>
                    <Editor
                        // id={this.getFieldId(field)} // this will cause multiple Tiny instances to fail
                        key={k1}
                        initialValue={coalesceString(fieldValue, field.defaultValue)}
                        init={{
                            menubar: false,
                            plugins: 'autoresize paste',
                            paste_as_text: true,
                            content_css: '/tinymce.css?2',
                            // https://www.tiny.cloud/docs/advanced/editor-control-identifiers/#toolbarcontrols
                            toolbar: [
                                'undo redo',
                                'removeformat bold italic underline strikethrough fontsizeselect',
                            ].join(' | '),
                            force_br_newlines: false,
                            force_p_newlines: false,
                            forced_root_block: 'div',
                            entity_encoding: 'raw',
                            statusbar: false,
                            elementpath: false,
                            branding: false,
                            contextmenu: '',
                            autoresize_bottom_margin: 0,
                            language: 'pl',
                            language_url: '/pl.js',
                        }}
                        onChange={this.handleTinyChange(field.name)}
                    />
                    {error && <FormHelperText>{error}</FormHelperText>}
                </FormControl>
            } else if (field.type === 'tinyLessBasic') {
                return <FormControl style={Object.assign({ paddingTop: 50 }, thisFieldStyle)} className={classes.formControl} key={k1} error={!!error}>
                    <InputLabel htmlFor={field.name}>{field.label}</InputLabel>
                    <Editor
                        // id={this.getFieldId(field)} // this will cause multiple Tiny instances to fail
                        key={k1}
                        initialValue={coalesceString(fieldValue, field.defaultValue)}
                        init={{
                            menubar: false,
                            plugins: 'autoresize table link lists paste',
                            paste_as_text: true,
                            content_css: '/tinymce.css?2',
                            // https://www.tiny.cloud/docs/advanced/editor-control-identifiers/#toolbarcontrols
                            toolbar: [
                                'undo redo',
                                'removeformat bold italic underline strikethrough',
                                'alignnone alignleft aligncenter alignright alignjustify',
                                'bullist numlist',
                                'table',
                                'link unlink',
                            ].join(' | '),
                            force_br_newlines: false,
                            force_p_newlines: false,
                            forced_root_block: 'div',
                            entity_encoding: 'raw',
                            statusbar: false,
                            elementpath: false,
                            branding: false,
                            contextmenu: '',
                            autoresize_bottom_margin: 0,
                            language: 'pl',
                            language_url: '/pl.js',
                        }}
                        onChange={this.handleTinyChange(field.name)}
                    />
                    {error && <FormHelperText>{error}</FormHelperText>}
                </FormControl>
            } else if (field.type === 'tiny') {
                return <FormControl style={Object.assign({ paddingTop: 50 }, thisFieldStyle)} className={classes.formControl} key={k1} error={!!error}>
                    <InputLabel htmlFor={field.name}>{field.label}</InputLabel>
                    <input type="file" id="image-upload-tinymce" name="image" style={{ display: "none" }} accept="image/png, image/gif, image/jpeg, image/jpg, image/svg" />
                    <Editor
                        // id={this.getFieldId(field)} // this will cause multiple Tiny instances to fail
                        key={k1}
                        initialValue={coalesceString(fieldValue, field.defaultValue)}
                        init={{
                            menubar: false,
                            // plugins: 'preview searchreplace autolink autosave advcode visualblocks visualchars fullscreen image link media mediaembed table charmap hr advlist lists noneditable quickbars advtable',
                            // advtable
                            plugins: 'autoresize table link lists paste autolink code visualblocks visualchars image imagetools charmap hr advlist noneditable quickbars',
                            quickbars_insert_toolbar: false,
                            quickbars_selection_toolbar: 'removeformat bold italic underline strikethrough | quicklink',
                            paste_as_text: true,
                            paste_data_images: true,
                            image_advtab: true,
                            // SVG support:
                            // extended_valid_elements: "svg[*],defs[*],pattern[*],desc[*],metadata[*],g[*],mask[*],path[*],line[*],marker[*],rect[*],circle[*],ellipse[*],polygon[*],polyline[*],linearGradient[*],radialGradient[*],stop[*],image[*],view[*],text[*],textPath[*],title[*],tspan[*],glyph[*],symbol[*],switch[*],use[*]",
                            extended_valid_elements: "svg[*],path[*]",
                            table_resize_bars: false,
                            file_browser_callback_types: 'image',
                            file_picker_callback: (callback, value, meta) => {
                                if (meta.filetype === 'image') {
                                    const input = document.getElementById('image-upload-tinymce');
                                    input.click();
                                    input.onchange = () => {
                                        const file = input.files[0];
                                        const reader = new FileReader();
                                        reader.onload = (e) => {
                                            callback(e.target.result, {
                                                alt: file.name
                                            });
                                        };
                                        reader.readAsDataURL(file);
                                    };
                                }
                            },
                            content_css: '/tinymce.css?2',
                            // https://www.tiny.cloud/docs/advanced/editor-control-identifiers/#toolbarcontrols
                            toolbar: [
                                [
                                    'undo redo',
                                    'fontsizeselect formatselect',
                                    'bold italic underline strikethrough superscript subscript removeformat',
                                    'forecolor backcolor',
                                ].join(' | '), [
                                    'visualchars visualblocks code',
                                    'table image hr charmap',
                                    'numlist bullist',
                                    'alignnone alignleft aligncenter alignright alignjustify',
                                    'outdent indent',
                                    'link unlink',
                                ].join(' | '),
                            ],
                            force_br_newlines: false,
                            force_p_newlines: false,
                            forced_root_block: 'div',
                            entity_encoding: 'raw',
                            statusbar: false,
                            elementpath: false,
                            branding: false,
                            contextmenu: '',
                            autoresize_bottom_margin: 0,
                            language: 'pl',
                            language_url: '/pl.js',
                        }}
                        onChange={this.handleTinyChange(field.name)}
                    />
                    {error && <FormHelperText>{error}</FormHelperText>}
                </FormControl>
            } else if (field.type === 'password') {
                return <TextField
                    id={this.getFieldId(field)}
                    type="password"
                    key={k1}
                    label={field.label}
                    defaultValue={coalesceString(fieldValue, field.defaultValue)}
                    className={classes.textField}
                    style={thisFieldStyle}
                    onBlur={this.handleChange(field.name)}
                    margin="normal"
                    error={!!error}
                    helperText={error ? error : ''}
                    disabled={!!field.disabled}
                    inputProps={{ autoComplete: 'new-password', ...inputProps }}
                />
            } else if (field.type === 'textarea') {
                return <TextField
                    id={this.getFieldId(field)}
                    multiline
                    key={k1}
                    label={field.label}
                    defaultValue={coalesceString(fieldValue, field.defaultValue)}
                    className={classes.textField}
                    style={thisFieldStyle}
                    onBlur={this.handleChange(field.name)}
                    margin="normal"
                    error={!!error}
                    helperText={error ? error : ''}
                    disabled={!!field.disabled}
                    inputProps={{ ...inputProps }}
                />
            } else if (field.type === 'select') {
                let inputLabelProps = {};
                if (!!field.emptyValue) {
                    inputLabelProps.shrink = true;
                }
                return <FormControl className={classes.formControl} key={k1} error={!!error}>
                    <InputLabel {...inputLabelProps} htmlFor={field.name}>{field.label}</InputLabel>
                    <Select
                        id={this.getFieldId(field)}
                        displayEmpty={!!field.emptyValue}
                        value={coalesceString(fieldValue)}
                        onChange={this.handleChange(field.name)}
                        style={thisFieldStyle}
                        className={classes.selectField}
                        disabled={!!field.disabled}
                        onOpen={field.onOpen || null}
                        open={field.open === undefined ? null : field.open}
                    >
                        {!!field.emptyValue && (
                            <MenuItem key="" value=""><em>{field.emptyValue}</em></MenuItem>
                        )}
                        {thisFieldOptions.map((option, k2) => {
                            return <MenuItem value={option.value} key={k2} disabled={option.disabled}>
                                {option.text || option.value}
                            </MenuItem>
                        })}
                    </Select>
                    {error && <FormHelperText>{error}</FormHelperText>}
                </FormControl>
            } else if (field.type === 'checkboxgroup') {
                return <div key={k1}>
                    <Typography variant="h6" component="h3" className={classes.info} style={thisFieldStyle}>
                        {field.label}
                    </Typography>
                    {field.groups.map((group, groupKey) => {
                        return <div key={'group-' + groupKey}>
                            <Typography variant="h6" component="h6" style={thisFieldStyle}>{group.name}</Typography>
                            {group.options.map((option, optionKey) => {
                                return <FormControlLabel
                                    key={'option-' + optionKey}
                                    control={<Checkbox
                                        id={this.getFieldId(field, optionKey)}
                                        key={k1 + '_' + optionKey}
                                        checked={!!fieldValue[option.value]}
                                        value={option.value + ''}
                                        onChange={this.handleGroupCheckboxChange(field.name, option.value)}
                                    />}
                                    label={option.text || option.value}
                                />
                            })}
                        </div>
                    })}
                </div>
            } else if (field.type === 'boolean') {
                return <FormControlLabel
                    className={classes.formControl}
                    style={Object.assign({ marginRight: 30 }, thisFieldStyle)}
                    key={k1}
                    label={field.label}
                    control={<Checkbox onChange={this.handleCheckboxChange(field.name)}
                                       checked={!!fieldValue}
                                       value="1"/>}
                    disabled={!!field.disabled}
                />
            } else if (field.type === 'boolean_select') {
                let inputLabelProps = {};
                if (!!field.emptyValue) {
                    inputLabelProps.shrink = true;
                }
                return <FormControl className={classes.formControl} key={k1} error={!!error}>
                    <InputLabel {...inputLabelProps} htmlFor={field.name}>{field.label}</InputLabel>
                    <Select
                        id={this.getFieldId(field)}
                        displayEmpty={!!field.emptyValue}
                        value={coalesceString(fieldValue)}
                        onChange={this.handleChange(field.name)}
                        style={thisFieldStyle}
                        className={classes.selectField}
                        disabled={!!field.disabled}
                        onOpen={field.onOpen || null}
                        open={field.open === undefined ? null : field.open}
                    >
                        {!!field.emptyValue && (
                            <MenuItem key="" value=""><em>{field.emptyValue}</em></MenuItem>
                        )}
                        {[{ value: 'tak' }, { value: 'nie' }].map((option, k2) => {
                            return <MenuItem value={option.value} key={k2}>
                                {option.value}
                            </MenuItem>
                        })}
                    </Select>
                    {error && <FormHelperText>{error}</FormHelperText>}
                </FormControl>
            } else if (field.type === 'voivodeship') {
                let inputLabelProps = {};
                if (!!field.emptyValue) {
                    inputLabelProps.shrink = true;
                }
                return <FormControl className={classes.formControl} key={k1} error={!!error}>
                    <InputLabel {...inputLabelProps} htmlFor={field.name}>{field.label}</InputLabel>
                    <Select
                        id={this.getFieldId(field)}
                        multiple={!!field.multiple}
                        displayEmpty={!!field.emptyValue}
                        value={fieldValue}
                        onChange={this.handleChange(field.name)}
                        style={thisFieldStyle}
                        className={classes.selectField}
                        disabled={!!field.disabled}
                        onOpen={field.onOpen || null}
                        open={field.open === undefined ? null : field.open}
                    >
                        {!!field.emptyValue && (
                            <MenuItem key="" value=""><em>{field.emptyValue}</em></MenuItem>
                        )}
                        {Museum.voivodeships.map((option, k2) => {
                            return <MenuItem value={option} key={k2}>
                                {option}
                            </MenuItem>
                        })}
                    </Select>
                    {error && <FormHelperText>{error}</FormHelperText>}
                </FormControl>

            } else if (field.type === 'multicheckbox') {
                return <FormControl className={classes.formControl} key={k1} error={!!error} disabled={!!field.disabled}>
                    <Typography variant="subtitle1" style={thisFieldStyle}>{field.label}</Typography>
                    {thisFieldOptions.map((option, k2) => {
                        return <FormControlLabel
                            key={'option-' + k2}
                            control={<Checkbox
                                key={k1 + '_' + k2}
                                checked={!!fieldValue[option.value]}
                                value={option.value + ''}
                                onChange={this.handleGroupCheckboxChange(field.name, option.value)}
                            />}
                            label={option.text || option.value}
                        />
                    })}
                    {error && <FormHelperText>{error}</FormHelperText>}
                </FormControl>
            } else if (field.type === 'multiselect') {
                let inputLabelProps = {};
                if (!!field.emptyValue) {
                    inputLabelProps.shrink = true;
                }
                return <FormControl className={classes.formControl} key={k1} error={!!error} disabled={!!field.disabled} style={field.containerStyle || {}}>
                    <InputLabel {...inputLabelProps} htmlFor={field.name}>{field.label}</InputLabel>
                    <Select
                        displayEmpty={!!field.emptyValue}
                        multiple
                        value={fieldValue || []}
                        onChange={this.handleChange(field.name)}
                        style={thisFieldStyle}
                        className={classes.selectField}
                        onClose={field.onClose || (() => {})}
                    >
                        {!!field.emptyValue && (
                            <MenuItem key="" value=""><em>{field.emptyValue}</em></MenuItem>
                        )}
                        {thisFieldOptions.map((option, k2) => {
                            return <MenuItem value={option.value} key={k2}>
                                {option.text || option.value}
                            </MenuItem>
                        })}
                    </Select>
                    {error && <FormHelperText>{error}</FormHelperText>}
                </FormControl>
            } else if (field.type === 'custom') {
                return field.component;
            }

            return ''; // satisfy react validator
        }).map((component, key) => {
            const { tooltip } = fields[key];
            if (tooltip) {
                const tooltipProps = Object.assign({},
                    {
                        placement: 'left',
                        hidden: !tooltip,
                        title: tooltip,
                    },
                    typeof tooltip === 'string' ? { title: tooltip } : tooltip,
                );
                return <Tooltip key={key} {...tooltipProps}>{component}</Tooltip>
            }
            return component;
        });
    }

    render() {
        const { classes, fieldsContainerStyle } = this.props;
        const { fields } = this.props;

        return <form ref={this.formRef} autoComplete="off" style={this.props.style || {}} onSubmit={event => event.preventDefault()}>
            {!!this.props.title && (
                <Typography variant="h6" component="h2" className={classes.header}>
                    {this.props.title}
                </Typography>
            )}
            <div className={classes.container} style={fieldsContainerStyle}>
                {this.renderFields(fields)}
            </div>
            <div>
                {!!this.props.cancelLink && (
                    <Button onClick={() => this.props.history.push(this.props.cancelLink)} variant="contained" color="secondary" className={classes.button}>
                        {this.props.cancelButtonLabel || "Anuluj"}
                    </Button>
                )}
                {!this.props.hideSubmitButton && (
                    <Button onClick={() => this.props.saveHandler(this.state.values)} variant="contained" color="primary" className={classes.button}>
                        {this.props.saveButtonLabel || "Zapisz"}
                    </Button>
                )}
            </div>
        </form>
    }
}

NCForm.propTypes = {
    fields: PropTypes.array.isRequired,
    fieldsContainerStyle: PropTypes.object,
    saveButtonLabel: PropTypes.string,
    cancelButtonLabel: PropTypes.string,
    cancelLink: PropTypes.string,
    hideSubmitButton: PropTypes.bool,
    errors: PropTypes.object,
    values: PropTypes.object,
    title: PropTypes.string,
    saveHandler: PropTypes.func,
    onChange: PropTypes.func,
    formRef: PropTypes.any,
    saveTrigger: PropTypes.bool,
};
NCForm.defaultProps = {
    fieldsContainerStyle: {},
    errors: {},
    title: '',
    onChange: () => {},
    formRef: React.createRef(),
    saveTrigger: false,
};

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    header: {
        marginBottom: theme.spacing(),
    },
    info: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(1),
        flexBasis: '100%',
    },
    textField: {
        marginTop: 0,
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(),
        width: 200,
    },
    selectField: {
        marginTop: 0,
        marginRight: theme.spacing(2),
        width: 200,
    },
    formControl: {
        marginBottom: theme.spacing(),
        minWidth: 120,
    },
    button: {
        marginTop: theme.spacing(4),
        marginRight: theme.spacing(2),
    },
    divider: {
        flexBasis: '100%',
    },
    hr: {
        flexBasis: '100%',
        border: 0,
        borderBottom: '2px solid #607d8b',
    },
    picker: {
        width: 80,
    },
    pickerColor: {
        display: 'inline-block',
        width: 20,
        height: 20,
        marginTop: 22,
        marginLeft: -40,
    },
});

export default withStyles(styles)(withRouter(NCForm));
