import PropTypes from "prop-types";
import QuestionTableCreator from "./QuestionTableCreator";
import jexcel from 'jexcel';

class TableColumnPicker extends QuestionTableCreator {
    historyActive = false;

    constructor(props) {
        super(props);

        this.options.allowInsertRow = false;
        this.options.allowManualInsertRow = false;
        this.options.allowDeleteRow = false;
        this.options.onselection = this.selectionHandler;
    }

    renderToolbar = () => {
        return ''
    };

    renderDialogs = () => {
        return '';
    };

    selectionHandler = (instance, x1, y1, x2, y2) => {
        if (this.jexcel == null) {
            return;
        }

        if (this.props.multiple) {
            if (y1 === 0 && y2 === 1) { // only when columns are clicked
                let columns = [];
                for (let x = x1; x <= x2; x++) {
                    columns.push({ column: jexcel.getColumnName(x), x });
                }
                this.props.changeHandler(columns);
            } else {
                this.props.changeHandler([]);
            }
        } else {
            if (y1 === 0 && y2 === 1) { // only when column is clicked
                this.props.changeHandler(jexcel.getColumnName(x1), x1);
            }
        }
    };
}

TableColumnPicker.propTypes = {
    table: PropTypes.object.isRequired,
    changeHandler: PropTypes.func.isRequired,
    multiple: PropTypes.bool,
};
TableColumnPicker.defaultProps = {
    table: {},
    changeHandler: () => {},
    isDynamic: true,
    multiple: false,
};

export default TableColumnPicker;
