import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { withStyles } from "@material-ui/core";
import { withRouter } from "react-router";
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import User from './User';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import Axios from "axios";
import BaseComponent from "../shared/BaseComponent";

class Login extends BaseComponent {
    state = {
        isLoggedIn: User.isLoggedIn(),
        passwordReset: false,
    };

    static getDerivedStateFromProps(props, state) {
        return { passwordReset: props.location.pathname === '/password-reset' };
    }

    submitForm = (values, { setSubmitting, setErrors }) => {
        if (this.state.passwordReset) {
            Axios.post('/password-reset', { email: values.email })
                .then((response) => {
                    this.props.history.push('/');
                    setSubmitting(false);
                })
                .catch(err => {
                    setSubmitting(false);
                });
        } else {
            User.logIn(values.email, values.password)
                .then(res => {
                    this.setState({ isLoggedIn: true });
                })
                .catch(err => {
                    this.setState({ isLoggedIn: false });
                    setSubmitting(false);
                });
        }
    };

    render() {
        const { classes, children } = this.props;
        const { passwordReset } = this.state;

        if (User.isLoggedIn()) {
            return (children);
        }

        return (
            <Grid
                container
                // spacing={30}
                direction="row"
                alignItems="center"
                justify="center"
                style={{ minHeight: '100vh', background: '#fafafa' }}
            >
                <Grid item>
                    <Paper className={classes.paper}>
                        <Typography variant="h5" component="h5" className={classes.header}>
                            {passwordReset ? 'Resetowanie hasła' : 'Logowanie'}
                        </Typography>
                        <Formik
                            validateOnBlur={false}
                            initialValues={{ email: '', password: '' }}
                            validate={values => {
                                let errors = {};
                                if (!values.email.length) {
                                    errors.email = 'Wymagane';
                                } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                                    errors.email = 'Nieprawidłowy adres email';
                                }
                                if (!passwordReset && !values.password.length) {
                                    errors.password = 'Wymagane';
                                }
                                return errors;
                            }}
                            onSubmit={this.submitForm}
                        >
                            {({ isSubmitting }) => (
                                <Form>
                                    <div className={classes.inputContainer}>
                                        <label htmlFor="email" aria-activedescendant="email" tabIndex="1" className={classes.hidden}>Login</label>
                                        <Field placeholder="Login" id="email" type="email" name="email" className={classes.inputField}/>
                                        <Typography><ErrorMessage name="email" component="span" className={classes.errorMessage}/></Typography>
                                    </div>
                                    {!passwordReset && (
                                        <div className={classes.inputContainer}>
                                            <label htmlFor="password" aria-activedescendant="password" tabIndex="2" className={classes.hidden}>Hasło</label>
                                            <Field placeholder="Hasło" id="password" type="password" name="password" className={classes.inputField}/>
                                            <Typography><ErrorMessage name="password" component="span" className={classes.errorMessage}/></Typography>
                                        </div>
                                    )}
                                    <Button type="submit" disabled={isSubmitting} size="small" variant="contained" color="primary">
                                        {passwordReset ? 'Resetuj hasło' : 'Zaloguj'}
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                        <Typography>
                            <Link to={passwordReset ? '/' : '/password-reset'} className={classes.linkButton}>
                                {passwordReset ? 'wróć do logowania' : 'nie pamiętam hasła'}
                            </Link>
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}

const styles = theme => ({
    paper: {
        padding: theme.spacing(2),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        textAlign: 'center',
    },
    header: {
        marginBottom: 20,
    },
    inputField: {
        background: '#fff',
        // marginRight: 10,
        border: '1px solid #ccc',
        padding: theme.spacing(0.5),
        width: 200,
    },
    inputContainer: {
        marginBottom: theme.spacing(2),
    },
    errorMessage: {
        color: '#f00',
    },
    linkButton: {
        marginTop: theme.spacing(2),
        display: 'inline-block',
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    hidden: {
        display: 'none',
    },
});

export default withRouter(withStyles(styles)(Login));
