import React from 'react';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import NCForm from '../nc-form/NCForm';

import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CallMergeIcon from '@material-ui/icons/CallMerge';

import DataComponent from "../shared/DataComponent";
import Axios from 'axios';

import Typography from "@material-ui/core/Typography";

import MuseumTabNotes from "./MuseumTabNotes";
import MuseumTabBranches from "./MuseumTabBranches";
import MuseumTabUsers from "./MuseumTabUsers";
import MuseumTabFiles from "./MuseumTabFiles";
import MuseumTabHistory from "./MuseumTabHistory";

import User from "../auth/User";
import MuseumTabProjects from "./MuseumTabProjects";
import FabContainer from "../shared/FabContainer";
import PaperRoot from "../shared/PaperRoot";
import ConfirmDialog from "../shared/ConfirmDialog";
import { getSelectOptions, removeItemsFromArrayByAttribute, dotSet } from "../shared/Helpers";
import Museum from "../../models/Museum";
import FabButton, { FabLink } from "../shared/FabButton";
import MuseumsPicker from "./MuseumsPicker";
import InfoIcon from "@material-ui/icons/Info";
import { FormHelperText } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import HistoryIcon from "@material-ui/icons/History";
import MuseumTabHistoryBranch from "./MuseumTabHistoryBranch";

class MuseumEdit extends DataComponent {
    static permissions = ['edit-museums'];
    url = '/museums/add';

    getObjectId() {
        return this.props.match.params.museum_id;
    }

    constructor(props) {
        super(props);

        if (this.getObjectId()) {
            this.url = '/museums/' + this.getObjectId();
        }

        this.state.currentTab = 'info';
        this.state.mergeDialog = false;
    }

    getTitle = () => {
        return this.hasObject() ? "Edycja muzeum: " + this.getObject('name') : 'Dodaj muzeum';
    };

    saveObject = () => {
        if (this.hasObject()) {
            Axios.put(this.url, this.getValues()).then(response => {
                // this.props.history.push('/museums/' + this.getObjectId() + '/edit');
                // this.reloadStateData(response.data, { errors: {} });
                this.props.history.push('/museums');
            }).catch(this.catchErrors);
        } else {
            Axios.post('/museums', this.getValues()).then(response => {
                // this.props.history.push('/museums/' + response.data.object.id + '/edit');
                this.props.history.push('/museums');
            }).catch(this.catchErrors);
        }
    };

    openMergeDialog = () => {
        this.setState({ mergeDialog: true });
    };

    mergeMuseum = (museum) => {
        if (museum) {
            Axios.put('/museums/merge/' + this.getObjectId(), { mergeWith: museum.id }).then(response => {
                this.setState({ mergeDialog: false }, () => {
                    this.props.history.push('/museums/' + museum.id + '/edit');
                });
            }).catch(this.catchErrors);
        } else {
            this.setState({ mergeDialog: false });
        }
    };

    // dataSelectField = (name, label, width = 400) => {
    //     const options = this.state.data.options;
    //     return {
    //         name: name,
    //         type: 'select',
    //         label: label,
    //         width: width,
    //         options: options[name.replace('_data_id', '')] || [],
    //     };
    // };

    changeHandler = (name, value) => {
        let values = this.state.values;
        if (name === 'data.scientific_type' && value === 'interdyscyplinarne') {
            values = dotSet(values, 'data.scientific_type_value', null);
        }
        this.setState({ values: dotSet(values, name, value) });
    };

    renderInfoTab = () => {
        const fields = [
            {
                text: 'Dane instytucji',
                type: 'info',
            },
            {
                label: 'Pełna nazwa instytucji',
                name: 'name',
                type: 'text',
                width: 660,
                disabled: !User.hasPermissionTo('change-museum-name'),
            },
            {
                label: 'Rodzaj',
                name: 'type',
                type: 'select',
                options: removeItemsFromArrayByAttribute(getSelectOptions(Museum.types), 'branch', 'value'),
                disabled: !User.hasPermissionTo('change-museum-type') || (this.hasObject() && this.getObject('branches').length),
                width: 140,
            },
            { type: 'divider' },
        ];
        if (!User.hasAllPermissions('change-museum-name', 'change-museum-type')) {
            fields.push(
                {
                    type: 'custom',
                    component: <Box display="flex">
                        <Box><InfoIcon color="primary" style={{ marginRight: 6 }}/></Box>
                        <Box><FormHelperText>W celu zmiany nazwy lub typu muzeum skontaktuj się z administratorem systemu wysyłając maila na adres: <a
                            href="mailto:wsparcie.statystyka@nimoz.pl">wsparcie.statystyka@nimoz.pl</a></FormHelperText></Box>
                    </Box>
                },
                { type: 'divider' },
            );
        }
        if (User.hasPermissionTo('change-museum-internal-data')) {
            fields.push(
                {
                    label: 'ID (zgodne z numeracją NIMOZ)',
                    name: 'nimoz_id',
                    type: 'text',
                    width: 250,
                },
            );
        }
        fields.push(
            {
                label: 'Numer REGON',
                name: 'data.regon',
                type: 'text',
                width: 150,
            },
            {
                label: 'Numer NIP',
                name: 'data.nip',
                type: 'text',
                width: 150,
            },
        );

        fields.push(
            {
                text: 'Dane teleadresowe muzeum wynikające z procedury uzgadniania dokumentów z MKiDN',
                type: 'info',
            },
            {
                label: 'ulica',
                name: 'data.street',
                type: 'text',
                width: 300,
            },
            {
                label: 'numer domu',
                name: 'data.building_nb',
                type: 'text',
                width: 100,
            },
            {
                label: 'numer lokalu',
                name: 'data.local_nb',
                type: 'text',
                width: 100,
            },
            { type: 'divider' },
            {
                label: 'miejscowość',
                name: 'data.city',
                type: 'text',
                width: 416,
            },
            {
                label: 'kod pocztowy',
                name: 'data.postcode',
                type: 'postcode',
                width: 100,
            },
            { type: 'divider' },
            {
                label: 'poczta',
                name: 'data.post',
                type: 'text',
                width: 258,
            },
            {
                label: 'gmina',
                name: 'data.commune',
                type: 'text',
                width: 258,
            },
            { type: 'divider' },
            {
                label: 'powiat',
                name: 'data.county',
                type: 'text',
                width: 258,
            },
            {
                label: 'województwo',
                name: 'data.voivodeship',
                type: 'voivodeship',
                width: 258,
            },
            { type: 'divider' },
            {
                label: 'nr telefonu',
                name: 'data.phone_nb',
                type: 'phone',
                width: 100,
            },
            {
                label: 'e-mail',
                name: 'data.email',
                type: 'email',
                width: 416,
            },
        );

        fields.push(
            {
                text: 'Adres do korespondencji',
                type: 'info',
            },
            {
                label: 'ulica',
                name: 'data.contact_street',
                type: 'text',
                width: 300,
            },
            {
                label: 'numer domu',
                name: 'data.contact_building_nb',
                type: 'text',
                width: 100,
            },
            {
                label: 'numer lokalu',
                name: 'data.contact_local_nb',
                type: 'text',
                width: 100,
            },
            { type: 'divider' },
            {
                label: 'miejscowość',
                name: 'data.contact_city',
                type: 'text',
                width: 416,
            },
            {
                label: 'kod pocztowy',
                name: 'data.contact_postcode',
                type: 'postcode',
                width: 100,
            },
            { type: 'divider' },
            {
                label: 'poczta',
                name: 'data.contact_post',
                type: 'text',
                width: 258,
            },
            {
                label: 'gmina',
                name: 'data.contact_commune',
                type: 'text',
                width: 258,
            },
            { type: 'divider' },
            {
                label: 'powiat',
                name: 'data.contact_county',
                type: 'text',
                width: 258,
            },
            {
                label: 'województwo',
                name: 'data.contact_voivodeship',
                type: 'voivodeship',
                width: 258,
            },
            { type: 'divider' },
            {
                label: 'nr telefonu',
                name: 'data.contact_phone_nb',
                type: 'phone',
                width: 100,
            },
            {
                label: 'e-mail',
                name: 'data.contact_email',
                type: 'email',
                width: 416,
            },
            { type: 'hr' },
        );
        fields.push(
            {
                label: 'Strona www muzeum',
                name: 'data.www',
                type: 'text',
                width: 416
            },
            {
                label: 'Adres profilu na Facebooku (opcjonalnie)',
                name: 'data.facebook',
                type: 'text',
                width: 416,
            },
            { type: 'divider' },
        );
        if (User.hasPermissionTo('change-museum-internal-data')) {
            fields.push(
                {
                    label: 'Adres internetowego katalogu zbiorów',
                    name: 'data.catalog_url',
                    type: 'text',
                    width: 652,
                },
            );
        }
        fields.push(
            {
                label: 'Rok założenia muzeum',
                name: 'data.year',
                type: 'year',
                width: 180,
            },
            { type: 'divider' },
            {
                label: 'Wielkość jednostki administracyjnej',
                name: 'data.administrative_size',
                type: 'select',
                // emptyValue: '- wybierz -',
                options: [
                    { value: 'powyżej 500 tys. mieszkańców' },
                    { value: 'powyżej 100 do 500 tys.' },
                    { value: 'powyżej 10 do 100 tys.' },
                    { value: 'do 10 tys.' },
                ],
                width: 290,
            },
            {
                label: 'Teren prowadzenie działalności',
                name: 'data.territory',
                type: 'select',
                // emptyValue: '- wybierz -',
                options: [
                    { value: 'miejska' },
                    { value: 'wiejska' },
                ],
                width: 254,
            },
            {
                label: 'Źródło finansowania',
                name: 'data.financing',
                type: 'select',
                // emptyValue: '- wybierz -',
                options: [
                    { value: 'publiczne' },
                    { value: 'prywatne' },
                    { value: 'publiczno-prywatne' },
                ],
                width: 272,
            },
            { type: 'divider' },
            {
                label: 'Forma organizacyjna muzeum',
                name: 'data.organizational_form',
                type: 'select',
                // emptyValue: '- wybierz -',
                options: Object.keys(Museum.organizational_forms).map(value => ({ value })),
                width: 560,
            },
            {
                label: 'Współprowadzenie',
                name: 'data.co_lead',
                type: 'boolean_select',
                width: 272,
            },
            { type: 'hr' },
            {
                label: 'Rodzaj podmiotu organizującego',
                name: 'data.entity_type',
                type: 'select',
                // emptyValue: '- wybierz -',
                options: [
                    { value: 'MKiDN' },
                    { value: 'pozostałe ministerstwa' },
                    { value: 'inne publiczne osoby prawne' },
                    { value: 'jednostka samorządu terytorialnego' },
                    { value: 'państwowa instytucja kultury' },
                    { value: 'samorządowa instytucja kultury' },
                    { value: 'fundacja' },
                    { value: 'stowarzyszenie' },
                    { value: 'jednostka kościelna lub wyznaniowa' },
                    { value: 'uczelnia publiczna' },
                    { value: 'uczelnia niepubliczna' },
                    { value: 'osoba fizyczna' },
                    { value: 'podmiot gospodarczy' },
                    { value: 'inna' },
                ],
                width: 290,
            },
            {
                label: 'Pełna nazwa podmiotu organizującego',
                name: 'data.entity_name',
                type: 'text',
                width: 542,
            },
            { type: 'divider' },
        );
        fields.push(
            {
                text: 'Dane teleadresowe podmiotu organizującego',
                type: 'info',
                style: { fontSize: '1.1rem' },
            },
            {
                label: 'ulica',
                name: 'data.entity_street',
                type: 'text',
                width: 300,
            },
            {
                label: 'numer domu',
                name: 'data.entity_building_nb',
                type: 'text',
                width: 100,
            },
            {
                label: 'numer lokalu',
                name: 'data.entity_local_nb',
                type: 'text',
                width: 100,
            },
            { type: 'divider' },
            {
                label: 'miejscowość',
                name: 'data.entity_city',
                type: 'text',
                width: 416,
            },
            {
                label: 'kod pocztowy',
                name: 'data.entity_postcode',
                type: 'postcode',
                width: 100,
            },
            { type: 'divider' },
            {
                label: 'poczta',
                name: 'data.entity_post',
                type: 'text',
                width: 258,
            },
            {
                label: 'gmina',
                name: 'data.entity_commune',
                type: 'text',
                width: 258,
            },
            { type: 'divider' },
            {
                label: 'powiat',
                name: 'data.entity_county',
                type: 'text',
                width: 258,
            },
            {
                label: 'województwo',
                name: 'data.entity_voivodeship',
                type: 'voivodeship',
                width: 258,
            },
            { type: 'divider' },
            {
                label: 'nr telefonu',
                name: 'data.entity_phone_nb',
                type: 'phone',
                width: 100,
            },
            {
                label: 'e-mail',
                name: 'data.entity_email',
                type: 'email',
                width: 416,
            },
            { type: 'divider' },
        );

        fields.push(
            {
                label: 'Rodzaj podmiotu organizującego 2',
                name: 'data.entity2_type',
                type: 'select',
                emptyValue: '- brak -',
                options: [
                    { value: 'MKiDN' },
                    { value: 'pozostałe ministerstwa' },
                    { value: 'inne publiczne osoby prawne' },
                    { value: 'jednostka samorządu terytorialnego' },
                    { value: 'państwowa instytucja kultury' },
                    { value: 'samorządowa instytucja kultury' },
                    { value: 'fundacja' },
                    { value: 'stowarzyszenie' },
                    { value: 'jednostka kościelna lub wyznaniowa' },
                    { value: 'uczelnia publiczna' },
                    { value: 'uczelnia niepubliczna' },
                    { value: 'osoba fizyczna' },
                    { value: 'podmiot gospodarczy' },
                    { value: 'inna' },
                ],
                width: 316,
            },
            {
                label: 'Pełna nazwa podmiotu organizującego 2',
                name: 'data.entity2_name',
                type: 'text',
                width: 316,
            },
            { type: 'divider' },
        );

        fields.push(
            {
                label: 'Rodzaj podmiotu organizującego 3',
                name: 'data.entity3_type',
                type: 'select',
                emptyValue: '- brak -',
                options: [
                    { value: 'MKiDN' },
                    { value: 'pozostałe ministerstwa' },
                    { value: 'inne publiczne osoby prawne' },
                    { value: 'jednostka samorządu terytorialnego' },
                    { value: 'państwowa instytucja kultury' },
                    { value: 'samorządowa instytucja kultury' },
                    { value: 'fundacja' },
                    { value: 'stowarzyszenie' },
                    { value: 'jednostka kościelna lub wyznaniowa' },
                    { value: 'uczelnia publiczna' },
                    { value: 'uczelnia niepubliczna' },
                    { value: 'osoba fizyczna' },
                    { value: 'podmiot gospodarczy' },
                    { value: 'inna' },
                ],
                width: 316,
            },
            {
                label: 'Pełna nazwa podmiotu organizującego 3',
                name: 'data.entity3_name',
                type: 'text',
                width: 316,
            },
            { type: 'divider' },
        );

        fields.push(
            {
                label: 'Rodzaj podmiotu organizującego 4',
                name: 'data.entity4_type',
                type: 'select',
                emptyValue: '- brak -',
                options: [
                    { value: 'MKiDN' },
                    { value: 'pozostałe ministerstwa' },
                    { value: 'inne publiczne osoby prawne' },
                    { value: 'jednostka samorządu terytorialnego' },
                    { value: 'państwowa instytucja kultury' },
                    { value: 'samorządowa instytucja kultury' },
                    { value: 'fundacja' },
                    { value: 'stowarzyszenie' },
                    { value: 'jednostka kościelna lub wyznaniowa' },
                    { value: 'uczelnia publiczna' },
                    { value: 'uczelnia niepubliczna' },
                    { value: 'osoba fizyczna' },
                    { value: 'podmiot gospodarczy' },
                    { value: 'inna' },
                ],
                width: 316,
            },
            {
                label: 'Pełna nazwa podmiotu organizującego 4',
                name: 'data.entity4_name',
                type: 'text',
                width: 316,
            },
            { type: 'hr' },
        );

        fields.push(
            {
                label: 'Muzeum na wolnym powietrzu',
                name: 'data.outdoor',
                type: 'boolean_select',
                width: 316,
            },
            {
                label: 'Typ muzeum',
                name: 'data.scientific_type',
                type: 'select',
                // emptyValue: '- wybierz -',
                options: [
                    { value: 'jednorodne' },
                    { value: 'interdyscyplinarne' },
                ],
                width: 316,
            },
        );
        if (this.state.values.data && this.state.values.data['scientific_type'] === 'jednorodne') {
            fields.push(
                {
                    label: 'Jakie?',
                    name: 'data.scientific_type_value',
                    type: 'select',
                    // emptyValue: '- wybierz -',
                    options: [
                        { value: 'sztuki' },
                        { value: 'archeologiczne' },
                        { value: 'etnograficzne i antropologiczne' },
                        { value: 'historyczne' },
                        { value: 'martyrologiczne' },
                        { value: 'historii naturalnej' },
                        { value: 'techniki i nauki' },
                        { value: 'specjalistyczne' },
                        { value: 'inne' },
                    ],
                    width: 316,
                },
            );
        }
        fields.push(
            { type: 'divider' },
            {
                label: 'Dokument stanowiący podstawę działalności muzeum',
                name: 'data.activity_document',
                type: 'select',
                // emptyValue: '- wybierz -',
                options: [
                    { value: 'statut' },
                    { value: 'regulamin' },
                    { value: 'inny' },
                ],
                width: 416,
            },
            {
                label: 'Uzgodnienie statutu lub regulaminu z MKiDN',
                name: 'data.mkidn_statute',
                type: 'boolean_select',
                width: 356,
            },
            { type: 'divider' },
            {
                label: 'Wpis do Państwowego Rejestru Muzeów',
                name: 'data.register_entry',
                type: 'boolean_select',
                width: 316,
            },
            {
                label: 'Numer PRM',
                name: 'data.prm_number',
                type: 'text',
                width: 316,
            },
            { type: 'divider' },
        );
        if (User.hasPermissionTo('change-museum-internal-data')) {
            fields.push(
                {
                    label: 'Konto w systemie SM',
                    name: 'data.sm_account',
                    type: 'boolean_select',
                    width: 205,
                },
                {
                    label: 'Udział w SM',
                    name: 'data.sm_contribution',
                    type: 'boolean_select',
                    width: 170,
                },
                {
                    label: 'Udział w ostatniej edycji SM',
                    name: 'data.sm_last_edition',
                    type: 'boolean_select',
                    width: 240,
                },
                { type: 'divider' },
            );
        }
        fields.push(
            {
                label: 'Uwagi',
                name: 'data.comments',
                type: 'textarea',
                width: 647,
            },
            { type: 'divider' },
            {
                label: 'Data aktualizacji rekordu',
                name: 'updated_at',
                type: 'text',
                disabled: true,
                width: 180,
            },
            {
                label: 'Zaktualizował(a)',
                name: 'updater.full_name',
                type: 'text',
                disabled: true,
                width: 375,
            },
            {
                label: 'ID',
                name: 'updated_by',
                type: 'text',
                disabled: true,
                width: 60,
            },
            { type: 'divider' },
        );

        const { deleteDialog, mergeDialog } = this.state;

        return <>
            <PaperRoot>
                <NCForm
                    fields={fields}
                    values={this.getValues()}
                    errors={this.getErrors()}
                    onChange={this.changeHandler}
                    hideSubmitButton
                    // saveHandler={this.saveObject}
                    style={{ marginTop: -16 }}
                />
            </PaperRoot>

            <ConfirmDialog open={deleteDialog} onClose={this.closeDeleteDialog} onConfirm={this.deleteObject('/museums')}>
                Czy na pewno chcesz usunąć tę instytucję i wszystkie jej oddziały?<br/>
                Tylko administrator będzie miał możliwość przywrócenia usuniętej instytucji.<br/>
                Po usunięciu instytucji dane z wypełnionych ankiet nie będą brane pod uwagę w raportach.
            </ConfirmDialog>

            {mergeDialog && (
                <MuseumsPicker changeHandler={this.mergeMuseum} types={['branched']} multiple={false}/>
            )}
        </>
    };

    renderCurrentTab = () => {
        const museum_id = this.getObjectId();

        let tab = '';
        let buttons = [];
        switch (this.state.currentTab) {
            case 'projects':
                tab = <MuseumTabProjects museum_id={museum_id}/>;
                break;
            case 'notes':
                tab = <MuseumTabNotes museum_id={museum_id} buttons={buttons}/>;
                break;
            case 'branches':
                tab = <MuseumTabBranches museum={this.getObject()} museum_id={museum_id} buttons={buttons} historyHandler={this.showBranchHistory} />;
                break;
            case 'users':
                tab = <MuseumTabUsers museum_id={museum_id} buttons={buttons}/>;
                break;
            case 'files':
                tab = <MuseumTabFiles museum_id={museum_id} buttons={buttons}/>;
                break;
            case 'history':
                tab = <MuseumTabHistory museum_id={museum_id} buttons={buttons}/>;
                break;
            case 'history_branch':
                tab = <MuseumTabHistoryBranch museum_id={this.state.branch_id} buttons={buttons}/>;
                break;
            case 'info':
            default:
                tab = this.renderInfoTab();
                if (User.hasPermissionTo('edit-museums') || (User.hasPermissionTo('museum-manager') && this.getObject('id') === User.getMuseumId())) {
                    buttons = <>
                        {this.hasObject() && User.hasPermissionTo('view-museum-history') && <>
                            <FabButton title="Historia zmian" onClick={() => this.changeTabHandler(null, 'history')} color="default"><HistoryIcon/></FabButton>
                        </>}
                        {this.hasObject() && User.hasPermissionTo('edit-museums') && <>
                            {this.renderDeleteButton()}
                            {this.getObject('type') === 'branchless' && (
                                <FabButton title="Przyłącz do muzeum oddziałowego" onClick={this.openMergeDialog} color="default"><CallMergeIcon/></FabButton>
                            )}
                        </>}
                        <FabButton title="Zapisz" onClick={this.saveObject} color="primary"><SaveIcon/></FabButton>
                    </>
                }
                break;
        }

        let backButton = '';
        if (User.hasPermissionTo('view-museums')) {
            switch (this.state.currentTab) {
                case 'history':
                    backButton = <FabButton title="Wróć" onClick={() => this.changeTabHandler(null, 'info')} color="default"><ArrowBackIcon/></FabButton>;
                    break;
                case 'history_branch':
                    backButton = <FabButton title="Wróć" onClick={() => this.changeTabHandler(null, 'branches')} color="default"><ArrowBackIcon/></FabButton>;
                    break;
                default:
                    backButton = <FabLink color="default" title="Wróć" to="/museums"><ArrowBackIcon/></FabLink>;
            }
        }

        return <>
            {tab}
            {(User.hasPermissionTo('view-museums') || User.hasPermissionTo('museum-manager')) && (
                <FabContainer>
                    {backButton}
                    {buttons}
                </FabContainer>
            )}
        </>
    };

    changeTabHandler = (event, value) => {
        this.setState({
            currentTab: value
        });
    };

    showBranchHistory = id => {
        this.setState({
            branch_id: id,
            currentTab: 'history_branch',
        });
    };

    render() {
        if (this.state.isLoading) {
            return this.showLoader();
        }
        const { values } = this.state;
        const hasMuseum = this.hasObject();

        return (
            <Paper>
                <Typography style={{ padding: '16px 0 12px 24px' }} variant="h5" component="h2">{this.getTitle()}</Typography>

                <Tabs
                    value={this.state.currentTab}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={this.changeTabHandler}
                >
                    <Tab value="info" label="Dane identyfikacyjne"/>
                    {User.hasPermissionTo('view-museum-projects') && (
                        <Tab value="projects" disabled={!hasMuseum} label="Projekty"/>
                    )}
                    {User.hasPermissionTo('view-notes') && (
                        <Tab value="notes" disabled={!hasMuseum} label="Adnotacje"/>
                    )}
                    {User.hasPermissionTo('view-museum-branches') && values.type === 'branched' && (
                        <Tab value="branches" disabled={!hasMuseum || this.getObject('type') !== 'branched'} label="Oddziały"/>
                    )}
                    {User.hasPermissionTo('view-museum-users') && (
                        <Tab value="users" disabled={!hasMuseum} label="Użytkownicy"/>
                    )}
                    {User.hasPermissionTo('view-museum-files') && (
                        <Tab value="files" disabled={!hasMuseum} label="Pliki"/>
                    )}
                    {User.hasPermissionTo('view-museum-history') && <>
                        <Tab value="history" disabled={!hasMuseum} label="Historia" style={{ display: 'none' }}/>
                        <Tab value="history_branch" disabled={!hasMuseum} label="Historia" style={{ display: 'none' }}/>
                    </>}
                </Tabs>
                {this.renderCurrentTab()}
            </Paper>
        );
    }
}

// export class MuseumEditWithoutStyles extends MuseumEdit {
//     // workaround
// }
// export default withStyles(styles)(MuseumEdit);

export default MuseumEdit;
