import React  from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router";
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import PostAddIcon from '@material-ui/icons/PostAdd';
import TableChartOutlinedIcon from '@material-ui/icons/TableChartOutlined';
import OfflinePinIcon from '@material-ui/icons/OfflinePin';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import VerticalAlignCenterIcon from '@material-ui/icons/VerticalAlignCenter';
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop';

import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import DataComponent from "../shared/DataComponent";
import DataTable from "../shared/DataTable";

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import PollQuestionPicker from "./PollQuestionPicker";
import UserPicker from "../users/UserPicker";

import Typography from "@material-ui/core/es/Typography/Typography";
import {
    removeItemsFromArrayByAttribute,
    getObjValuesFromArrayByAttribute,
    arrayRemove,
    arrayInsert,
    arrayNullifyAttributes, arrayMove, getItemIndexFromArrayByAttribute
} from "../shared/Helpers";
import NCForm from "../nc-form/NCForm";
import DeleteIcon from "@material-ui/icons/Delete";
import Question from "../../models/Question";
import PollSectionPicker from "./PollSectionPicker";
import FabButton from "../shared/FabButton";
import TooltipIconButton from "../shared/TooltipIconButton";
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import Tooltip from '@material-ui/core/Tooltip';
import TableQuestionPickerDialog from "../nc-table-creator/TableQuestionPickerDialog";
import QuestionPicker from "../questions/QuestionPicker";
import ConditionsPicker from "../questions/ConditionsPicker";
import ConfirmDialog from "../shared/ConfirmDialog";
import PollAggregateQuestionPicker from "./PollAggregateQuestionPicker";
import VisibilityIcon from "@material-ui/icons/Visibility";
import MuseumViewPollSection from "../museum-polls/MuseumViewPollSection";

const SortContainer = SortableContainer(props => {
    return <div>{props.children}</div>;
});

const SortElement = SortableElement(props => {
    return <Accordion {...props} />;
});

const SortHandle = SortableHandle(props => {
    return <TooltipIconButton title="Sortuj" style={{ cursor: 'ns-resize', maxHeight: 46, margin: '7px 0 0 -10px' }}>
        <DragIndicatorIcon/>
    </TooltipIconButton>
});

class PollSections extends DataComponent {
    tableColumns = {
        id: 'ID',
        name_string: 'Pytanie',
        is_active_string: { field: 'is_active', title: 'Aktywne?', lookup: { 0: 'Nie', 1: 'Tak' } },
        is_public_string: { field: 'is_public', title: 'Publiczne?', lookup: { 0: 'Nie', 1: 'Tak' } },
        type_string: { field: 'type', title: 'Typ', lookup: Question.types },
        'catalog.name': 'Katalog',
    };

    constructor(props){
        super(props);

        this.state.questionPicker = false;
        this.state.parentQuestionPicker = false;
        this.state.tableQuestionPicker = false;
        this.state.conditionsPicker = false;
        this.state.aggregatesPicker = false;
        this.state.sectionPicker = false;
        this.state.userPicker = false;
        this.state.confirmDialog = false;
        this.state.values = props.sections;
        this.state.preview = false;
    }

    // shouldComponentUpdate(nextProps, nextState, nextContent) {
    //     return this.shouldComponentUpdateHandler(nextProps, nextState);
    // }

    changeHandler = () => {
        this.props.changeHandler(this.props.valuesKey, this.getValues());
    };

    addSection = sectionKey => event => {
        event.stopPropagation();

        const newSection = {
            name: '',
            description: '',
            poll_questions: [],
            owners: [],
        };
        let values = this.getValues();
        if (sectionKey !== undefined) {
            arrayInsert(values, sectionKey, newSection)
        } else {
            values.push(newSection);
        }

        this.setState({ values }, this.changeHandler);
    };

    importSections = sectionKey => sections => {
        const selectedQuestionIds = getObjValuesFromArrayByAttribute(this.getQuestionsFromAllSections());
        let values = this.getValues();
        sections = arrayNullifyAttributes(sections, ['id', 'poll_id']);
        sections.forEach((section) => {
            section.poll_questions = removeItemsFromArrayByAttribute(section.poll_questions, selectedQuestionIds);
        });

        if (sectionKey !== undefined) {
            values = arrayInsert(values, sectionKey, sections)
        } else {
            values = values.concat(sections);
        }

        // TODO: maybe some warning if question was filtered out?

        this.setState({ values: values, sectionPicker: false }, this.changeHandler);
    };

    addQuestions = (sectionKey) => questions => {
        let values = this.getValues();

        values[sectionKey]['poll_questions'] = values[sectionKey]['poll_questions'].concat(questions);

        this.setState({ values: values, questionPicker: false }, this.changeHandler);
    };

    addOwners = (sectionKey) => (owners) => {
        let values = this.getValues();

        let properOwnerFormat = owners.map((owner) => {
            return {user_id: owner.id, user: owner};
        });

        values[sectionKey]['owners'] = values[sectionKey]['owners'].concat(properOwnerFormat);

        this.setState({ values: values, userPicker: false }, this.changeHandler);
    };

    getQuestionsFromAllSections = (type) => {
        let questions = [];
        this.getValues().forEach((section, sectionKey) => {
            if (!!type) {
                section.poll_questions.forEach((question) => {
                    if (question.type === type) {
                        questions.push(question);
                    }
                });
            } else {
                questions = questions.concat(section.poll_questions);
            }
        });

        return questions;
    };

    removeSection = (sectionKey) => event => {
        event.stopPropagation();
        this.setState({
            confirmDialog: {
                text: 'Na pewno usunąć wybrany dział?',
                onConfirm: () => {
                    let values = this.getValues();
                    arrayRemove(values, sectionKey);
                    this.setState({ confirmDialog: false, values }, this.changeHandler);
                }
            }
        });
    };

    change = (sectionKey, field) => event => {
        let values = this.getValues();
        values[sectionKey][field] = event.target.value;

        this.setState({ values }, this.changeHandler);
    };

    changeValue = (sectionKey) => (field, value) => {
        let values = this.getValues();
        values[sectionKey][field] = value;

        this.setState({ values }, this.changeHandler);
    };

    showQuestionPicker = (sectionKey) => event => {
        this.setState({ questionPicker: sectionKey });
    };

    showParentQuestionPicker = (sectionKey, question) => event => {
        this.setState({ parentQuestionPicker: { sectionKey, question } });
    };

    showTableQuestionPicker = (sectionKey, question) => event => {
        this.setState({ tableQuestionPicker: { sectionKey, question } });
    };

    showConditionsPicker = (sectionKey, question) => event => {
        this.setState({ conditionsPicker: { sectionKey, question } });
    };

    showAggregatesPicker = (sectionKey, question, type) => event => {
        this.setState({ aggregatesPicker: { sectionKey, question, type } });
    };

    showPreview = sectionKey => event => {
        event.stopPropagation();
        const section = this.state.values[sectionKey];
        let answersData = {};
        let number = 1;
        section.poll_questions.forEach((question, key) => {
            answersData[question.id] = {
                number: number++,
                isVisible: true,
            };
            // section.poll_questions[key].answer = null;
        });
        // console.log('section preview:', section);
        // console.log('answersData:', answersData);
        this.setState({
            preview: {
                name: section.name,
                description: section.description,
                poll_questions: section.poll_questions,
                owners: section.owners,
                comments: [],
                state: '',
                answersData,
            },
        });
    }

    removeParentQuestion = (sectionKey, question) => event => {
        this.setState({
            confirmDialog: {
                text: 'Na pewno usunąć dane dziedziczenia?',
                onConfirm: () => {
                    let values = this.getValues();
                    const questionKey = getItemIndexFromArrayByAttribute(values[sectionKey]['poll_questions'], question.question_id);
                    question['parent_question'] = null;
                    values[sectionKey]['poll_questions'][questionKey] = question;
                    this.setState({ confirmDialog: false, values }, this.changeHandler);
                }
            }
        });
    };

    setParentQuestion = (parentQuestion, cell) => {
        let { sectionKey, question } = this.state.parentQuestionPicker;
        if (parentQuestion) {
            question['parent_question'] = { id: parentQuestion.id, question: parentQuestion, cell };

            let values = this.getValues();
            const questionKey = getItemIndexFromArrayByAttribute(values[sectionKey]['poll_questions'], question.question_id);

            values[sectionKey]['poll_questions'][questionKey] = question;
            this.setState({ values, parentQuestionPicker: false }, this.changeHandler);
        } else {
            this.setState({ parentQuestionPicker: false });
        }
    };

    setParentTableCells = (cells) => {
        let { sectionKey, question } = this.state.tableQuestionPicker;
        if (cells) {
            question['parent_question'] = cells;

            let values = this.getValues();
            const questionKey = getItemIndexFromArrayByAttribute(values[sectionKey]['poll_questions'], question.question_id);

            values[sectionKey]['poll_questions'][questionKey] = question;
            this.setState({ values, tableQuestionPicker: false }, this.changeHandler);
        } else {
            this.setState({ tableQuestionPicker: false });
        }
    };

    setQuestionConditions = (conditions) => {
        let { sectionKey, question } = this.state.conditionsPicker;
        if (conditions) {
            question['conditions'] = conditions.length ? conditions : null;

            let values = this.getValues();
            const questionKey = getItemIndexFromArrayByAttribute(values[sectionKey]['poll_questions'], question.question_id);

            values[sectionKey]['poll_questions'][questionKey] = question;
            this.setState({ values, conditionsPicker: false }, this.changeHandler);
        } else {
            this.setState({ conditionsPicker: false });
        }
    };

    setQuestionAggregates = (aggregates) => {
        let { sectionKey, question, type } = this.state.aggregatesPicker;
        if (aggregates) {
            aggregates = aggregates.map(v => v.id);
            if (!question['aggregates']) {
                question['aggregates'] = {};
            }
            if (aggregates.length) {
                question['aggregates'][type] = aggregates;
            } else if (question['aggregates'][type] !== undefined) {
                delete question['aggregates'][type]; // cleanup
            }

            let values = this.getValues();
            const questionKey = getItemIndexFromArrayByAttribute(values[sectionKey]['poll_questions'], question.question_id);

            values[sectionKey]['poll_questions'][questionKey] = question;
            this.setState({ values, aggregatesPicker: false }, this.changeHandler);
        } else {
            this.setState({ aggregatesPicker: false });
        }
    };

    showSectionPicker = (sectionKey) => event => {
        this.setState({ sectionPicker: sectionKey });
    };

    showUserPicker = (sectionKey) => (event) => {
        event.stopPropagation();

        this.setState({ userPicker: sectionKey });
    };

    removeOwner = (sectionKey, ownerIdx) => event => {
        event.stopPropagation();

        let values = this.getValues();
        arrayRemove(values[sectionKey]['owners'], ownerIdx);

        this.setState({ values }, this.changeHandler);
    };

    onSortEndTable = (sectionKey) => ({ newIndex, oldIndex, collection, isKeySorting }, event) => {
        let values = this.getValues();
        values[sectionKey]['poll_questions'] = arrayMove(values[sectionKey]['poll_questions'], oldIndex, newIndex);

        this.setState({ values }, this.changeHandler);
    };

    onSortEndSections = ({ newIndex, oldIndex, collection, isKeySorting }, event) => {
        this.setState({ values: arrayMove(this.getValues(), oldIndex, newIndex) }, this.changeHandler);
    };

    renderRowParentQuestionActions = (sectionKey, rowData) => {
        const { classes } = this.props;

        if (['file', 'dynamic_table', 'label'].includes(rowData.type)) {
            return '';
        }
        if (rowData.parent_question) {
            if (rowData.type === 'static_table') {
                return <Tooltip title="Sprawdź dziedziczenie w komórkach tabeli">
                    <Chip
                        label={<TableChartOutlinedIcon color="secondary"/>}
                        onDelete={this.removeParentQuestion(sectionKey, rowData)}
                        onClick={this.showTableQuestionPicker(sectionKey, rowData)}
                        size="small"
                    />
                </Tooltip>;
            } else {
                const { question, cell } = rowData.parent_question;
                return <Tooltip title={question.name + (cell ? ' [komórka: ' + cell.name + ']' : '')}>
                    <Chip
                        label={<>
                            <Typography noWrap={true} className={classes.chipText}>{question.name}</Typography>
                            {cell ? <Typography className={classes.chipText}>&nbsp;[{cell.name}]</Typography> : ''}
                        </>}
                        onDelete={this.removeParentQuestion(sectionKey, rowData)}
                        size="small"
                    />
                </Tooltip>;
            }
        } else {
            if (rowData.type === 'static_table') {
                return <TooltipIconButton title="Dodaj dziedziczenie do komórek tabeli"
                                      size="small"
                                      onClick={this.showTableQuestionPicker(sectionKey, rowData)}>
                    <TableChartOutlinedIcon/>
                </TooltipIconButton>
            } else {
                return <TooltipIconButton title="Wybierz pytanie z którego dziedziczyć odpowiedź"
                                      size="small"
                                      onClick={this.showParentQuestionPicker(sectionKey, rowData)}>
                    <PostAddIcon/>
                </TooltipIconButton>
            }
        }
    };

    renderRowAggregatesActions = (sectionKey, rowData) => {
        if (rowData.type !== 'number') {
            return '';
        }
        const aggregates = rowData.aggregates || {};

        const ltColor = !aggregates['lt'] ? null : { color: 'secondary' };
        const eqColor = !aggregates['eq'] ? null : { color: 'secondary' };
        const gtColor = !aggregates['gt'] ? null : { color: 'secondary' };

        return <>
            <TooltipIconButton title="Suma wartości z pytań mniejsza niż"
                               size="small"
                               onClick={this.showAggregatesPicker(sectionKey, rowData, 'lt')}>
                <VerticalAlignBottomIcon {...ltColor} />
            </TooltipIconButton>
            <TooltipIconButton title="Suma wartości z pytań równa"
                               size="small"
                               onClick={this.showAggregatesPicker(sectionKey, rowData, 'eq')}>
                <VerticalAlignCenterIcon {...eqColor} />
            </TooltipIconButton>
            <TooltipIconButton title="Suma wartości z pytań większa niż"
                               size="small"
                               onClick={this.showAggregatesPicker(sectionKey, rowData, 'gt')}>
                <VerticalAlignTopIcon {...gtColor} />
            </TooltipIconButton>
        </>
    };

    render(){
        const { classes, errors, lockQuestions } = this.props;
        const {
            questionPicker,
            parentQuestionPicker,
            tableQuestionPicker,
            conditionsPicker,
            aggregatesPicker,
            sectionPicker,
            userPicker,
            confirmDialog,
            preview,
        } = this.state;

        return <SortContainer
            ref={el => this.sortContainerRef = el}
            useDragHandle
            lockAxis="y"
            onSortEnd={this.onSortEndSections}
            helperContainer={() => this.sortContainerRef.container}
        >
            {this.getValues().length > 0 && (<>
                <Button disabled={lockQuestions} variant="contained" color="primary" className={classes.addButtonBig} onClick={this.addSection(0)}>
                    <AddIcon/> Dodaj dział
                </Button>
                <Button disabled={lockQuestions} variant="contained" color="primary" className={classes.addButtonBig} onClick={this.showSectionPicker(0)}>
                    <AddIcon/> Importuj dział
                </Button>
            </>)}
            {this.getValues().map((section, sectionKey) => {
                const error = errors[sectionKey] || {};

                return <SortElement defaultExpanded={true} key={sectionKey} index={sectionKey} className={classes.expansionPanel}>
                    <AccordionSummary className={classes.expansionPanelSummary} expandIcon={<ExpandMoreIcon/>}>
                        <SortHandle/>
                        <FabButton title="Usuń dział" onClick={this.removeSection(sectionKey)} color="secondary" className={classes.sectionButton}>
                            <RemoveIcon className={classes.smallIcon}/>
                        </FabButton>
                        <FabButton title="Podgląd" onClick={this.showPreview(sectionKey)} className={classes.sectionButton}>
                            <VisibilityIcon/>
                        </FabButton>
                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="baseline"
                        >
                            <Grid item style={{ flex: 'auto', paddingRight: 20 }}>
                                <TextField
                                    name="section-name"
                                    key={section.id}
                                    label="Nazwa działu"
                                    className={classes.textField}
                                    onBlur={this.change(sectionKey, 'name')}
                                    onClick={(event) => event.stopPropagation()}
                                    defaultValue={section.name}
                                    margin="normal"
                                    error={!!error['name']}
                                    helperText={error['name'] ? error['name'] : ''}
                                />
                            </Grid>
                            <Grid item>
                                <div className={classes.ownerEntry}>
                                    <FabButton
                                        title="Dodaj właścicieli"
                                        onClick={this.showUserPicker(sectionKey)}
                                        color="primary"
                                        className={classes.ownersButton}>
                                        <AddIcon className={classes.smallIcon}/>
                                    </FabButton>
                                    <Typography className={classes.ownerEntryText}>Właściciele biznesowi:</Typography>
                                </div>
                                {section.owners.map((owner, idx) => {
                                    return <div key={idx} className={classes.ownerEntry}>
                                        <FabButton
                                            title="Usuń właściciela"
                                            onClick={this.removeOwner(sectionKey, idx)}
                                            color="secondary"
                                            className={classes.ownersButton}
                                        >
                                            <RemoveIcon className={classes.smallIcon}/>
                                        </FabButton>
                                        <Typography className={classes.ownerEntryText}>{owner.user.first_name+' '+owner.user.last_name}</Typography>
                                    </div>
                                })}

                                {!!error['owners'] && <Typography className={classes.ownerEntryText} color="secondary">Pole jest wymagane</Typography>}
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.expansionPanelWrapper} style={{ flexBasis: '100%' }}>
                            <div style={{ width: '100%', marginBottom: 10 }}>
                                <NCForm
                                    fields={[{
                                        name: 'description',
                                        type: 'tinyLessBasic',
                                        label: 'Opis',
                                        width: '100%',
                                    }]}
                                    values={{ description: section.description === null ? '' : section.description }}
                                    errors={{ description: error['description'] }}
                                    onChange={this.changeValue(sectionKey)}
                                    hideSubmitButton
                                />
                            </div>
                            <DataTable
                                sortable
                                useDragHandle={false}
                                onSortEnd={this.onSortEndTable(sectionKey)}
                                storageKey={this.constructor.name}
                                title={"Wybrane pytania"}
                                columns={[].concat(this.getTableColumns(), [{
                                    field: 'function', title: 'Akcje', filtering: false, callback: rowData => {
                                        const conditionsColor = !rowData.conditions ? null : { color: 'secondary' };
                                        return <>
                                            <TooltipIconButton title={(!rowData.conditions ? 'Dodaj' : 'Zobacz') + ' warunki widoczności'}
                                                           size="small"
                                                           onClick={this.showConditionsPicker(sectionKey, rowData)}>
                                                <OfflinePinIcon {...conditionsColor}/>
                                            </TooltipIconButton>
                                            {this.renderRowParentQuestionActions(sectionKey, rowData)}
                                            {this.renderRowAggregatesActions(sectionKey, rowData)}
                                        </>;
                                    }
                                },
                                ])}
                                data={section.poll_questions}
                                options={{
                                    selection: !lockQuestions,
                                    sorting: false,
                                }}
                                actions={lockQuestions ? [] : [
                                    {
                                        tooltip: 'Dodaj pytania',
                                        icon: () => <AddCircleIcon className={classes.addButton} />,
                                        isFreeAction: true,
                                        onClick: this.showQuestionPicker(sectionKey),
                                    },
                                    {
                                        tooltip: 'Wyrzuć zaznaczone',
                                        icon: DeleteIcon,
                                        onClick: (event, rows) => {
                                            let values = this.getValues();
                                            values[sectionKey]['poll_questions'] = removeItemsFromArrayByAttribute(section.poll_questions, getObjValuesFromArrayByAttribute(rows));
                                            this.setState({ values }, this.changeHandler);
                                        },
                                    },
                                ]}
                            />
                        </div>
                    </AccordionDetails>
                </SortElement>
            })}

            <Button id="poll-section-add" disabled={lockQuestions} variant="contained" color="primary" className={classes.addButtonBig} onClick={this.addSection()}>
                <AddIcon/> Dodaj dział
            </Button>
            <Button disabled={lockQuestions} variant="contained" color="primary" className={classes.addButtonBig} onClick={this.showSectionPicker(this.getValues().length)}>
                <AddIcon/> Importuj dział
            </Button>

            {questionPicker !== false && (
                <PollQuestionPicker
                    sectionName={this.getValues(questionPicker).name}
                    questions={this.getQuestionsFromAllSections()}
                    changeHandler={this.addQuestions(questionPicker)}
                />
            )}
            {parentQuestionPicker !== false && (
                <QuestionPicker
                    url={'/catalogs/questions/parents/' + parentQuestionPicker.question.id}
                    question={parentQuestionPicker.question}
                    changeHandler={this.setParentQuestion}
                />
            )}

            {tableQuestionPicker !== false && (
                <TableQuestionPickerDialog
                    question={tableQuestionPicker.question}
                    table={tableQuestionPicker.question.properties.table}
                    changeHandler={this.setParentTableCells}
                />
            )}

            {conditionsPicker !== false && (
                <ConditionsPicker
                    conditions={conditionsPicker.question.conditions}
                    questions={removeItemsFromArrayByAttribute(this.getQuestionsFromAllSections(), conditionsPicker.question.id)}
                    changeHandler={this.setQuestionConditions}
                />
            )}

            {aggregatesPicker !== false && (
                <PollAggregateQuestionPicker
                    aggregates={aggregatesPicker.question.aggregates}
                    type={aggregatesPicker.type}
                    questions={removeItemsFromArrayByAttribute(this.getQuestionsFromAllSections('number'), aggregatesPicker.question.id)}
                    changeHandler={this.setQuestionAggregates}
                />
            )}

            {sectionPicker !== false && (
                <PollSectionPicker
                    sections={this.getValues()}
                    changeHandler={this.importSections(sectionPicker)}
                />
            )}

            {userPicker !== false && (
                <UserPicker
                    url="/projects/polls/users"
                    users={this.getValues()[userPicker]['owners'].map((o) => ({id: o.user_id}))}
                    changeHandler={this.addOwners(userPicker)}
                />
            )}

            {!!preview && (
                <ConfirmDialog
                    title="Podgląd"
                    open={true}
                    isContentText={false}
                    confirmTitle=""
                    cancelTitle=""
                    dialogProps={{ maxWidth: 'lg', fullWidth: true }}
                    onClose={() => this.setState({ preview: false })}>
                    <MuseumViewPollSection
                        key={'' + -new Date()}
                        section={preview}
                        answersData={preview.answersData}
                        showActions={false}
                        showComments={false}
                        questionsCounts={preview.poll_questions.length}
                    />
                </ConfirmDialog>
            )}

            {!!confirmDialog && (
                <ConfirmDialog open={true}
                               cancelTitle="Nie"
                               confirmTitle="Tak"
                               onConfirm={confirmDialog.onConfirm}
                               onClose={() => this.setState({ confirmDialog: false })}>
                    {confirmDialog.text}
                </ConfirmDialog>
            )}
        </SortContainer>
    }
}

PollSections.propTypes = {
    valuesKey: PropTypes.string,
    sections: PropTypes.array,
    changeHandler: PropTypes.func.isRequired,
    errors: PropTypes.array,
    lockQuestions: PropTypes.bool,
};
PollSections.defaultProps = {
  valuesKey: 'sections',
  sections: [],
  errors: [],
  lockQuestions: false,
};

const styles = theme => ({
    expansionPanelWrapper: {
        '& tbody .MuiTableRow-root': {
            cursor: 'ns-resize',
            userSelect: 'none',
        },
    },
    addButtonBig: {
        marginRight: theme.spacing(2),
    },
    addButton: {
        color: theme.palette.primary.main,
    },
    listItem: {
        paddingTop: 0,
        paddingBottom: 0,
        marginBottom: 0,
    },
    textField: {
        width: '100%',
        marginTop: 0,
    },
    smallButton: {
        width: 20,
        height: 20,
        minWidth: 0,
        minHeight: 0,
        marginRight: 0,
    },
    sectionButton: {
        width: 22,
        height: 20,
        minWidth: 0,
        minHeight: 0,
        marginTop: 20,
        marginRight: 20,
    },
    ownersButton: {
        width: 22,
        height: 20,
        minWidth: 0,
        minHeight: 0,
        marginRight: 20,
        verticalAlign: 'top'
    },
    questionButton: {
        width: 22,
        height: 20,
        minWidth: 0,
        minHeight: 0,
        marginRight: 10,
        '&:last-child': {
            marginRight: 0,
        }
    },
    ownerEntry : {
        marginTop: 8,
        width: 250
    },
    ownerEntryText : {
        display: 'inline-block',
        width: 200
    },
    smallIcon: {
        width: 20,
        height: 20,
    },
    smallerIcon: {
        width: 13,
        height: 13,
    },
    badge: {
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(),
    },
    expansionPanel: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    expansionPanelSummary: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        '& .MuiExpansionPanelSummary-content.Mui-expanded': {
            marginTop: 12,
            marginBottom: 12,
        },
    },
    selectOwner: {
        width: 200,
        marginRight: theme.spacing(2),
    },
    chipText: {
        maxWidth: 200,
        fontSize: 'inherit'
    },
});

export default withStyles(styles)(withRouter(PollSections));
