import React from 'react';
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/core/styles';
import NCForm from '../nc-form/NCForm';

import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import EditIcon from '@material-ui/icons/Edit';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';

import { DialogTitle, DialogContent } from "../shared/Dialog";
import DataComponent from "../shared/DataComponent";
import DataTable from "../shared/DataTable";
import Axios from 'axios';

import { FormHelperText, IconButton } from "@material-ui/core";
import User from "../auth/User";
import { clone, dotSet } from "../shared/Helpers";
import ConfirmDialog from "../shared/ConfirmDialog";
import FabButton from "../shared/FabButton";
import UserPicker from "../users/UserPicker";
import InfoIcon from "@material-ui/icons/Info";

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    button: {
        marginRight: 20,
    },
});

class MuseumTabUsers extends DataComponent {
    museum_id = this.props.museum_id;
    url = '/museums/' + this.museum_id + '/users';
    tableColumns = {
        id: 'ID',
        email: 'Login',
        full_name: 'Imię i nazwisko',
        phone: 'Nr telefonu',
        'role.title': 'Rola',
        branch_name: 'Oddział',
        last_logged_at: "Data ostatniego logowania",
    };
    actions = [];

    constructor(props) {
        super(props);

        this.state.addDialog = false;
        this.state.addExistingDialog = false;
        this.state.userPicker = false;
        this.state.editUserId = false;
        this.state.deleteUserId = false;

        this.state.rolesOptions = [];
        this.state.usersOptions = [];

        if (User.hasPermissionTo('edit-users')) {
            props.buttons.push(<FabButton key="addUser"
                                          onClick={this.openAddExistingDialog}
                                          color="primary"
                                          title="Dodaj istniejącego użytkownika"
                                          className={props.classes.button}>
                <PersonAddIcon/>
            </FabButton>);
        }
        if (User.hasAnyPermission('edit-museum-users', 'edit-museum-admins')) {
            props.buttons.push(<FabButton key="addNewUser" onClick={this.openAddDialog} color="primary" title="Dodaj użytkownika" className={props.classes.button}>
                <AddIcon/>
            </FabButton>);
        }

        if (User.hasAnyPermission('edit-museum-users', 'edit-museum-admins', 'edit-museum-users-self')) {
            this.actions.push(rowData => {
                if (rowData.id === User.getUserId() && User.hasPermissionTo('edit-museum-users-self')) {
                    // can edit himself
                } else {
                    switch (rowData.role_name) {
                        case 'museum-admin':
                        case 'branch-admin':
                            if (!User.hasPermissionTo('edit-museum-admins')) {
                                return '';
                            }
                        // case 'user':
                    }
                }
                return {
                    tooltip: 'Edytuj',
                    icon: EditIcon,
                    onClick: this.openEditDialog,
                };
            });
        }
    }

    processData = (data) => {
        const { roles, branches } = data;
        let rolesOptions = [];
        if (roles) {
            roles.forEach((role, roleKey) => {
                rolesOptions.push({
                    value: role.id,
                    text: role.title,
                });
            });
        }

        let branchesOptions = [];
        if (branches) {
            branches.forEach((branch, branchKey) => {
                branchesOptions.push({
                    value: branch.id,
                    text: branch.name,
                });
            });
        }

        return { data, rolesOptions, branchesOptions };
    };

    saveObject = () => {
        const { editUserId, values } = this.state;
        if (editUserId) {
            Axios.put(this.url + '/' + editUserId, values).then(response => {
                this.setState({
                    editUserId: false,
                    data: response.data,
                    values: {},
                    errors: {},
                });
            }).then(this.reloadTable).catch(this.catchErrors);
        } else {
            if (!values.password) {
                // will be generated
                delete values.password;
                delete values.password_confirmation;
            }
            Axios.post(this.url, values).then(response => {
                this.setState({
                    addDialog: false,
                    data: response.data,
                    values: {},
                    errors: {},
                });
            }).then(this.reloadTable).catch(this.catchErrors);
        }
    };

    deleteObject = () => {
        Axios.delete(this.url, { data: { user_id: this.state.deleteUserId } }).then(response => {
            this.setStateData(response.data, {
                isLoading: false,
                editUserId: false,
                deleteUserId: false,
            });
        }).then(this.reloadTable).catch(this.catchErrors);
    };

    openAddDialog = event => {
        this.setState({
            addDialog: true,
            dialogTitle: 'Dodaj użytkownika',
            passwordLabel: 'Hasło',
            saveLabel: 'Dodaj',
        });
    };

    openAddExistingDialog = event => {
        this.setState({
            addExistingDialog: true,
            dialogTitle: 'Dodaj istniejącego użytkownika',
            saveLabel: 'Dodaj',
        });
    };

    openEditDialog = (event, data) => {
        this.setState({
            editUserId: data.id,
            values: clone(data),
            dialogTitle: 'Edycja użytkownika',
            passwordLabel: 'Nowe hasło',
            saveLabel: 'Zapisz',
        });
    };

    openDeleteDialog = (user_id) => event => {
        this.setState({ deleteUserId: user_id });
    };

    closeDeleteDialog = () => {
        this.setState({ deleteUserId: false });
    };

    closeDialogs = () => {
        this.setState({ deleteUserId: false, editUserId: false, addDialog: false, addExistingDialog: false, usersOptions: [], values: {}, errors: {} });
    };

    addUser = () => {
        const { user_id, role_id } = this.state.values;
        this.setState({ addExistingDialog: false, usersOptions: [] }, () => {
            Axios.post(this.url + '/users', { ids: [user_id], role_id }).then(this.reloadTable).catch(this.catchErrors);
        });
    };

    addUserOption = (user) => {
        let { usersOptions, values } = this.state;
        if (user) {
            usersOptions.push({
                value: user.id,
                text: user.full_name,
            });
            values.user_id = user.id;
        }
        this.setState({ usersOptions, userPicker: false });
    };

    render() {
        if (this.state.isLoading) {
            return this.showLoader();
        }
        const { values, usersOptions, rolesOptions, branchesOptions, userPicker, editUserId } = this.state;

        const fields = [
            {
                name: 'email',
                type: 'text',
                label: 'Login (adres email)',
                width: 516,
            },
            { type: 'divider' },
            {
                name: 'first_name',
                type: 'text',
                label: 'Imię',
                width: 250,
            },
            {
                name: 'last_name',
                type: 'text',
                label: 'Nazwisko',
                width: 250,
            },
            { type: 'divider' },
            {
                name: 'phone',
                type: 'text',
                label: 'Nr telefonu',
                width: 250,
                inputProps: { autoComplete: 'off' },
            },
            {
                type: 'custom',
                component: editUserId ? '' : <>
                    <InfoIcon color="primary" style={{margin: '20px 6px 0 0'}} /><FormHelperText style={{ marginTop: 14, maxWidth: 220, textAlign: 'justify' }}>
                    Dodając nowego użytkownika nie trzeba wpisywać hasła. Zostanie ono wygenerowane automagicznie i wysłane do niego mailem.
                </FormHelperText></>,
            },
            { type: 'divider' },
            {
                name: 'password',
                type: 'password',
                label: this.state.passwordLabel,
                width: 250,
            },
            {
                name: 'password_confirmation',
                type: 'password',
                label: 'Potwierdź hasło',
                width: 250,
            },
        ];

        if (User.hasAnyPermission('edit-museum-users', 'edit-museum-admins')) {
            if (!User.hasPermissionTo('edit-museum-admins') && User.getUserId() === this.state.editUserId) {
                // don't add role and branches
            } else {
                fields.push(
                    { type: 'divider' },
                    {
                        name: 'role_id',
                        type: 'select',
                        label: 'Rola',
                        options: rolesOptions,
                        width: 250,
                    },
                    {
                        name: 'museum_id',
                        type: 'select',
                        emptyValue: '- brak -',
                        label: 'Oddział',
                        options: branchesOptions,
                        width: 250,
                    },
                );
            }
        }

        return (<>
            <DataTable
                tableRef={this.tableRef}
                storageKey={this.constructor.name}
                title={"Lista użytkowników"}
                columns={this.getTableColumns()}
                data={this.loadTableData(this.url)}
                actions={this.actions}
            />

            <Dialog open={!!(this.state.addDialog || this.state.editUserId)} onClose={this.closeDialogs}>
                <DialogTitle onClose={this.closeDialogs}>{this.state.dialogTitle}</DialogTitle>
                <DialogContent>
                    <NCForm
                        fields={fields}
                        values={values}
                        errors={this.getErrors()}
                        onChange={this.changeHandler}
                        hideSubmitButton
                    />
                    <DialogActions>
                        {values.id && User.hasAnyPermission('edit-museum-users', 'edit-museum-admins') && (
                            <IconButton color="secondary" onClick={this.openDeleteDialog(this.state.editUserId)}>
                                <DeleteIcon/>
                            </IconButton>
                        )}
                        <Button variant="outlined" onClick={this.closeDialogs} color="default">Anuluj</Button>
                        <Button variant="contained" onClick={this.saveObject} color="primary">{this.state.saveLabel}</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>

            {!!User.hasAnyPermission('edit-museum-users', 'edit-museum-admins') && (
                <Dialog open={!!this.state.addExistingDialog} onClose={this.closeDialogs}>
                    <DialogTitle onClose={this.closeDialogs}>{this.state.dialogTitle}</DialogTitle>
                    <DialogContent>
                        <NCForm
                            fields={[
                                {
                                    name: 'user_id',
                                    type: 'select',
                                    label: 'Użytkownik',
                                    emptyValue: '- wyszukaj -',
                                    options: usersOptions,
                                    width: 250,
                                    open: false,
                                    onOpen: () => this.setState({ userPicker: true }),
                                },
                                { type: 'divider' },
                                {
                                    name: 'role_id',
                                    type: 'select',
                                    label: 'Rola',
                                    options: rolesOptions,
                                    width: 250,
                                },
                            ]}
                            values={values}
                            errors={this.getErrors()}
                            onChange={(name, value) => {
                                if (name === 'user_id' && !value) {
                                    this.setState({ userPicker: true });
                                }
                                this.setState({ values: dotSet(values, name, value) });
                            }}
                            hideSubmitButton
                        />
                        {userPicker && (
                            <UserPicker
                                url={this.url + '/users'}
                                users={this.getValues('users')}
                                changeHandler={this.addUserOption}
                                multiple={false}
                                tableTitle="Użytkownicy bez muzeum"
                            />
                        )}
                        <DialogActions>
                            <Button variant="outlined" onClick={this.closeDialogs} color="default">Anuluj</Button>
                            <Button variant="contained" onClick={this.addUser} color="primary">{this.state.saveLabel}</Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
            )}

            <ConfirmDialog open={this.state.deleteUserId} onClose={this.closeDeleteDialog} onConfirm={this.deleteObject}>
                Czy na pewno chcesz usunąć tego użytkownika?<br/>
                Tylko administrator będzie miał możliwość go przywrócić.
            </ConfirmDialog>
        </>);
    }
}

MuseumTabUsers.propTypes = {
    addNew: PropTypes.bool,
    buttons: PropTypes.array,
    museum_id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]).isRequired,
};
MuseumTabUsers.defaultProps = {
    addNew: false,
    buttons: [],
};

export default withStyles(styles)(MuseumTabUsers);
