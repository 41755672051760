import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import NCForm from '../nc-form/NCForm';
import DataComponent from "../shared/DataComponent";
import Axios from 'axios';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Grid from '@material-ui/core/Grid';

import PollNotifications from "./PollNotifications";
import PollUnregisteredMuseums from "./PollUnregisteredMuseums";
import PollMuseums from "./PollMuseums";
import PollSections from "./PollSections";
import FabContainer from "../shared/FabContainer";
import PaperRoot from "../shared/PaperRoot";
import { getSelectOptions } from "../shared/Helpers";
import Poll from "../../models/Poll";
import ConfirmDialog from "../shared/ConfirmDialog";
import FabButton, { FabLink } from "../shared/FabButton";

class PollEdit extends DataComponent {
    static role = ['superadmin'];
    project_id = this.props.match.params.project_id;
    poll_id = this.props.match.params.poll_id;
    url = '/projects/polls/parent/' + this.project_id;

    constructor(props) {
        super(props);

        // different url for editing
        if (this.poll_id) {
            this.url = '/projects/polls/' + this.poll_id;
        }

        this.state.parent = {};
        this.state.values = {
            notifications: [],
            unregisteredMuseums: [],
            museums: [],
            sections: [],
        };
    }

    processData = (data) => {
        let output = { data };
        if (data.parent) {
            output.parent = data.parent;
        }
        return output;
    };

    // processData = (data) => {
    //     let output = { data };
    //
    //     if (data.notifications) {
    //         output.notifications = data.notifications;
    //     }
    //     if (data.museums) {
    //         output.museums = data.museums;
    //     }
    //     if (data.sections) {
    //         output.sections = data.sections;
    //     }
    //
    //     return output;
    // };

    saveObject = () => {
        if (this.hasObject()) {
            Axios.put(this.url, this.getValues()).then(response => {
                // this.reloadStateData(response.data, { errors: {} });
                this.props.history.push('/projects/' + this.project_id + '/polls');
            }).catch(this.catchErrors);
        } else {
            Axios.post('/projects/polls/' + this.project_id, this.getValues()).then(response => {
                // this.props.history.push('/projects/' + this.project_id + '/polls/' + response.data.object.id + '/edit');
                this.props.history.push('/projects/' + this.project_id + '/polls');
            }).catch(this.catchErrors);
        }
    };

    getTitle = () => {
        return (this.hasObject() ? 'Edycja ankiety "' + this.getObject('name') + '" z projektu: ' : "Dodaj ankietę do projektu: ") + this.state.parent.name;
    };

    render() {
        if (this.state.isLoading) {
            return this.showLoader();
        }
        const { classes } = this.props;
        const museums = this.getValues('museums') || [];

        const fields = [
            {
                name: 'name',
                type: 'text',
                label: 'Nazwa',
                width: 400,
            },
            {
                name: 'type',
                type: 'select',
                label: 'Rodzaj',
                width: 400,
                options: getSelectOptions(Poll.types),
                disabled: museums.length,
            },
            { type: 'divider' },
            {
                name: 'year',
                type: 'year',
                label: 'Ankieta za okres',
                width: 193,
            },
            {
                name: 'starts_at',
                type: 'datetime',
                label: 'Data rozpoczęcia',
                width: 193,
            },
            {
                name: 'ends_at',
                type: 'datetime',
                label: 'Data zakończenia',
                width: 183,
            },
            {
                name: 'deadline',
                type: 'datetime',
                label: 'Data zablokowania',
                width: 203,
            },
            { type: 'divider' },
            {
                name: 'description',
                type: 'tinyLessBasic',
                label: 'Opis',
                width: 816,
            },
        ];

        return <>
            <PaperRoot>
                <NCForm
                    title={this.getTitle()}
                    fields={fields}
                    values={this.getValues()}
                    errors={this.getErrors()}
                    onChange={this.changeHandler}
                    hideSubmitButton
                />

                <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={10}>
                    <Grid item className={classes.gridItem}>
                        <PollNotifications
                            notifications={this.getValues('notifications') || []}
                            changeHandler={this.changeHandler}
                            errors={this.getErrors('notifications')}
                        />
                    </Grid>
                    <Grid item className={classes.gridItem}>
                        <PollUnregisteredMuseums
                            unregisteredMuseums={this.getValues('unregistered_museums') || []}
                            changeHandler={this.changeHandler}
                        />
                    </Grid>
                    <Grid item className={classes.gridItem}>
                        <PollMuseums
                            museums={this.getValues('museums') || []}
                            changeHandler={this.changeHandler}
                            pollType={this.getValues('type')}
                        />
                    </Grid>
                    <Grid item className={classes.gridItem}>
                        <PollSections
                            sections={this.getValues('sections') || []}
                            changeHandler={this.changeHandler}
                            errors={this.getErrors('sections')}
                            lockQuestions={!!this.getValues('starts_at') && new Date(this.getValues('starts_at')) < new Date()}
                        />
                    </Grid>
                </Grid>
            </PaperRoot>

            <FabContainer>
                <FabLink title="Wróć" to={'/projects/' + this.project_id + '/polls'} color="default"><ArrowBackIcon/></FabLink>
                {this.renderDeleteButton()}

                <FabButton title="Zapisz" onClick={this.saveObject} color="primary"><SaveIcon/></FabButton>
            </FabContainer>

            <ConfirmDialog open={this.state.deleteDialog} onClose={this.closeDeleteDialog} onConfirm={this.deleteObject('/projects/' + this.project_id + '/polls')}>
                Czy na pewno chcesz usunąć tę ankietę?
            </ConfirmDialog>
        </>
    }
}

const styles = theme => ({
    gridItem: {
        // marginTop: theme.spacing(4),
    }
});

export default withStyles(styles)(PollEdit);
