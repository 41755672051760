import React from 'react';
import Axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DataComponent from "../shared/DataComponent";
import NCForm from '../nc-form/NCForm';
import User from "../auth/User";
import PaperRoot from "../shared/PaperRoot";
import FabContainer from "../shared/FabContainer";
import ConfirmDialog from "../shared/ConfirmDialog";
import FabButton, { FabLink } from "../shared/FabButton";

class RoleEdit extends DataComponent {
    static permissions = ['edit-roles'];
    url = '/users/roles/add';
    role_id = this.props.match.params.role_id;

    constructor(props) {
        super(props);

        this.state.groups = [];
        if (this.role_id) {
            this.url = '/users/roles/' + this.role_id;
        }
    }

    processData = (data) => {
        if (!!data.object) {
            let permissions = {}; // add ID as keys for checkboxgroup
            data.object.permissions.forEach(permission => {
                permissions[permission.id] = permission;
            });
            data.object.permissions = permissions;
        }

        let output = { data };
        if (data.groups) {
            output.groups = data.groups;
        }

        return output;
    };

    saveObject = () => {
        if (this.hasObject()) {
            Axios.put('/users/roles/' + this.role_id, this.getValues()).then(response => {
                // this.setStateData(response.data, { errors: {} }); // reload data
                this.props.history.push('/users/roles');
            }).catch(this.catchErrors);
        } else {
            Axios.post('/users/roles', this.getValues()).then(response => {
                // this.props.history.push('/users/roles/' + response.data.object.id + '/edit');
                this.props.history.push('/users/roles');
            }).catch(this.catchErrors);
        }
    };

    deleteObject = () => {
        Axios.delete('/users/roles/' + this.role_id).then(response => {
            this.props.history.push('/users/roles');
        }).catch(this.catchErrors);
    };

    getTitle = () => {
        return this.hasObject() ? 'Edycja roli: ' + this.getObject('title') : 'Dodaj rolę';
    };

    render() {
        if (this.state.isLoading) {
            return this.showLoader();
        }
        const { classes } = this.props;
        const { groups } = this.state;
        const fields = [
            {
                label: 'Nazwa',
                name: 'title',
                type: 'text',
                width: 400,
            },
            { type: 'divider' },
            {
                type: 'custom', component:
                    <div className={classes.help}>
                        Uwaga: SuperAdministratorzy nie otrzymują wiadomości e-mail z powiadomień.
                    </div>
            },
            { type: 'divider' },
            {
                label: 'Uprawnienia:',
                name: 'permissions',
                type: 'checkboxgroup',
                multiple: true,
                width: '100%',
                groups: groups,
            },
        ];

        return <>
            <PaperRoot>
                <NCForm
                    fields={fields}
                    title={this.getTitle()}
                    values={this.getValues()}
                    errors={this.getErrors()}
                    onChange={this.changeHandler}
                    hideSubmitButton
                    // saveHandler={this.saveObject}
                />
            </PaperRoot>

            <FabContainer>
                <FabLink title="Wróć" to="/users/roles" color="default"><ArrowBackIcon/></FabLink>

                {User.hasPermissionTo('edit-roles') && <>
                    {this.hasObject() && !this.getObject('protected') && <>
                        {this.renderDeleteButton()}
                    </>}
                    <FabButton title="Zapisz" onClick={this.saveObject} color="primary"><SaveIcon/></FabButton>
                </>}
            </FabContainer>

            <ConfirmDialog open={this.state.deleteDialog} onClose={this.closeDeleteDialog} onConfirm={this.deleteObject}>
                Czy na pewno chcesz usunąć te rolę?<br/>
                Tylko administrator będzie miał możliwość przywrócenia usuniętej roli.
            </ConfirmDialog>
        </>
    }
}

const styles = theme => ({
    help: {
        color: '#aaa',
        marginTop: theme.spacing(4),
    },
});

export default withStyles(styles)(RoleEdit);
