import React from 'react';
import DataComponent from "../shared/DataComponent";
import PropTypes from "prop-types";
import DataTable from "../shared/DataTable";
import MuseumPoll from "../../models/MuseumPoll";

class MuseumTabHistory extends DataComponent {
    museum_id = this.props.museum_id;
    url = '/museums/' + this.museum_id + '/history';

    tableColumns = {
        created_at: 'Data zmiany',
        'user.full_name': 'Użytkownik',
        'user.id': 'ID użytkownika',
        changed_html: { title: 'Zmienione dane', raw: true, cellStyle: { width: "60%" } },
    };

    actions = [];

    render() {
        if (this.state.isLoading) {
            return this.showLoader();
        }

        return (
            <DataTable
                tableRef={this.tableRef}
                storageKey={this.constructor.name}
                title="Historia zmian danych muzeum"
                columns={this.getTableColumns()}
                data={this.state.data.history}
            />
        );
    }
}

MuseumTabHistory.propTypes = {
    museum_id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]).isRequired,
};

export default MuseumTabHistory;
