import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DataComponent from "../shared/DataComponent";
import DataTable from "../shared/DataTable";
import { getObjValuesFromArrayByAttribute } from "../shared/Helpers";
import { DialogTitle } from "../shared/Dialog";

class TablePicker extends DataComponent {
    values = [];
    dialogTitle = 'Dodawanie nowych pozycji';
    tableTitle = 'Dostępne pozycje';
    tableOptions = {
        selection: true,
    };
    tableActions = [];
    showLink = null;
    editLink = null;

    constructor(props) {
        super(props);

        this.state.values = [];
    }

    changeHandler = (save) => () => {
        if (this.props.multiple === true || this.props.multiple === undefined) {
            let values = this.getValues();
            if (save) { // save is false when picker doesn't return any values
                values.map(v => {
                    if (!v.tableData) {
                        // add missing tableData (the row must have been checked automagically)
                        v.tableData = {};
                    }
                    v.tableData.checked = false;

                    return v;
                }); // manually uncheck all rows
            }

            this.props.changeHandler(!save ? [] : values);
            this.tableRef.current.onAllSelected(false);
        } else {
            return this.props.changeHandler(false);
        }
    };

    fetchTable = (url, query, resolve, counter = 0) => {
        const ids = getObjValuesFromArrayByAttribute(this.values);
        if (ids.length) {
            query.notIn = { id: ids };
        }
        super.fetchTable(url, query, resolve, counter);
    };

    onSelectionChange = (rows, rowData) => {
        this.setState({ values: rows });
    };

    parentChildData = (row, rows) => {};

    renderContentBeforeTable = () => {};

    renderTableDialogActions = () => {
        return <DialogActions>
            <Button id="table-picker-close" onClick={this.changeHandler(false)} variant="contained">Zamknij</Button>
            <Button id="table-picker-add" onClick={this.changeHandler(true)} disabled={!this.getValues().length} color="primary" variant="contained">Dodaj wybrane</Button>
        </DialogActions>
    };

    renderTableDialog = () => {
        return <Dialog fullScreen maxWidth={'md'} fullWidth={true} open={true} onClose={this.changeHandler(false)}>
            <DialogTitle onClose={this.changeHandler(false)}>{this.dialogTitle}</DialogTitle>
            <DialogContent style={{ paddingTop: 24 }}>
                {this.renderContentBeforeTable()}
                <DataTable
                    tableRef={this.tableRef}
                    storageKey={this.constructor.name}
                    title={this.tableTitle}
                    data={this.loadTableData(this.url)}
                    columns={this.getTableColumns()}
                    options={this.tableOptions}
                    actions={this.tableActions}
                    onSelectionChange={this.onSelectionChange}
                    parentChildData={this.parentChildData}
                    showLink={this.showLink}
                    editLink={this.editLink}
                />
            </DialogContent>
            {this.renderTableDialogActions()}
        </Dialog>
    };

    render() {
        return this.renderTableDialog();
    }
}

export default TablePicker;
