import React, { Component } from "react";

const GlobalStateContext = React.createContext({});

class GlobalStateContextProvider extends Component {
    state = {};

    _setState = data => {
        this.setState(data);
    };

    _getState = () => {
        return this.state;
    }

    render() {
        const { Provider } = GlobalStateContext;
        const { children } = this.props;

        return <Provider value={{ setState: this._setState, getState: this._getState }}>
            {children}
        </Provider>;
    }
}

export { GlobalStateContextProvider };
export default GlobalStateContext;
