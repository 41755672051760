import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import NCForm from '../nc-form/NCForm';
import NCChoiceCreator from '../nc-choice-creator/NCChoiceCreator';
import Axios from 'axios';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/Save';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import VisibilityIcon from "@material-ui/icons/Visibility";

import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from "@material-ui/core/Button";
import User from "../auth/User";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { DialogTitle, DialogContent } from "../shared/Dialog";
import DataComponent from "../shared/DataComponent";
import { dotGet, dotSet, getSelectOptions, clone } from "../shared/Helpers";
import FabContainer from "../shared/FabContainer";
import PaperRoot from "../shared/PaperRoot";
import Question from "../../models/Question";
import ConfirmDialog from "../shared/ConfirmDialog";
import FabButton, { FabLink } from "../shared/FabButton";
import QuestionTableCreator from "../nc-table-creator/QuestionTableCreator";
import NCPollAnswer from "../nc-poll/NCPollAnswer";
import MuseumPollContext from "../museum-polls/MuseumPollContext";

class QuestionEdit extends DataComponent {
    static contextType = MuseumPollContext;
    static permissions = ['edit-questions'];
    question_id = this.props.match.params.question_id;
    catalog_id = this.props.match.params.catalog_id;
    detailsSet = {
        number: clone(Question.details),
        table: clone(Question.details),
        regular: clone(Question.details),
    };

    constructor(props) {
        super(props);

        if (this.catalog_id) {
            this.url = '/catalogs/questions/parent/' + this.catalog_id;
        }

        // different url for editing
        if (this.question_id) {
            this.url = '/catalogs/questions/' + this.question_id;
        }

        this.state.catalogs = {};
        this.state.moveDialog = false;
        this.state.moveTo = "";
        this.state.overrideDialog = false;
        this.state.preview = false;

        delete this.detailsSet.number.zeros;
        delete this.detailsSet.number.clear;
        delete this.detailsSet.regular.zeros;
        delete this.detailsSet.regular.clear;
        delete this.detailsSet.regular.estimated;
    }

    processData = (data) => {
        let output = { data };
        if (data.catalogs) {
            output.catalogs = data.catalogs;
        }
        return output;
    };

    saveAndOverride = () => {
        this.setState({ values: dotSet(this.state.values, 'override', true) }, this.saveObject);
    };

    saveObject = () => {
        if (this.hasObject()) {
            Axios.put(this.url, this.getValues()).then(response => {
                // this.reloadStateData(response.data, { errors: {} });
                if (this.catalog_id) {
                    this.props.history.push('/catalogs/list/' + this.catalog_id + '/questions');
                } else {
                    this.props.history.push('/catalogs/questions');
                }
            }).catch(this.catchErrors);
        } else {
            Axios.post('/catalogs/questions/' + this.catalog_id, this.getValues()).then(response => {
                // this.props.history.push('/catalogs/' + this.catalog_id + '/questions/' + response.data.object.id + '/edit');
                if (this.catalog_id) {
                    this.props.history.push('/catalogs/list/' + this.catalog_id + '/questions');
                } else {
                    this.props.history.push('/catalogs/questions');
                }
            }).catch(this.catchErrors);
        }
    };

    changeHandler = (name, value) => {
        let values = this.state.values;
        if (name === 'type' || values.properties === null) { // some questions rely on properties which cannot be null
            let properties = {};
            switch (name === 'type' ? value : values.type) {
                case 'mask':
                    properties.variant = '';
                    break;
                case 'open':
                case 'text':
                    properties.maxlength = '';
                    break;
                case 'number':
                    properties = {
                        precision: 0,
                        suffix: '',
                        min: '',
                        max: '',
                    };
                    break;
                case 'dynamic_table':
                    properties.table = {
                        data: [['', '', '', ''], ['', '', '', '']],
                        collWidths: [],
                        mergeCells: {},
                        meta: {
                            'A1': { type: 'label', config: 'Liczba nr 1' },
                            'B1': { type: 'label', config: 'Liczba nr 2' },
                            'C1': { type: 'label', config: 'Liczba nr 3' },
                            'D1': { type: 'label', config: 'Suma' },
                            'A2': { type: 'number', config: { precision: 0, suffix: '', min: '', max: '' } },
                            'B2': { type: 'number', config: { precision: 0, suffix: '', min: '', max: '' } },
                            'C2': { type: 'number', config: { precision: 0, suffix: '', min: '', max: '' } },
                            'D2': { type: 'formula', config: '=SUMA(A$:C$)' },
                        },
                    };
                    break;
                case 'static_table':
                    properties.table = {
                        data: [['', '', '', ''], ['', '', '', ''], ['', '', '', ''], ['', '', '', ''], ['', '', '', '']],
                        collWidths: [],
                        mergeCells: {},
                        meta: {
                            'A1': { type: 'label', config: 'Kolumna nr 1' },
                            'B1': { type: 'label', config: 'Kolumna nr 2' },
                            'C1': { type: 'label', config: 'Kolumna nr 3' },
                            'D1': { type: 'label', config: 'Suma wiersza' },
                            'A2': { type: 'number', config: { precision: 0, suffix: '', min: '', max: '' } },
                            'B2': { type: 'number', config: { precision: 0, suffix: '', min: '', max: '' } },
                            'C2': { type: 'number', config: { precision: 0, suffix: '', min: '', max: '' } },
                            'D2': { type: 'formula', config: '=SUMA(A2:C2)' },
                            'A3': { type: 'number', config: { precision: 0, suffix: '', min: '', max: '' } },
                            'B3': { type: 'number', config: { precision: 0, suffix: '', min: '', max: '' } },
                            'C3': { type: 'number', config: { precision: 0, suffix: '', min: '', max: '' } },
                            'D3': { type: 'formula', config: '=SUMA(A3:C3)' },
                            'A4': { type: 'number', config: { precision: 0, suffix: '', min: '', max: '' } },
                            'B4': { type: 'number', config: { precision: 0, suffix: '', min: '', max: '' } },
                            'C4': { type: 'number', config: { precision: 0, suffix: '', min: '', max: '' } },
                            'D4': { type: 'formula', config: '=SUMA(A4:C4)' },
                            'A5': { type: 'formula', config: '=SUMA(A2:A4)' },
                            'B5': { type: 'formula', config: '=SUMA(B2:B4)' },
                            'C5': { type: 'formula', config: '=SUMA(C2:C4)' },
                            'D5': { type: 'label', config: '<= suma kolumny' },
                        },
                    };
                    break;
                case 'choice_fixed':
                    properties.choice_options = [];
                case 'choice_single':
                case 'choice_multiple':
                    properties.choices = [];
                    break;
            }
            values.properties = properties;
            values.details = Object.keys(this.getDetailsSet(value));
        }

        this.setState({ values: dotSet(values, name, value) });
    };

    getDetailsSet = (type) => {
        switch (type) {
            case 'static_table':
            case 'dynamic_table':
                return this.detailsSet.table;
            case 'number':
                return this.detailsSet.number;
            default:
                return this.detailsSet.regular;
        }
    }

    moveObject = () => {
        Axios.put(this.url + '/move', { moveTo: this.state.moveTo }).then(response => {
            this.props.history.push('/catalogs/list/' + response.data.object.catalog_id + '/questions');
        }).catch(this.catchErrors);
    };

    closeDialog = (name) => () => {
        this.setState({ [name + 'Dialog']: false });
    };

    openDialog = (name) => () => {
        this.setState({ [name + 'Dialog']: true });
    };

    moveQuestionSelect = (event, child) => {
        this.setState({ moveTo: child.props.value });
    };

    getTitle = () => {
        return (this.hasObject() ? "Edycja pytania z katalogu: " : "Dodaj pytanie do katalogu: ") + this.getParentObject('name');
    };

    render() {
        if (this.state.isLoading) {
            return this.showLoader();
        }
        const { classes } = this.props;
        const { values, catalogs } = this.state;
        const { properties } = values;
        const { choices, choice_options, table } = properties || {};

        const fields = [
            {
                name: 'name_string',
                type: 'text',
                label: 'Nazwa',
                width: 816,
            },
            { type: 'divider' },
            {
                name: 'name',
                type: 'tinyBasic',
                label: 'Pytanie',
                width: 816,
            },
            { type: 'divider' },
            {
                name: 'hint',
                type: 'tinyBasic',
                label: 'Podpowiedź',
                width: 816,
            },
            { type: 'divider' },
            {
                name: 'description',
                type: 'tinyLessBasic',
                label: 'Opis',
                width: 816,
            },
            { type: 'divider' },
            {
                name: 'is_active',
                type: 'select',
                label: 'Aktywne?',
                width: 261,
                options: [
                    { value: 1, text: 'Tak' },
                    { value: 0, text: 'Nie' }
                ],
            },
            {
                name: 'is_public',
                type: 'select',
                label: 'Publiczne?',
                width: 261,
                options: [
                    { value: 1, text: 'Tak' },
                    { value: 0, text: 'Nie' }
                ],
            },
            {
                name: 'is_public_filter',
                type: 'select',
                label: 'Wykorzystaj jako filtr',
                width: 261,
                options: [
                    { value: 1, text: 'Tak' },
                    { value: 0, text: 'Nie' }
                ],
            },
            { type: 'divider' },
            {
                name: 'state',
                type: 'select',
                label: 'Status',
                width: 261,
                options: [].concat(User.hasPermissionTo('accept-questions') || this.getValues('state') !== 'published' ? [
                    { value: 'draft', text: 'Wersja robocza' },
                    { value: 'ready', text: 'Gotowe do moderacji' },
                ] : []).concat(User.hasPermissionTo('accept-questions') || this.getValues('state') === 'published'
                    ? { value: 'published', text: 'Opublikowane' }
                    : []
                ),
            },
            {
                name: 'type',
                type: 'select',
                label: 'Rodzaj pytania',
                width: 261,
                options: getSelectOptions(Question.types),
            },
            { type: 'divider' },
        ];

        let hasText = true;
        switch (values.type) {
            case 'mask':
                fields.push({
                    name: 'properties.variant',
                    type: 'select',
                    label: 'Wariant',
                    width: 261,
                    options: getSelectOptions(Question.maskVariants),
                });
                break;
            case 'regexp':
                fields.push({
                    name: 'properties.pattern',
                    type: 'text',
                    label: 'Wyrażenie',
                    width: 261,
                });
                fields.push({
                    name: 'properties.message',
                    type: 'text',
                    label: 'Wyjaśnienie',
                    width: 261,
                });
                break;
            case 'choice_fixed':
                hasText = false;
                fields.push({ type: 'info', text: 'Skala' });
                fields.push({
                    type: 'custom',
                    component: <NCChoiceCreator
                        valuesKey="properties.choice_options"
                        tiny={false}
                        key={values.type + '_options'}
                        onChange={this.changeHandler}
                        choices={choice_options}
                        errors={dotGet(this.getErrors(), 'properties.choice_options')}
                        hasText={hasText}
                    />
                });
                fields.push({ type: 'info', text: 'Odpowiedzi' });
            case 'choice_single':
            case 'choice_multiple':
                fields.push({
                    type: 'custom',
                    component: <NCChoiceCreator
                        valuesKey="properties.choices"
                        key={values.type}
                        onChange={this.changeHandler}
                        choices={choices}
                        errors={dotGet(this.getErrors(), 'properties.choices')}
                        hasText={hasText}
                    />
                });
                break;
            case 'dynamic_table':
                fields.push({
                    type: 'custom',
                    component: <QuestionTableCreator
                        valuesKey="properties.table"
                        key={values.type}
                        isDynamic={true}
                        table={table}
                        onChange={this.changeHandler}
                    />
                });
                fields.push({ type: 'divider' });
                fields.push({
                    type: 'custom',
                    component: <div key="" style={{ paddingTop: 16 }}>
                        W formułach tabel dynamiczny należy użyć odniesień do kolumn w postaci: "<b>A$</b>"<br/>
                        Przykład: <b>=SUMA(A$:C$)</b>
                    </div>
                });
                break;
            case 'static_table':
                fields.push({
                    type: 'custom',
                    component: <QuestionTableCreator
                        valuesKey="properties.table"
                        key={values.type}
                        isDynamic={false}
                        table={table}
                        onChange={this.changeHandler}
                    />
                });
                break;
            case 'number':
                fields.push({
                    name: 'properties.precision',
                    type: 'number',
                    label: 'Miejsc po przecinku',
                    width: 130,
                    step: 1,
                    min: 0,
                    max: 4,
                    defaultValue: 0,
                });
                fields.push({
                    name: 'properties.suffix',
                    type: 'text',
                    label: 'Jednostka',
                    width: 100,
                });
                fields.push({
                    name: 'properties.min',
                    type: 'number',
                    label: 'Minimum',
                    width: 100,
                });
                fields.push({
                    name: 'properties.max',
                    type: 'number',
                    label: 'Maximum',
                    width: 100,
                });
                break;
            case 'open':
            case 'text':
                fields.push({
                    name: 'properties.maxlength',
                    type: 'number',
                    label: 'Limit znaków',
                    width: 100,
                });
                break;
            case 'year':
            case 'date':
            case 'boolean':
            default:
            // no configuration needed
        }
        if (values.type) {
            fields.push({ type: 'divider' });
            fields.push({
                name: 'details',
                type: 'multiselect',
                label: 'Opcje odpowiedzi',
                width: 261 * 2 + 16,
                options: getSelectOptions(this.getDetailsSet(values.type)),
            });
        }

        return <>
            <PaperRoot>
                <NCForm
                    fields={fields}
                    title={this.getTitle()}
                    values={this.getValues()}
                    errors={this.getErrors()}
                    onChange={this.changeHandler}
                    hideSubmitButton
                    // saveHandler={this.saveObject}
                />
            </PaperRoot>

            <FabContainer>
                <FabLink title="Wróć" to={'/catalogs' + (this.catalog_id ? '/list/' + this.catalog_id : '') + '/questions'} color="default"><ArrowBackIcon/></FabLink>

                {User.hasPermissionTo('move-questions') && this.hasObject() && (
                    <FabButton title="Przenieś" onClick={this.openDialog('move')} color="primary"><ImportExportIcon/></FabButton>
                )}

                <FabButton title="Podgląd" onClick={() => this.setState({ preview: true })} color="primary"><VisibilityIcon/></FabButton>

                {User.hasPermissionTo('edit-questions') && <>
                    {this.renderDeleteButton()}
                    <FabButton title="Zapisz i nadpisz w aktywnych ankietach" onClick={() => this.setState({ overrideDialog: true })} color="secondary"><SaveOutlinedIcon/></FabButton>
                    <FabButton title="Zapisz" onClick={this.saveObject} color="primary"><SaveIcon/></FabButton>
                </>}
            </FabContainer>

            {this.state.preview && (
                <ConfirmDialog
                    title="Podgląd"
                    open={true}
                    isContentText={false}
                    confirmTitle=""
                    cancelTitle=""
                    dialogProps={{ maxWidth: 'xl', fullWidth: true }}
                    onClose={() => this.setState({ preview: false })}>
                    <NCPollAnswer
                        context={this.context}
                        key={'' + -new Date()}
                        pollQuestion={this.state.values}
                        answer={null}
                        details={null}
                        museumPollId={null}
                        expanded={true}
                        autoSave={false}
                    />
                </ConfirmDialog>
            )}

            {this.hasObject() && (<>
                <ConfirmDialog open={this.state.deleteDialog} onClose={this.closeDeleteDialog} onConfirm={this.deleteObject('/catalogs' + (this.catalog_id ? '/list/' + this.catalog_id : '') + '/questions')}>
                    Czy na pewno chcesz usunąć to pytanie?
                </ConfirmDialog>

                <ConfirmDialog title="Nadpisywanie pytania" open={this.state.overrideDialog} onClose={() => this.setState({ overrideDialog: false })} onConfirm={this.saveAndOverride}>
                    Czy na pewno chcesz nadpisać pytanie we wszystkich ankietach?<br/>
                    Uwaga! Zbyt duże zmiany mogą spowodować nieoczekiwane i nieobsługiwane błędy!<br/>
                    Używaj rozważnie.
                </ConfirmDialog>

                <Dialog open={this.state.moveDialog} onClose={this.closeDialog('move')}>
                    <DialogTitle onClose={this.closeDialog('move')}>Przenoszenie pytania</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Wybierz katalog do którego trafi pytanie:<br/>
                        </DialogContentText>
                        <Select id="move-to" value={this.state.moveTo} onChange={this.moveQuestionSelect} className={classes.selectField}>
                            {Object.entries(catalogs).map(([key, item]) => <MenuItem key={key} value={item.id}>{item.name}</MenuItem>)}
                        </Select>
                        <br/><br/>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={this.closeDialog('move')} color="default">Anuluj</Button>
                        <Button variant="contained" onClick={this.moveObject} disabled={!this.state.moveTo} color="secondary">Przenieś</Button>
                    </DialogActions>
                </Dialog>
            </>)}
        </>
    }
}

const styles = theme => ({
    selectField: {
        marginTop: 0,
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(),
        width: '100%',
    },
});

export default withStyles(styles)(QuestionEdit);
