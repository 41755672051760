import 'react-app-polyfill/stable';
import 'custom-event-polyfill';
import 'document-register-element';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { StylesProvider, createGenerateClassName } from "@material-ui/styles";
import App from './App';

import grey from '@material-ui/core/colors/blueGrey';
import orange from '@material-ui/core/colors/orange';

window.APP_NAME = 'Statystyka';

const generateClassName = createGenerateClassName();
const theme = createMuiTheme({
    palette: {
        primary: grey,
        secondary: orange,
    },
    status: {
        danger: 'orange',
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: 'inherit',
            },
        },
        MuiSelect: {
            selectMenu: {
                height: null,
            },
        },
    },
});

ReactDOM.render(
    <StylesProvider generateClassName={generateClassName}>
        <MuiThemeProvider theme={theme}>
            <Router>
                <App/>
            </Router>
        </MuiThemeProvider>
    </StylesProvider>,
    document.getElementById('root')
);
