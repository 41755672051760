import PropTypes from "prop-types";
import TablePicker from "../shared/TablePicker";
import { getObjValuesFromArrayByAttribute } from "../shared/Helpers";
import Poll from "../../models/Poll";

class PollSectionPicker extends TablePicker {
    url = '/projects/polls/sections';
    tableColumns = {
        id: 'ID',
        name: 'Dział',
        'poll.name': 'Ankieta',
        'poll.year': 'Okres',
        type_string: { field: 'poll.type', title: 'Rodzaj', lookup: Poll.types },
    };
    dialogTitle = 'Import działu do ankiety';
    tableTitle = 'Dostępne działy';

    constructor(props) {
        super(props);

        this.values = props.sections;
        this.tableOptions.sorting = false;
    }

    fetchTable = (url, query, resolve, counter = 0) => {
        query.notIn = {};

        const pollId = getObjValuesFromArrayByAttribute(this.values, 'poll_id').pop();
        if (pollId) {
            query.notIn.poll_id = pollId;
        }

        const ids = getObjValuesFromArrayByAttribute(this.values);
        if (ids.length) {
            query.notIn['polls_sections.id'] = ids;
        }

        if (Object.keys(this.props.pollFilters).length) {
            query.filters.poll = this.props.pollFilters;
        }
        if (Object.keys(this.props.museumFilters).length) {
            query.filters.museum = this.props.museumFilters;
        }
        if (Object.keys(this.props.dataFilters).length) {
            query.filters.data = this.props.dataFilters;
        }

        super.fetchTable(url, query, resolve, counter);
    };
}

PollSectionPicker.propTypes = {
    sections: PropTypes.array,
    changeHandler: PropTypes.func.isRequired,
    pollFilters: PropTypes.object,
    museumFilters: PropTypes.object,
    dataFilters: PropTypes.object,
};
PollSectionPicker.defaultProps = {
    sections: [],
    pollFilters: {},
    museumFilters: {},
    dataFilters: {},
};

export default PollSectionPicker;
