import React from 'react';
import PropTypes from "prop-types";
import Museum from "../../models/Museum";
import TablePicker from "../shared/TablePicker";
import { arrayUnique, getItemFromArrayByAttribute, getObjValuesFromArrayByAttribute, removeItemsFromArrayByAttribute } from "../shared/Helpers";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TooltipIconButton from "../shared/TooltipIconButton";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

class MuseumsPicker extends TablePicker {
    url = this.props.url;
    dialogTitle = this.props.dialogTitle;
    tableTitle = this.props.tableTitle;
    lookupTypes = null;

    getLookupTypes = () => {
        if (this.lookupTypes === null) {
            this.lookupTypes = {};
            Object.entries(Museum.types).forEach(([type, name]) => {
                if (this.props.types.includes(type)) {
                    this.lookupTypes[type] = name;
                }
            });
        }

        return this.lookupTypes;
    };

    tableColumns = {
        idx: 'ID',
        name: 'Nazwa instytucji',
        type_string: { field: 'type', title: 'Typ', lookup: this.getLookupTypes() },
        'data.phone_nb': 'Nr telefonu',
        'data.email': 'Email',
    };

    constructor(props) {
        super(props);

        this.values = props.museums;

        if (!props.multiple) {
            this.tableOptions.selection = false;
            this.tableColumns.actions = {
                field: 'function', title: 'Akcje', filtering: false, callback: rowData => {
                    if (rowData.type === 'branch' && !props.pickBranches) return '';
                    return <TooltipIconButton title="Wybierz" onClick={this.clickHandler(rowData)}>
                        <CheckCircleIcon/>
                    </TooltipIconButton>;
                },
            };
        }
    }

    clickHandler = rowData => event => {
        this.props.changeHandler(rowData);
    };

    changeHandler = (save) => () => {
        this.props.changeHandler(save ? this.getValues() : (this.props.multiple ? [] : null));
        this.tableRef.current.onAllSelected(false);
    };

    fetchTable = (url, query, resolve, counter = 0) => {
        const ids = getObjValuesFromArrayByAttribute(this.values);
        if (ids.length) {
            query.notIn = { id: ids };
        }
        if (this.props.types.length) {
            query.types = this.props.types;
        }
        super.fetchTable(url, query, resolve, counter);
    };

    onSelectionChange = (rows, rowData) => {
        if (rowData && rowData.parent_id) { // rowData is undefined when using SelectAllCheckbox
            let parent = getItemFromArrayByAttribute(this.table, rowData.parent_id);
            if (getItemFromArrayByAttribute(rows, rowData.parent_id, 'parent_id')) {
                parent.tableData.checked = true;
                rows.push(parent);
            } else {
                parent.tableData.checked = false;
                rows = removeItemsFromArrayByAttribute(rows, rowData.parent_id);
            }
        }


        this.setState({ values: arrayUnique(rows) });
    };

    parentChildData = (row, rows) => rows.find(v => v.id === row.parent_id);

    renderTableDialogActions = () => {
        return <DialogActions>
            <Button onClick={this.changeHandler(false)} variant="contained">Zamknij</Button>
            {this.props.multiple && (
                <Button onClick={this.changeHandler(true)} disabled={!this.getValues().length} color="primary" variant="contained">Dodaj wybrane</Button>
            )}
        </DialogActions>
    };
}

MuseumsPicker.propTypes = {
    url: PropTypes.string,
    dialogTitle: PropTypes.string,
    tableTitle: PropTypes.string,
    museums: PropTypes.array,
    types: PropTypes.array,
    multiple: PropTypes.bool,
    pickBranches: PropTypes.bool,
    changeHandler: PropTypes.func.isRequired,
};
MuseumsPicker.defaultProps = {
    url: '/museums',
    dialogTitle: 'Wybierz muzeum',
    tableTitle: 'Dostępne muzea',
    types: ['branched', 'branchless', 'branch'],
    museums: [],
    multiple: true,
    pickBranches: false,
};

export default MuseumsPicker;
