import React from 'react';
import PropTypes from "prop-types";
import TablePicker from "../shared/TablePicker";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

class CatalogPicker extends TablePicker {
    url = '/catalogs/active';
    tableColumns = {
        name_depth: { title: 'Nazwa', column: 'name' },
        id: 'ID',
        published_questions_count: { title: 'Ilość pytań zaakceptowanych', filtering: false },
        ready_questions_count: { title: 'Ilość pytań zaproponowanych', filtering: false },
        created_at: "Data utworzenia",
    };
    dialogTitle = 'Wybierz katalog do którego dodać pytanie:';
    tableTitle = 'Dostępne katalogi';
    tableOptions = {
        selection: false,
    };
    showLink = (rowData) => '/catalogs/list/' + rowData.id + '/questions/add';

    parentChildData = (row, rows) => rows.find(v => v.id === row.parent_id);

    renderTableDialogActions = () => {
        return <DialogActions>
            <Button onClick={this.changeHandler(false)} variant="contained">Zamknij</Button>
        </DialogActions>
    };
}

CatalogPicker.propTypes = {
    changeHandler: PropTypes.func.isRequired,
};
CatalogPicker.defaultProps = {};

export default CatalogPicker;
