import React from 'react';
import Axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import NCForm from '../nc-form/NCForm';
import SaveIcon from '@material-ui/icons/Save';
import VpnLockIcon from '@material-ui/icons/VpnLock';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DataComponent from "../shared/DataComponent";
import User from "../auth/User";
import PaperRoot from "../shared/PaperRoot";
import FabContainer from "../shared/FabContainer";
import ConfirmDialog from "../shared/ConfirmDialog";
import FabButton, { FabLink } from "../shared/FabButton";
import MuseumsPicker from "../museums/MuseumsPicker";
import { dotSet } from "../shared/Helpers";
import { FormHelperText } from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Info';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

class UserEdit extends DataComponent {
    static permissions = ['edit-users'];
    url = '/users/add';
    user_id = this.props.match.params.user_id;
    museum = null;

    constructor(props) {
        super(props);

        if (this.user_id) {
            this.url = '/users/' + this.user_id;
        }

        this.state.rolesOptions = [];
        this.state.passwordDialog = false;
        this.state.anonymizeDialog = false;
        this.state.museumPicker = false;
    }

    processData = (data) => {
        let output = { data };
        let rolesOptions = [];

        if (data.roles) {
            data.roles.forEach((role, roleKey) => {
                rolesOptions.push({
                    value: role.id,
                    text: role.title,
                });
            });
            output.rolesOptions = rolesOptions;
        }

        return output;
    };

    sendPassword = () => {
        Axios.put('/users/' + this.user_id + '/sendPassword').then(response => {
            this.closePasswordDialog();
        }).catch(this.catchErrors);
    };

    saveObject = () => {
        if (this.hasObject()) {
            Axios.put('/users/' + this.user_id, this.getValues()).then(response => {
                // this.reloadStateData(response.data, { errors: {} });
                this.props.history.push('/users');
            }).catch(this.catchErrors);
        } else {
            Axios.post('/users', this.getValues()).then(response => {
                // this.props.history.push('/users/' + response.data.object.id + '/edit');
                this.props.history.push('/users/list');
            }).catch(this.catchErrors);
        }
    };

    deleteAnonymize = () => {
        Axios.delete(this.url, { data: { anonymize: true } }).then(response => {
            this.props.history.push('/users/list');
        }).catch(this.catchErrors);
    };

    closePasswordDialog = () => {
        this.setState({ passwordDialog: false });
    };

    setMuseum = museum => {
        if (museum) {
            dotSet(this.getObject(), 'museum', museum);
            this.museum = museum;
            this.changeHandler('museum_id', museum.id);
        } else {
            // this.setState({ museumPicker: false });
            this.changeHandler('museum_id', null);
        }
    };

    changeHandler = (name, value) => {
        let museumPicker = false;
        if (name === 'museum_id' && value === true) {
            value = null;
            museumPicker = true;
        }
        this.setState({ values: dotSet(this.state.values, name, value), museumPicker });
    };

    getTitle = () => {
        return this.hasObject() ? "Edycja użytkownika: " + this.getObject('full_name') : 'Dodaj użytkownika';
    };

    render() {
        if (this.state.isLoading) {
            return this.showLoader();
        }
        const { rolesOptions, museumPicker } = this.state;
        const museum = this.getObject('museum') || this.museum;
        let museumsOptions = [{ value: true, text: '- wybierz -' }];
        if (museum) {
            museumsOptions = [{ value: museum.id, text: museum.name }].concat(museumsOptions);
        }

        const fields = [
            {
                name: 'email',
                type: 'text',
                label: 'Login (adres email)',
                width: 616,
            },
            { type: 'divider' },
            {
                name: 'first_name',
                type: 'text',
                label: 'Imię',
                width: 300,
            },
            {
                name: 'last_name',
                type: 'text',
                label: 'Nazwisko',
                width: 300,
            },
            { type: 'divider' },
            {
                name: 'phone',
                type: 'text',
                label: 'Nr telefonu',
                width: 300,
                inputProps: { autoComplete: 'off' },
            },
            {
                type: 'custom',
                component: this.hasObject() ? '' : <>
                    <InfoIcon color="primary" style={{margin: '20px 6px 0 0'}} /><FormHelperText style={{ marginTop: 14, maxWidth: 270, textAlign: 'justify', lineHeight: 'normal' }}>
                    Dodając nowego użytkownika nie trzeba wpisywać hasła. Zostanie ono wygenerowane automagicznie i wysłane do niego mailem.
                </FormHelperText></>,
            },
            { type: 'divider' },
            {
                name: 'password',
                type: 'password',
                label: this.hasObject() ? 'Nowe hasło' : 'Hasło',
                width: 300,
            },
            {
                name: 'password_confirmation',
                type: 'password',
                label: 'Potwierdź hasło',
                width: 300,
            },
            { type: 'divider' },
            {
                name: 'role_id',
                type: 'select',
                label: 'Rola',
                options: rolesOptions,
                width: 300,
            },
            {
                name: 'museum_id',
                type: 'select',
                label: 'Instytucja',
                options: museumsOptions,
                width: 300,
                emptyValue: '- brak -',
            },
        ];

        return <>
            <PaperRoot>
                <NCForm
                    fields={fields}
                    title={this.getTitle()}
                    values={this.getValues()}
                    errors={this.getErrors()}
                    onChange={this.changeHandler}
                    hideSubmitButton
                />
            </PaperRoot>

            {museumPicker && (
                <MuseumsPicker
                    changeHandler={this.setMuseum}
                    multiple={false}
                    pickBranches={true}
                />
            )}

            <FabContainer>
                <FabLink  title="Wróć" to="/users/list" color="default"><ArrowBackIcon/></FabLink>

                {User.hasPermissionTo('edit-users') && <>
                    {this.hasObject() && <>
                        {this.renderDeleteButton()}
                        <FabButton title="Usuń i anonimizuj" onClick={() => this.setState({ anonymizeDialog: true })} color="secondary"><DeleteForeverIcon/></FabButton>
                        <FabButton title="Wyślij nowe hasło" color="secondary" onClick={() => this.setState({ passwordDialog: true })}><VpnLockIcon/></FabButton>
                    </>}
                    <FabButton title={this.hasObject() ? "Zapisz" : "Dodaj i wyślij hasło"} onClick={this.saveObject} color="primary"><SaveIcon/></FabButton>
                </>}
            </FabContainer>

            <ConfirmDialog title="Nowe hasło" open={this.state.passwordDialog} onClose={this.closePasswordDialog} onConfirm={this.sendPassword}>
                Czy na pewno chcesz zmienić i wysłać losowe hasło do tego użytkownika?
            </ConfirmDialog>

            <ConfirmDialog open={this.state.deleteDialog} onClose={this.closeDeleteDialog} onConfirm={this.deleteObject('/users/list')}>
                Czy na pewno chcesz usunąć tego użytkownika?<br/>
                Tylko administrator będzie miał możliwość go przywrócić.
            </ConfirmDialog>

            <ConfirmDialog open={this.state.anonymizeDialog} onClose={() => this.setState({ anonymizeDialog: false })} onConfirm={this.deleteAnonymize}>
                Czy na pewno chcesz usunąć tego użytkownika i zanonimizować jego dane osobowe?<br/>
                Danych osobowych nie będzie można przywrócić!
            </ConfirmDialog>
        </>
    }
}

const styles = theme => ({
});

export default withStyles(styles)(UserEdit);
