import React from 'react';
import { withRouter } from "react-router";
import DataComponent from "../shared/DataComponent";
import DataTable from "../shared/DataTable";
import AddIcon from '@material-ui/icons/Add';
import User from "../auth/User";
import FabContainer from "../shared/FabContainer";
import { FabLink } from "../shared/FabButton";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Axios from "axios";

class UserList extends DataComponent {
    static permissions = ['view-users', 'edit-users'];
    url = '/users';
    tableColumns = {
        id: 'ID',
        email: 'Login',
        phone: 'Nr telefonu',
        full_name: 'Imię i nazwisko',
        'role.title': { title: 'Rola', sorting: false }, // TODO sorting
        'museum.name': { title: 'Instytucja', sorting: false }, // TODO sorting
        created_at: "Data dodania",
        last_logged_at: "Ostatnie logowanie",
    };

    constructor(props) {
        super(props);

        this.addDeletedAtColumn();
    }

    render() {
        if (this.state.isLoading) {
            return this.showLoader();
        }

        return <>
            <DataTable
                tableRef={this.tableRef}
                storageKey={this.constructor.name}
                title={"Lista użytkowników"}
                columns={this.getTableColumns()}
                data={this.loadTableData(this.url)}
                editLink={User.hasPermissionTo('edit-roles') ? (rowData) => '/users/list/' + rowData.id + '/edit' : null}
                actions={[
                    rowData => User.hasPermissionTo('edit-users') && rowData.deleted_at && rowData.last_name !== 'Usunięty' ? ({
                        tooltip: 'Anonimizuj',
                        icon: DeleteForeverIcon,
                        onClick: (event, data) => {
                            Axios.delete('/users/anonymize/' + data.id, { data: { anonymize: true } }).then(response => {
                                this.reloadTable();
                            }).catch(this.catchErrors);
                        },
                    }) : ''
                ]}
            />

            {User.hasPermissionTo('edit-users') && (
                <FabContainer>
                    <FabLink title="Dodaj użytkownika" to="/users/list/add" color="primary"><AddIcon/></FabLink>
                </FabContainer>
            )}
        </>
    }
}

export default withRouter(UserList);
