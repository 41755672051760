import React from 'react';
import { withRouter } from "react-router";
import DataComponent from "../shared/DataComponent";
import DataTable from "../shared/DataTable";

import AddIcon from '@material-ui/icons/Add';
import User from "../auth/User";
import FabContainer from "../shared/FabContainer";
import Museum from "../../models/Museum";
import { FabLink } from "../shared/FabButton";
import NCForm from "../nc-form/NCForm";
import { arrayMove, queryStringify } from "../shared/Helpers";
import TooltipIconButton from "../shared/TooltipIconButton";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";

DataTable.localization.toolbar.exportCSVName = 'Zapisz jako XLSX';

class MuseumList extends DataComponent {
    static permissions = ['view-museums'];
    url = '/museums';
    storageKey = 'museum_list';
    tableColumns = {
        idx: 'ID',
        nimoz_id: 'ID (numeracja NIMOZ)',
        museum_name: 'Nazwa instytucji',
        branch_name: 'Nazwa oddziału',
        type_string: { field: 'type', title: 'Typ', lookup: Museum.types },

        ...Museum.dataColumns,

        created_at: 'Data dodania',
        updated_at: 'Data aktualizacji',
    };

    defaultColumns = [
        'idx',
        'museum_name',
        'branch_name',
        'type_string',
        'created_at',
    ];

    constructor(props) {
        super(props);

        this.state.columns = [...this.defaultColumns];

        this.addDeletedAtColumn();

        // retrieve columns from localStorage:
        const columns = localStorage.getItem(this.storageKey + '_columns');
        if (columns !== null && columns.length) {
            this.state.columns = (JSON.parse(columns) || []).filter(column => Object.keys(this.tableColumns).includes(column)); // get rid of invalid columns
        }
    }

    changeHandler = (name, value) => {
        this.setState({ [name]: value }, () => {
            // save columns in localStorage:
            localStorage.setItem(this.storageKey + '_columns', JSON.stringify(this.state.columns));
        });
    };

    getTableColumns = () => {
        return this.state.columns.map(key => {
            const column = this.tableColumns[key];
            return Object.assign({ field: key }, typeof column === 'string' ? { title: column } : column);
        });
    };

    getFilterColumns = () => {
        return Object.entries(this.tableColumns).map(([key, column]) => {
            return { value: key, text: typeof column === 'string' ? column : column.title }
        });
    };

    downloadFile = (columns) => {
        let form = document.createElement('form');
        form.method = 'post';
        form.action = process.env.REACT_APP_SERVER_URL + this.resolvedUrl + '/export?' + queryStringify(this.query);

        let tokenInput = document.createElement('input');
        tokenInput.name = 'token';
        tokenInput.value = localStorage.getItem('id_token');
        form.appendChild(tokenInput);

        columns.forEach(column => {
            let input = document.createElement('input');
            input.name = 'columns[' + column.field + ']';
            input.value = column.title;
            form.appendChild(input);
        });

        document.body.appendChild(form);
        form.submit();
        form.remove();
    };

    render() {
        if (this.state.isLoading) {
            return this.showLoader();
        }
        const { columns } = this.state;

        return <>
            <>
                <NCForm
                    fields={[
                        {
                            type: 'custom',
                            component: <TooltipIconButton key="check-all" title="Zaznacz wszystkie kolumny" onClick={() => this.setState({ columns: Object.keys(this.tableColumns) })}>
                                <CheckBoxIcon/>
                            </TooltipIconButton>
                        },
                        {
                            type: 'custom',
                            component: <TooltipIconButton key="uncheck-all" title="Odznacz wszystkie kolumny" onClick={() => this.setState({ columns: this.defaultColumns })}>
                                <CheckBoxOutlineBlankIcon/>
                            </TooltipIconButton>
                        },
                        {
                            label: 'Kolumny',
                            name: 'columns',
                            type: 'multiselect',
                            options: this.getFilterColumns(),
                            containerStyle: { width: 'calc(100% - 100px)', maxWidth: 'calc(100vw - 40px)' },
                            width: '100%',
                            onClose: () => this.tableRef.current.onFilterChangeDebounce(), // reload table when columns change (they could be filtered)
                        },
                    ]}
                    values={{ columns }}
                    onChange={this.changeHandler}
                    hideSubmitButton
                    // saveHandler={this.saveObject}
                    // style={{ marginTop: -16 }}
                />
            </>
            <DataTable
                tableRef={this.tableRef}
                storageKey={this.constructor.name}
                title="Lista muzeów"
                columns={this.getTableColumns()}
                data={this.loadTableData(this.url)}
                editLink={User.hasPermissionTo('edit-museums') ? (rowData) => '/museums/' + (rowData.deleted_at ? rowData.id : rowData.parent_id || rowData.id) + '/edit' : null}
                parentChildData={(row, rows) => {
                    // NOTE: material-table has a bug which will expand every row after filtering/searching
                    const type = this.queryFilters.type;
                    if (!type || !type.length || type.length === 3 || (type.length === 2 && !type.includes('branchless'))) {
                        row.tableData.isTreeExpanded = true; // force expand
                    } else {
                        row.tableData.isTreeExpanded = false; // force collapse
                    }

                    return rows.find(v => v.id === row.parent_id);
                }}

                onColumnDragged={(from, to) => {
                    this.setState({ columns: arrayMove(this.state.columns, from, to) });
                }}
                options={{
                    exportButton: { csv: true },
                    exportAllData: true,
                    exportCsv: (columns, data) => {
                        console.log('columns:', columns);
                        console.log('this.tableColumns:', this.tableColumns);
                        this.downloadFile(this.state.columns.map(key => {
                            console.log('key:', key);
                            const column = this.tableColumns[key];
                            return Object.assign({ field: key }, typeof column === 'string' ? { title: column } : { title: column.title });
                        }));
                    },
                }}
            />

            <FabContainer>
                {User.hasPermissionTo('edit-museums') && (
                    <FabLink color="primary" title="Dodaj muzeum" to="/museums/add"><AddIcon/></FabLink>
                )}
            </FabContainer>
        </>
    }
}

export default withRouter(MuseumList);
