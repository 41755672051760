import React from 'react';
import PropTypes from "prop-types";
import Museum from "../../models/Museum";
import TablePicker from "../shared/TablePicker";
import { getObjValuesFromArrayByAttribute } from "../shared/Helpers";

class PollMuseumsPicker extends TablePicker {
    url = '/projects/polls/museums';
    questionsUrl = '/catalogs/questions';
    tableColumns = {
        idx: 'ID',
        nimoz_id: 'ID (numeracja NIMOZ)',
        museum_name: 'Nazwa instytucji',
        branch_name: 'Nazwa oddziału',
        type_string: { field: 'type', title: 'Typ', lookup: Museum.types },
        'data.email': 'Email',
        'data.phone_nb': 'Numer telefonu',
        // created_at: 'Data dodania',
    };
    dialogTitle = this.props.dialogTitle;
    tableTitle = 'Dostępne muzea';

    constructor(props) {
        super(props);

        this.values = props.museums;
        this.state.conditions = [];
    }

    parentChildData = (row, rows) => {
        // NOTE: material-table has a bug which will expand every row after filtering/searching
        const type = this.queryFilters.type;
        if (!type || !type.length || type.length === 3 || (type.length === 2 && !type.includes('branchless'))) {
            row.tableData.isTreeExpanded = true; // force expand
        } else {
            row.tableData.isTreeExpanded = false; // force collapse
        }

        return rows.find(v => v.id === row.parent_id);
    };

    fetchTable = (url, query, resolve, counter = 0) => {
        if (url !== this.questionsUrl) {
            if (Object.keys(this.props.pollFilters).length) {
                query.filters.poll = this.props.pollFilters;
            }
            if (Object.keys(this.props.museumFilters).length) {
                query.filters.museum = this.props.museumFilters;
            }
            if (Object.keys(this.props.dataFilters).length) {
                query.filters.data = this.props.dataFilters;
            }
        }

        const ids = getObjValuesFromArrayByAttribute(this.values);
        if (ids.length) {
            query.notIn = { id: ids };
        }

        super.fetchTable(url, query, resolve, counter);
    };
}

PollMuseumsPicker.propTypes = {
    museums: PropTypes.array,
    changeHandler: PropTypes.func.isRequired,
    dialogTitle: PropTypes.string,
    pollFilters: PropTypes.object,
    museumFilters: PropTypes.object,
    dataFilters: PropTypes.object,
};
PollMuseumsPicker.defaultProps = {
    museums: [],
    dialogTitle: 'Dodawanie muzeów',
    pollFilters: {},
    museumFilters: {},
    dataFilters: {},
};

export default PollMuseumsPicker;
