import PropTypes from "prop-types";
import TablePicker from "../shared/TablePicker";
import { getObjValuesFromArrayByAttribute } from "../shared/Helpers";
import TooltipIconButton from "../shared/TooltipIconButton";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import React from "react";
import Poll from "../../models/Poll";
import Question from "../../models/Question";

class PollPicker extends TablePicker {
    url = '/projects/polls/table';
    lookupTypes = null;

    getLookupTypes = () => {
        if (this.lookupTypes === null) {
            this.lookupTypes = {};
            const types = this.props.pollFilters && this.props.pollFilters.type ? this.props.pollFilters.type : [];
            Object.entries(Poll.types).forEach(([type, name]) => {
                if (types.includes(type) || !types.length) {
                    this.lookupTypes[type] = name;
                }
            });
        }

        return this.lookupTypes;
    };

    tableColumns = {
        id: 'ID',
        name: 'Ankieta',
        type_string: {
            field: 'type',
            title: 'Rodzaj',
            lookup: this.getLookupTypes(),
            // filtering: !(this.props.pollFilters && this.props.pollFilters.type ? this.props.pollFilters.type : []).length,
        },
        year: 'Za okres',
        'project.name': 'Projekt',
    };
    dialogTitle = 'Dodaj ankietę';
    tableTitle = 'Dostępne ankiety';

    constructor(props) {
        super(props);

        this.values = props.polls;
        this.tableOptions.sorting = false;

        if (!props.multiple) {
            this.tableOptions.selection = false;

            this.tableColumns.actions = {
                field: 'function', title: 'Akcje', filtering: false, callback: rowData => {
                    return <TooltipIconButton title="Wybierz" onClick={this.clickHandler(rowData)}>
                        <CheckCircleIcon/>
                    </TooltipIconButton>;
                },
            };
        }
    }

    clickHandler = (poll) => event => {
        this.props.changeHandler(poll);
    };

    fetchTable = (url, query, resolve, counter = 0) => {
        if (url !== this.questionsUrl) {
            if (Object.keys(this.props.pollFilters).length) {
                query.filters.poll = this.props.pollFilters;
            }
            if (Object.keys(this.props.museumFilters).length) {
                query.filters.museum = this.props.museumFilters;
            }
            if (Object.keys(this.props.dataFilters).length) {
                query.filters.data = this.props.dataFilters;
            }
        }

        const ids = getObjValuesFromArrayByAttribute(this.values);
        if (ids.length) {
            query.notIn = { id: ids };
        }

        super.fetchTable(url, query, resolve, counter);
    };
}

PollPicker.propTypes = {
    changeHandler: PropTypes.func.isRequired,
    polls: PropTypes.array,
    multiple: PropTypes.bool,
    pollFilters: PropTypes.object,
    museumFilters: PropTypes.object,
    dataFilters: PropTypes.object,
};
PollPicker.defaultProps = {
    polls: [],
    multiple: true,
    pollFilters: {},
    museumFilters: {},
    dataFilters: {},
};

export default PollPicker;
