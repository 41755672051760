import React from "react";
import { withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import classNames from 'classnames';

function FabContainer(props) {
    const { classes, children, isVisible } = props;

    return <div id="fab-container" className={classNames(classes.buttonContainer, isVisible ? null : classes.hidden)}>
        {children}
    </div>;
}

FabContainer.propTypes = {
    isVisible: PropTypes.bool,
};
FabContainer.defaultProps = {
    isVisible: true,
};

const styles = theme => ({
    buttonContainer: {
        margin: 0,
        top: 'auto',
        right: 0,
        bottom: 20,
        left: 'auto',
        position: 'fixed',
        zIndex: 20,
        '& button': {
            marginRight: 20,
        }
    },
    hidden: {
        display: 'none',
    },
});

export default withStyles(styles)(FabContainer);
