import React from 'react';
import { withRouter } from "react-router";
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Axios from 'axios';
import Typography from '@material-ui/core/Typography';

import { DialogContent, DialogTitle } from "../shared/Dialog";
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import Button from '@material-ui/core/Button';
import Paper from "@material-ui/core/Paper";

import DataComponent from "../shared/DataComponent";
import NCForm from '../nc-form/NCForm';
import { arraySum, debounce, getItemFromArrayByAttribute, getItemIndexFromArrayByAttribute, getItemsFromArrayByAttribute, isNumber, } from "../shared/Helpers";
import { blue, green, purple, red, yellow } from '@material-ui/core/colors';

import List from "@material-ui/core/List";
import User from "../auth/User";
import PollComments from "../nc-poll/PollComments";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Box from "@material-ui/core/Box";
import MuiExpansionPanel from '@material-ui/core/Accordion';
import MuiExpansionPanelSummary from '@material-ui/core/AccordionSummary';
import Question from "../../models/Question";
import Badge from "@material-ui/core/Badge";
import FabContainer from "../shared/FabContainer";
import FabButton from "../shared/FabButton";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import ConfirmDialog from "../shared/ConfirmDialog";
import MuseumViewPollSection from "./MuseumViewPollSection";
import MuseumPollContext from "./MuseumPollContext";
import { Tooltip } from "@material-ui/core";

class MuseumViewPoll extends DataComponent {
    static contextType = MuseumPollContext;
    static permissions = ['view-museum-polls'];
    id = this.props.match.params.id;
    url = '/museum-polls/' + this.id;
    answersData = {};
    answersCounts = [];
    questionsCounts = [];
    containerRef = React.createRef();
    legend = '';

    constructor(props) {
        super(props);

        this.state.sectionsLocked = {};
        this.state.permissionsTrigger = false;
        this.state.dialogConfigurationOpen = false;
        this.state.legendDialog = false;
        this.state.totalCommentsToSend = 0;
        this.state.updateKey = '' + -new Date();
        this.state.activeSectionKey = null;
        this.state.expandedSections = {};
        this.state.showScroll = false;

        this.state.totalAnsweredCount = null;
        this.state.totalQuestionsCount = null;
        this.state.onlyCommented = false;
    }

    componentDidMount() {
        super.componentDidMount();
        window.addEventListener('scroll', this.handleScroll);
    }

    handleScroll = debounce(() => {
        if (this.containerRef.current) {
            this.setState({ showScroll: this.containerRef.current.offsetTop - 67 < window.scrollY });
        } else {
            // container is gone, probably page has changed
            window.removeEventListener('scroll', this.handleScroll);
        }
    });

    onDataLoaded() {
        this.checkLocks();
    }

    cancelLocksRequest = () => {}; // safety feature to avoid concurrent requests
    checkLocks = debounce((activeLocks = {}) => {
        this.cancelLocksRequest();
        Axios.put('/museum-polls/' + this.id + '/locks', { locks: activeLocks }, {
            timeout: 70000,
            cancelToken: new Axios.CancelToken((c) => {
                this.cancelLocksRequest = c;
            }),
        }).then((response) => {
            const { locks, sections } = response.data;
            let data = this.state.data;
            if (Object.keys(locks).length || Object.keys(this.state.sectionsLocked).length) {
                sections.forEach((section) => {
                    if (!section) return;
                    let index = getItemIndexFromArrayByAttribute(data.sections, section.id);
                    section.updateKey = '' + -new Date();
                    data.sections[index] = section;
                });
                this.setState({ sectionsLocked: locks || {}, data }, () => this.checkLocks(locks));
            } else {
                this.checkLocks(locks);
            }
        }).catch((error) => {
            if (error && error.response) {
                // TODO: handle more errors
                switch (error && error.response.status) {
                    case 401: // logged out
                        break;
                    default:
                        this.checkLocks();
                }
            } else {
                this.checkLocks();
            }
        });
    }, 2000);

    processData = (data) => {
        let workersOptions = [];
        data.workers.forEach((user, userKey) => {
            workersOptions.push({
                value: user.id,
                text: user.full_name,
            });
        });

        let aggregates = { 'lt': {}, 'eq': {}, 'gt': {} };
        let expandedSections = {};
        let sections = [];
        let activeSectionKey = this.state.activeSectionKey;
        data.sections.forEach((section, sectionKey) => {
            section.isVisible = this.canEditSection(data, section);
            if (activeSectionKey === null && this.canEditSection(data, section)) {
                activeSectionKey = sectionKey;
            }
            sections[sectionKey] = section;
            const sectionCounts = data.sections_counts[section.id] || {};
            this.answersCounts[sectionKey] = sectionCounts.answers || 0;
            this.questionsCounts[sectionKey] = sectionCounts.questions || 0;
            expandedSections[sectionKey] = true;

            section.poll_questions.forEach((question, questionKey) => {
                const parentId = question.conditions && question.conditions[0].question_id ? question.conditions[0].question_id : null;
                if (parentId) {
                    if (this.answersData[parentId]) {
                        this.answersData[parentId].childIds.push(question.id); // parent is set, so just add a child
                    } else {
                        this.answersData[parentId] = { childIds: [question.id] }; // parent not set, create dummy to keep children
                    }
                }
                this.answersData[question.id] = {
                    isVisible: !question.conditions,
                    conditions: question.conditions,
                    aggregates: question.aggregates || {},
                    aggregatedBy: { 'lt': [], 'eq': [], 'gt': [] },
                    name: question.name,
                    type: question.type,
                    answer: question.type === 'file' ? question.answer.length > 0 : question.answer,
                    sectionKey,
                    parentId, // parent question ID
                    childIds: this.answersData[question.id] ? this.answersData[question.id].childIds : [],
                    number: 0,
                };
                if (question.aggregates) {
                    Question.aggregateTypes.forEach(type => {
                        (question.aggregates[type] || []).forEach(qId => {
                            if (!aggregates[type][qId]) {
                                aggregates[type][qId] = [];
                            }
                            aggregates[type][qId].push(question.id);
                        });
                    });
                }
            });
        });
        data.sections = sections;

        Question.aggregateTypes.forEach(type => {
            Object.entries(aggregates[type]).forEach(([questionId, related]) => {
                this.answersData[questionId].aggregatedBy[type].push(...related);
            });
        });

        this.processAnswers(false, data);

        this.context.setState({ museumPoll: data });

        return {
            data,
            visibleSectionsCount: data.sections.filter(v => v.isVisible).length,
            activeSectionKey,
            expandedSections,
            workersOptions,
        };
    };

    canEditSection = (data, section) => {
        const userSections = getItemsFromArrayByAttribute(data.permissions, User.getUserId(), 'user_id');
        const canEditPoll = !!getItemFromArrayByAttribute(userSections, null, 'section_id');
        const isMuseumAdmin = User.getMuseumId() === data.museum_id && User.hasPermissionTo('edit-museum-poll-permissions');

        return User.hasAnyPermission('verify-museum-polls', 'send-museum-polls')
            || !!getItemFromArrayByAttribute(userSections, section ? section.id : false, 'section_id')
            || canEditPoll
            || isMuseumAdmin;
    };

    validateHandler = (question, answer) => {
        const questionId = question.id;
        if (question.type === 'number') {
            const { classes } = this.props;
            const answerData = this.answersData[questionId];
            try {
                Object.entries(answerData.aggregates).forEach(([type, aggregates]) => {
                    if (aggregates && aggregates.length) {
                        let sum = 0;
                        let count = 0;
                        aggregates.forEach(id => {
                            const answerValue = this.answersData[id].answer;
                            sum += +answerValue || 0;
                            if (answerValue && answerValue.length) {
                                count++;
                            }
                        });

                        if (count !== aggregates.length) {
                            return; // don't compare values without all answers
                        }

                        let condition;
                        let conditionText;
                        switch (type) {
                            case 'lt':
                                condition = +answer > sum;
                                conditionText = 'większa';
                                break;
                            case 'eq':
                                condition = +answer !== sum;
                                conditionText = 'inna';
                                break;
                            case 'gt':
                                condition = +answer < sum;
                                conditionText = 'mniejsza';
                                break;
                        }

                        if (answer.length && condition) {
                            throw <>Podana wartość "{answer}" nie może być {conditionText} niż {aggregates.length > 1 ? 'suma wartości podanych' : 'wartość podana'} w:
                                <ul className={classes.warningList}>
                                    {aggregates.map((id, i) =>
                                        <li key={i} dangerouslySetInnerHTML={{ __html: this.answersData[id].name }}/>
                                    )}
                                    {/*dziale "{this.state.data.sections[answerData.sectionKey].name}", pytaniu*/}
                                </ul>
                            </>;
                        }
                    }
                });

                Question.aggregateTypes.forEach(type => {
                    if (answerData.aggregatedBy[type].length) {
                        let messages = [];
                        answerData.aggregatedBy[type].forEach(id => {
                            let sum = 0;
                            let count = 0;
                            const aggregatedData = this.answersData[id];
                            aggregatedData.aggregates[type].forEach(id2 => {
                                const answerValue = id2 === questionId ? answer : this.answersData[id2].answer;
                                sum += +answerValue;
                                if (answerValue && answerValue.length) {
                                    count++;
                                }
                            });

                            if (count !== aggregatedData.aggregates[type].length) {
                                return; // don't compare values without all answers
                            }

                            let condition;
                            let conditionText;
                            const aggregatedAnswer = aggregatedData.answer || '';
                            switch (type) {
                                case 'lt':
                                    condition = +aggregatedAnswer > sum;
                                    conditionText = 'mniejsza';
                                    break;
                                case 'eq':
                                    if (count !== aggregatedData.aggregates[type].length) {
                                        return; // don't compare values without all answers
                                    }
                                    condition = +aggregatedAnswer !== sum;
                                    conditionText = 'inna';
                                    break;
                                case 'gt':
                                    condition = +aggregatedAnswer < sum;
                                    conditionText = 'większa';
                                    break;
                            }

                            if (aggregatedAnswer.length && condition) {
                                messages.push(<>{aggregatedData.aggregates[type].length > 1 ? 'Suma wartości podanych' : 'Wartość podana'} w:
                                    <ul className={classes.warningList}>
                                        {aggregatedData.aggregates[type].map((id2, i) =>
                                            <li key={i}>
                                                {/*dziale "{this.state.data.sections[this.answersData[id2].sectionKey].name}", pytaniu*/}
                                                {this.answersData[id2].name}
                                            </li>
                                        )}
                                    </ul>
                                    nie może być {conditionText} niż wartość w 
                                    {/*dziale "{this.state.data.sections[aggregatedData.sectionKey].name}",*/}
                                    pytaniu "{aggregatedData.name}"
                                </>);
                            }
                        });

                        if (messages.length) {
                            throw messages.map((m, i) => <>{m}<br/></>);
                        }
                    }
                });
            } catch (output) {
                return output;
            }
        }

        return true;
    };

    updateHandler = (reload = false) => {
        if (!this.state.totalAnsweredCount || reload) {
            Axios.get(this.url).then((response) => {
                this.setState({ // reload saved comments, etc
                    data: response.data,
                    updateKey: '' + -new Date(),
                });
            }).catch(this.catchErrors);
        } else {
            this.setState({
                updateKey: '' + -new Date(),
            });
        }
    };

    answerHandler = sectionKey => (questionKey, question_id) => (isAnswered, answer, details) => {
        this.answersData[question_id].answer = answer;
        this.answersData[question_id].isAnswered = isAnswered;
        const index = getItemIndexFromArrayByAttribute(this.state.data.sections[sectionKey].poll_questions, question_id, 'question_id');
        this.state.data.sections[sectionKey].poll_questions[index].answer = answer;
        this.state.data.sections[sectionKey].poll_questions[index].answer_details = details;

        this.updateAnsweredCount();
    };

    updateAnsweredCount = debounce(() => {
        this.processAnswers(true);
    });

    setPollState = (state, section_id = null) => () => {
        Axios.post('/museum-polls/' + this.id + '/set-state', { state, section_id }).then((response) => {
            this.updateHandler(true);
        }).catch(this.catchErrors);
    };

    updatePermissions = (state) => {
        Axios.post('/museum-polls/' + this.id + '/update-permissions', {
            section_id: this.state.currentSectionID,
            permissions: state.user_id,
            user_sections: state.user_sections,
        }).then((response) => {
            this.closeDialogs();
        }).catch(this.catchErrors);
    };

    openDialog = (sectionID) => event => {
        event.stopPropagation();

        Axios.get('/museum-polls/' + this.id + '/get-permissions/' + (sectionID !== false ? sectionID : 0)).then((response) => {
            let values = this.state.values;
            let permissions = {};
            response.data.forEach(id => permissions[id] = true);
            values['sectionPermissions'] = permissions;
            values['user_sections'] = {};
            this.setState({ values: values, dialogConfigurationOpen: true, currentSectionID: sectionID });
        });
    };

    openLegendDialog = () => {
        if (this.legend) {
            this.setState({ legendDialog: true });
        } else {
            Axios.get('/museum-polls/legend').then(response => {
                this.legend = response.data;
                this.setState({ legendDialog: true });
            });
        }
    };

    closeDialogs = () => {
        this.setState({ dialogConfigurationOpen: false, legendDialog: false });
    };

    toggleOnlyCommented = () => {
        let { data, onlyCommented } = this.state;
        let expandedQuestions = {};
        data.sections.forEach(section => {
            if (section === null) return;
            section.poll_questions.forEach(question => {
                expandedQuestions[question.id] = onlyCommented ? true : !!question.comments.length;
            });
        });
        this.setState({ onlyCommented: !onlyCommented });
        this.context.setState({ onlyCommented, expandedQuestions }, () => this.forceUpdate());
    };

    downloadPdf = (isFilled) => () => {
        let excluded = [];

        if (isFilled)
        {
            Object.entries(this.answersData).forEach(([questionId, data]) => {
                if (!data.isVisible) {
                    excluded.push(questionId);
                }
            });
        }

        let url = isFilled ? '/museum-polls/' + this.id + '/download-filled-pdf' : '/museum-polls/' + this.id + '/download-empty-pdf';

        Axios({
            url: url,
            method: 'POST',
            responseType: 'blob',
            data : {
                excluded: excluded
            }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', isFilled ? 'ankieta.pdf' : 'pusta-ankieta.pdf');
            document.body.appendChild(link);
            link.click();
        });
    };

    getPermissionFields = () => {
        let fields = [
            {
                name: 'user_id',
                type: 'multicheckbox',
                multiple: true,
                width: 470,
                label: 'Użytkownicy, którzy mają uprawnienia do wypełniania:',
                options: this.state.workersOptions,
            }
        ];

        if (this.state.currentSectionID) {
            fields.push({
                name: 'user_sections',
                type: 'multicheckbox',
                multiple: true,
                width: 470,
                label: 'Zastosuj powyższe uprawnienia dla tego działu w pozostałych oddziałach dla:',
                options: this.state.workersOptions,
            });
        }

        return fields;
    };

    processAnswers = (setState = false, data) => {
        if (!data) {
            // we need active section to properly set numbers
            data = this.state.data;
        }
        const { activeSectionKey, totalAnsweredCount, totalQuestionsCount } = this.state;
        this.answersCounts[activeSectionKey] = 0;
        this.questionsCounts.fill(0);

        Object.entries(this.answersData).forEach(([questionId, answerData]) => {
            if (answerData.conditions) {
                answerData.isVisible = answerData.conditions ? this.resolveConditions(answerData.conditions) : true;
            }
            if (!answerData.isVisible) {
                return;
            }

            this.questionsCounts[answerData.sectionKey]++;
            if (answerData.isAnswered && activeSectionKey === answerData.sectionKey) {
                this.answersCounts[answerData.sectionKey]++;
            }
        });

        const resolveChildNumbers = (childIds, parentNumber) => {
            if (childIds.length) {
                let childNumber = 0;
                childIds.forEach(childId => {
                    if (this.answersData[childId].sectionKey === activeSectionKey) {
                        if (!this.answersData[childId].isVisible) {
                            return;
                        }
                        this.answersData[childId].number = parentNumber + '.' + (++childNumber);
                        resolveChildNumbers(this.answersData[childId].childIds || [], this.answersData[childId].number)
                    }
                });
            }
        };

        data.sections.forEach(section => {
            if (section === null) return;
            let number = 0;
            section.poll_questions.forEach(question => {
                const answerData = this.answersData[question.id];
                if (!answerData.isVisible) {
                    return;
                }
                if (!answerData.parentId) {
                    answerData.number = ++number;
                    resolveChildNumbers(answerData.childIds || [], number);
                }
            });
        });

        if (setState) {
            let data = {
                totalAnsweredCount: 0,
                totalQuestionsCount: arraySum(this.questionsCounts),
                sections_counts: {},
            };
            this.state.data.sections.forEach((section, sectionKey) => {
                if (!section) return;
                data.totalAnsweredCount += this.questionsCounts[sectionKey] ? this.answersCounts[sectionKey] : 0;
                // already filtered by permissions
                data.sections_counts[section.id] = {
                    answers: this.answersCounts[sectionKey],
                    questions: this.questionsCounts[sectionKey],
                };
            });
            const doQuery = totalAnsweredCount !== data.totalAnsweredCount || totalQuestionsCount !== data.totalQuestionsCount;
            this.setState(data); // set state first to speed up the user experience
            if (doQuery) { // execute query only when needed
                Axios.put('/museum-polls/' + this.id + '/counts', data, { timeout: 70000 });
            }
        }
    };

    resolveConditions = (conditions) => {
        const resolveArray = (conditions) => {
            let bool = false, operator;
            conditions.forEach((item, key) => {
                let result;
                if (!!item.children) {
                    result = resolveArray(item.children);
                } else {
                    result = resolveItem(item);
                }

                if (!key) {
                    bool = result;
                } else if (operator === 'or') {
                    bool = bool || result;
                } else if (operator === 'and') {
                    bool = bool && result;
                }
                operator = item.operator;
            });

            return bool;
        };

        const compareValues = (v1, v2, comparison) => {
            if (isNumber(v1) && isNumber(v2)) {
                v1 = +v1;
                v2 = +v2;
            }

            switch (comparison) {
                case '!=':
                    if (typeof v1 === "object"){
                        return !(v2 in v1);
                    } else {
                        return (v1 !== undefined && v1 !== null && (v1 + '').length) && v1 !== v2;
                    }
                case '>':  return v1 > v2;
                case '>=': return v1 >= v2;
                case '<':  return v1 < v2;
                case '<=': return v1 <= v2;
                case 'LIKE':     return v1.search(new RegExp(v2, "i")) !== -1;
                case 'NOT LIKE': return v1.search(new RegExp(v2, "i")) === -1;
                case '=':
                default:
                    if (typeof v1 === "object" && !Array.isArray(v1) && v1 !== null) {
                        return v2 in v1;
                    } else {
                        return v1 === v2;
                    }
            }
        };

        const resolveItem = (item) => {
            const data = this.answersData[item.question_id];
            if (!data || !data.isVisible) { // hidden questions shall always return false
                return false;
            }
            const { answer, type } = data;
            const { comparison, value, cell } = item;

            if (['choice_single', 'choice_multiple', 'choice_fixed'].includes(type)) {
                if (!answer || !Object.keys(answer).length) return false;
                const output = Object.keys(answer).map(v => v.replace(/<[^>]+>/g, '')).includes(value.replace(/<[^>]+>/g, ''));
                switch (comparison) {
                    case '=': return output;
                    case '!=': return !output;
                }
            }
            if (cell) { // static_table
                return !answer || !answer.length ? false : compareValues(answer[cell.y][cell.x]?.value ?? answer[cell.y][cell.x], value, comparison);
            }

            return compareValues(answer, value, comparison);
        };

        return resolveArray(conditions);
    };

    scrollTop = () => {
        if (this.containerRef.current) {
            window.scrollTo({
                top: this.containerRef.current.offsetTop - 67,
                behavior: 'smooth',
            });
        }
    };

    changeSection = (sectionKey) => () => {
        this.setState({ activeSectionKey: sectionKey }, this.scrollTop);
    };

    render() {
        if (this.state.isLoading) {
            return this.showLoader();
        }
        const { classes } = this.props;
        const { isSaving = false } = this.context.getState();
        const { data, sectionsLocked, visibleSectionsCount, totalAnsweredCount, totalQuestionsCount, updateKey, activeSectionKey, showScroll, onlyCommented } = this.state;
        const { is_started, has_ended, deadline_reached } = data;

        const userSections = getItemsFromArrayByAttribute(data.permissions, User.getUserId(), 'user_id');
        const activeSectionId = data.sections[activeSectionKey] ? data.sections[activeSectionKey].id : null;

        let isPollLocked = false;
        if (User.hasPermissionTo('verify-museum-polls')) {
            // NIMOZ admins have unlimited powah!
        } else if (User.hasPermissionTo('send-museum-polls')) {
            // Museum admins
            isPollLocked = ['sent', 'accepted'].includes(data.state);
            if (!is_started || deadline_reached || (has_ended && ['new', 'filling'].includes(data.state))) {
                isPollLocked = true;
            }
        } else if (User.hasPermissionTo('fill-museum-polls') && (userSections.length || User.hasPermissionTo('edit-museum-poll-permissions'))) {
            // Museum employees
            isPollLocked = ['sent', 'accepted'].includes(data.state);
            if (!is_started || deadline_reached || (has_ended && ['new', 'filling'].includes(data.state))) {
                isPollLocked = true;
            }
        } else {
            isPollLocked = true;
        }

        return <>
            {this.state.dialogConfigurationOpen && (
                <Dialog open={true} onClose={this.closeDialogs} maxWidth="sm">
                    <DialogTitle onClose={this.closeDialogs}>Ustawienia uprawnień</DialogTitle>
                    <DialogContent>
                        <NCForm
                            title=""
                            fields={this.getPermissionFields()}
                            values={{
                                'user_id': this.state.values['sectionPermissions'],
                                'user_sections': this.state.values['user_sections'],
                            }}
                            errors={this.getErrors()}
                            saveHandler={this.updatePermissions}
                            saveTrigger={this.state.permissionsTrigger}
                            // saveButtonLabel="Zapisz"
                            hideSubmitButton
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({ permissionsTrigger: true })} color="primary" variant="contained">Zapisz</Button>
                    </DialogActions>
                </Dialog>
            )}

            {this.state.legendDialog && (
                <ConfirmDialog
                    title="Legenda"
                    open={true}
                    onClose={this.closeDialogs}
                    onConfirm={this.closeDialogs}
                    cancelTitle={false}
                    confirmTitle={false}
                    dialogProps={{
                        maxWidth: 'lg',
                    }}
                >
                    <Typography component="div" dangerouslySetInnerHTML={{ __html: this.legend }} style={{ minWidth: 840 }}/>
                </ConfirmDialog>
            )}

            <Paper className={classes.root}>
                <Box display="flex">
                    <Box flexGrow={1}>
                        <Typography variant="h4" component="h1" style={{ position: 'relative' }}>
                            Ankieta: {data.name}
                            {User.hasPermissionTo('edit-museum-poll-permissions') && (
                                User.getMuseumId() === data.museum_id || User.hasAnyPermission('verify-museum-polls', 'send-museum-polls')
                            ) && (
                                <IconButton aria-label="Close" className={classes.closeButton} onClick={this.openDialog(false)}>
                                    <SettingsIcon/>
                                </IconButton>
                            )}
                            {/*{!!totalQuestionsCount && (*/}
                            {/*    <Tooltip title="Udzielonych odpowiedzi">*/}
                            {/*        <Typography component='span' variant="h4" className={classes.questionsCount}>*/}
                            {/*            {totalAnsweredCount} / {totalQuestionsCount}*/}
                            {/*        </Typography>*/}
                            {/*    </Tooltip>*/}
                            {/*)}*/}
                        </Typography>
                        <Typography variant="h5" component="span" style={{ position: 'relative' }}>
                            {visibleSectionsCount ? '[' + data.state_string + data.state_comment + ']' : ''}
                        </Typography>
                    </Box>
                    <Box textAlign="right">
                        <Button
                            size="medium"
                            className={classes.legendButton}
                            onClick={this.openLegendDialog}
                            variant="contained"
                            color="primary"
                        >Zobacz legendę</Button><br/>
                        <Tooltip title={onlyCommented ? 'Rozwiń wszystkie pytania' : 'Zwiń wszystkie pytania z wyjątkiem tych które posiadają komentarze'}>
                            <Button
                                size="medium"
                                className={classes.onlyCommentedButton}
                                onClick={this.toggleOnlyCommented}
                                variant="contained"
                                color="primary"
                            ><ImportExportIcon className={classes.onlyCommentedIcon} /> {onlyCommented ? <>Wszystkie<br/>pytania</> : <>Tylko pytania<br/>z komentarzem</>}</Button>
                        </Tooltip>
                    </Box>
                </Box>
                <Typography variant="h5" component="h2" style={{ position: 'relative' }}>
                    Muzeum: {data.museum.parent ? data.museum.parent.name + ', oddział: ' + data.museum.name : data.museum.name}
                </Typography>
                <div style={{ display: 'flex' }}>
                    <Typography className={classes.panelMain} component='div' dangerouslySetInnerHTML={{ __html: data.description }}/>
                    <List className={classes.panelComments} disablePadding onClick={(e) => e.stopPropagation()}>
                        <PollComments
                            key={data.id + updateKey}
                            updateKey={updateKey}
                            comments={data.comments}
                            museumPollId={data.id}
                            commentableId={data.id}
                            commentableType="MuseumPoll"
                            updateHandler={this.updateHandler}
                            isLocked={isPollLocked}
                            unlockComments={User.hasPermissionTo('verify-museum-polls') && data.state === 'sent'}
                        />
                    </List>
                </div>

                {visibleSectionsCount === 0 ? (
                    <Typography variant="h6" align="center" className={classes.noPermissions}>
                        Żadna część tej ankiety nie została przydzielona do Ciebie.<br/>
                        W razie wątpliwości skontaktuj się z administratorem muzeum.
                    </Typography>
                ) : (
                    <div ref={this.containerRef}>
                        {visibleSectionsCount > 1 && (
                            <Box boxShadow={1} className={classes.stepperBox}>
                                <Stepper nonLinear alternativeLabel activeStep={activeSectionKey} className={classes.stepper}>
                                    {data.sections.map((section, sectionKey) => {
                                        if (!this.canEditSection(data, section) || !section || !this.questionsCounts[sectionKey]) {
                                            return;
                                        }
                                        let iconClass = null;
                                        switch (true) {
                                            case !!sectionsLocked[section.id]:
                                                iconClass = classes.colorChanging;
                                                break;
                                            case this.answersCounts[sectionKey] === this.questionsCounts[sectionKey]:
                                                iconClass = classes.colorReady;
                                                break;
                                            case this.answersCounts[sectionKey] >= 1:
                                                iconClass = classes.colorFilling;
                                                break;
                                            case this.answersCounts[sectionKey] === 0:
                                                iconClass = classes.colorWaiting;
                                                break;
                                        }
                                        return <Step key={sectionKey}>
                                            <StepButton
                                                icon={
                                                    <Badge badgeContent={this.answersCounts[sectionKey] + '/' + this.questionsCounts[sectionKey]}
                                                           classes={{ badge: classNames(iconClass, classes.stepBadge) }}
                                                    />
                                                }
                                                disableRipple
                                                onClick={this.changeSection(sectionKey)}
                                            >
                                                {section.name}
                                            </StepButton>
                                        </Step>
                                    })}
                                </Stepper>
                            </Box>
                        )}
                        <MuseumViewPollSection
                            key={activeSectionKey}
                            updateKey={updateKey}
                            section={data.sections[activeSectionKey]}
                            museumPoll={data}
                            answersData={this.answersData}
                            lockedBy={sectionsLocked[activeSectionId] || ''}
                            isPollLocked={isPollLocked}
                            answersCounts={this.answersCounts[activeSectionKey]}
                            questionsCounts={this.questionsCounts[activeSectionKey]}
                            showAnswersCounts={visibleSectionsCount === 1}
                            answerHandler={this.answerHandler(activeSectionKey)}
                            permissionsDialogHandler={this.openDialog(activeSectionId)}
                            setPollStateHandler={this.setPollState}
                            updateHandler={this.updateHandler}
                            validateHandler={this.validateHandler}
                        />
                        <div className="pollActionButtons">
                            <Button size="large" variant="contained" color="primary" className={classes.confirmButton}
                                    disabled={isSaving}
                                    onClick={this.downloadPdf(false)}>
                                Pobierz pustą ankietę
                            </Button>
                            <Button size="large" variant="contained" color="primary" className={classes.confirmButton}
                                    onClick={this.downloadPdf(true)}
                                    disabled={totalAnsweredCount === 0 || isSaving}>
                                Pobierz wypełnioną ankietę
                            </Button>
                            {User.hasPermissionTo('verify-museum-polls') ? (<>
                                {data.state === 'accepted' ? (
                                    <Button size="large" onClick={this.setPollState('needs_correction')} variant="contained" color="primary"
                                            className={classes.confirmButton}>
                                        Odblokuj
                                    </Button>
                                ) : (
                                    <Button size="large" onClick={this.setPollState('needs_correction')} variant="contained" color="primary"
                                            className={classes.confirmButton}
                                            disabled={isPollLocked || isSaving || deadline_reached}>
                                        Odeślij do korekty
                                    </Button>
                                )}
                                <Button id="poll-accept" size="large" onClick={this.setPollState('accepted')} variant="contained" color="primary"
                                        className={classes.confirmButton}
                                        disabled={data.state === 'accepted' || isSaving}
                                >
                                    Zaakceptuj ankietę
                                </Button>
                            </>) : (
                                User.hasPermissionTo('send-museum-polls') && (
                                    <Button size="large" variant="contained" color="primary" className={classes.confirmButton}
                                            onClick={this.setPollState('sent')}
                                            disabled={isPollLocked || isSaving || !is_started || totalAnsweredCount !== totalQuestionsCount || !!Object.keys(sectionsLocked).length}>
                                        Złóż ankietę
                                    </Button>
                                )
                            )}
                        </div>
                    </div>
                )}
            </Paper>

            <FabContainer isVisible={showScroll}>
                <FabButton size="small" title="Wróć do początku działu" onClick={this.scrollTop} color="primary"><ArrowUpwardIcon/></FabButton>
            </FabContainer>
        </>
    }
}

const ExpansionPanel = withStyles({
    root: {
        width: '100%',
    },
    expanded: {
        margin: 0
    },
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
    root: {
        minHeight: 0,
        '&$expanded': {
            minHeight: 0,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiExpansionPanelSummary);

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    stepper: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: theme.spacing(1),
        backgroundColor: '#fff',
    },
    stepperBox: {
        top: 64,
        zIndex: 100,
        position: 'sticky',
    },
    addButton: {
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 20,
        left: 'auto',
        position: 'fixed',
    },
    textArea: {
        flex: 12,
        width: 800
    },
    button: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(),
    },
    actionsContainer: {
        marginBottom: theme.spacing(),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    heading: {
        flexBasis: '100%',
        // fontSize: theme.typography.pxToRem(15),
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    ownerName: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        marginTop: 5
    },
    panelDetails: {
        display: 'block',
    },
    panelMain: {
        flexBasis: '70%',
    },
    panelComments: {
        flexBasis: '30%',
    },
    panel: {
        width: '100%',
        margin: '16px 0',
    },
    ownerInfo: {
        lineHeight: 'normal',
    },
    ownerAvatar: {
        backgroundColor: purple[400],
    },
    table: {
        width: '100%',
    },
    badge: {
        marginLeft: 10
    },
    menuIcon: {
        marginRight: 10
    },
    addCommentIcon: {
        cursor: 'pointer'
    },
    confirmButton: {
        marginTop: 10,
        clear: 'both',
        marginRight: 10
    },
    legendButton: {
        whiteSpace: 'nowrap',
    },
    onlyCommentedButton: {
        marginTop: theme.spacing(1.5),
    },
    onlyCommentedIcon: {
        marginRight: theme.spacing(1),
    },
    questionsCount: {
        position: 'absolute',
        top: 0,
        right: 0,
    },
    stepBadge: {
        transform: 'none',
        position: 'relative',
        fontSize: 'inherit',
        height: theme.spacing(3),
        padding: 8,
    },
    colorWaiting: {
        backgroundColor: blue[200],
    },
    colorFilling: {
        backgroundColor: yellow[200],
    },
    colorReady: {
        backgroundColor: green[200],
    },
    colorChanging: {
        backgroundColor: red[200],
    },
    warningList: {
        paddingLeft: theme.spacing(2),
    },
    noPermissions: {
        marginTop: theme.spacing(2),
        fontStyle: 'italic',
    },
});

export default withStyles(styles)(withRouter(MuseumViewPoll));
