import React from 'react';
import Axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import NCForm from '../nc-form/NCForm';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import User from "../auth/User";
import DataComponent from "../shared/DataComponent";
import FabContainer from "../shared/FabContainer";
import PaperRoot from "../shared/PaperRoot";
import ConfirmDialog from "../shared/ConfirmDialog";
import FabButton, { FabLink } from "../shared/FabButton";

class CatalogEdit extends DataComponent {
    static permissions = ['edit-catalogs'];
    catalog_id = this.props.match.params.catalog_id;
    parent_id = this.props.match.params.parent_id;

    constructor(props) {
        super(props);

        this.state.moveTo = "";
        this.state.catalogs = [];

        if (this.catalog_id) {
            this.url = '/catalogs/' + this.catalog_id;
        } else {
            this.state.isLoading = false;
        }
    }

    processData = (data) => {
        let output = { data };
        if (data.catalogs) {
            output.catalogs = data.catalogs;
        }
        return output;
    };

    saveObject = () => {
        if (this.hasObject()) {
            Axios.put(this.url, this.getValues()).then(response => {
                // this.reloadStateData(response.data, { errors: {} });
                this.props.history.push('/catalogs/list');
            }).catch(this.catchErrors);
        } else {
            Axios.post('/catalogs', { ...this.getValues(), parent_id: this.parent_id }).then(response => {
                // this.props.history.push('/catalogs/' + response.data.object.id + '/edit');
                this.props.history.push('/catalogs/list');
            }).catch(this.catchErrors);
        }
    };

    deleteObject = () => {
        Axios.delete(this.url, { data: { moveTo: this.state.moveTo } }).then(response => {
            this.props.history.push('/catalogs/list');
        }).catch(this.catchErrors);
    };

    moveQuestionsSelect = (event, child) => {
        this.setState({ moveTo: child.props.value });
    };

    closeDeleteDialog = () => {
        this.setState({ deleteDialog: false, moveTo: "" });
    };

    getTitle = () => {
        return this.hasObject() ? "Edycja katalogu: " + this.getObject('name') : 'Dodaj katalog';
    };

    render() {
        if (this.state.isLoading) {
            return this.showLoader();
        }
        const { classes } = this.props;
        const { catalogs, moveTo } = this.state;

        const fields = [
            {
                name: 'name',
                type: 'text',
                label: 'Nazwa katalogu',
                width: 400,
            }
        ];

        return <>
            <PaperRoot>
                <NCForm
                    fields={fields}
                    title={this.getTitle()}
                    values={this.getValues()}
                    errors={this.getErrors()}
                    onChange={this.changeHandler}
                    hideSubmitButton
                    // saveHandler={this.saveObject}
                />
            </PaperRoot>

            <FabContainer>
                <FabLink title="Wróć" to="/catalogs/list" color="default"><ArrowBackIcon/></FabLink>
                {User.hasPermissionTo('edit-catalogs') && <>
                    {this.renderDeleteButton()}
                    <FabButton title="Zapisz" onClick={this.saveObject} color="primary"><SaveIcon/></FabButton>
                </>}
            </FabContainer>

            <ConfirmDialog open={this.state.deleteDialog} onClose={this.closeDeleteDialog} onConfirm={this.deleteObject} isContentText={false} actions={<>
                <Button variant="outlined" onClick={this.closeDeleteDialog} color="default">Anuluj</Button>
                <Button variant="contained" disabled={!moveTo} onClick={this.deleteObject} color="secondary">Usuń i przenieś pytania</Button>
                <Button variant="contained" disabled={!!moveTo} onClick={this.deleteObject} color="secondary">Usuń z pytaniami</Button>
            </>}>
                <DialogContentText>
                    Czy na pewno chcesz usunąć ten katalog?<br/><br/>
                    Jeśli w katalogu są jakieś pytania możesz wybrać katalog, do którego je przenieść:
                </DialogContentText>
                <Select displayEmpty value={moveTo} onChange={this.moveQuestionsSelect} className={classes.selectField}>
                    <MenuItem key="" value=""><em>Nie przenoś</em></MenuItem>
                    {catalogs.map((item, key) => <MenuItem key={key} value={item.id}>{item.name}</MenuItem>)}
                </Select>
            </ConfirmDialog>
        </>
    }
}

const styles = theme => ({
    selectField: {
        marginTop: 0,
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(),
        width: 200,
    },
});

export default withStyles(styles)(CatalogEdit);
