import React from "react";
import PropTypes from "prop-types";
import TablePicker from "../shared/TablePicker";
import { getObjValuesFromArrayByAttribute } from "../shared/Helpers";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import TooltipIconButton from "../shared/TooltipIconButton";

class UserPicker extends TablePicker {
    url = this.props.url;
    dialogTitle = this.props.dialogTitle;
    tableTitle =  this.props.tableTitle;
    tableColumns = {
        id: 'ID',
        first_name: 'Imię',
        last_name: 'Nazwisko',
        email : 'E-mail'
    };

    constructor(props) {
        super(props);

        this.values = props.users;
        this.tableOptions.sorting = false;

        if (!props.multiple) {
            this.tableOptions.selection = false;
            this.tableColumns.actions = {
                field: 'function', title: 'Akcje', filtering: false, callback: rowData => {
                    return <TooltipIconButton title="Wybierz" onClick={this.clickHandler(rowData)}>
                        <CheckCircleIcon/>
                    </TooltipIconButton>;
                },
            };
        }
    }

    fetchTable = (url, query, resolve, counter = 0) => {
        query.notIn = {};

        const ids = getObjValuesFromArrayByAttribute(this.values);
        if (ids.length) {
            query.notIn['id'] = ids;
        }

        super.fetchTable(url, query, resolve, counter);
    };

    clickHandler = rowData => event => {
        this.props.changeHandler(rowData);
    };

    changeHandler = (save) => () => {
        this.props.changeHandler(save ? this.getValues() : (this.props.multiple ? [] : null));
        this.tableRef.current.onAllSelected(false);
    };

    renderTableDialogActions = () => {
        return <DialogActions>
            <Button onClick={this.changeHandler(false)} variant="contained">Zamknij</Button>
            {this.props.multiple && (
                <Button onClick={this.changeHandler(true)} disabled={!this.getValues().length} color="primary" variant="contained">Dodaj wybranych</Button>
            )}
        </DialogActions>
    };
}

UserPicker.propTypes = {
    users: PropTypes.array,
    url: PropTypes.string,
    changeHandler: PropTypes.func.isRequired,
    multiple: PropTypes.bool,
    dialogTitle: PropTypes.string,
    tableTitle: PropTypes.string,
};
UserPicker.defaultProps = {
    users: [],
    multiple: true,
    dialogTitle: 'Wybór użytkownika systemu',
    tableTitle: 'Dostępni użytkownicy',
};

export default UserPicker;
