import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import { DialogContent, DialogTitle } from "./Dialog";
import Axios from "axios";
import { debounce, hashCode } from "./Helpers";
import { DialogContentText } from "@material-ui/core";

const ErrorContext = React.createContext(false);

class ErrorContextProvider extends Component {
    state = {
        error: false,
        info: false,
        code: false,
    };

    componentDidCatch(error, info) {
        this.catchErrors(error, info);
    }

    catchErrors = (error, info) => {
        const code = Math.abs(hashCode([info.componentStack, error.toString()]));
        this.setState({ error, info, code });
        this.logError(error, info, code);
    };

    logError = debounce((error, info, code) => {
        Axios.put('/log', {
            code,
            message: error.toString(),
            stack: info.componentStack,
            browser: window.navigator.userAgent,
            platform: window.navigator.platform,
        }, { timeout: 10000 });
    });

    closeDialog = () => {
        this.setState({ error: false });
    };

    render() {
        const { error, info, code } = this.state;
        const { children } = this.props;
        const { Provider } = ErrorContext;
        const style = { color: 'red' };

        return <Provider value={{ catchErrors: this.catchErrors }}>
            {!!error && (
                <Dialog open={true} onClose={this.closeDialog} maxWidth="md">
                    <DialogTitle onClose={this.closeDialog}>Wystąpił nieoczekiwany błąd</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <code style={style}>Kod błędu: {code}</code>
                        </DialogContentText>
                        <code style={style}>{error.toString()}</code>
                        <pre style={style}>{info.componentStack}</pre>
                    </DialogContent>
                </Dialog>
            )}
            {children}
        </Provider>;
    }
}

export { ErrorContextProvider };
export default ErrorContext;
