import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";

function FabButton(props) {
    const { to, title, children, ...other } = props;
    if (to) {
        return <FabLink {...props} />
    }

    return <Tooltip title={title}>
        <Fab aria-label={title} {...other}>
            {children}
        </Fab>
    </Tooltip>
}

const props = {
    title: PropTypes.string.isRequired,
    to: PropTypes.string,
    color: PropTypes.oneOf([
        'default',
        'inherit',
        'primary',
        'secondary',
    ]),
    size: PropTypes.oneOf([
        'small',
        'medium',
        'large',
    ]),
    onClick: PropTypes.func,
};

FabButton.propTypes = props;

function FabLink(props) {
    const { to, ...other } = props;

    return <Link to={to}>
        <FabButton {...other} />
    </Link>
}
FabLink.propTypes = { ...props, to: PropTypes.string.isRequired };

export { FabLink };
export default FabButton;
