import React from 'react';
import BaseComponent from "../shared/BaseComponent";
import { MuseumPollContextProvider } from "./MuseumPollContext";

export default function withMuseumPollContext(ComponentToWrap) {
    return class extends BaseComponent {
        render() {
            return (
                <MuseumPollContextProvider>
                    <ComponentToWrap {...this.props}></ComponentToWrap>
                    {/*<MuseumPollContext.Consumer>*/}
                    {/*    {context => <ComponentToWrap {...this.props} context={context}></ComponentToWrap>}*/}
                    {/*</MuseumPollContext.Consumer>*/}
                </MuseumPollContextProvider>
            );
        }
    }
}
