import React from 'react';
import PropTypes from "prop-types";
import { DialogTitle } from "../shared/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import TableCellPicker from "./TableCellPicker";
import BaseComponent from "../shared/BaseComponent";

class TableCellPickerDialog extends BaseComponent {
    state = {
        cells: [],
    };

    multipleChangeHandler = cells => {
        this.setState({ cells });
    };

    addMultiple = () => {
        this.props.changeHandler(this.state.cells);
    };

    render() {
        const { question, changeHandler, multiple } = this.props;
        const { cells } = this.state;

        return <Dialog maxWidth="md" open={true} onClose={() => changeHandler(false)}>
            <DialogTitle onClose={() => changeHandler(false)}>Wybierz komórkę: {question.name_string}</DialogTitle>
            <DialogContent style={{ paddingTop: 24 }}>
                <TableCellPicker
                    table={question.properties.table}
                    changeHandler={multiple ? this.multipleChangeHandler : changeHandler}
                    multiple={multiple}
                />
            </DialogContent>
            <DialogActions>
                {!!multiple && (
                    <Button onClick={this.addMultiple} variant="contained" color="primary" disabled={!cells.length}>Użyj</Button>
                )}
                <Button onClick={() => changeHandler(false)} variant="contained">Zamknij</Button>
            </DialogActions>
        </Dialog>;
    }
}

TableCellPickerDialog.propTypes = {
    question: PropTypes.object.isRequired,
    changeHandler: PropTypes.func.isRequired,
    multiple: PropTypes.bool,
};
TableCellPickerDialog.defaultProps = {
    multiple: false,
};

export default TableCellPickerDialog;
