import React from 'react';
import Axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import NCForm from '../nc-form/NCForm';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { ReactSVG } from 'react-svg';

import { scaleSequential } from 'd3-scale'
import { color } from 'd3-color';
import DataTable from "../shared/DataTable";
import BaseComponent from "../shared/BaseComponent";
// import { interpolateWarm } from 'd3-scale-chromatic';

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    header: {
        marginBottom: theme.spacing(),
    },
});

class GISReport extends BaseComponent {
    state = {
        loading: true,
        mapData : [],
        values: {
            show_values: true,
            current_province : 'polska',
            show_labels: false,
            measure: 'sum',
            show_table: false,
            presetTitle: '',
            preset: false,
            previousPreset: false,
            year: null,
            question: null,
            base_color: 'rgba(255, 153, 0, 0.9)',
        },
    };

    componentDidMount() {
        let questions = this.props.params.questions.map((v) => {return { id: v.id, cell: v.cell }; });
        let polls = this.props.params.polls.map((v) => v.id);
        let museums = this.props.params.museums.map((v) => v.id);
        let conditions = this.props.params.conditions;
        const { poll, state, filling_type, filling_year, question_type, estimated_data, data, museum } = this.props.params.values;

        Axios.post('/data-reports/getGISData', {
            questions, polls, museums, state,
            poll, filling_type, filling_year, question_type, estimated_data,
            data, museum, conditions,
        }).then((response) => {
            let values = this.state.values;

            if (Object.keys(response.data.data).length > 0)
            {
                values.year = Object.keys(response.data.data)[0];
                values.question = Object.keys(response.data.data[values.year])[0];

                Object.assign(values, this.props.params.additionalData.gis);

                this.setState({
                    loading: false,
                    mapData : response.data.data,
                    values : values
                });
            } else {
                Object.assign(values, this.props.params.additionalData.gis);

                this.setState({
                    loading: false,
                    values : values
                });
            }
        });

        window.addEventListener("resize", () => {
            let wrapper = document.getElementsByClassName('map-wrapper')[0];
            if (wrapper) {
                this.showLabels(wrapper.getElementsByTagName('svg')[0]);
            }
        });
    }

    showLabels(svg) {
        let provinceOffsets = {
            'opolski': {y: -10}, 'prudnicki': {y: 20}, 'kędzierzyńsko-kozielski' : {y: 15},
            'głogowski' : {y: 7}, 'górowski' : {y: 10}, 'trzebnicki': {x: -10}, 'lwówecki' : {x: 10}, 'Jelenia Góra': {y: -11}, 'jeleniogórski': {x: -20, y: 13}, 'wrocławski': {y: 18}, 'Wrocław': {y: 5}, 'Wałbrzych': {y: 3}, 'wałbrzyski' : {y: 21, x: 25}, 'kamiennogórski': {y: 10}, 'lubiński' : {y: 20}, 'legnicki': {x: -20, y: -8},
            'mogileński' : {y: 15}, 'radziejowski' : {y:10}, 'włocławski' : {y: 20}, 'toruński': {y: -20}, 'grudziądzki' : {x: 20}, 'bydgoski' : {y: -20}, 'sępoleński' : {y: 15},
            'janowski' : {y: 10}, 'rycki' : {y: 15}, 'lubelski' : {y: 20}, 'zamojski' : {y: 20}, 'chełmski' : {x: 20, y: 30}, 'biłgorajski' : {y: 30}, 'bialski' : {y: 20}, 'radzyński' : {y: 10}, 'lubartowski' : {y: 10},
            'zielonogórski' : {y: -40}, 'nowosolski' : {y: 20}, 'sulęciński' : {x: 30}, 'gorzowski' : {y: -20, x: -20},
            'łaski' : {y: 20}, 'pabianicki' : {x: 20, y: 20}, 'piotrkowski' : {y: 40}, 'skierniewicki' : {y: 25},
            'chrzanowski' : {y: 10, x: -10}, 'oświęcimski' : {y: 10}, 'wadowicki' : {y: 10}, 'nowosądecki' : {y: 30}, 'tatrzański' : {y: 10}, 'tarnowski': {y: 30}, 'proszowicki' : {x: -25}, 'krakowski': {y: -30},
            'Warszawa' : {x: 10, y: 7}, 'pruszkowski' : {y: -10}, 'ostrołęcki' : {y: -30}, 'ciechanowski' : {y: 20}, 'wołomiński' : {y: 8}, 'żyrardowski' : {x: -10}, 'płocki' : {x: 30}, 'gostyniński' : {y: 10}, 'sierpecki' : {y: 5}, 'radomski' : {x: 7, y: 30}, 'siedlecki': {x: 30, y: -10}, 'sochaczewski' : {y: 15}, 'piaseczyński' : {y: 8}, 'kozienicki' : {x: -10},
            'przemyski' : {x: -40}, 'przeworski' : {x: -20, y: 20}, 'rzeszowski' : {y: -30, x: -20}, 'krośnieński' : {y: 20}, 'tarnobrzeski' : {y: 20},
            'łomżyński' : {x: -50}, 'białostocki' : {x: 60}, 'wysokomazowiecki' : {y: 15}, 'suwalski' : {y: -15}, 'zambrowski' : {x: -20, y: 10},
            'słupski' : {x: 30}, 'Gdynia' : {y: -10}, 'nowodworski' : {x: -15}, 'starogardzki' : {y: 20}, 'gdański' : {y: 10},
            'Chorzów' : {y: -5}, 'będziński' : {y: -5}, 'Rybnik' : {y: 10}, 'Piekary Śląskie' : {y: -10, x: -10}, 'Katowice' : {y: -5}, 'gliwicki' : {x: -20, y: -10}, 'Jastrzębie-Zdrój' : {y: 5}, 'raciborski' : {x: -20}, 'bielski' : {y: -35}, 'Żory' : {y: 5}, 'bieruńsko-lędziński' : {y: 7}, 'rybnicki' : {x: -39, y: 2}, 'Dąbrowa Górnicza' : {x: 5, y: 5}, 'Gliwice' : {x: 5, y: 5},
            'jędrzejowski' : {x: 20}, 'pińczowski' : {y: 20}, 'kazimierski' : {y: 10}, 'opatowski' : {y: 30}, 'sandomierski' : {y: 20}, 'starachowicki' : {y: 10}, 'kielecki' : {y: 30},
            'elbląski' : {y: 30}, 'bartoszycki' : {y: -10}, 'giżycki' : {y: 10}, 'mrągowski' : {y: 10}, 'olsztyński' : {y: -30},
            'poznański' : {x: -35, y: -10}, 'pilski' : {y: 10}, 'obornicki' : {y: 10}, 'szamotulski' : {y: 10, x: 20}, 'wolsztyński' : {y: 10}, 'leszczyński' : {y: -20, x: -10}, 'rawicki' : {y: 10}, 'kaliski' : {y: -10}, 'koniński' : {y: -30}
        };

        const questionData = this.getFormatedData();
        const baseColor = color(this.state.values.base_color);
        const sequentialScale = scaleSequential()
            .domain([parseInt(questionData.min), parseInt(questionData.max)])
            .range([baseColor.formatRgb(), baseColor.darker(Object.keys(questionData.data).length).formatRgb()]);

        let links = svg.getElementsByTagName('a');

        links.forEach((link) => {
            link.setAttribute('href', '#');

            let provinceName = link.getElementsByTagName('path')[0].getAttribute('rel');
            link.setAttribute('title', provinceName);
            link.setAttribute('alt', provinceName);

            let p = link.getElementsByTagName('path')[0];
            p.style.fill = '#fff';
            p.style.stroke = '#000';
            p.style.strokeWidth = '0.1';

            let baseFontSize = 16;
            if (this.state.values.current_province !== 'polska')
            {
                baseFontSize = 10;
            }

            let val;
            if (questionData.data !== undefined && questionData.data[provinceName] !== undefined)
            {
                val = questionData.data[provinceName];
                p.style.fill = sequentialScale(parseInt(val));
            } else {
                val = '';
            }

            p.provinceName = provinceName;


            link.onclick = (e) => {
                e.preventDefault();
                e.stopImmediatePropagation();

                if (this.state.values.current_province === 'polska')
                {
                    let values = this.state.values;
                    values.current_province = provinceName;

                    this.setState({values});
                }
                return false;
            };

            let offsetX = document.getElementsByClassName('MuiDialogContent-root')[0].scrollLeft, offsetY = document.getElementsByClassName('MuiDialogContent-root')[0].scrollTop;

            if (provinceOffsets[provinceName])
            {
                offsetX += provinceOffsets[provinceName].x ? provinceOffsets[provinceName].x : 0;
                offsetY += provinceOffsets[provinceName].y ? provinceOffsets[provinceName].y : 0;
            }

            if ((this.state.values.show_labels) && (!this.state.labels_hidden_temporary))
            {
                let bb = p.getBoundingClientRect();

                let t = p.parentNode.parentNode.parentNode.parentNode.getElementsByClassName('span-province-name-'+provinceName);
                if (t.length === 0)
                {
                    t = document.createElement('span');
                    p.parentNode.parentNode.parentNode.parentNode.append(t);
                } else {
                    t = t[0];
                }

                t.className = 'span-province-name-'+provinceName;
                t.innerHTML = provinceName;
                t.style.position = 'absolute';
                t.style.fontSize = baseFontSize+'px';
                t.style.top = (bb.top + bb.height/2 - parseInt(t.offsetHeight) - 5 + offsetY)+'px';
                t.style.left = (bb.left + bb.width/2 - parseInt(t.offsetWidth) / 2 + offsetX)+'px';
            }

            if (this.state.values.show_values)
            {
                let bb = p.getBoundingClientRect();

                let t = p.parentNode.parentNode.parentNode.parentNode.getElementsByClassName('span-value-'+provinceName);
                if (t.length === 0)
                {
                    t = document.createElement('span');
                    p.parentNode.parentNode.parentNode.parentNode.append(t);
                } else {
                    t = t[0];
                }

                t.innerHTML =  (val + '').length ? parseFloat(val) : '';
                t.className = 'span-value-'+provinceName;
                t.style.position = 'absolute';
                t.style.fontSize = baseFontSize+'px';
                t.style.fontWeight = 'bold';
                t.style.display = 'block';
                t.style.top = (bb.top + bb.height/2 - parseInt(t.offsetHeight) / 2 + offsetY)+'px';
                t.style.left = (bb.left + bb.width/2 - parseInt(t.offsetWidth) / 2 + offsetX)+'px';
            }
        });
    }

    changeSettings = (name, value) => {
        let values = this.state.values;
        values[name] = value;

        if (name === 'year')
        {
            values.question = Object.keys(this.state.mapData[values.year])[0];
        }

        this.setState({values});
    }

    calcSum(currentQuestionData)
    {
        let data = [];
        for(var i = 0; i < currentQuestionData.length; i++)
        {
            let currentData =  currentQuestionData[i];
            let value = isNaN(parseFloat(currentData[0])) ? 0 : parseFloat(currentData[0]);
            let province = currentData[2];
            let county = currentData[1];
            let key;

            if (this.state.values.current_province === 'polska')
            {
                key = province;
            } else {
                key = county;

                if (province !== this.state.values.current_province)
                {
                    continue;
                }
            }

            if (data[key] === undefined)
            {
                data[key] = value;
            } else {
                data[key] += value;
            }
        }
        return data;
    }

    calcPercent(currentQuestionData)
    {
        let data = [];
        let total = 0;
        let totalAreas = {};
        let key;

        for(var i = 0; i < currentQuestionData.length; i++)
        {
            let currentData =  currentQuestionData[i];
            let value = isNaN(parseFloat(currentData[0])) ? 0 : parseFloat(currentData[0]);
            let province = currentData[2];
            let county = currentData[1];

            if (this.state.values.current_province === 'polska')
            {
                key = province;
            } else {
                key = county;

                if (province !== this.state.values.current_province)
                {
                    continue;
                }
            }

            total+=value;

            if (totalAreas[key] === undefined)
            {
                totalAreas[key] = value;
            } else {
                totalAreas[key] += value;
            }
        }

        for(var area in totalAreas)
        {
            data[area] = (Math.round(parseFloat(100*totalAreas[area]/total)*100)/100)+'%';
        }

        return data;
    }

    calcAvg(currentQuestionData)
    {
        let data = [];

        let totalAreas = {};
        let key;

        for(var i = 0; i < currentQuestionData.length; i++)
        {
            let currentData =  currentQuestionData[i];
            let value = isNaN(parseFloat(currentData[0])) ? 0 : parseFloat(currentData[0]);
            let province = currentData[2];
            let county = currentData[1];

            if (this.state.values.current_province === 'polska')
            {
                key = province;
            } else {
                key = county;

                if (province !== this.state.values.current_province)
                {
                    continue;
                }
            }


            if (totalAreas[key] === undefined)
            {
                totalAreas[key] = {total: 1, value: value};
            } else {
                totalAreas[key] = {total: totalAreas[key].total+1, value: totalAreas[key].value+value};
            }
        }

        for(var area in totalAreas)
        {
            data[area] = (Math.round(totalAreas[area].value/totalAreas[area].total*100)/100);
        }

        return data;
    }

    calcQuartile(currentQuestionData, q)
    {
        let data = [];

        let totalAreas = {};
        let key;

        for(var i = 0; i < currentQuestionData.length; i++)
        {
            let currentData =  currentQuestionData[i];
            let value = isNaN(parseFloat(currentData[0])) ? 0 : parseFloat(currentData[0]);
            let province = currentData[2];
            let county = currentData[1];

            if (this.state.values.current_province === 'polska')
            {
                key = province;
            } else {
                key = county;

                if (province !== this.state.values.current_province)
                {
                    continue;
                }
            }

            if (totalAreas[key] === undefined)
            {
                totalAreas[key] = [value];
            } else {
                totalAreas[key].push(value);
            }
        }

        for(var area in totalAreas)
        {
            data[area] = this.calcQ(totalAreas[area], q);
        }

        return data;
    }

    calcQ(data, q) {
        data = this.arraySortNumbers(data);
        let pos = ((data.length) - 1) * q;
        var base = Math.floor(pos);
        var rest = pos - base;

        if( (data[base+1]!==undefined) ) {
            return data[base] + rest * (data[base+1] - data[base]);
        } else {
            return data[base];
        }
    }

    arraySortNumbers(inputarray) {
        return inputarray.sort(function(a, b) {
            return a - b;
        });
    }

    calcMin(currentQuestionData)
    {
        let data = [];
        for(var i = 0; i < currentQuestionData.length; i++)
        {
            let currentData =  currentQuestionData[i];
            let value = isNaN(parseFloat(currentData[0])) ? 0 : parseFloat(currentData[0]);
            let province = currentData[2];
            let county = currentData[1];
            let key;

            if (this.state.values.current_province === 'polska')
            {
                key = province;
            } else {
                key = county;

                if (province !== this.state.values.current_province)
                {
                    continue;
                }
            }

            if (data[key] === undefined)
            {
                data[key] = value;
            } else {
                if (data[key] > value)
                {
                    data[key] = value;
                }
            }
        }
        return data;
    }

    calcMax(currentQuestionData)
    {
        let data = [];
        for(var i = 0; i < currentQuestionData.length; i++)
        {
            let currentData =  currentQuestionData[i];
            let value = isNaN(parseFloat(currentData[0])) ? 0 : parseFloat(currentData[0]);
            let province = currentData[2];
            let county = currentData[1];
            let key;

            if (this.state.values.current_province === 'polska')
            {
                key = province;
            } else {
                key = county;

                if (province !== this.state.values.current_province)
                {
                    continue;
                }
            }

            if (data[key] === undefined)
            {
                data[key] = value;
            } else {
                if (data[key] < value)
                {
                    data[key] = value;
                }
            }
        }
        return data;
    }

    calcDominant(currentQuestionData)
    {
        let data = [];

        let totalAreas = {};
        let key;

        for(var i = 0; i < currentQuestionData.length; i++)
        {
            let currentData =  currentQuestionData[i];
            let value = isNaN(parseFloat(currentData[0])) ? 0 : parseFloat(currentData[0]);
            let province = currentData[2];
            let county = currentData[1];

            if (this.state.values.current_province === 'polska')
            {
                key = province;
            } else {
                key = county;

                if (province !== this.state.values.current_province)
                {
                    continue;
                }
            }

            if (totalAreas[key] === undefined)
            {
                totalAreas[key] = [value];
            } else {
                totalAreas[key].push(value);
            }
        }

        for(var area in totalAreas)
        {
            data[area] = this.findDominant(totalAreas[area]);
        }

        return data;
    }

    calcStandardDeviation(currentQuestionData)
    {
        let data = [];

        let totalAreas = {};
        let key;

        for(var i = 0; i < currentQuestionData.length; i++)
        {
            let currentData =  currentQuestionData[i];
            let value = isNaN(parseFloat(currentData[0])) ? 0 : parseFloat(currentData[0]);
            let province = currentData[2];
            let county = currentData[1];

            if (this.state.values.current_province === 'polska')
            {
                key = province;
            } else {
                key = county;

                if (province !== this.state.values.current_province)
                {
                    continue;
                }
            }

            if (totalAreas[key] === undefined)
            {
                totalAreas[key] = [value];
            } else {
                totalAreas[key].push(value);
            }
        }

        for(var area in totalAreas)
        {
            data[area] = Math.round(this.standardDeviation(totalAreas[area])*100)/100;
        }

        return data;
    }

    findDominant(arr) {
        let numbs = [];
        let indxs = [];


        for (let i = 0, len = arr.length; i < len; ++i) {
            let found = false;

            for (let j = 0, nlen = numbs.length; j < nlen; ++j) {
                if (arr[i] === numbs[j]) {
                    indxs[j]++;
                    found = true;
                    break;
                }
            }

            if (!found) {
                numbs.push(arr[i]);
                indxs[numbs.length-1] = 1;
            }
        }

        let max = Math.max.apply(Math, indxs);
        return numbs[indxs.findIndex(x=>x===max)];
    }

    standardDeviation(values) {
        const average = (data) => data.reduce((sum, value) => sum + value, 0) / data.length;

        const avg = average(values);
        const diffs = values.map((value) => value - avg);
        const squareDiffs = diffs.map((diff) => diff * diff);
        const avgSquareDiff = average(squareDiffs);
        return Math.sqrt(avgSquareDiff);
    };


    getFormatedData()
    {
        let data = {};

        if (!this.state.values.question || !this.state.values.year)
        {
            return [];
        }

        let currentQuestionData = this.state.mapData[this.state.values.year][this.state.values.question].data;

        switch(this.state.values.measure)
        {
            case 'sum':
                data = this.calcSum(currentQuestionData);
                break;
            case 'percent':
                data = this.calcPercent(currentQuestionData);
                break;
            case 'avg':
                data = this.calcAvg(currentQuestionData);
                break;
            case 'quartile1':
                data = this.calcQuartile(currentQuestionData, 0.25);
                break;
            case 'quartile2':
                data = this.calcQuartile(currentQuestionData, 0.5);
                break;
            case 'quartile3':
                data = this.calcQuartile(currentQuestionData, 0.75);
                break;
            case 'min':
                data = this.calcMin(currentQuestionData);
                break;
            case 'max':
                data = this.calcMax(currentQuestionData);
                break;
            case 'dominant':
                data = this.calcDominant(currentQuestionData);
                break;
            case 'standard-deviation':
                data = this.calcStandardDeviation(currentQuestionData);
                break;
        }


        var arr = Object.keys( data ).map(function ( key ) { return parseInt(data[key]); });

        return {data: data, max: Math.max.apply(Math, arr), min: Math.min.apply(Math, arr)}
    }

    render() {
        // const { classes } = this.props;

        const fields = [
            {
                name : 'current_province',
                type : 'select',
                multiple: true,
                label : 'Obszar',
                width : 400,
                options : [
                    {
                        value : 'polska',
                        text : 'Cała Polska'
                    },
                    {
                        value : 'dolnośląskie',
                        text : 'woj. Dolnośląskie'
                    },
                    {
                        value : 'kujawsko-pomorskie',
                        text : 'woj. Kujawsko-pomorskie'
                    },
                    {
                        value : 'lubelskie',
                        text : 'woj. Lubelskie'
                    },
                    {
                        value : 'lubuskie',
                        text : 'woj. Lubuskie'
                    },
                    {
                        value : 'łódzkie',
                        text : 'woj. Łódzkie'
                    },
                    {
                        value : 'małopolskie',
                        text : 'woj. Małopolskie'
                    },
                    {
                        value : 'mazowieckie',
                        text : 'woj. Mazowieckie'
                    },
                    {
                        value : 'opolskie',
                        text : 'woj. Opolskie'
                    },
                    {
                        value : 'podkarpackie',
                        text : 'woj. Podkarpackie'
                    },
                    {
                        value : 'podlaskie',
                        text : 'woj. Podlaskie'
                    },
                    {
                        value : 'pomorskie',
                        text : 'woj. Pomorskie'
                    },
                    {
                        value : 'śląskie',
                        text : 'woj. Śląskie'
                    },
                    {
                        value : 'świętokrzyskie',
                        text : 'woj. Świętokrzyskie'
                    },
                    {
                        value : 'warmińsko-mazurskie',
                        text : 'woj. Warmińsko-mazurskie'
                    },
                    {
                        value : 'wielkopolskie',
                        text : 'woj. Wielkopolskie'
                    },
                    {
                        value : 'zachodniopomorskie',
                        text : 'woj. Zachodniopomorskie'
                    }
                ]
            },
            {
                type : 'divider',
                style : {
                    flexBasis : '100%',
                }
            },
            {
                type: 'boolean',
                name: 'show_labels',
                label: 'Wyświetlaj etykiety',
            },
            {
                type : 'divider',
                style : {
                    flexBasis : '100%',
                }
            },
            {
                type: 'boolean',
                name: 'show_values',
                label: 'Wyświetlaj wartości',

            },
            {
                type : 'divider',
                style : {
                    flexBasis : '100%',
                }
            },
            {
                type: 'boolean',
                name: 'show_table',
                label: 'Wyświetlaj tabelę wartości',
            },
            {
                type : 'divider',
                style : {
                    flexBasis : '100%',
                }
            },
            {
                type: 'colorpicker',
                name: 'base_color',
                label: 'Kolor'
            },
            {
                type : 'divider',
                style : {
                    flexBasis : '100%',
                }
            },
            {
                type: 'select',
                name: 'year',
                label: 'Rok',
                width : 400,
                options: Object.keys(this.state.mapData).map((v) => {return {value: parseFloat(v), text: v};})
            },
            {
                type : 'divider',
                style : {
                    flexBasis : '100%',
                }
            },
            {
                type: 'select',
                name: 'question',
                label: 'Pytanie',
                width : 400,
                options: Object.keys(this.state.mapData).length ?
                    Object.keys(this.state.mapData[this.state.values.year]).map((v) => {
                        return {value: v, text: this.state.mapData[this.state.values.year][v].question
                    };})
                    : []
            },
            {
                type : 'divider',
                style : {
                    flexBasis : '100%',
                }
            },
            {
                type: 'select',
                name: 'measure',
                label: 'Miara',
                width : 400,
                options: [
                    {value: 'sum', text : 'suma'},
                    {value: 'percent', text : 'procent'},
                    {value: 'avg', text : 'średnia'},
                    {value: 'quartile1', text: 'pierwszy kwartyl'},
                    {value: 'quartile2', text: 'mediana'},
                    {value: 'quartile3', text: 'trzeci kwartyl'},
                    {value: 'min', text: 'wartość minimalna'},
                    {value: 'max', text: 'wartość maksymalna'},
                    {value: 'standard-deviation', text: 'odchylenie standardowe'},
                    {value: 'dominant', text: 'dominata'}
                ]
            }
        ];


        let svgFilename;
        if (this.state.values.current_province !== 'polska')
        {
            svgFilename = '/maps/'+this.state.values.current_province+'.svg';
        } else {
            svgFilename = '/maps/polska.svg';
        }


        let questionData = this.getFormatedData();

        // let sequentialScale = scaleSequential()
        //   .domain([questionData.min, questionData.max])
        //   .interpolator(interpolateWarm);



        const tableColumns = [
            {
                title: "Nazwa obszaru",
                field: "area",
            },
            {
                title: "Wartosć",
                field: "value"
            },
        ];

        let tableData = [];

        if (questionData.data !== undefined)
        {
            for(let area in questionData.data)
            {
                tableData.push({area: area, value: questionData.data[area]});
            }
        }

        return (
              <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-start"
                >
                  <Grid item xs>
                    <NCForm values={this.state.values} fields={fields} hideSubmitButton={true} saveButtonLabel="Aktualizuj mapę" title="Ustawienia mapy" onChange={this.changeSettings}/>
                </Grid>
                <Grid style={{textAlign: 'center'}} item xs>
                {this.state.loading ? (
                    <Typography style={{ marginTop: 100 }}>Ładowanie danych...</Typography>
                ) : (
                    <>
                        {!this.state.values.show_table && Object.keys(this.state.mapData).length === 0 &&
                        <Typography style={{ marginTop: 100 }}>Brak danych dla podanych kryteriów</Typography>
                        }
                        {!this.state.values.show_table && Object.keys(this.state.mapData).length > 0 &&
                    <div className="map-wrapper"><Typography variant="h5">{this.state.mapData[this.state.values.year][this.state.values.question].question}</Typography>
                    <ReactSVG src={svgFilename}
                      afterInjection={(err, svg) => {
                        this.showLabels(svg);
                      }}
                      beforeInjection={svg => {
                        svg.classList.add('svg-class-name')
                        svg.setAttribute('width', 500);
                        svg.setAttribute('height', window.innerHeight-100);

                        svg.setAttribute('style', 'width: 500px');
                        svg.setAttribute('style', 'max-height: '+(window.innerHeight-100)+'px');

                      }}
                      evalScripts="always"
                      fallback={() => <span>Błąd</span>}
                      loading={() => <span>Ładowanie mapy...</span>}
                      renumerateIRIElements={false}
                      wrapper="div"
                      className=""
                      /></div>}
                      {this.state.values.show_table &&  <DataTable style={{width: '100%'}} data={tableData} columns={tableColumns} title={"Dane"} />}

                    </>)}
                    </Grid>
              </Grid>
        );
    }
}

export default withStyles(styles)(GISReport);
