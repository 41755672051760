import PropTypes from "prop-types";
import Question from "../../models/Question";
import TablePicker from "../shared/TablePicker";
import { getObjValuesFromArrayByAttribute } from "../shared/Helpers";

class QuestionsPicker extends TablePicker {
    url = this.props.url;

    dialogTitle = this.props.dialogTitle;
    tableTitle = this.props.tableTitle;
    lookupTypes = null;

    getLookupTypes = () => {
        if (this.lookupTypes === null) {
            this.lookupTypes = {};
            Object.entries(Question.types).forEach(([type, name]) => {
                if (this.props.types.includes(type) || !this.props.types.length) {
                    this.lookupTypes[type] = name;
                }
            });
        }

        return this.lookupTypes;
    };

    tableColumns = {
        id: 'ID',
        name_string: 'Pytanie',
        is_active_string: { field: 'is_active', title: 'Aktywne?', lookup: { 0: 'Nie', 1: 'Tak' } },
        is_public_string: { field: 'is_public', title: 'Publiczne?', lookup: { 0: 'Nie', 1: 'Tak' } },
        type_string: { field: 'type', title: 'Typ', lookup: this.getLookupTypes() },
        is_imported: { field: 'is_imported', title: 'Z importu?', lookup: { 0: 'Nie', 1: 'Tak' } },
        'catalog.name': 'Katalog',
    };

    constructor(props) {
        super(props);

        this.values = props.questions;
    }

    fetchTable = (url, query, resolve, counter = 0) => {
        if (!query.filters.type || !query.filters.type.length) {
            query.filters.type = Object.keys(this.lookupTypes);
        }
        if (Object.keys(this.props.pollFilters).length) {
            query.filters.poll = this.props.pollFilters;
        }
        if (Object.keys(this.props.museumFilters).length) {
            query.filters.museum = this.props.museumFilters;
        }
        if (Object.keys(this.props.dataFilters).length) {
            query.filters.data = this.props.dataFilters;
        }

        const ids = getObjValuesFromArrayByAttribute(this.values);
        if (ids.length) {
            query.notIn = { id: ids };
        }

        super.fetchTable(url, query, resolve, counter); // it will reach DataComponent instead of TablePicker
    };
}

QuestionsPicker.propTypes = {
    url: PropTypes.string,
    dialogTitle: PropTypes.string,
    tableTitle: PropTypes.string,
    changeHandler: PropTypes.func.isRequired,
    types: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(Question.types))),
    questions: PropTypes.array,
    pollFilters: PropTypes.object,
    museumFilters: PropTypes.object,
    dataFilters: PropTypes.object,
};
QuestionsPicker.defaultProps = {
    url: '/catalogs/questions',
    dialogTitle: 'Dodawanie pytań:',
    tableTitle: 'Dostępne pytania',
    types: Object.keys(Question.types),
    questions: [],
    pollFilters: {},
    museumFilters: {},
    dataFilters: {},
};

export default QuestionsPicker;
