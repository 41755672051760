import React from "react";
import PropTypes from "prop-types";
import { Tooltip, makeStyles } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";

const useStyles = makeStyles({
    active: {
        cursor: 'pointer',
    },
    inactive: {
        cursor: 'default',
    },
});

function TooltipButton(props) {
    const classes = useStyles();
    const { to, title, iconButton, children, color, ...other } = props;
    if (to) {
        return <TooltipIconButtonLink {...props} />
    }

    let activeProps = {
        color: 'inherit',
    };
    if (props.disabled !== undefined) {
        activeProps.color = props.disabled ? 'default' : (color || 'primary');
        activeProps.className = props.disabled ? classes.inactive : classes.active;
    }

    let button;
    if(iconButton){
        button = <IconButton
            aria-label={title}
            color={color}
            {...activeProps}
            {...other}>
            {children}
        </IconButton>;
    } else {
        button = <Button
            aria-label={title}
            color={color}
            {...activeProps}
            {...other}>
            {children}
        </Button>;
    }

    if (!props.disabled) {
        return <Tooltip title={title}>
            {button}
        </Tooltip>
    }

    return button;
}

function TooltipIconButton(props) {
    return <TooltipButton iconButton={true} {...props} />;
}

const props = {
    title: PropTypes.string.isRequired,
    to: PropTypes.string,
    color: PropTypes.oneOf([
        'default',
        'inherit',
        'primary',
        'secondary',
    ]),
    size: PropTypes.oneOf([
        'small',
        'medium',
    ]),
    edge: PropTypes.oneOf([
        'start',
        'end',
        false,
    ]),
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
};

TooltipButton.propTypes = props;
TooltipIconButton.propTypes = props;

function TooltipIconButtonLink(props) {
    const { to, ...other } = props;

    return <Link style={{ color: 'inherit' }} to={to}>
        <TooltipIconButton {...other} />
    </Link>
}

TooltipIconButtonLink.propTypes = { ...props, to: PropTypes.string.isRequired };

export { TooltipIconButtonLink, TooltipIconButton };
export default TooltipIconButton;
