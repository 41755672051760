import React from 'react';
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/core/styles';
import jexcel from 'jexcel';
import TableAnswer, { tableStyles } from "./TableAnswer";
import TooltipIconButton from "../shared/TooltipIconButton";
import AddIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/RemoveCircleOutline';
import { arrayRemove, clone } from "../shared/Helpers";

class DynamicTableAnswer extends TableAnswer {
    getTableOptions = () => {
        const { table, values, disabled } = this.props;

        let columns = [];
        let titles = {};
        table.data.forEach((row, y) => {
            row.forEach((v, x) => {
                const cellName = jexcel.getColumnNameFromId([x, y]);
                const cellMeta = table.meta[cellName] || {};
                let { type, config } = cellMeta;
                if (y) {
                    columns[x] = { type: type + '', config, title: titles[x], editor: this.getCellInput(cellMeta) };
                } else {
                    titles[x] = cellMeta.config || '';
                }
            });
        });
        table.data = arrayRemove(table.data, 0); // remove row used for headers

        let data = clone(values.length ? values : table.data);
        // extract details:
        data.forEach((row, y) => {
            this.details[y] = [];
            row.forEach((v, x) => {
                if (typeof v === 'object') {
                    let { value, details } = v;
                    data[y][x] = value;
                    this.details[y][x] = details || null;
                } else {
                    this.details[y][x] = null;
                }
            });
        });

        return {
            allowInsertRow: !disabled,
            allowManualInsertRow: !disabled,
            allowDeleteRow: !disabled,
            //
            mergeCells: table.mergeCells,
            colWidths: table.colWidths || [],
            data,
            columns,
        };
    };

    renderAddButton = () => {
        return <TooltipIconButton
            title="Dodaj wiersz" size="small"
            onClick={() => {
                if (this.props.disabled) return;
                // this.jexcel.insertRow(new Array(this.jexcel.options.columns.length).fill(''));
                // this.details.push(new Array(this.jexcel.options.columns.length).fill(null));
                this.jexcel.insertRow();
            }}
            disabled={this.props.disabled}>
            <AddIcon/>
        </TooltipIconButton>
    };

    renderDeleteButton = () => {
        return <TooltipIconButton
            title="Usuń wiersz" size="small"
            color="secondary"
            onClick={() => {
                if (this.props.disabled) return;
                if (this.jexcel.rows.length) {
                    this.jexcel.deleteRow(this.jexcel.rows.length);
                    // arrayRemove(this.details, this.jexcel.rows.length - 1);
                }
            }}
            disabled={!this.jexcel.rows.length || this.props.disabled}>
            <DeleteIcon/>
        </TooltipIconButton>
    };

    renderZerofillButton = () => {
        if (!this.props.details.includes('zeros')) {
            return '';
        }
        return <TooltipIconButton
            title="Wpisz zera w puste komórki liczbowe"
            size="small"
            onClick={() => {
                if (this.props.disabled) return;
                let data = this.jexcel.getData();
                data.forEach((row, y) => {
                    row.forEach((v, x) => {
                        const { type } = this.jexcel.options.columns[x];
                        if (type === 'number' && !v.length && !this.details[y][x]) {
                            data[y][x] = '0';
                        }
                    });
                });
                this.jexcel.setData(data);
                this.changeHandler();
            }}
            disabled={this.props.disabled}>
            <span style={{ fontWeight: 'bold', fontSize: '1.313rem', lineHeight: '1.313rem', width: '1.313rem' }}>⓪</span>
        </TooltipIconButton>
    };

    renderNoDataButton = () => {
        if (!this.props.details.includes('no_data')) {
            return '';
        }
        return <TooltipIconButton
            title='Brak danych dla pustych komórek'
            size="small"
            onClick={() => {
                if (this.props.disabled) return;
                let data = this.jexcel.getData();
                data.forEach((row, y) => {
                    row.forEach((v, x) => {
                        const { type } = this.jexcel.options.columns[x];
                        if (!v.length && !this.details[y][x]) {
                            switch (type) {
                                case 'formula':
                                case 'label':
                                case 'choice_single':
                                case 'choice_multiple':
                                    break;
                                default:
                                    this.details[y][x] = 'no_data';
                            }
                        }
                    });
                });
                // this.jexcel.setData(data);
                this.changeHandler();
            }}
            disabled={this.props.disabled}>
            <span style={{ fontWeight: 'bold', fontSize: '1.313rem', lineHeight: '1.313rem', width: '1.313rem' }}>∅</span>
        </TooltipIconButton>
    };

    renderButtons = () => {
        return !!this.jexcel && <>
            {this.renderDeleteButton()}
            {this.renderAddButton()}
            <i style={{ marginLeft: 20 }}/>
            {this.renderZerofillButton()}
            {this.renderNoDataButton()}
        </>
    };
}

DynamicTableAnswer.propTypes = {
    table: PropTypes.object.isRequired,
    details: PropTypes.array,
    answerDetails: PropTypes.string,
    values: PropTypes.array,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
};
DynamicTableAnswer.defaultProps = {
    table: {},
    values: [],
    disabled: false,
    onChange: () => {},
    onFocus: () => {},
};

const styles = theme => (Object.assign(tableStyles(theme), {

}));

export default withStyles(styles)(DynamicTableAnswer);
