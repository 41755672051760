import React from 'react';
import DataComponent from "../shared/DataComponent";
import DataTable from "../shared/DataTable";

class UserProjects extends DataComponent {
    static permissions = ['user-projects'];
    url = '/projects/mine';
    tableColumns = {
        project_name: { title: 'Projekt', filtering: false },
        poll_name: { title: 'Ankieta', filtering: false },
        sent_count: { title: 'Wysłane', filtering: false },
        filling_count: { title: 'Częściowe', filtering: false },
        accepted_count: { title: 'Wypełnione', filtering: false },
    };

    render() {
        if (this.state.isLoading) {
            return this.showLoader();
        }

        return <DataTable
            storageKey={this.constructor.name}
            title="Moje projekty"
            columns={this.getTableColumns()}
            data={this.loadTableData(this.url)}
            options={{ paging: false }}
            parentChildData={(row, rows) => rows.find(v => v.id === row.project_id)}
        />
    }
}

export default UserProjects;
