import React from 'react';
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
import DataComponent from "../shared/DataComponent";

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ConfirmDialog from "../shared/ConfirmDialog";
import Axios from 'axios';

class Dashboard extends DataComponent {
    static permissions = ['view-dashboard'];
    static isDashboard = true;
    url = '/dashboard';

    constructor(props) {
        super(props);

        this.state.dialog = false;
    }

    processData = (data) => {
        return { data, dialog: !!data.verification_dialog };
    };

    closeDialog = () => {
        this.setState({ dialog: false });
    };

    confirm = () => {
        Axios.put('/museums/verify').then(response => {
            this.props.history.push('/institution-manager');
        }).catch(this.catchErrors);
    };

    render() {
        if (this.state.isLoading) {
            return this.showLoader();
        }
        const { classes } = this.props;
        const { data, dialog } = this.state;

        return <>
            <Paper className={classes.root} elevation={1}>
                <Typography component="div" dangerouslySetInnerHTML={{ __html: data.dashboard }}/>
            </Paper>

            <ConfirmDialog title="Weryfikacja danych" confirmTitle="OK" open={dialog} onClose={this.closeDialog} onConfirm={this.confirm}>
                <Typography component="div" dangerouslySetInnerHTML={{ __html: data.verification_dialog }}/>
            </ConfirmDialog>
        </>;
    }
}

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
});

export default withRouter(withStyles(styles)(Dashboard));
