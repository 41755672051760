import React from 'react';
import PropTypes from 'prop-types';
import AddCommentIcon from '@material-ui/icons/AddComment';
import User from "../auth/User";
import List from "@material-ui/core/List";
import NCPollComment from "./NCPollComment";
import { areObjectsEqual } from "../shared/Helpers";
import BaseComponent from "../shared/BaseComponent";
import TooltipIconButton from "../shared/TooltipIconButton";

class PollComments extends BaseComponent {
    state = {
        comments: [],
        addComment: false,
        editComment: false,
    };

    static getDerivedStateFromProps(props, state) {
        if (!areObjectsEqual(props.comments, state.comments)) {
            state.addComment = false;
            state.editComment = false;
        }
        state.comments = props.comments;

        return state;
    }

    updateHandler = (reload) => {
        this.props.updateHandler(reload);
    };

    beginAddComment = () => {
        const lastComment = this.props.comments.slice(-1)[0];
        if (lastComment && lastComment.user.id === User.getUserId() && lastComment.state === 'draft') {
            this.setState({ editComment: true });
        } else {
            this.setState({ addComment: '' + -new Date() });
        }
    };

    cancelAddComment = () => {
        this.setState({ addComment: false, editComment: false });
    };

    render() {
        const { comments, museumPollId, commentableId, commentableType, isLocked, unlockComments, showAvatar, updateKey } = this.props;
        const { addComment, editComment } = this.state;
        const lastIndex = comments.length - 1;

        return User.hasPermissionTo('view-polls-comments') && <>
            <List disablePadding>
                {comments.map((comment, commentIndex) =>
                    <NCPollComment
                        key={comment.id + 'comment' + (editComment ? 'edit' : '') + updateKey}
                        updateKey={updateKey}
                        comment={comment}
                        editMode={editComment && commentIndex === lastIndex}
                        updateHandler={this.updateHandler}
                        onCancel={this.cancelAddComment}
                        showAvatar={showAvatar}
                        allowReply={comment.state !== 'draft' && (!isLocked || unlockComments)}
                        commentableId={commentableId}
                        commentableType={commentableType}
                        museumPollId={museumPollId}
                        showFirstReply={true}
                    />
                )}
                {!!addComment && (
                    <NCPollComment
                        editMode
                        key={addComment}
                        commentableId={commentableId}
                        commentableType={commentableType}
                        museumPollId={museumPollId}
                        updateHandler={this.updateHandler}
                        onCancel={this.cancelAddComment}
                        showAvatar={showAvatar}
                        allowReply={false}
                    />)}
            </List>
            {(!isLocked || unlockComments) && User.hasPermissionTo('edit-polls-comments') && (
                <div align="right">
                    <TooltipIconButton title="Napisz komentarz" onClick={this.beginAddComment}>
                        <AddCommentIcon/>
                    </TooltipIconButton>
                </div>
            )}
        </>
    }
}

PollComments.propTypes = {
    comments: PropTypes.array,
    updateHandler: PropTypes.func,
    isLocked: PropTypes.bool,
    unlockComments: PropTypes.bool,
    showAvatar: PropTypes.bool,
    museumPollId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    commentableId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    commentableType: PropTypes.string,
};
PollComments.defaultProps = {
    isLocked: false,
    unlockComments: false,
    showAvatar: true,
    updateHandler: () => {},
};

export default PollComments;
