import React from 'react';
import PropTypes from "prop-types";
import { DialogTitle } from "../shared/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import TableQuestionPicker from "./TableQuestionPicker";
import { clone } from "../shared/Helpers";
import BaseComponent from "../shared/BaseComponent";

class TableQuestionPickerDialog extends BaseComponent {
    state = { values: null };

    constructor(props) {
        super(props);

        this.state.values = clone(props.question.parent_question, {});
    }

    changeHandler = (values) => {
        if (!values) {
            values = null;
        }
        this.setState({ values });
    };

    render() {
        const { question, table, changeHandler } = this.props;

        return <Dialog maxWidth="md" open={true} onClose={() => changeHandler(false)}>
            <DialogTitle onClose={() => changeHandler(false)}>Ustaw dziedziczenie komórek tabeli: {question.name}</DialogTitle>
            <DialogContent style={{ paddingTop: 24 }}>
                <TableQuestionPicker
                    question={question}
                    table={table}
                    changeHandler={this.changeHandler}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => changeHandler(false)} variant="contained">Anuluj</Button>
                <Button onClick={() => changeHandler(this.state.values)} color="primary" variant="contained">Zastosuj</Button>
            </DialogActions>
        </Dialog>;
    }
}

TableQuestionPickerDialog.propTypes = {
    question: PropTypes.object.isRequired,
    table: PropTypes.object.isRequired,
    changeHandler: PropTypes.func.isRequired,
};
TableQuestionPickerDialog.defaultProps = {};

export default TableQuestionPickerDialog;
