import React from 'react';
import { withRouter } from "react-router";
import DataComponent from "../shared/DataComponent";
import DataTable from "../shared/DataTable";
import User from "../auth/User";
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FabContainer from "../shared/FabContainer";
import Question from "../../models/Question";
import CatalogPicker from "./CatalogPicker";
import FabButton, { FabLink } from "../shared/FabButton";

class QuestionList extends DataComponent {
    static permissions = ['view-questions'];
    catalog_id = this.props.match.params.catalog_id;
    url = '/catalogs/' + this.catalog_id + '/questions';
    tableColumns = {
        id: 'ID',
        name_string: 'Pytanie',
        is_active_string: { field: 'is_active', title: 'Aktywne?', lookup: { 0: 'Nie', 1: 'Tak' } },
        is_public_string: { field: 'is_public', title: 'Publiczne?', lookup: { 0: 'Nie', 1: 'Tak' } },
        state_string: { field: 'state', title: 'Status', lookup: Question.states },
        type_string: { field: 'type', title: 'Typ', lookup: Question.types },
        is_imported: { field: 'is_imported', title: 'Z importu?', lookup: { 0: 'Nie', 1: 'Tak' } },
        created_at: "Data utworzenia",
        occurrence_count: { field: 'occurrence_count', title: 'Liczba wystąpień', filtering: false },
    };

    constructor(props) {
        super(props);

        if (!this.catalog_id) {
            this.url = '/catalogs/questions';
            this.tableColumns['catalog.name'] = 'Katalog';
        }
        this.state.catalogPicker = false;

        this.addDeletedAtColumn();
    }

    getTitle = () => {
        if (this.hasObject()) {
            return 'Lista pytań w katalogu: ' + this.getObject('name');
        } else {
            return 'Baza pytań';
        }
    };

    render() {
        if (this.state.isLoading) {
            return this.showLoader();
        }

        const { catalogPicker } = this.state;

        return <>
            <DataTable
                storageKey={this.constructor.name}
                title={this.getTitle()}
                columns={this.getTableColumns()}
                data={this.loadTableData(this.url)}
                showLink={User.hasPermissionTo('view-polls') ?
                    (rowData) => '/catalogs' + (this.catalog_id ? '/list/' + this.catalog_id : '') + '/questions/' + rowData.id + '/polls'
                    : null
                }
                editLink={User.hasPermissionTo('edit-questions')
                    ? (rowData) => '/catalogs' + (this.catalog_id ? '/list/' + this.catalog_id : '') + '/questions/' + rowData.id + '/edit'
                    : null
                }
            />

            <FabContainer>
                {this.hasObject() ? <>
                    <FabLink title="Wróć" to="/catalogs/list"><ArrowBackIcon/></FabLink>

                    {User.hasPermissionTo('edit-questions') && (
                        <FabLink title="Dodaj pytanie" to={"/catalogs/list/" + this.getObject('id') + '/questions/add'} color="primary">
                            <AddIcon/>
                        </FabLink>
                    )}
                </> : <>
                    {User.hasPermissionTo('edit-questions') && <>
                        <FabButton title="Dodaj pytanie" onClick={() => this.setState({ catalogPicker: true })} color="primary"><AddIcon/></FabButton>
                    </>}

                    {catalogPicker && (
                        <CatalogPicker changeHandler={() => this.setState({ catalogPicker: false })}/>
                    )}
                </>}
            </FabContainer>
        </>
    }
}

export default withRouter(QuestionList);
