import React from 'react';
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';
import JExcelComponent from "./JExcelComponent";
import jexcel from 'jexcel';
import UndoIcon from '@material-ui/icons/Undo';
import RedoIcon from '@material-ui/icons/Redo';
import TooltipIconButton from "../shared/TooltipIconButton";
import SettingsIcon from '@material-ui/icons/Settings';
import MergeIcon from '@material-ui/icons/VerticalAlignCenter';
import UnmergeIcon from '@material-ui/icons/Code';
import Dialog from "@material-ui/core/Dialog";
import { DialogContent, DialogTitle } from "../shared/Dialog";
import NCForm from "../nc-form/NCForm";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { clone, dotSet, getSelectOptions, range } from "../shared/Helpers";
import Question from "../../models/Question";
import NCChoiceCreator from "../nc-choice-creator/NCChoiceCreator";
import BaseComponent from "../shared/BaseComponent";

class QuestionTableCreator extends BaseComponent {
    ref = React.createRef();
    classes = {};
    wrapper = null;
    jexcel = null;
    state = {
        selection: null,
        cell: null,
    };
    history = [];
    historyActive = true;
    historyIndex = -1;
    options = {
        editable: false,
    };

    constructor(props) {
        super(props);

        this.classes = makeStyles(styles);

        this.options = Object.assign(this.options, {
            allowInsertRow: !props.isDynamic,
            allowManualInsertRow: !props.isDynamic,
            allowDeleteRow: !props.isDynamic,
            minDimensions: props.isDynamic ? [1, 2] : [1, 1],
            onselection: this.selectionHandler,
            onBeforeCellDoubleClick: (instance, cell) => {
                const { c1, x1, y1 } = this.state.selection;
                this.configureCell(c1, x1, y1);
            },
            updateCell: this.updateCell,
            // onchange: this.changeHandler, // messes our history and is called on cells update
            onresizecolumn: this.resizeColumnHandler,
            oninsertcolumn: this.changeHandler,
            ondeletecolumn: this.changeHandler,
            oninsertrow: this.changeHandler,
            ondeleterow: this.changeHandler,
            onmerge: this.changeHandler,
            onremovemerge: this.changeHandler,
            onblur: () => this.setState({ selection: null }),
        }, props.table);
    }

    componentDidMount() {
        this.jexcel = this.ref.current.jexcel;
        this.jexcel.ignoreHistory = true;
        this.addHistory();
        this.forceUpdate();
    }

    changeHandler = () => {
        if (!this.jexcel || !this.historyActive) {
            return;
        }

        const tableConfig = this.getTableConfig();
        this.addHistory(tableConfig);

        this.props.onChange(this.props.valuesKey, tableConfig);
    };

    addHistory = (tableConfig) => {
        if (!this.historyActive) {
            return;
        }
        this.historyIndex++;
        this.history = this.history.slice(0, this.historyIndex);
        this.history.push(clone(tableConfig || this.getTableConfig()));
    };

    applyHistory = (index) => {
        if (0 > index || index >= this.history.length) {
            return;
        }
        this.historyActive = false;
        const { data, meta, mergeCells, colWidths } = this.history[index];

        this.jexcel.setData(JSON.stringify(data)); // setData parses string
        this.jexcel.destroyMerged();
        Object.entries(mergeCells).forEach(([cellName, mergeData]) => {
            const [colspan, rowspan] = mergeData;
            this.jexcel.setMerge(cellName, colspan, rowspan, true);
        });
        this.jexcel.options.meta = clone(meta);
        this.jexcel.options.colWidths = clone(colWidths);
        this.jexcel.updateTableReferences();

        this.props.onChange(this.props.valuesKey, { data, meta, mergeCells, colWidths });
        this.historyActive = true;
        this.forceUpdate();
    };

    resizeColumnHandler = (instance, column, width, oldWidth) => {
        this.jexcel.options.colWidths[column] = parseInt(width);
        this.changeHandler();
    };

    getTableConfig = () => {
        if (!this.jexcel) {
            return {};
        }
        const { data, meta, mergeCells, colWidths } = this.jexcel.getConfig();

        return {
            data, // required to keep empty rows / columns
            meta,
            mergeCells,
            colWidths,
        };
    };

    getCellLabel = (cellName, cell) => {
        if (!cellName) {
            return '';
        }
        let cellMeta;
        if (this.jexcel) {
            // if jexcel instance is ready - read meta from it, so we don't have to track changes
            cellMeta = this.jexcel.getMeta(cellName);
        } else {
            // use initial meta information
            const { meta } = this.props.table;
            cellMeta = meta[cellName];
        }
        const { type, config, label } = cellMeta || {};
        let cellLabel = label ? '[' + label + ']<br/>' : '';
        switch (type) {
            case 'choice_single':
            case 'choice_multiple':
                const { choices } = config;
                cellLabel = Question.types[type] + ':<br/>';
                cellLabel += '<div style="text-align: left; padding-left: 4px;">';
                choices.forEach(choice => {
                    let v = choice.name;
                    if (v.substr(0, 5) === '<div>') {
                        cellLabel += '&#9675; ' + v.slice(5, -6); // get rid of the wrapper
                    }
                    cellLabel += choice.has_text ? ': <span style="font-size: 2em; vertical-align: sub; line-height: 0;">▭</span>' : '';
                    cellLabel += '<br/>';
                });
                cellLabel += '</div>';
                return cellLabel;
            case  'boolean':
            case  'text':
            case  'open':
            case  'email':
                return Question.types[type];
            case 'number':
                if (config) {
                    const { precision, suffix, min, max } = config;
                    cellLabel += 'Liczba<br/>';
                    if (min.length) {
                        cellLabel += min + ' <= ';
                    }
                    cellLabel += '<b>*';
                    if (precision > 0) {
                        cellLabel += '.' + '*'.repeat(precision);
                    }
                    cellLabel += ' ' + suffix + '</b>';
                    if (max.length) {
                        cellLabel += ' <= ' + max;
                    }
                }
                return cellLabel;
            case  'date':
                return cellLabel + 'Data:<br/><b>yyyy-MM-dd</b>';
            case  'year':
                return cellLabel + 'Rok:<br/><b>yyyy</b>';
            case  'formula':
                return cellLabel + 'Formuła:<br/><b>' + config + '</b>';
            case  'label':
                return 'Etykieta:<br/><b>' + config + '</b>';
            case  'regexp':
                return cellLabel + Question.types[type] + ':<br/><b>' + config + '</b>';
            case  'mask':
                return cellLabel + Question.types[type] + ':<br/><b>' + Question.maskVariants[config] + '</b>';
            default:
                return '';
        }
    };

    updateCell = (instance, cell, x, y, value, label, cellName) => {
        let inner;
        if (cell.getAttribute('data-merged')) {
            if (cell.getAttribute('data-x') === '' + x && cell.getAttribute('data-y') === '' + y) {
                // all merged cells nodes are referenced to their primary cell node!
            } else {
                return;
            }
        }

        inner = this.getCellLabel(cellName, cell);
        if (inner !== undefined) {
            cell.innerHTML = inner;
        }
    };

    selectionHandler = (instance, x1, y1, x2, y2) => {
        this.setState({
            selection: {
                x1, y1,
                x2, y2,
                c1: jexcel.getColumnNameFromId([x1, y1]),
                c2: jexcel.getColumnNameFromId([x2, y2]),
            }
        });
    };

    configureCell = (c1, x, y) => {
        const { type, config, label } = this.jexcel.getMeta(c1) || {};
        this.setState({
            cell: {
                name: c1,
                type: type || (this.props.isDynamic && !y ? 'label' : null),
                config: config || '',
                label,
                x, y,
            }
        });
    };

    configureCells = (c1, c2, x1, y1, x2, y2) => {
        this.configureCell(c1, x1, y1); // merged cells
    };

    updateCellSettings = () => {
        let { name, type, config, label } = this.state.cell;

        if (type === 'regexp' && !this.isRegExpValid(config)) {
            alert('RegExp nieprawidłowy!');
            return;
        }
        if (type === 'formula') {
            config = config.toUpperCase();
            if (config.substr(0, 1) !== '=') {
                config = '=' + config;
            }
        }
        if (type === 'label' && !config.length) {
            alert('Proszę podać etykietę');
            return;
        }

        if (['choice_single', 'choice_multiple'].includes(type)) {
            let empty = [];
            if (!Array.isArray(config.choices)) { // if no choices were added, we'll receive an empty object
                config.choices = [];
            }
            config.choices.forEach((choice, key) => {
                if (!choice.name.length) {
                    empty.push(key);
                }
            });
            if (empty.length) {
                alert('Brakuje ' + empty.length + ' opowiedzi');
                return;
            }
        }

        if (type) {
            this.jexcel.setMeta(name, { type, config, label });
        } else {
            this.jexcel.setMeta(name, {});
        }

        this.setState({ cell: null }, () => {
            this.changeHandler();
        });
    };

    renderUndoButton = () => {
        return <TooltipIconButton
            title="Cofnij" size="small"
            onClick={() => {
                // this.jexcel.undo();
                this.applyHistory(--this.historyIndex);
            }}
            disabled={!this.history.length || this.historyIndex <= 0}>
            <UndoIcon/>
        </TooltipIconButton>
    };

    renderRedoButton = () => {
        return <TooltipIconButton
            title="Ponów" size="small"
            onClick={() => {
                // this.jexcel.redo();
                this.applyHistory(++this.historyIndex);
            }}
            disabled={this.history.length <= this.historyIndex + 1}>
            <RedoIcon/>
        </TooltipIconButton>
    };

    renderSettingsButton = () => {
        let onClick = null, many = false;
        if (this.state.selection) {
            const { c1, c2, x1, y1, x2, y2 } = this.state.selection;
            if (c1 !== c2) {
                many = true;
                onClick = () => this.configureCells(c1, c2, x1, y1, x2, y2);
            } else {
                onClick = () => this.configureCell(c1, x1, y1);
            }
        }
        return <TooltipIconButton title={'Konfiguruj ' + (many ? 'komórki' : 'komórkę')} size="small" onClick={onClick} disabled={!onClick}>
            <SettingsIcon/>
        </TooltipIconButton>
    };

    renderMergeButton = () => {
        let color = 'default', onClick = null, merged = false;
        if (this.state.selection) {
            const { c1, c2, x1, y1, x2, y2 } = this.state.selection;
            merged = this.jexcel.getMerge(c1);
            if (merged) {
                color = 'secondary';
                onClick = () => {
                    this.jexcel.removeMerge(c1);
                    this.jexcel.updateTableReferences();
                }
            } else if (c1 !== c2) {
                color = 'primary';
                onClick = () => {
                    // this.jexcel.setMerge(c1, x2 - x1 + 1, y2 - y1 + 1);
                    this.jexcel.setMerge(); // jexcel can detect the selection itself
                    // remove data from hidden cells:
                    range(x1, x2).forEach(x => {
                        range(y1, y2).forEach(y => {
                            const name = jexcel.getColumnNameFromId([x, y]);
                            if (name !== c1) {
                                this.jexcel.setMeta(name, {});
                            }
                        });
                    });
                    this.jexcel.updateTableReferences();
                }
            }
        }
        return <TooltipIconButton title={(merged ? 'Rozdziel' : 'Scal') + ' komórki'} size="small" onClick={onClick} color={color} disabled={!onClick}>
            {merged ? <UnmergeIcon/> : <MergeIcon style={{ transform: 'rotate(90deg)' }}/>}
        </TooltipIconButton>
    };

    closeDialog = () => {
        this.setState({ cell: null });
    };

    settingsChangeHandler = (name, value) => {
        let cell = this.state.cell;

        if (name === 'type') {
            switch (value) {
                case 'number':
                    cell.config = {
                        precision: 0,
                        suffix: '',
                        min: '',
                        max: '',
                    };
                    break;
                case 'mask':
                    cell.config = 'phone';
                    break;
                case 'choice_single':
                case 'choice_multiple':
                    cell.config = {
                        choices: {},
                    };
                    break;
                case 'label':
                    delete cell.label;
                default:
                    cell.config = '';
            }
        }

        this.setState({ cell: dotSet(cell, name, value) });
    };

    getCellFields = () => {
        const { type, config, y } = this.state.cell;

        let typeOptions = [
            { value: 'label', text: 'Etykieta' },
            { value: 'boolean', text: 'TAK / NIE' },
            { value: 'number', text: 'Liczbowe' },
            { value: 'text', text: 'Tekstowe' },
            { value: 'open', text: 'Otwarte' },
            { value: 'regexp', text: 'RegExp' },
            { value: 'mask', text: 'Maska' },
            { value: 'email', text: 'Email' },
            { value: 'date', text: 'Data' },
            { value: 'year', text: 'Rok' },
            { value: 'formula', text: 'Formuła' },
            { value: 'choice_single', text: 'Jednokrotny wybór' },
            { value: 'choice_multiple', text: 'Wielokrotny wybór' },
        ];
        if (this.props.isDynamic && !y) {
            typeOptions = [typeOptions.shift()];
        }
        let fields = [
            {
                name: 'type',
                type: 'select',
                label: 'Rodzaj pola',
                width: 200,
                emptyValue: this.props.isDynamic && !y ? false : 'Puste',
                options: typeOptions,
            },
        ];
        if (!this.props.isDynamic && type !== 'label') {
            fields.push({
                name: 'label',
                type: 'text',
                label: 'Etykieta do statystyk',
                width: 400,
            });
        }

        switch (type) {
            case 'choice_single':
            case 'choice_multiple':
                fields.push({ type: 'divider' });
                fields.push({
                    type: 'custom',
                    component: <NCChoiceCreator
                        valuesKey="config.choices"
                        key={type}
                        onChange={this.settingsChangeHandler}
                        choices={config.choices}
                        hasText={true}
                        width={400}
                    />
                });
                break;
            case 'label':
                fields.push({ type: 'divider' });
                fields.push({
                    name: 'config',
                    type: 'tinyBasic',
                    label: 'Treść',
                    width: 400,
                });
                break;
            case 'number':
                fields.push({ type: 'divider' });
                fields.push({
                    name: 'config.precision',
                    type: 'number',
                    label: 'Miejsc po przecinku',
                    width: 130,
                    step: 1,
                    min: 0,
                    max: 4,
                    defaultValue: 0,
                });
                fields.push({
                    name: 'config.suffix',
                    type: 'text',
                    label: 'Jednostka',
                    width: 100,
                });
                fields.push({
                    name: 'config.min',
                    type: 'number',
                    label: 'Minimum',
                    width: 100,
                });
                fields.push({
                    name: 'config.max',
                    type: 'number',
                    label: 'Maximum',
                    width: 100,
                });
                break;
            case 'regexp':
                fields.push({ type: 'divider' });
                fields.push({
                    name: 'config',
                    type: 'text',
                    label: 'Wyrażenie',
                    width: 400,
                });
                // fields.push({
                //     type: 'custom',
                //     component: <div key="" style={{ paddingTop: 16 }}>
                //         Wyrażenie regularne w formacie ze slashami: "/[a-z]/i" lub bez: "[a-zA-Z]"<br/> // just use the html5 pattern format
                //         Zgodne z <a href="https://developer.mozilla.org/pl/docs/Web/JavaScript/Referencje/Obiekty/RegExp" target="_blank">dokumentacją</a>
                //     </div>
                // });
                break;
            case 'mask':
                fields.push({ type: 'divider' });
                fields.push({
                    name: 'config',
                    type: 'select',
                    label: 'Wariant',
                    width: 261,
                    options: getSelectOptions(Question.maskVariants),
                });
                break;
            case 'formula':
                fields.push({ type: 'divider' });
                fields.push({
                    name: 'config',
                    type: 'text',
                    label: 'Formuła',
                    width: 400,
                });
                let functions = '=ILOCZYN(A1,B1,C1:C4)|=SUMA(A1:A3,B2)|=ŚREDNIA(A1:A3,B1,C1)';
                fields.push({
                    type: 'custom',
                    component: <div key="" style={{ paddingTop: 16 }}>
                        Dostępne funkcje:<br/>
                        <ul>
                            {functions.split('|').map((v, k) => <li key={k}>{v}</li>)}
                        </ul>
                    </div>
                });
                break;
            default:
        }

        return fields;
    };

    isRegExpValid = (pattern) => {
        let parts = pattern.split('/'),
            regex = pattern,
            options = "";
        if (parts.length > 1) {
            regex = parts[1];
            options = parts[2];
        }
        try {
            new RegExp(regex, options);
            return true;
        } catch (e) {
            return false;
        }
    };

    renderDialogs = () => {
        if (!!this.state.cell) {
            const { classes } = this;

            return <Dialog open={true} onClose={this.closeDialog} maxWidth="md">
                <DialogTitle onClose={this.closeDialog}>Ustawienia komórki {this.state.cell.name}</DialogTitle>
                <DialogContent className={classes.formContent}>
                    <NCForm
                        fields={this.getCellFields()}
                        values={this.state.cell}
                        onChange={this.settingsChangeHandler}
                        hideSubmitButton={true}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.closeDialog} variant="contained">Anuluj</Button>
                    <Button onClick={this.updateCellSettings} color="primary" variant="contained">Zastosuj</Button>
                </DialogActions>
            </Dialog>
        }

        return '';
    };

    renderToolbar = () => {
        const { classes } = this;

        return <div className="jexcel_toolbar">
            {!!this.jexcel && <>
                {this.renderUndoButton()}
                {this.renderRedoButton()}<i className={classes.separator}/>
                {this.renderMergeButton()}<i className={classes.separator}/>
                {this.renderSettingsButton()}
            </>}
        </div>
    };

    render() {
        const { classes } = this;

        return <div className={classes.container}>
            <JExcelComponent ref={this.ref} options={this.options} adminMode={true}>
                {this.renderToolbar()}
            </JExcelComponent>

            {this.renderDialogs()}
        </div>
    };
}

QuestionTableCreator.propTypes = {
    valuesKey: PropTypes.string,
    table: PropTypes.object.isRequired,
    isDynamic: PropTypes.bool,
};
QuestionTableCreator.defaultProps = {
    valuesKey: 'properties',
    table: {},
    onChange: () => {},
    isDynamic: false,
};

const styles = theme => ({
    separator: {
        width: 10,
    },
    container: {
        zIndex: 10,
        '& .jexcel_pagination': {
            display: 'none',
        },
        '& .jexcel_content': {
            paddingRight: 0,
        },
        '& .jexcel_toolbar': {
            paddingTop: 2,
            paddingBottom: 2,
        },
    },
    formContent: {
        minWidth: 600,
    },
});

export default QuestionTableCreator;
