import React from "react";
import { Paper, withStyles } from "@material-ui/core";
import BaseComponent from "./BaseComponent";

class PaperRoot extends BaseComponent {
    render() {
        const { classes } = this.props;

        return <Paper className={classes.root} {...this.props} />;
    }
}

const styles = theme => ({
    root: {
        position: 'relative',
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
});

export default withStyles(styles)(PaperRoot);
