import React from 'react';
import { withRouter } from "react-router";
import { withStyles } from '@material-ui/core/styles';
import DataComponent from "../shared/DataComponent";
import DataTable from "../shared/DataTable";
import NCForm from '../nc-form/NCForm';
import Axios from 'axios';

const styles = theme => ({
    addButton : {
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 20,
        left: 'auto',
        position: 'fixed',
    },
});

class AnswersByMuseums extends DataComponent {
    static permissions = ['view-museums'];
    url = '/museums';
    tableColumns = {
        id: 'ID',
        name: 'Nazwa instytucji',
    };

    catalogueColumns = {
        id: 'ID',
        name: 'Nazwa katalogu',
    };

    answerColumns = {
        museum: 'Nazwa instytucji',
        catalogue: 'Nazwa katalogu',
        question: 'Pytanie',
        answer: 'Odpowiedź',
    };

    getCustomColumns = (columns) => {
        let customColumns = Object.entries(columns).map(([key, value]) => {
            return Object.assign({ field: key }, typeof value === 'string' ? { title: value } : value);
        });

        return customColumns;
    };

    componentDidMount() {
        Axios.get(this.url+'?&orderDirection=&page=1&pageSize=100&search=&totalCount=0' , {}).then((response) => {
            this.setState({museumList: response.data.table.data, museumsLoaded: true});
            this.loadAnswers();
        });

        Axios.get('/catalogs?&orderDirection=&page=1&pageSize=100&search=&totalCount=0' , {}).then((response) => {
            this.setState({catalogueList: response.data.table.data, cataloguesLoaded: true});
            this.loadAnswers();
        });

    }

    loadAnswers() {
        if ((!this.state.museumsLoaded) || (!this.state.cataloguesLoaded)){
            return;
        }

        let catalogues = [];
        let museums = [];

        Axios.post('/data-reports/getAnswersByMuseum', {
            catalogues,
            museums,
        }).then((response) => {
            this.setState({
                answersData : response.data.data
            });
            this.updateAnswers();
        });
    }

    updateAnswers = () => {
        let filteredAnswersData = []
        for (let i in this.state.answersData) if (i > 0) {
            for (let i2 in this.state.answersData[i]) if (i > 0) {
                let canAdd = true;
                let museum = '';
                let catalogue = '';
                for (let j in this.state.museumList){
                    if (this.state.museumList[j].id === this.state.answersData[i][i2][2]) {
                        if (this.state.museumList[j].unchecked){
                            canAdd = false;
                            break;
                        }
                        museum = this.state.museumList[j].name;
                    }
                }
                if (canAdd){
                    for (let j in this.state.catalogueList){
                        if (this.state.catalogueList[j].id === this.state.answersData[i][i2][1]){
                            if (this.state.catalogueList[j].unchecked){
                                canAdd = false;
                                break;
                            }
                            catalogue = this.state.catalogueList[j].name;
                        }
                    }
                }
                if (canAdd){
                    let answer = {
                        museum: museum,
                        catalogue: catalogue,
                        question: this.state.answersData[0][(i-1)],
                        answer: this.state.answersData[i][i2][0],
                    }
                    filteredAnswersData.push(answer)
                }
            }
        }
        this.setState({filteredAnswersData:filteredAnswersData})
    }

    generateReport = (state) => {
        let museums = []
        for (let j in this.state.museumList){
            if (!this.state.museumList[j].unchecked){
                museums.push(this.state.museumList[j].id)
            }
        }

        let catalogs = []
        for (let j in this.state.catalogueList){
            if (!this.state.catalogueList[j].unchecked){
                catalogs.push(this.state.catalogueList[j].id)
            }
        }

        Axios({
            url: '/system-reports/answers-by-museums',
            method: 'POST',
            responseType: 'blob',
            data: {
                museums: museums,
                catalogs: catalogs
            }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'raport.xlsx');
            document.body.appendChild(link);
            link.click();
        });
    }

    render() {
        if ((!this.state.museumsLoaded) || (!this.state.cataloguesLoaded)){
            return this.showLoader();
        }
        // const { classes } = this.props;

        const fields = [];

        return (
            <React.Fragment>
                <div style={style.selectionTable}>
                    <DataTable
                        storageKey={this.constructor.name}
                        title={"Lista muzeów"}
                        columns={this.getTableColumns()}
                        options={{
                            //pageSize:5,
                            //selection:true,
                            paging: false
                        }}
                        data={this.state.museumList}
                        checkable
                        onCheck={() => {this.updateAnswers()}}
                    />
                </div>
                <div style={style.selectionTable}>
                    <DataTable
                        storageKey={this.constructor.name}
                        title={"Katalogi"}
                        columns={this.getCustomColumns(this.catalogueColumns)}
                        options={{
                            //pageSize:5,
                            //selection:true,
                            paging: false
                        }}
                        data={this.state.catalogueList}
                        checkable
                        onCheck={() => {this.updateAnswers()}}
                        style={{marginLeft:10}}
                    />
                </div>
                <div style={{clear:'both'}}/>
                <DataTable
                    style={{marginTop:20}}
                    storageKey={this.constructor.name}
                    title={"Odpowiedzi muzeów"}
                    columns={this.getCustomColumns(this.answerColumns)}
                    options={{pageSize:20}}
                    data={this.state.filteredAnswersData}
                />
                <NCForm
                    fields={fields}
                    saveHandler={this.generateReport}
                    saveButtonLabel="Generuj raport"/>
            </React.Fragment>
        );
    }
}

const style = ({
    selectionTable: {
        width:'50%',
        height:300,
        float:'left',
        overflow: 'scroll'
    }
})

export default withStyles(styles)(withRouter(AnswersByMuseums));
