import React from 'react';
import { withRouter } from "react-router";
import DataComponent from "../shared/DataComponent";
import DataTable from "../shared/DataTable";
import MuseumPoll from "../../models/MuseumPoll";
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ConfirmDialog from "../shared/ConfirmDialog";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import User from "../auth/User";

const StyledBadge = withStyles(theme => ({
    badge: {
        whiteSpace: 'nowrap',
        position: 'relative',
        alignSelf: 'center',
        transform: 'none',
        marginLeft: theme.spacing(1),
    },
}))(Badge);

class MuseumPollList extends DataComponent {
    static permissions = ['view-museum-polls'];
    url = '/museum-polls';
    tableColumns = {
        // 'museum.id': 'ID Muzeum',
        'poll.id': 'ID ankiety',
        'poll.name': 'Nazwa ankiety',
        'museum.name': 'Muzeum',
        'poll.year': 'Okres sprawozdawczy',
        'poll.ends_at': 'Termin na wypełnienie',
        state_string: { field: 'state', title: "Status", lookup: MuseumPoll.states },
        questions_summary: { title: 'Wypełnione pytania', filtering: false },
    };

    constructor(props) {
        super(props);

        this.state.permissions = false;
    }

    openPermissionsDialog = (museumPoll) => {
        Axios.get('/museum-polls/' + museumPoll.id + '/permissions').then(response => {
            this.setState({ permissions: response.data });
        }).catch(this.catchErrors);
    };

    render() {
        if (this.state.isLoading) {
            return this.showLoader();
        }
        const { classes } = this.props;
        const { permissions } = this.state;

        return <>
            <DataTable
                storageKey={this.constructor.name}
                title="Lista ankiet przydzielonych do Twojej instytucji"
                columns={this.getTableColumns()}
                data={this.loadTableData(this.url)}
                showLink={(rowData) => '/museum-polls/' + rowData.id}
                actions={[
                    rowData => User.hasPermissionTo('edit-museum-poll-permissions') ? ({
                        tooltip: 'Uprawnienia',
                        icon: AccountBoxIcon,
                        onClick: (event, data) => {
                            this.openPermissionsDialog(data);
                        },
                    }) : '',
                ]}
            />

            {!!permissions && (
                <ConfirmDialog
                    title={"Uprawnienia ankiety: " + permissions.poll_name}
                    open={true}
                    onClose={() => this.setState({ permissions: false })}
                    onConfirm={() => {}}
                    confirmTitle=""
                    cancelTitle=""
                    isContentText={false}
                    dialogProps={{
                        maxWidth: false,
                    }}
                >
                    {permissions.sections.length ? <div style={{ marginTop: -32 }}>
                        {permissions.sections.map(section =>
                            <Box className={classes.section}>
                                <Box display="flex" className={classes.header}>
                                    <Typography variant="h6" component="h2">{section.name}</Typography>
                                    <StyledBadge badgeContent={section.counts} color="primary"/>
                                </Box>
                                <Box display="flex" justifyContent="flex-start" flexWrap="wrap">
                                    {section.users.map(user =>
                                        <Box className={classes.column}>
                                            <Box display="flex" alignItems="center" className={classes.row}>
                                                <Typography className={classes.text}>{user.full_name}</Typography>
                                            </Box>
                                            <Box display="flex" alignItems="center" className={classes.row}>
                                                <Typography className={classes.text}>{user.email}</Typography>
                                            </Box>
                                            <Box display="flex" alignItems="center" className={classes.row}>
                                                <Typography className={classes.text}>{user.phone}</Typography>
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        )}
                    </div> : <Typography>Nie ustawiono jeszcze uprawnień.</Typography>}
                </ConfirmDialog>
            )}
        </>;
    }
}

const styles = theme => ({
    section: {
        marginTop: theme.spacing(4),
    },
    header: {
    },
    row: {
        width: 260,
        borderBottom: '1px solid #eaeaea',
        height: '2.8em',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    column: {
        borderTop: '2px solid #aaa',
    },
    text: {
        lineHeight: 1.05,
    },
});

export default withStyles(styles)(withRouter(MuseumPollList));
