import React  from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router";
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { DateTimePicker } from "@material-ui/pickers";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';

import { arrayRemove, debounce, formatDate } from "../shared/Helpers";
import FabButton from "../shared/FabButton";
import BaseComponent from "../shared/BaseComponent";

class PollNotifications extends BaseComponent {
    state = { values: [] };

    constructor(props){
        super(props);

        this.state.values = this.props.notifications;
    }

    changeHandler = debounce(() => {
        this.props.changeHandler(this.props.valuesKey, this.state.values);
    });

    changeContent = (key) => event => {
        let values = this.state.values;
        values[key]['content'] = event.target.value;
        this.setState({ values }, this.changeHandler);
    };

    changeDate = (key, format) => value => {
        let values = this.state.values;
        values[key]['send_at'] = value ? formatDate(format, value) : null;
        this.setState({ values }, this.changeHandler);
    };

    addItem = () => {
        let values = this.state.values;
        values.push({
            send_at: '',
            content: ''
        });
        this.setState({ values }, this.changeHandler);
    };

    removeItem = (key) => event => {
        let values = this.state.values;
        arrayRemove(values, key);
        this.setState({ values }, this.changeHandler);
    };

    render(){
        const {classes, errors, timeFormat} = this.props;
        const { values } = this.state;
        let number = 1;

        return (<>
            <Typography variant="h6" component="h4">Powiadomienia:</Typography>
            <List id="poll-notifications" dense>
                {values.map((item, key) => {
                    let error = errors[key] || {};
                    return <ListItem dense className={classes.listItem} key={key}>
                        <ListItemIcon>
                            <FabButton title="Usuń" onClick={this.removeItem(key)} color="secondary" className={classes.smallButton}>
                                <RemoveIcon className={classes.smallIcon}/>
                            </FabButton>
                        </ListItemIcon>
                        <DateTimePicker
                            key={key}
                            clearable={true}
                            clearLabel="Wyczyść"
                            cancelLabel="Anuluj"
                            initialFocusedDate={formatDate('yyyy-MM-dd HH:00')}
                            minutesStep={5}
                            label={"Data"}
                            value={item['send_at'] ? formatDate(timeFormat, item['send_at']) : null}
                            format={timeFormat}
                            ampm={false}
                            disablePast
                            className={classes.textField}
                            style={{width: 150}}
                            onChange={this.changeDate(key, timeFormat)}
                            error={!!error['send_at']}
                            helperText={error['send_at'] ? error['send_at'] : ''}
                        />
                        <TextField
                            label={"Treść powiadomienia nr " + (number++)}
                            multiline
                            margin="normal"
                            className={classes.textField}
                            style={{width: 580}}
                            onChange={this.changeContent(key)}
                            value={item['content'] ? item['content'] : ""}
                            error={!!error['content']}
                            helperText={error['content'] ? error['content'] : ''}
                        />
                    </ListItem>
                })}

                <ListItem dense style={{ marginTop: 17 }} className={classes.listItem} key="add">
                    <FabButton title="Dodaj" onClick={this.addItem} color="primary" className={classes.smallButton}>
                        <AddIcon className={classes.smallIcon}/>
                    </FabButton>
                </ListItem>
            </List>
        </>);
    }
}

PollNotifications.propTypes = {
    valuesKey: PropTypes.string,
    timeFormat: PropTypes.string,
    notifications: PropTypes.array.isRequired,
    changeHandler: PropTypes.func.isRequired,
    errors: PropTypes.array,
};
PollNotifications.defaultProps = {
    valuesKey: 'notifications',
    timeFormat: 'yyyy-MM-dd HH:mm',
    notifications: [],
    errors: [],
};

const styles = theme => ({
    listItem: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    textField: {
        marginTop: 0,
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(),
    },
    smallButton: {
        width: 20,
        height: 20,
        minWidth: 0,
        minHeight: 0,
        marginRight: 0,
    },
    smallIcon: {
        width: 20,
        height: 20,
    },
});

export default withStyles(styles)(withRouter(PollNotifications));
