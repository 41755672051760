import dateFnsFormat from 'date-fns/format'
import { pl as dateFnsLocalePl } from 'date-fns/locale'
import jexcel from 'jexcel';
import User from "../auth/User";

export function formatDate(format, date = new Date()) {
    if (typeof date === 'string') {
        if (!+new Date(date)) {
            date = null;
        }
        date = new Date(date);
    }
    return dateFnsFormat(date, format, { locale: dateFnsLocalePl, weekStartsOn: 1 });
}

export function clone(input, emptyValue = {}) {
    switch (input) {
        case undefined:
        case null:
            return emptyValue;
        default:
            return JSON.parse(JSON.stringify(input)); // safe deep copy
    }
}

export function areObjectsEqual(obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2)
}

export function dotGet(obj, attributePath) {
    attributePath = attributePath ? attributePath + '' : '';
    return attributePath.split('.').reduce((a, b) => a ? a[b] : undefined, obj);
}

export function dotSet(obj, attributePath, value) {
    let tmpObj = obj;
    attributePath = attributePath ? attributePath + '' : '';
    if (attributePath) {
        let tags = attributePath.split('.'), len = tags.length - 1;
        for (let i = 0; i < len; i++) {
            if (tmpObj[tags[i]] === undefined) {
                tmpObj[tags[i]] = {};
            } else if (typeof tmpObj[tags[i]] === 'string') {
                tmpObj[tags[i]] = {};
            }
            tmpObj = tmpObj[tags[i]];
        }
        tmpObj[tags[len]] = value;
    }

    return obj;
}

export function getItemFromArrayByAttribute(array, value, attributePath = 'id') {
    return array.find(object => dotGet(object, attributePath) === value);
}

export function getItemIndexFromArrayByAttribute(array, value, attributePath = 'id') {
    return array.findIndex(object => dotGet(object, attributePath) === value);
}

export function getObjFromArrayByAttribute(array, value, attributePath = 'id') {
    return getItemFromArrayByAttribute(array, value, attributePath) || {};
}

export function getItemsFromArrayByAttribute(array, values, attributePath = 'id') {
    if (Array.isArray(values)) {
        return array.filter(object => values.includes(dotGet(object, attributePath)));
    } else {
        return array.filter(object => dotGet(object, attributePath) === values);
    }
}

export function getObjCopyFromArrayByAttribute(array, value, attributePath = 'id') {
    return clone(getObjFromArrayByAttribute(array, value, attributePath));
}

export function removeItemsFromArrayByAttribute(array, values, attributePath = 'id') {
    if (Array.isArray(values)) {
        return array.filter(object => !values.includes(dotGet(object, attributePath)));
    } else {
        return array.filter(object => dotGet(object, attributePath) !== values);
    }
}

export function getObjValuesFromArrayByAttribute(array, attributePath = 'id') {
    return array.map(object => dotGet(object, attributePath));
}

export function arrayUnique(array, attributePath = 'id') {
    return array.filter((object, index, self) => getItemIndexFromArrayByAttribute(self, dotGet(object, attributePath), attributePath) === index);
}

export function arraySortByAttribute(array, attributePath = 'position') {
    return array.sort((a, b) => dotGet(a, attributePath) - dotGet(b, attributePath));
}

export function arrayMove(array, from, to) {
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);

    return array;
}

export function arrayMoveUp(array, index) {
    arrayMove(array, index, index - 1);

    return array;
}

export function arrayMoveDown(array, index) {
    arrayMove(array, index, index + 1);

    return array;
}

export function arrayRemove(array, index) {
    array.splice(index, 1);

    return array;
}

export function arrayInsert(array, index, items) {
    items = Array.isArray(items) ? items : [items];
    array.splice(index, 0, ...items);

    return array;
}

export function arraySum(array, attributePath = null) {
    if(attributePath){
        array = getObjValuesFromArrayByAttribute(array, attributePath);
    }

    return array.reduce((a, b) => a + b, 0);
}

export function arrayChangeAttributes(array, attributePaths, value) {
    if(Array.isArray(attributePaths)){
        return array.map(object => {
            attributePaths.forEach(path => dotSet(object, path, value));
            return object;
        });
    } else {
        return array.map(object => dotSet(object, attributePaths, value));
    }
}

export function arrayNullifyAttributes(array, attributePath) {
    return arrayChangeAttributes(array, attributePath, null);
}

/**
 * Returns first non-empty value or null
 * @returns {null|any}
 */
export function coalesce() {
    let len = arguments.length;
    for (let i = 0; i < len; i++) {
        if (arguments[i] !== null && arguments[i] !== undefined) {
            return arguments[i];
        }
    }
    return null;
}

/**
 * Alaways returns string
 * @returns {string}
 */
export function coalesceString() {
    let v = coalesce(...arguments);

    return v === null ? '' : v + '';
}

/**
 * @param func
 * @param wait
 * @param immediate     If true, trigger the function on the leading edge, instead of the trailing
 * @returns {Function}
 */
export function debounce(func, wait = 300, immediate = false) {
    let timeout;
    return function () {
        if (!User.isLoggedIn()) return;
        let context = this, args = arguments;
        let later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        let callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

export function range(start, end) {
    const length = 1 + end - start;
    return Array.from({ length }, (_, i) => start + i);
}

export function queryStringify(obj, prefix) {
    let pairs = [];
    for (let key in obj) {
        if (!obj.hasOwnProperty(key)) {
            continue
        }

        let value = obj[key];
        let enkey = encodeURIComponent(key);
        let pair;
        if (typeof value === 'object') {
            pair = queryStringify(value, prefix ? prefix + '[' + enkey + ']' : enkey);
        } else {
            pair = (prefix ? prefix + '[' + enkey + ']' : enkey) + '=' + encodeURIComponent(value === undefined || value === null ? '' : value);
        }
        pairs.push(pair);
    }
    return pairs.join('&')
}

export function getSelectOptions(object) {
    return Object.entries(object).map(([type, label]) => ({ value: type, text: label }))
}

export function hashCode(data) {
    data = JSON.stringify(data);
    let hash = 0;
    if (data.length === 0) return hash;
    for (let i = 0; i < data.length; i++) {
        let char = data.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
}

export function getCellsFromFormula(expression) {
    let tokensUpdate = (tokens) => {
        for (let index = 0; index < tokens.length; index++) {
            let f = [], x1, x2, y1, y2;
            let token = tokens[index].split(':');
            let e1 = jexcel.getIdFromColumnName(token[0], true);
            let e2 = jexcel.getIdFromColumnName(token[1], true);

            if (e1[0] <= e2[0]) {
                x1 = e1[0];
                x2 = e2[0];
            } else {
                x1 = e2[0];
                x2 = e1[0];
            }

            if (e1[1] <= e2[1]) {
                y1 = e1[1];
                y2 = e2[1];
            } else {
                y1 = e2[1];
                y2 = e1[1];
            }

            for (let j = y1; j <= y2; j++) {
                for (let i = x1; i <= x2; i++) {
                    f.push(jexcel.getColumnNameFromId([i, j]));
                }
            }

            expression = expression.replace(tokens[index], f.join(','));
        }
    };

    let tokens = expression.match(/([A-Z]+[0-9]+):([A-Z]+[0-9]+)/g);
    if (tokens && tokens.length) {
        tokensUpdate(tokens);
    }

    return expression.match(/([A-Z]+[0-9]+)/g);
}

export function isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}
