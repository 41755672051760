import PropTypes from "prop-types";
import QuestionTableCreator from "./QuestionTableCreator";
import jexcel from 'jexcel';

class TableCellPicker extends QuestionTableCreator {
    historyActive = false;

    constructor(props) {
        super(props);

        this.options.allowInsertRow = false;
        this.options.allowManualInsertRow = false;
        this.options.allowDeleteRow = false;
        this.options.onselection = this.selectionHandler;
    }

    renderToolbar = () => {
        return ''
    };

    renderDialogs = () => {
        return '';
    };

    selectionHandler = (instance, x1, y1, x2, y2) => {
        if (this.jexcel == null) {
            return;
        }

        const { meta } = this.jexcel.getConfig();
        if (this.props.multiple) {
            let cells = [], cellName;
            for (let x = x1; x <= x2; x++) {
                for (let y = y1; y <= y2; y++) {
                    cellName = jexcel.getColumnNameFromId([x, y]);
                    cells.push({ name: cellName, x, y, label: meta[cellName].label, meta: meta[cellName] });
                }
            }
            this.props.changeHandler(cells);
        } else {
            const cellName = jexcel.getColumnNameFromId([x1, y1]);
            this.props.changeHandler(cellName, x1, y1, meta[cellName]);
        }
    };
}

TableCellPicker.propTypes = {
    table: PropTypes.object.isRequired,
    changeHandler: PropTypes.func.isRequired,
    multiple: PropTypes.bool,
};
TableCellPicker.defaultProps = {
    table: {},
    changeHandler: () => {},
    isDynamic: false,
    multiple: false,
};

export default TableCellPicker;
