const File = {
    allowedMime: [
        'image/gif', 'image/png', 'image/jpeg', 'image/tiff', // images
        'application/msword', // doc
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
        'application/vnd.ms-excel', // xls
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
        'application/vnd.ms-powerpoint', // ppt
        'application/vnd.openxmlformats-officedocument.presentationml.presentation', // pptx
        'application/vnd.oasis.opendocument.text', // odt
        'application/vnd.oasis.opendocument.spreadsheet', // ods
        'application/vnd.oasis.opendocument.presentation', // odp
        'application/pdf', // pdf
        'application/xml', 'text/xml', // xml
        'text/csv', // csv
        'text/plain',
        'application/zip', // zip
        'application/x-7z-compressed', // 7zip
    ],
    maxFileSize: 1024 * 1024 * 1024,
};

export default File
