import React from 'react';
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import TooltipIconButton from "../shared/TooltipIconButton";
import Axios from "axios";
import { Badge } from "@material-ui/core";
import NotificationsIcon from '@material-ui/icons/Notifications';
import Drawer from "@material-ui/core/Drawer";
import { debounce } from "../shared/Helpers";
import UserNotifications from "./UserNotifications";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import BaseComponent from "../shared/BaseComponent";

class NotificationsMenu extends BaseComponent {
    static permissions = ['user-notifications'];
    url = '/notifications/count';

    state = {
        notificationsCount: 0,
        drawerOpen: false,
    };

    constructor(props) {
        super(props);

        this.checkNotifications();
    }

    toggleDrawer = (drawerOpen) => event => {
        this.setState({ drawerOpen })
    };

    clickHandler = () => {
        this.toggleDrawer(false)();
        this.checkNotifications();
    };

    countHandler = count => {
        this.setState({ notificationsCount: count })
    };

    checkNotificationsBouncer = debounce(() => {
        this.checkNotifications();
    }, 60000);

    cancelNotificationsRequest = () => {};
    checkNotifications = () => {
        this.cancelNotificationsRequest();
        Axios.get(this.url, {
            timeout: 60000,
            cancelToken: new Axios.CancelToken((c) => {
                this.cancelNotificationsRequest = c;
            }),
        }).then((response) => {
            const { count } = response.data;

            if (this.state.notificationsCount !== count) {
                this.setState({ notificationsCount: count }, () => this.checkNotificationsBouncer());
            } else {
                this.checkNotificationsBouncer();
            }
        }).catch((error) => {
            if (error && error.response) {
                // TODO: handle more errors
                switch (error && error.response.status) {
                    case 401: // logged out
                        break;
                    default:
                        this.checkNotificationsBouncer();
                }
            } else {
                this.checkNotificationsBouncer();
            }
        });
    };

    render() {
        const { classes } = this.props;

        return <>
            <TooltipIconButton title="Powiadomienia" color="inherit" onClick={this.toggleDrawer(true)}>
                <Badge overlap="rectangle" badgeContent={this.state.notificationsCount} color="secondary">
                    <NotificationsIcon/>
                </Badge>
            </TooltipIconButton>

            <Drawer anchor="right" classes={{ paper: classes.drawerPaper }} open={this.state.drawerOpen} onClose={this.toggleDrawer(false)}>
                <Typography variant="h6" align="center" className={classes.title}>Powiadomienia</Typography>
                <Divider/>
                <UserNotifications onClick={this.clickHandler} countHandler={this.countHandler}/>
                <Divider/>
                <Typography variant="subtitle1" align="center" className={classes.title}>
                    <Link to="/notifications/list" onClick={this.toggleDrawer(false)}>Zobacz wszystkie</Link>
                </Typography>
            </Drawer>
        </>;
    }
}

const styles = theme => ({
    title: {
        padding: theme.spacing(),
    },
    drawerPaper: {
        width: 400,
    },
});

export default withStyles(styles)(NotificationsMenu);
