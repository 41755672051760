import React from 'react';
import { withRouter } from "react-router";
import DataComponent from "../shared/DataComponent";
import DataTable from "../shared/DataTable";

import AddIcon from '@material-ui/icons/Add';
import User from "../auth/User";
import FabContainer from "../shared/FabContainer";
import { FabLink } from "../shared/FabButton";

class ProjectList extends DataComponent {
    static permissions = ['view-projects'];
    url = '/projects';
    tableColumns = {
        id: 'ID',
        name: 'Nazwa',
        'owner.full_name': 'Właściciel',
        created_at: 'Data utworzenia',
    };

    constructor(props) {
        super(props);

        this.addDeletedAtColumn();
    }

    render() {
        if (this.state.isLoading) {
            return this.showLoader();
        }

        return <>
            <DataTable
                storageKey={this.constructor.name}
                title="Lista projektów"
                columns={this.getTableColumns()}
                data={this.loadTableData(this.url)}
                showLink={User.hasPermissionTo('view-polls') ? (rowData) => '/projects/' + rowData.id + '/polls' : null}
                editLink={User.hasPermissionTo('edit-projects') ? (rowData) => '/projects/' + rowData.id + '/edit' : null}
            />

            <FabContainer>
                {User.hasPermissionTo('edit-projects') && (
                    <FabLink title="Dodaj projekt" to="/projects/add" color="primary"><AddIcon/></FabLink>
                )}
            </FabContainer>
        </>
    }
}

export default withRouter(ProjectList);
