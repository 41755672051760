import React from 'react';
import DataComponent from "../shared/DataComponent";
import PropTypes from "prop-types";
import DataTable from "../shared/DataTable";
import MuseumPoll from "../../models/MuseumPoll";

class MuseumTabProjects extends DataComponent {
    museum_id = this.props.museum_id;
    url = '/museums/' + this.museum_id + '/projects';

    tableColumns = {
        // 'museum.id': 'ID Muzeum',
        // 'museum.museum_name': 'Nazwa instytucji',
        'museum.branch_name': 'Nazwa oddziału',
        'poll.id': 'ID ankiety',
        'poll.name': 'Nazwa',
        'poll.project.name': 'Projekt',
        // 'poll.type_string': { field: 'type', title: 'Rodzaj', lookup: Poll.types },
        state_string: { field: 'state', title: 'Status', lookup: MuseumPoll.states },
        'poll.year': 'Rok',
    };

    actions = [];

    render() {
        if (this.state.isLoading) {
            return this.showLoader();
        }

        return (
            <DataTable
                tableRef={this.tableRef}
                storageKey={this.constructor.name}
                title={"Lista ankiet, w których muzeum brało udział"}
                columns={this.getTableColumns()}
                data={this.state.data.museumPolls}
                showLink={(rowData) => '/museum-polls/' + rowData.id}
            />
        );
    }
}

MuseumTabProjects.propTypes = {
    museum_id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]).isRequired,
};

export default MuseumTabProjects;
