import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';

class ReadMore extends PureComponent {
    state = {
        clamped: null,
        clampedHtml: '',
        expanded: false,
    };

    toggle = (event) => {
        event.preventDefault();
        this.setState({ expanded: !this.state.expanded });
    }

    handleReflow = (state) => {
        // const { clamped, html } = state;
        const { html } = state;
        if (!this.props.fixed) {
            this.setState({ clamped: this.htmlEllipsis.isClamped(), clampedHtml: html });
        }
    }

    render() {
        const { html, ellipsis, className, handleClassName, lines, basedOn, more, less, fixed } = this.props;
        const { clamped, clampedHtml, expanded } = this.state;

        return <div className={className}>
            {clamped === null ? (
                <HTMLEllipsis
                    ref={node => this.htmlEllipsis = node}
                    unsafeHTML={html}
                    // ellipsis={ellipsis}
                    // className={className}
                    maxLine={lines}
                    ellipsisHTML={ellipsis + (fixed ? '' : ' <a href="#" onclick="return false;">' + more + '</a>')}
                    basedOn={basedOn}
                    onReflow={this.handleReflow}
                />
            ) : (clamped ? (expanded ? (
                    <>
                        <div dangerouslySetInnerHTML={{ __html: html }}/>
                        <a href="#" onClick={this.toggle} className={handleClassName}>{less}</a>
                    </>
                ) : (
                    <div onClick={this.toggle} dangerouslySetInnerHTML={{ __html: clampedHtml }}/>
                )
            ) : (
                <div dangerouslySetInnerHTML={{ __html: clampedHtml }}/>
            ))}
        </div>
    }
}

ReadMore.propTypes = {
    html: PropTypes.node.isRequired,
    className: PropTypes.string,
    handleClassName: PropTypes.string,
    lines: PropTypes.number,
    ellipsis: PropTypes.string,
    basedOn: PropTypes.oneOf(['words', 'letters']),
    more: PropTypes.string,
    less: PropTypes.string,
    fixed: PropTypes.bool,
};

ReadMore.defaultProps = {
    lines: 1,
    ellipsis: '...',
    basedOn: 'letters',
    handleClassName: 'handle',
    more: 'rozwiń',
    less: 'zwiń',
    fixed: false,
};

export default ReadMore;
