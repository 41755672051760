import decode from "jwt-decode";
import Axios from 'axios';

const User = {
    logIn: (email, password) => {
        return Axios.post('/login', {
            email: email,
            password: password
        }).then(response => {
            User.setToken(response.data.token);

            return Promise.resolve(response);
        });
    },

    isLoggedIn: () => {
        return !!User.getToken() && !!User.getDecodedToken();
    },

    isSuperadmin: () => {
        return User.hasPermissionTo('superadmin');
    },

    hasPermissionTo: (permission) => {
        const permissions = User.getProfile('permissions') || [];
        let exactPermission = false;
        permission += ''; // make sure we're dealing with a string

        if (permission.charAt(0) === '!') {
            permission = permission.substring(1);
            exactPermission = true;
        }

        if (!exactPermission && permissions.includes('superadmin')) {
            return true;
        }

        return permissions.includes(permission);
    },

    hasAnyPermission: (...permissions) => {
        if (Array.isArray(permissions[0])) {
            permissions = permissions[0];
        }

        try {
            permissions.forEach(p => {
                if (User.hasPermissionTo(p)) {
                    throw new Error('Allowed');
                }
            });
        } catch (e) {
            return true;
        }

        return false;
    },

    hasAllPermissions: (...permissions) => {
        if (Array.isArray(permissions[0])) {
            permissions = permissions[0];
        }

        try {
            permissions.forEach(p => {
                if (!User.hasPermissionTo(p)) {
                    throw new Error('Denied');
                }
            });
        } catch (e) {
            return false;
        }

        return true;
    },

    setToken: (idToken) => {
        localStorage.setItem('id_token', idToken);
    },

    getToken: () => {
        return localStorage.getItem('id_token');
    },

    decodedToken: null,
    getDecodedToken: () => {
        if (User.decodedToken === null) {
            let decoded;
            try {
                decoded = decode(User.getToken());
                if (!decoded || !decoded.exp || decoded.exp < Date.now() / 1000) {
                    throw new Error('Token expired');
                }
                User.decodedToken = decoded;
            } catch (e) {
                User.logOut();
                return {};
            }
        }

        return User.decodedToken;
    },

    clearDecodedToken: () => {
        User.decodedToken = null;
    },

    logOut: () => {
        Axios.get('/logout', {
            headers: { Authorization: `Bearer ${User.getToken()}` }
        }).then((response) => {
            // if(typeof response.data.token !== 'undefined'){
            //         User.setToken(response.data.token); // invalidate token?
            // }
        });

        // localStorage.removeItem('id_token');
        localStorage.clear();
        User.clearDecodedToken();
    },

    getProfile: (value) => {
        const user = User.getDecodedToken().user;
        if (value && user) {
            return user[value];
        }

        return user;
    },

    getUserName: () => {
        return User.getProfile('name');
    },

    getUserId: () => {
        return User.getProfile('id');
    },

    getMuseumId: () => {
        return User.getProfile('museum_id');
    },

    getRememberToken: () => {
        return User.getProfile('remember_token');
    },

    hasRememberToken: () => {
        return !!User.getRememberToken();
    },
};

export default User;
