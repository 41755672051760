import React from 'react';
import PropTypes from "prop-types";
import Question from "../../models/Question";
import TablePicker from "../shared/TablePicker";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

class PollAggregateQuestionPicker extends TablePicker {
    tableColumns = {
        id: 'ID',
        name_string: 'Pytanie',
        is_active_string: { field: 'is_active', title: 'Aktywne?', lookup: { 0: 'Nie', 1: 'Tak' } },
        is_public_string: { field: 'is_public', title: 'Publiczne?', lookup: { 0: 'Nie', 1: 'Tak' } },
        type_string: { field: 'type', title: 'Typ', lookup: Question.types },
        is_imported: { field: 'is_imported', title: 'Z importu?', lookup: { 0: 'Nie', 1: 'Tak' } },
        'catalog.name': 'Katalog',
    };
    dialogTitle = this.props.dialogTitle;
    tableTitle = this.props.tableTitle;

    constructor(props) {
        super(props);

        if (!props.dialogTitle) {
            switch (props.type) {
                case 'lt':
                    this.dialogTitle = 'Pytania których suma wartości musi być mniejsza lub równa';
                    break;
                case 'eq':
                    this.dialogTitle = 'Pytania których suma wartości musi być równa';
                    break;
                case 'gt':
                    this.dialogTitle = 'Pytania których suma wartości musi być większa lub równa';
                    break;
            }
        }

        props.questions.map(v => {
            if (((props.aggregates ? props.aggregates[props.type] : []) || []).includes(v.id)) {
                this.state.values.push(v);
                if (!v.tableData) {
                    v.tableData = {}; // add tableData
                }
                v.tableData.checked = true;
            }

            return v;
        });
    }

    loadTableData = () => {
        return this.props.questions;
    };

    renderTableDialogActions = () => {
        return <DialogActions>
            <Button onClick={this.changeHandler(false)} variant="contained">Anuluj</Button>
            <Button onClick={this.changeHandler(true)} color="primary" variant="contained">Zastosuj</Button>
        </DialogActions>
    };

    changeHandler = (save) => () => {
        let values = this.getValues();

        this.props.changeHandler(save ? values : false);
        this.tableRef.current.onAllSelected(false);
    };
}

PollAggregateQuestionPicker.propTypes = {
    dialogTitle: PropTypes.string,
    tableTitle: PropTypes.string,
    changeHandler: PropTypes.func.isRequired,
    aggregates: PropTypes.array,
    questions: PropTypes.any.isRequired,
    type: PropTypes.oneOf(['lt', 'eq', 'gt']),
};
PollAggregateQuestionPicker.defaultProps = {
    tableTitle: 'Dostępne pytania',
    aggregates: [],
};

export default PollAggregateQuestionPicker;
