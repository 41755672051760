import React from 'react';
import PropTypes from "prop-types";
import Axios from 'axios';
import DataComponent from "../shared/DataComponent";
import PaperRoot from "../shared/PaperRoot";

import {
    Dialog,
    DialogContentText,
    DialogActions,
    Divider,
    Grid,
    ListItem,
    List,
    ListItemText,
    Button,
    Typography,
    withStyles,
    ExpansionPanelDetails, ListItemSecondaryAction, ListSubheader,
} from "@material-ui/core";
import { DialogTitle, DialogContent } from "../shared/Dialog";

import { DropzoneArea } from 'material-ui-dropzone'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DownloadFileIcon from '@material-ui/icons/CloudDownload';
import EditIcon from '@material-ui/icons/Edit';

import TooltipIconButton from "../shared/TooltipIconButton";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary/ExpansionPanelSummary";
import File from "../../models/File";
import User from "../auth/User";
import ConfirmDialog from "../shared/ConfirmDialog";
import NCForm from "../nc-form/NCForm";

class MuseumTabFiles extends DataComponent {
    museum_id = this.props.museum_id;
    url = '/museums/' + this.museum_id + '/files';
    dropzoneRef = {
        'docs': React.createRef(),
        'other': React.createRef(),
        'nimoz': React.createRef(),
    };

    constructor(props) {
        super(props);

        this.state.filesToUpload = [];
        this.state.file = false;
        this.state.noteDialog = false;
    }

    deleteFile = () => {
        Axios.delete(this.url, { data: { file_id: this.state.file.id } }).then((returnData) => {
            this.setState({ data: returnData.data, deleteDialog: false, file: false });
        }).catch(function () {
            alert('Błąd podczas usuwania plików.');
        });
    };

    openDeleteFileDialog = file => event => {
        this.setState({ deleteDialog: true, file });
    };

    openNoteDialog = file => event => {
        this.setState({ noteDialog: true, file, values: { note: file.note } });
    };

    closeDialogs = event => {
        this.setState({ deleteDialog: false, noteDialog: false, file: false });
    };

    saveNote = () => {
        const { file, values } = this.state;
        Axios.put(this.url + '/' + file.id, { note: values.note }).then(response => {
            file.note = values.note;
            this.setState({ noteDialog: false, file: false, values: {} });
        }).catch(this.catchErrors);
    };

    downloadFile = fileId => event => {
        Axios.get(this.url + '/download/' + fileId).then(response => {
            let form = document.createElement('form');
            form.method = 'post';
            form.action = process.env.REACT_APP_SERVER_URL + this.url + '/download/' + fileId;
            let input = document.createElement('input');
            input.name = 'token';
            input.value = localStorage.getItem('id_token');
            form.appendChild(input);
            document.body.appendChild(form);
            form.submit();
            form.remove();
        });
    };

    handleFilesChange = (files) => {
        this.setState({ filesToUpload: files });
    };

    uploadHandler = (category) => () => {
        let formData = new FormData();

        this.state.filesToUpload.forEach((file, key) => {
            formData.append('file' + key, file);
        });
        formData.append('category', category);

        Axios.post(this.url, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        }).then((response) => {
            this.dropzoneRef[category].current.setState({ fileObjects: [] });
            this.setState({ data: response.data, filesToUpload: [] });
        }).catch(function () {
            alert('Błąd podczas wysyłania plików.');
        });
    };

    render() {
        if (this.state.isLoading) {
            return this.showLoader();
        }
        const { classes } = this.props;
        const { data, deleteDialog, noteDialog, filesToUpload, file } = this.state;

        return (
            <PaperRoot>
                {User.hasPermissionTo('change-museum-internal-data') && (
                    <ExpansionPanel defaultExpanded className={classes.panel}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography>Pliki NIMOZ</Typography>
                            <Divider variant="fullWidth"/>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Grid container spacing={4}>
                                <Grid item xs={6}>
                                    <List>
                                        {data.nimoz_files.map((file, k) => <React.Fragment key={k}>
                                            <ListItem className={classes.listItem}>
                                                <ListItemIcon>
                                                    <TooltipIconButton title="Pobierz" onClick={this.downloadFile(file.id)}>
                                                        <DownloadFileIcon/>
                                                    </TooltipIconButton>
                                                </ListItemIcon>

                                                <ListItemText primary={file.originalName} secondary={file.size_string}/>
                                                <ListItemText secondary={file.created_at} secondaryTypographyProps={{ align: 'right' }}/>

                                                <ListItemSecondaryAction className={classes.listAction}>
                                                    <TooltipIconButton title="Notatka" size="small" edge="end"
                                                                       onClick={this.openNoteDialog(file)}>
                                                        <EditIcon color="primary"/>
                                                    </TooltipIconButton>
                                                    <TooltipIconButton title="Usuń" size="small" edge="end"
                                                                       onClick={this.openDeleteFileDialog(file)}>
                                                        <DeleteIcon color="secondary"/>
                                                    </TooltipIconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                            {!!file.note && (
                                                <ListItem className={classes.note}>
                                                    <Typography color="textSecondary">{file.note}</Typography>
                                                </ListItem>
                                            )}
                                            <Divider variant="fullWidth" component="li"/>
                                        </React.Fragment>)}
                                    </List>
                                </Grid>
                                <Grid item xs={6}>
                                    <DropzoneArea
                                        onClick={() => alert('XXX')}
                                        ref={this.dropzoneRef['nimoz']}
                                        onChange={this.handleFilesChange}
                                        dropzoneText="Kliknij lub przeciągnij pliki tutaj"
                                        maxFileSize={File.maxFileSize}
                                        showFileNamesInPreview={true}
                                        showFileNames={true}
                                        dropzoneClass={classes.dropZone}
                                        dropzoneParagraphClass={classes.dropZoneParagraph}
                                        acceptedFiles={File.allowedMime}
                                        getFileLimitExceedMessage={filesLimit => `Maksymalnie ${filesLimit} pliki na raz`}
                                        getFileAddedMessage={fileName => `Plik "${fileName}" został dodany`}
                                        getFileRemovedMessage={fileName => `Plik "${fileName}" został usunięty`}
                                        getDropRejectMessage={(rejectedFile, acceptedFiles, maxFileSize) => `Plik "${rejectedFile.name}" nie może być dodany`}
                                    />
                                    <Button variant="contained" color="primary" style={{ marginTop: 20 }} disabled={!filesToUpload.length}
                                            onClick={this.uploadHandler('nimoz')}>Zapisz pliki</Button>
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                )}

                <ExpansionPanel defaultExpanded className={classes.panel}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                        <Typography>Dokumenty organizacyjne</Typography>
                        <Divider variant="fullWidth"/>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container spacing={4}>
                            <Grid item xs={6}>
                                <List>
                                    {data.docs_files.map((file, k) => <React.Fragment key={k}>
                                        <ListItem className={classes.listItem}>
                                            <ListItemIcon>
                                                <TooltipIconButton title="Pobierz" onClick={this.downloadFile(file.id)}>
                                                    <DownloadFileIcon/>
                                                </TooltipIconButton>
                                            </ListItemIcon>

                                            <ListItemText primary={file.originalName} secondary={file.size_string}/>
                                            <ListItemText secondary={file.created_at} secondaryTypographyProps={{ align: 'right' }}/>

                                            <ListItemSecondaryAction className={classes.listAction}>
                                                <TooltipIconButton title="Notatka" size="small" edge="end"
                                                                   onClick={this.openNoteDialog(file)}>
                                                    <EditIcon color="primary"/>
                                                </TooltipIconButton>
                                                <TooltipIconButton title="Usuń" size="small" edge="end"
                                                                   onClick={this.openDeleteFileDialog(file)}>
                                                    <DeleteIcon color="secondary"/>
                                                </TooltipIconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        {!!file.note && (
                                            <ListItem className={classes.note}>
                                                <Typography color="textSecondary">{file.note}</Typography>
                                            </ListItem>
                                        )}
                                        <Divider variant="fullWidth" component="li"/>
                                    </React.Fragment>)}
                                </List>
                            </Grid>
                            <Grid item xs={6}>
                                <DropzoneArea
                                    onClick={() => alert('XXX')}
                                    ref={this.dropzoneRef['docs']}
                                    onChange={this.handleFilesChange}
                                    dropzoneText="Kliknij lub przeciągnij pliki tutaj"
                                    maxFileSize={File.maxFileSize}
                                    showFileNamesInPreview={true}
                                    showFileNames={true}
                                    dropzoneClass={classes.dropZone}
                                    dropzoneParagraphClass={classes.dropZoneParagraph}
                                    acceptedFiles={File.allowedMime}
                                    getFileLimitExceedMessage={filesLimit => `Maksymalnie ${filesLimit} pliki na raz`}
                                    getFileAddedMessage={fileName => `Plik "${fileName}" został dodany`}
                                    getFileRemovedMessage={fileName => `Plik "${fileName}" został usunięty`}
                                    getDropRejectMessage={(rejectedFile, acceptedFiles, maxFileSize) => `Plik "${rejectedFile.name}" nie może być dodany`}
                                />
                                <Button variant="contained" color="primary" style={{ marginTop: 20 }} disabled={!filesToUpload.length}
                                        onClick={this.uploadHandler('docs')}>Zapisz pliki</Button>
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel defaultExpanded className={classes.panel}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                        <Typography>Inne</Typography>
                        <Divider variant="fullWidth"/>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container spacing={4}>
                            <Grid item xs={6}>
                                <List>
                                    {data.other_files.map((file, k) => <React.Fragment key={k}>
                                        <ListItem className={classes.listItem}>
                                            <ListItemIcon>
                                                <TooltipIconButton title="Pobierz" onClick={this.downloadFile(file.id)}>
                                                    <DownloadFileIcon/>
                                                </TooltipIconButton>
                                            </ListItemIcon>

                                            <ListItemText primary={file.originalName} secondary={file.size_string}/>
                                            <ListItemText secondary={file.created_at} secondaryTypographyProps={{ align: 'right' }}/>

                                            <ListItemSecondaryAction className={classes.listAction}>
                                                <TooltipIconButton title="Notatka" size="small" edge="end"
                                                                   onClick={this.openNoteDialog(file)}>
                                                    <EditIcon color="primary"/>
                                                </TooltipIconButton>
                                                <TooltipIconButton title="Usuń" size="small" edge="end"
                                                                   onClick={this.openDeleteFileDialog(file)}>
                                                    <DeleteIcon color="secondary"/>
                                                </TooltipIconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        {!!file.note && (
                                            <ListItem className={classes.note}>
                                                <Typography color="textSecondary">{file.note}</Typography>
                                            </ListItem>
                                        )}
                                        <Divider variant="fullWidth" component="li"/>
                                    </React.Fragment>)}
                                </List>
                            </Grid>
                            <Grid item xs={6}>
                                <DropzoneArea
                                    onClick={() => alert('XXX')}
                                    ref={this.dropzoneRef['other']}
                                    onChange={this.handleFilesChange}
                                    dropzoneText="Kliknij lub przeciągnij pliki tutaj"
                                    maxFileSize={File.maxFileSize}
                                    showFileNamesInPreview={true}
                                    showFileNames={true}
                                    dropzoneClass={classes.dropZone}
                                    dropzoneParagraphClass={classes.dropZoneParagraph}
                                    acceptedFiles={File.allowedMime}
                                    getFileLimitExceedMessage={filesLimit => `Maksymalnie ${filesLimit} pliki na raz`}
                                    getFileAddedMessage={fileName => `Plik "${fileName}" został dodany`}
                                    getFileRemovedMessage={fileName => `Plik "${fileName}" został usunięty`}
                                    getDropRejectMessage={(rejectedFile, acceptedFiles, maxFileSize) => `Plik "${rejectedFile.name}" nie może być dodany`}
                                />
                                <Button variant="contained" color="primary" style={{ marginTop: 20 }} disabled={!filesToUpload.length}
                                        onClick={this.uploadHandler('other')}>Zapisz pliki</Button>
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel defaultExpanded className={classes.panel}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                        <Typography>Pliki z wypełnionych ankiet</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container spacing={4} style={{ marginTop: 10 }}>
                            {!data.polls_answers_files.length && (
                                <Grid item xs={6}>
                                    <Typography>Nie dodano jeszcze żadnych plików</Typography>
                                </Grid>
                            )}
                            {data.polls_answers_files.map((museum_poll, k1) =>
                                <Grid item xs={6} key={k1}>
                                    <Typography>{museum_poll.poll.name}</Typography>
                                    {museum_poll.poll_questions.map((poll_question, k2) =>
                                        <List key={k2} subheader={<ListSubheader component="div">{poll_question.name}</ListSubheader>}>
                                            {poll_question.answer_files.map((file, k3) => <React.Fragment key={k3}>
                                                <ListItem className={classes.listItem}>
                                                    <ListItemIcon>
                                                        <TooltipIconButton title="Pobierz" onClick={this.downloadFile(file.id)}>
                                                            <DownloadFileIcon/>
                                                        </TooltipIconButton>
                                                    </ListItemIcon>

                                                    <ListItemText primary={file.originalName} secondary={file.size_string}/>
                                                    <ListItemText secondary={file.created_at} secondaryTypographyProps={{ align: 'right' }}/>

                                                    {/*<ListItemSecondaryAction>*/}
                                                    {/*    <TooltipIconButton title="Usuń" edge="end" onClick={this.openDeleteFileDialog(file)}>*/}
                                                    {/*        <DeleteIcon color="secondary"/>*/}
                                                    {/*    </TooltipIconButton>*/}
                                                    {/*</ListItemSecondaryAction>*/}
                                                </ListItem>
                                                <Divider variant="fullWidth" component="li"/>
                                            </React.Fragment>)}
                                        </List>
                                    )}
                                </Grid>
                            )}
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                {noteDialog && (
                    <ConfirmDialog
                        title="Edycja notatki"
                        open={true}
                        onClose={this.closeDialogs}
                        onConfirm={this.saveNote}
                        confirmTitle="Zapisz"
                        isContentText={false}
                    >
                        <NCForm
                            fields={[{ name: 'note', type: 'textarea', width: 500 }]}
                            title={"Notatka pliku: " + file.originalName}
                            values={this.getValues()}
                            errors={this.getErrors()}
                            onChange={this.changeHandler}
                            hideSubmitButton
                            // saveHandler={this.saveObject}
                        />
                    </ConfirmDialog>
                )}

                {deleteDialog && (
                    <Dialog aria-labelledby="simple-dialog-title" open={true} onClose={this.closeDialogs}>
                        <DialogTitle>Usuń plik</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Czy na pewno chcesz usunąć wybrany plik?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.closeDialogs} color="primary">
                                Anuluj
                            </Button>
                            <Button onClick={this.deleteFile} color="secondary">
                                Potwierdzam
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}
            </PaperRoot>
        );
    }
}

MuseumTabFiles.propTypes = {
    museum_id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]).isRequired,
};

const styles = theme => ({
    panel: {
        marginBottom: theme.spacing(2),
    },
    dropZone: {
        '& img': {
            height: 'auto',
        },
        '& .MuiGrid-container': {
            margin: 0,
            padding: theme.spacing(1),
            marginTop: -100,
            width: 'auto',
        },
        '& .MuiGrid-item': {
            margin: 0,
            padding: theme.spacing(1),
        },
    },
    dropZoneParagraph: {
        paddingTop: 0,
        paddingBottom: theme.spacing(3),
        fontSize: '1em',
    },
    listItem: {
        paddingLeft: 0,
        paddingRight: 60,
    },
    listAction: {
        right: 0,
    },
    note: {
        paddingTop: 0,
        marginTop: -theme.spacing(1),
    },
});

const ExpansionPanel = withStyles({
    root: {
        width: '100%',
    },
    expanded: {
        margin: 0
    },
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
    root: {
        minHeight: 0,
        '&$expanded': {
            minHeight: 0,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiExpansionPanelSummary);

export default withStyles(styles)(MuseumTabFiles);
